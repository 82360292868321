<template>
  <div id="System"
       class="system"
       :class="system.isFullSizeView ? 'full-size' : 'initial-size'"
  >
    <v-dialog />

    <MobileTutorial v-if="!settings.isTipsTourDone && !isShowLoading" />
    <SideBar ref="sideBar" />
    <DesktopUserPreferences v-if="market.markets.length > 0" />

    <div class="brand" @click="goToPage('Home')">
      <div class="logo" />
    </div>

    <MobileBottomNavigation />
    <MobileFiltersPreferences v-if="system.isUserPreferencesOpen" />
    <TimeBar />
    <Header />
    <Container v-if="marketDays[settings.dateOptions.day] && market.markets.length > 0" />
    <div v-else class="loading_inner">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>

    <PageLoading v-if="isShowLoading" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import device from '@/utils/device';
import { autoUpdate } from '@/utils/autoupdate';
import PageLoading from './system/PageLoading.vue';
import SideBar from './system/SideBar.vue';
import Header from './system/Header.vue';
import Container from './system/Container.vue';
import TimeBar from './system/TimeBar.vue';
import MobileBottomNavigation from './system/MobileBottomNavigation.vue';
import MobileFiltersPreferences from './system/MobileFiltersPreferences.vue';
import DesktopUserPreferences from './system/DesktopUserPreferences.vue';
import MobileTutorial from './system/MobileTutorial.vue';

moment.tz.setDefault('America/Sao_Paulo');
moment.locale('pt-br');

export default {
  components: {
    PageLoading,
    SideBar,
    Header,
    Container,
    TimeBar,
    MobileBottomNavigation,
    MobileFiltersPreferences,
    DesktopUserPreferences,
    MobileTutorial,
  },
  data() {
    return {
      allowedMainTvNetworkMoves: process.env.ALLOWED_MAIN_TV_NETWORKS_SETTINGS_PANEL_MOVE,
      didResize: false,
      lastProgramTimeout: null,
      lastProgramInterval: null,
      isShowLoading: null,
      innerWidth: 0,
    };
  },
  computed: {
    ...mapState(['globoSchedule', 'getGloboSchedulesMock', 'market', 'system', 'settings', 'user']),
    ...mapGetters(['isMobileView', 'marketDays']),
    marketsEnabled() {
      return this.settings.marketIdOrder.map(
        marketId => this.market.markets.find(market => market.id === marketId),
      ).filter(market => market instanceof Object);
    },
    marketsDefaultEnabled() {
      const { MARKETS_DEFAULT } = process.env;

      return MARKETS_DEFAULT.map(
        marketId => this.market.markets.find(market => market.id === marketId),
      ).filter(market => market instanceof Object);
    },
    tvNetworksAvailable() {
      return _.union(...this.marketsEnabled
        .map(({ tvNetworks }) => tvNetworks.map(({ id }) => id)));
    },
    tvNetworksEnabled() {
      return this.settings.tvNetworkIdOrder.filter(
        tvNetworkId => this.tvNetworksAvailable.indexOf(tvNetworkId) >= 0,
      );
    },
    tvNetworksDefaultEnabled() {
      const { TV_NETWORKS_DEFAULT } = process.env;
      return TV_NETWORKS_DEFAULT.filter(
        tvNetworkId => this.tvNetworksAvailable.indexOf(tvNetworkId) >= 0,
      );
    },
  },
  watch: {
    isMobileView(newValue, oldValue) {
      // when mobile is resized to desktop
      if (oldValue !== newValue) this.goToPage('Home');
    },
  },
  async beforeCreate() {
    try {
      await this.$store.dispatch('getMarkets');
      this.setupSystem();
    } catch (error) {
      this.$store.dispatch('showError', { clicked: true });
    }
  },
  mounted() {
    this.showLoading();
    this.innerWidth = window.innerWidth;
    window.addEventListener('resize', this.handleResize);
    this.unwatch = this.$store.watch(state => state.settings, async () => {
      this.$store.dispatch('getDays');
      this.$store.dispatch('updatePreferencesDB');
      await this.$store.dispatch('getLastProgram');
    }, { deep: true });
    this.checkAppVersion();
  },
  beforeDestroy() {
    this.unwatch();
    clearTimeout(this.lastProgramTimeout);
    clearInterval(this.lastProgramInterval);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    checkAppVersion() {
      device.app(() => {
        document.addEventListener(
          'deviceready',
          async () => {
            // start autoUpdate
            autoUpdate();
            // window.GlobostoreAutoUpdate.check('version', () => {});
          },
          false,
        );
      });
    },
    checkMarkets() {
      let marketIdsEnabled = this.marketsEnabled.map(({ id }) => id);
      const updateMarket = !_.isEqual(marketIdsEnabled, this.settings.marketIdOrder);
      if (updateMarket) {
        if (marketIdsEnabled.length === 0) {
          marketIdsEnabled = this.marketsDefaultEnabled.map(({ id }) => id);
          this.$store.dispatch('selectMarketIdOrder', marketIdsEnabled);
        }

        // Select the first market
        const defaultMarket = this.market.markets.find(
          ({ id }) => id === marketIdsEnabled[0],
        );
        this.$store.dispatch('selectMarket', defaultMarket);

        // If there is only one market we need select market view
        if (marketIdsEnabled.length === 1) {
          this.$store.dispatch('selectIsMarketView', true);
        }
      } else {
        const defaultMarket = this.market.markets.find(
          ({ id }) => id === this.settings.market.id,
        );

        if (!_.isEqual(defaultMarket, this.settings.market)) {
          this.$store.dispatch('selectMarket', defaultMarket);
        }
      }

      let { tvNetworksEnabled } = this;
      // Force Globo to be the first item, maybe we need to check the index
      if (!this.allowedMainTvNetworkMoves.includes(tvNetworksEnabled[0])) {
        const globoIndex = tvNetworksEnabled.findIndex(x => x === 'GLOBO');
        if (globoIndex !== -1) {
          tvNetworksEnabled.splice(globoIndex, 1);
        }
        tvNetworksEnabled.unshift('GLOBO');
      }
      if (!_.isEqual(tvNetworksEnabled, this.settings.tvNetworkIdOrder)
        || updateMarket) {
        if (tvNetworksEnabled.length < 2) {
          tvNetworksEnabled = this.tvNetworksDefaultEnabled;
        }
        this.$store.dispatch('selectTvNetworkIdOrder', tvNetworksEnabled);
        if (!tvNetworksEnabled.includes(this.settings.tvNetworkId)) {
          this.$store.dispatch('selectTvNetworkId', tvNetworksEnabled[0]);
        }
      }
    },
    async setupSystem() {
      if (this.market.markets.length === 0) {
        this.$store.dispatch('showError', {
          insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
          subtitle: 'Você ainda não possui restrições atribuídas ao seu usuário. Por favor, entre em contato com a área de Pesquisa para solicitar o acesso.',
          buttons: [{
            text: 'sair',
            click: () => (this.user.isSimulateUser ? this.exitSimulateUser() : this.logout()),
          }],
        });
      } else {
        this.checkMarkets(); // just for automatic select default markets and tvnetworks

        await this.$store.dispatch('getLastProgram');

        try {
          await this.$store.dispatch('getGloboSchedules');
          this.$store.dispatch('getDays');
          this.setupDays();
        } catch (error) {
          this.$store.dispatch('showError', { clicked: true });
        }
      }
    },
    async setupDays() {
      try {
        this.lastProgramInterval = setInterval(async () => {
          this.$store.dispatch('getLastProgram');
        }, 120000);
      } catch (error) {
        throw error;
      }
    },
    showLoading() {
      this.isShowLoading = true;
      setTimeout(() => {
        this.isShowLoading = false;
      }, 2000);
    },
    handleResize() {
      if (this.innerWidth !== window.innerWidth) {
        this.innerWidth = window.innerWidth;
        // The user maybe using Desktop and mobile view responsive
        const isMobileView = this.innerWidth < 600;
        this.$store.dispatch('setMobileView', isMobileView);
        this.showLoading();
      }
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    logout() {
      this.$store.dispatch('logout');
      this.$store.dispatch('hideError');
    },
    exitSimulateUser() {
      this.$store.dispatch('exitSimulateUser');
      this.$router.push({ name: 'Users' });
      this.$router.go();
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.system {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  background-color: $color-gray-back;

  .loading_inner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: rgba($color-gray-lighter, 0.8);
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 3;
    .spinner {
      position: relative;
      width: 40px;
      height: 40px;

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

  // Brand
  .brand {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 4;
    top: -4px;
    left: 16px;
    width: 88px;
    height: 88px;
    // background-color: $color-gray-lighter;
    // box-shadow: 0 8px 24px rgba($color-gray-darker, 0.16);
    // transition: box-shadow 0.8s ease-in-out;
    overflow: hidden;

    &::after {
      content: "";
      background-color: rgba($color-gray-light, 0.4);
      width: 2px;
      height: 16px;
    }

    // Logo
    .logo {
      @include backLogin($icon-primary);
      background-position: center;
      background-size: 64% auto;
      width: 100%;
      height: 100%;
      margin: 0;
      transition: all 0.4s ease-in-out;
      cursor: pointer;
      overflow: hidden;
    }
  }
}


// ------------------------------------
// Breakpoints
// ------------------------------------

        // First Break : 1140px
        @media (max-width: 1140px) {
          .system {
            .brand {
              padding: 0 8px 0 0;
              background-color: $color-gray-back;
              box-shadow: none;
              border-bottom: solid 1px rgba($color-gray-light, 0.4);
              display: none;

              &::after {
                display: none;
              }
            }
          }
        }

        // SMARTPHONES
        @media (max-width: 600px) {
          .system {
            position: relative;
            z-index: 0;

            .sidebar {
              display: none;
            }

            .brand {
              display: none;
            }
          }
        }

// ------------------------------------

</style>
