<template>
  <div class="toggle" :class="{ icons: isIcon, disabled }">
    <button type="button" class="btn ripple toggler"
            :class="{ enabled: activated }" @click="changeToogle()"
    >
      <i v-if="icon" class="icone material-icons tool" :data-tip="tip">
        {{ icon }}
      </i>
      {{ name }}
    </button>

    <div :class="{selected: activated}" />
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    tip: {
      type: String,
      default: null,
    },
    activated: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isIcon() {
      return this.icon;
    },
  },
  methods: {
    changeToogle() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss">

@import '@/assets/scss/variables.scss';

// Toggles
.toggle {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  height: 40px;
  border-radius: 4px;
  background-color: $color-gray-lighter;
  box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);

  .selected {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 108px;
    height: 100%;
    background-image: linear-gradient(-224deg, $color-secondary 0%, $color-primary 100%);
    border-radius: 4px;
  }

  .toggler {
    position: relative;
    z-index: 4;
    height: 100%;
    padding: 0 16px;
    border: none;
    border-radius: 4px;
    font-family: $primary-typo;
    font-size: 0.64em;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.16em;
    color: $color-gray-main;
    cursor: pointer;
    background-color: transparent;
    animation: fadeIn 0.8s ease-in-out;

    // Ripple Effect
    &.ripple {
      background-position: center;
      transition: color 0.4s ease-in-out, background 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out;

      &:hover {
        background: $color-gray-lighter radial-gradient(circle, transparent 1%, $color-gray-lighter 1%) center/15000%;
        box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
        color: $color-secondary;
      }

      &:active {
        background-color: rgba($color-gray-light, 0.4);
        background-size: 100%;
        transition: background 0s;
      }
    }

    &.enabled {
      // Ripple Effect
      &.ripple {
        background-position: center;
        color: $color-gray-lighter;
        transition: color 0.4s ease-in-out, background 0.4s ease-in-out, box-shadow 0.4s ease-in-out, opacity 0.4s ease-in-out;

        &:hover {
          background: rgba($color-gray-lighter, 0.16) radial-gradient(circle, transparent 1%, rgba($color-gray-lighter, 0.16) 1%) center/15000%;
          box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
          color: $color-gray-lighter;
        }

        &:active {
          background-color: rgba($color-gray-lighter, 0.4);
          background-size: 100%;
          transition: background 0s;
        }
      }
    }

    /*== start of code for tooltips ==*/
    .tool {
      position: relative;

      &::before,
      &::after {
        left: 50%;
        opacity: 0;
        position: absolute;
        z-index: -100;
      }

      &:hover::before,
      &:focus::before,
      &:hover::after,
      &:focus::after {
        animation-delay: 0.4s !important;
        animation: fadeInDown 0.4s ease-in-out forwards;
        z-index: 100;
      }

      // Balão
      &::after {
        content: attr(data-tip);
        background: rgba($color-gray-lighter, 0.56);
        top: 124%;
        padding: 8px;
        box-sizing: border-box;
        margin-left: -54px;
        width: 108px;
        font-family: $secondary-typo;
        font-size: 0.50em;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 0.04em;
        color: $color-secondary;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
        opacity: 0;
        pointer-events: none;
        animation: fadeOutUp 0.4s ease-in-out forwards;
      }

      &:hover::after,
      &:focus::after  {
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
      }
    }
  }

  // ICONS
  &.icons {
    margin-left: 16px;

    .toggler {
      padding: 0;
    }

    .toggler,
    .selected {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        font-size: 1.8em;
        padding: 8px 4px;
      }
    }
  }
  // ---------------------------

  // DISABLED
  &.disabled,
  .disabled {
    pointer-events: none;
    background-color: $color-gray-lighter;
    opacity: 0.4;
  }
  // ---------------------------

}
</style>
