// initial state
import moment from 'moment';
import 'moment-timezone';
import bffRealtime from '@/gateways/bff-realtime';

const state = {
  schedulesGroup: [],
};

// getters
const getters = {
  schedulesGroup: state => state.schedulesGroup,
};

// actions
const actions = {
  async getSchedulesGroup({
    commit, rootGetters, dispatch, rootState,
  }) {
    const { globoDate } = rootGetters;

    const startDate = globoDate.startsIn;
    const endDate = globoDate.endsIn || moment().utc().format();

    let marketSelectedId = rootState.settings.market.id;
    let marketSelectedName = rootState.settings.market.name;

    if (marketSelectedId === 'RPT'
     // || marketSelectedId === 'PNT'
      || marketSelectedId === 'RPP') {
      marketSelectedId = rootGetters.markets[0].id;
      marketSelectedName = rootGetters.markets[0].name;
    }

    const isCustom = rootState.settings.usePrograms
      && rootState.settings.customProgramsMarkets.includes(marketSelectedId);

    bffRealtime.getSprintSchedule({
      startDate,
      endDate,
      isCustom,
      marketName: marketSelectedName,
      marketId: marketSelectedId,
    }).then(({ data }) => {
      commit('SET_GROUPED_SCHEDULES', data);
    }).catch((error) => {
      if (error !== 'Request Canceled') dispatch('showInnerError', { clicked: true }, { root: true });
    });
  },
};

// mutations
const mutations = {
  SET_GROUPED_SCHEDULES(state, data) {
    state.schedulesGroup = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
