<template>
  <div class="navigation">
    <template v-if="!isInPrograms">
      <ToogleButton icon-left="security" icon-right="person"
                    tip-left="Permissão" tip-right="Usuário"
                    :is-toogle-left="isInPermissionGroup"
                    @clicked="changeIsCPUserView"
      />
      <div class="divisor" />
    </template>
    <div class="search">
      <div class="item full">
        <i v-if="!this.$route.query.search" class="icone material-icons" @click="changeQuerySearch()">
          search
        </i>
        <i v-else class="icone material-icons" @click="clearSearch()">
          close
        </i>
        <input v-model="search" type="text" required="" @keyup.enter="changeQuerySearch()">
        <span class="highlight" />
        <span class="bar" />
        <label>Pesquisar...</label>
      </div>
    </div>
    <template v-if="isInPrograms">
      <div class="divisor" />
      <SelectDay />
      <template v-if="gridSelected">
        <div class="divisor" />
        <span class="info">
          Ultima Modificação:
          {{ gridSelected }}
        </span>
      </template>
    </template>
    <div class="buttons">
      <template v-if="isInPrograms">
        <ErrorState v-if="errorWhenImporting" :insides="['nowifi_item', 'nowifi_back']" title="Xiiiiiiiiiiiiii" :buttons="[{
          text: 'QUERO VOLTAR',
          click: () => {
            this.$router.go();
          },
        }]" subtitle="A programação não está disponível no sistema EPG para importação." />
        <!-- <button v-if="isGlobo" :disabled="settings.isLoadingSpinner" type="button" class="btn btn_primary ripple" @click="importConfirmation">
          <i class="icone material-icons tool">
            import_export
          </i>
          &nbsp;Importar
        </button> -->
        <button type="button" :disabled="disabledAddProgram" class="btn btn_primary ripple" @click="addProgram">
          <i class="icone material-icons tool">
            tv
          </i>
          &nbsp;Novo Programa
        </button>
      </template>
      <template v-else-if="isInPermissionGroup">
        <button type="button" class="btn btn_header ripple" @click="downloadGroupsCsv()">
          <i class="icone material-icons tool" data-tip="Download">
            file_download
          </i>
        </button>
        <button type="button" class="btn btn_header ripple" @click="openFilter">
          <i class="icone material-icons tool" data-tip="Preferências">
            tune
          </i>
        </button>
        <button type="button" class="btn btn_primary ripple" @click="addRule">
          <i class="icone material-icons tool">
            security
          </i>
          &nbsp;Incluir Regra
        </button>
      </template>
      <template v-else>
        <button type="button" class="btn btn_header ripple" @click="downloadUsersCsv()">
          <i class="icone material-icons tool" data-tip="Download">
            file_download
          </i>
        </button>

        <button type="button" class="btn btn_primary selected ripple" @click="addUser">
          <i class="icone material-icons tool">
            person
          </i>
          &nbsp;Incluir Usuário
        </button>
      </template>
    </div>
    <div class="divisor" />
  </div>
</template>

<script>
import ToogleButton from '@/components/modules/ToogleButton.vue';
import { mapState } from 'vuex';
import moment from 'moment';
import bffRealtime from '@/gateways/bff-realtime';
import _ from 'lodash';
import SelectDay from '../SelectDay.vue';
import ErrorState from '@/components/system/ErrorState.vue';

export default {
  components: {
    ToogleButton,
    ErrorState,
    SelectDay,
  },
  data() {
    return {
      search: '',
      importInfo: null,
      disabledAddProgram: false,
      errorWhenImporting: false,
    };
  },
  computed: {
    ...mapState(['settings']),
    isInPermissionGroup() {
      return this.$route.name === 'PermissionGroups';
    },

    isInPrograms() {
      return this.$route.name === 'Programs';
    },
    isGlobo() {
      const { network } = this.$route.query;
      return network === 'GLOBO';
    },
    daySelected() {
      const { day } = this.$route.query;
      return Number(day || moment().format('e'));
    },
    gridSelected() {
      const { network, market } = this.$route.query;

      const lastAction = _.chain(this.lastAction)
        .filter({ network, market, weekday: this.daySelected }).value();

      if (lastAction.length !== 0) {
        return moment(lastAction[0].date).format('DD/MM/YYYY - HH:mm');
      }

      return false;
    },
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler(query) {
        // Auto add search
        this.search = query.search;
      },
    },
  },
  mounted() {
    this.errorWhenImporting = false;
    this.clearLoading();
    this.getImportInfo();
  },
  methods: {
    clearLoading() {
      if (this.settings.isLoadingSpinner) this.$store.dispatch('selectIsLoadingSpinner', false);
    },
    isG5(network, market) {
      const marketG5 = ['SP', 'RJ', 'BH', 'REC', 'DF'];
      if (marketG5.includes(market) && network === 'GLOBO') {
        return true;
      }
      return false;
    },
    async getImportInfo() {
      try {
        const { data } = await bffRealtime.getProgramConfig(
          bffRealtime.tokenRequest('controlPanel', 'getImportInfo'),
        );
        this.lastAction = data.lastAction;
      } catch (error) {
        console.error(error);
      }
    },
    downloadCsv() {
      this.$modal.show('export');
    },
    downloadUsersCsv() {
      this.$modal.show('export-users');
    },
    downloadGroupsCsv() {
      this.$modal.show('export-groups');
    },
    changeIsCPUserView() {
      this.$router.push({ name: this.isInPermissionGroup ? 'Users' : 'PermissionGroups' });
    },
    clearSearch() {
      this.search = '';
      this.changeQuerySearch();
    },
    changeQuerySearch() {
      const query = { ...this.$route.query, search: this.search };
      if (this.search === '') delete query.search;
      this.$router.push({ name: this.$route.name, query });
    },
    addUser() {
      this.$modal.show('sidebar', {
        componentName: 'UserRules',
        title: 'Incluir Usuário',
        icon: 'person',
        onClose: () => {
          this.$store.dispatch('selectIsLoading', true);
        },
      });
    },
    addRule() {
      this.$modal.show('sidebar', {
        componentName: 'CreateRule',
        title: 'Incluir Regra',
        icon: 'security',
        onClose: () => {
          this.$store.dispatch('selectIsLoading', true);
        },
      });
    },
    addProgram() {
      this.$modal.show('sidebar', {
        componentName: 'CreateProgram',
        title: 'Novo Programa',
        icon: 'tv',
        onClose: () => {
          this.$store.dispatch('selectIsLoading', true);
        },
      });
    },
    importConfirmation() {
      this.$modal.show('dialog', {
        title: 'Confirmação',
        text: 'A importação irá sobrescrever todos os dados de programação do dia. Deseja continuar?',
        buttons: [
          {
            title: 'Cancelar',
          },
          {
            title: 'Importar',
            handler: () => {
              this.$store.dispatch('selectIsLoadingSpinner', true);
              this.importPrograms();
              this.$modal.hide('dialog');
            },
          },
        ],
      });
    },
    async importPrograms() {
      try {
        const { network, market } = this.$route.query;
        this.$store.dispatch('selectIsImportActivate', true);
        const controlPanelToken = await bffRealtime.tokenRequest('controlPanel', 'importPrograms');
        await bffRealtime.importPrograms(
          {
            network,
            market,
            weekDay: this.daySelected,
          },
          controlPanelToken,
        );
        this.$store.dispatch('selectIsImportActivate', false);
      } catch (error) {
        this.errorWhenImporting = true;
        throw error;
      } finally {
        this.$store.dispatch('selectIsLoadingSpinner', false);
      }
    },
    openFilter() {
      this.$modal.show('sidebar', {
        componentName: 'Filters',
        title: 'Filtros',
        icon: 'tune',
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.info {
  font-family: $secondary-typo;
  font-weight: 400;
  font-size: 0.64em;
  color: $color-gray-main;
}

// Search
.search {
  position: relative;
  z-index: 0;
  height: 100%;
  padding: 16px 0;
  width: 24%;

  // Configurações de itens de formulário
  .item {
    display: inline-block;
    position:relative;
    height: 100%;
    margin: 0 24px 8px 0;

    font-family: $secondary-typo;
    font-weight: 400;
    font-size: 0.88em;
    color: $color-gray-main;

    &.full {
      width: 100%;
    }

    i {
      position: absolute;
      top: 16px;
      font-size: 1.4em;
      right: 0;
      cursor: pointer;
    }

    input {
      display: block;
      width: 100%;
      border: none;
      padding: 16px 32px 8px 0;
      font-weight: 400;
      font-size: 1.2em;
      background: transparent;
      border-bottom: solid 1px $color-gray-light;

      &:focus {
        outline: none;
      }
    }

    label {
      position: absolute;
      pointer-events: none;
      font-style: italic;
      left: 0;
      top: 16px;
      transition: 0.2s ease all;
    }

    // Bottom Bars
    .bar {
      position: relative;
      display: block;
      width: 100%;

      &:before,
      &:after {
        position: absolute;
        bottom: 0;
        content: '';
        width: 0;
        height: 1px;
        background-color: $color-secondary;
        transition: 0.2s ease all;
      }

      &:before {
        left: 50%;
      }

      &:after {
        right: 50%;
      }
    }

    // Highlight
    .highlight {
      position: absolute;
      height: 45px;
      width: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      opacity: 0.24;
    }

    // Active State
    input:focus ~ label, input:valid ~ label 		{
      top: 0;
      font-size: 0.8em;
      color: $color-secondary;
    }

    input:focus ~ .bar:before,
    input:focus ~ .bar:after {
      width: 50%;
    }

    input:focus ~ .highlight {
      animation: inputHighlighter 0.4s ease;
    }

    // Animations
    @keyframes inputHighlighter {
      from {
        background: $color-secondary;
      }

      to {
          width: 0;
          background: transparent;
      }
    }
  }
}

</style>
