<template>
  <div class="header" :class="headerClass">
    <div class="box">
      <NavControlPanel v-if="isInControlPanelPage" />
      <NavHome v-else />

      <div class="user">
        <div class="menu" @click="openSideBar()">
          <div class="avatar" :style="{ backgroundColor: hslColor }">{{ initialsName }}</div>
          <i class="icone material-icons tool">menu</i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import initials from "initials";
import { mapState, mapGetters } from "vuex";
import NavControlPanel from "./Header/navigation/NavControlPanel.vue";
import NavHome from "./Header/navigation/NavHome.vue";

export default {
  components: {
    NavControlPanel,
    NavHome
  },
  computed: {
    ...mapState(["user", "settings", "market"]),
    ...mapGetters(["currentUser"]),
    isInControlPanelPage() {
      return ["PermissionGroups", "Users", "Programs"].includes(
        this.$route.name
      );
    },
    initialsName() {
      const { name, email } = this.currentUser;
      return initials(name ? name : email)
        .toUpperCase()
        .substr(0, 2);
    },
    hslColor() {
      const { name } = this.currentUser;
      return this.stringToHslColor(name, 100, 20);
    },
    headerClass() {
      return {
        home: !this.isInControlPanelPage,
        controlpannel: this.isInControlPanelPage
      };
    }
  },
  methods: {
    openSideBar() {
      this.$parent.$refs.sideBar.openSideBar(true);
    },
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h},${s}%,${l}%)`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

// Header8
.header {
  position: relative;
  z-index: 3;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 80px;
  margin: 0;
  align-self: flex-start;
  border-bottom: solid 1px rgba($color-gray-light, 0.4);

  .box {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 80px);
    padding: 0 0 0 64px;
    margin: auto;
  }

  // Navigation
  & /deep/ .navigation {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    align-items: center;

    // Divisor
    .divisor {
      background-color: rgba($color-gray-light, 0.4);
      width: 2px;
      height: 16px;
      margin: 0 16px;
    }

    .buttons {
      margin: 0 0 0 auto;

      .btn_primary {
        letter-spacing: 0.16em;
        border-radius: 4px;
        padding: 8px 16px;
        height: 48px;
      }
    }
  }

  // User Actions
  .user {
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: auto;

    // Avatar
    .avatar {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      background-color: $color-gray-darker;
      box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
      font-size: 0.8em;
      font-family: $primary-typo;
      color: $color-gray-lighter;
      margin-right: 8px;
    }
    // -----------------
  }

  .menu {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    cursor: pointer;

    i {
      color: $color-gray-main;
      transition: color 0.56s ease-in-out;
    }

    &:hover {
      i {
        color: $color-secondary;
      }
    }
  }
  // Buttons
  & /deep/ .buttons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    .btn {
      margin: 0 4px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;

      &:disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &:first-child {
        margin: 0 4px 0 0;
      }

      &:last-child {
        margin: 0 0 0 4px;
      }

      i {
        position: relative;
        top: inherit;
        left: inherit;
        padding: 0;
        margin: 0;
        transition: color 0.4s ease-in-out;

        /*== start of code for tooltips ==*/
        &.tool {
          position: relative;

          // Balão
          &::after {
            top: 180%;
          }
        }
      }

      &.selected {
        background-color: $color-secondary;
        color: $color-gray-lighter;
        box-shadow: 0 4px 8px rgba($color-gray-darker, 0.08);

        i {
          color: $color-gray-lighter;
        }
      }
    }
  }

  // Ajustes no posicionamento dos botões para a home (tabelas + dashboards)
  &.home {
    .box {
      & /deep/ .navigation {
        margin: 0 0 0 16px;
        .buttons {
          margin: 0;
        }
      }
    }
  }
}

// ------------------------------------
// Breakpoints
// ------------------------------------

// First Break : 1260px
@media (max-width: 1260px) {
  .box {
    width: 100%;
    padding: 0 16px;

    // User Info
    .user {
      .avatar {
        display: none;
      }
    }
  }
}

// First Break : 1140px
@media (max-width: 1140px) {
  .header {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .box {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 8px;

      // Navigation
      & /deep/ .navigation {
        display: flex;
        justify-content: flex-start;
        margin-left: 0;
        width: calc(100% - 32px);

        .select_dropdown {
          margin: 0 auto 0 16px;
        }

        .divisor {
          display: none;
        }
      }

      .user {
        display: none;
      }
    }
  }
}

// ------------------------------------

// First Break : 960px
@media (max-width: 960px) {
  .header {
    .box {
      & /deep/ .navigation {
        .buttons {
          justify-content: flex-end;

          :nth-child(4),
          :nth-child(3) {
            display: none;
          }

          :nth-child(2) {
            margin: 0;
          }
        }
      }
    }
  }
}

// Second Break : 756px
@media (max-width: 756px) {
  .header {
    .box {
      & /deep/ .navigation {
        .buttons {
          display: none;
        }

        // Select
        .select_dropdown {
          margin: 0 0 0 auto;

          select {
            max-width: 100%;
            border: none;
            background-color: transparent;
            padding: 0 16px 0 8px;

            font-family: $primary-typo;
            font-weight: 500;
            font-size: 0.56em;
            color: $color-gray-dark;
            transition: color 0.4s ease-in-out;

            cursor: pointer;

            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;

            &:active,
            &:focus {
              outline: none;
              box-shadow: none;
            }

            &:hover {
              color: $color-secondary;
            }
          }
          &:after {
            content: " ";
            position: absolute;
            top: 50%;
            margin-top: -2px;
            right: 8px;
            width: 0;
            height: 0;
            border-left: 2px solid transparent;
            border-right: 2px solid transparent;
            border-top: 2px solid $color-gray-main;
          }
        }
        // Fim do Select --------------
      }
    }
  }
}

// Third Break : 656px
@media (max-width: 656px) {
  .header {
    .box {
      & /deep/ .navigation {
        // Select
        .select_dropdown {
          display: none;
        }
      }
    }
  }
}

// SMARTPHONES
@media (max-width: 600px) {
  .header {
    display: none;
    pointer-events: none;
  }
}

// ------------------------------------
</style>
