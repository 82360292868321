<template>
  <div id="Login" class="login">
    <div class="brand-container">
      <div class="brand" />
      <div v-if="showAppRedirectOption" class="feedback">
        <div class="title" style="font-size: 1.5em">
          Estamos quase lá!
        </div>
        <div
          class="subtitle"
          style="margin-bottom: 25px"
        >
          Clique na opção abaixo para retornar ao App Realtime e concluir o seu login.
        </div>
        <div style="width: 100%; display: flex; align-content: center;justify-content: center">
          <a :href="appUrlScheme" class="btn btn_outline ripple" style="width: 80%; font-style: initial!important;text-decoration: initial!important;">CONCLUIR LOGIN</a>
        </div>
      </div>
      <div v-else-if="!isErrorAuthUser" class="loader loader--show">
        <svg
          class="spinner"
          width="30px"
          height="30px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            class="path"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30"
          />
        </svg>
      </div>

      <div v-if="isErrorAuthUser" class="feedback">
        <div class="title">
          Ooops!
        </div>
        <div
          class="subtitle"
        >
          Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes.
        </div>

        <button
          type="button"
          name="submit"
          class="btn btn_outline btn_full ripple"
          @click="redirectTologinPage()"
        >
          Tentar Novamente
        </button>
      </div>
    </div>

    <div class="gradient" />
    <div class="black" />
    <div class="background-image" />
  </div>
</template>

<script>
import queryString from 'query-string';
import AuthService from '@/v2/Services/auth.service';
import { isApp, isMobile } from '@/utils/device';
import { Plugins } from '@capacitor/core';

const { App } = Plugins;

const auth = new AuthService();

export default {
  name: 'LoginVue',
  data() {
    return {
      isErrorAuthUser: false,
      showAppRedirectOption: false,
      appUrlScheme: '',
    };
  },
  async mounted() {
    if (isApp()) {
      App.addListener('appUrlOpen', this.checkAppUrlCallback);
      this.redirectTologinPage();
    } else {
      const params = this.searchToObject();
      auth
        .checkSigninRedirectCallback(params)
        .then((obj) => {
          if (isMobile() && !isApp()) {
            this.appUrlScheme = `br.com.tvglobo.realtime://${window.location.search}`;
            this.showAppRedirectOption = true;
          } else {
            this.validateUser(obj);
          }
        })
        .catch((err) => {
          if (err.name === 'UndetectedRedirection') {
            this.redirectTologinPage();
          } else {
            this.isErrorAuthUser = true;
          }
        });
    }
  },
  methods: {
    async checkAppUrlCallback(urlOpen = {}) {
      const locationSearch = (urlOpen.url && urlOpen.url.split('br.com.tvglobo.realtime://')[1]) || {};
      const { code } = queryString.parse(locationSearch, { decode: false });
      await this.validateUser({
        code,
        codeVerifier: '1234',
      });
    },

    redirectTologinPage() {
      window.location = auth.generateAuthRedirection().toString();
    },

    async validateUser(callbackParams) {
      try {
        await this.$store.dispatch('loginAccounts', callbackParams);

        if (!isApp()) {
          const { origin } = window.location;
          window.location.href = origin;
          await this.$router.push({ path: '' });
        }
      } catch (e) {
        this.isErrorAuthUser = true;
      }
    },

    searchToObject() {
      const pairs = window.location.search.substring(1).split('&');
      const obj = {};
      let pair;
      let i;

      for (i in pairs) {
        if (pairs[i] === '') continue;
        pair = pairs[i].split('=');
        obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      }

      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.feedback {
  margin-top: 10%;
  z-index: 8;

  .title {
    position: relative;
    display: inline-block;
    margin: 8px 0 24px 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;

    font-family: $secondary-typo;
    font-size: 2em;
    font-weight: 600;
    font-style: italic;
    color: $color-gray-line;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 0.08em;
    animation: fadeIn 0.6s ease-in-out forwards;

    &::after {
      content: "";
      position: absolute;
      bottom: -12px;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 2px;
      background-color: rgba(255, 255, 255, 0.72);
    }
  }

  .subtitle {
    width: 100%;
    padding: 0 32px;
    font-family: $secondary-typo;
    font-size: 1em;
    font-weight: 600;
    font-style: italic;
    text-transform: initial;
    line-height: 1.6em;
    color: $color-gray-line;
    text-align: center;
    animation: fadeIn 0.8s ease-in-out forwards;
    max-width: 600px;
  }

  button {
    margin-top: 4%;
    box-shadow: none;
  }
}

.login {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  height: 100vh;

  animation: fadeIn 1.6s ease-in-out forwards;
  transition: height 0.8s, width 0.8s;

  .brand-container {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  // Loader
  .loader {
    z-index: 8;
    pointer-events: none;
    margin-top: 5%;

    .spinner {
      transform-origin: center center;

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation: dash $duration ease-in-out infinite,
          colors ($duration * 4) ease-in-out infinite;
      }
    }

    &.loader--show {
      animation: fadeIn 0.8s ease-in-out forwards;
    }

    &.loader--hide {
      animation: fadeOut 0.8s ease-in-out forwards;
    }
  }

  .brand {
    height: 124px;
    width: 124px;
    margin: 0 auto;
    z-index: 8;
    @include brand($asset);
  }

  // Background Image
  .background-image {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    width: 100%;
    height: 100%;

    @include backLogin($background);
  }

  // Black background
  .black {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;

    width: 100%;
    height: 100%;

    opacity: 0.8;
    background-color: $color-gray-darkest;
    background-size: 100% 100%;
  }

  // Gradiente
  .gradient {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;

    width: 100%;
    height: 100%;

    opacity: 0.72;
    background: linear-gradient(
      140deg,
      $color-primary-lighter,
      $color-primary,
      $color-secondary
    );
    background-size: 100% 100%;
  }
}
</style>
