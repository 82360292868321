<template>
  <!-- Bottom Navigation -->
  <div class="bottom_navigation" :class="{ hidden: !system.isMobileNavigation || system.isUserPreferencesOpen }">
    <button type="button" class="btn ripple" :class="btnCss('Home')"
    @click="changeIsHomeView">
      <i class="icone material-icons">
        format_align_justify
      </i>
    </button>

    <button type="button" class="btn ripple" :class="btnCss('Dashboards')"
    @click="changeIsDashBoardView">
      <i class="icone material-icons">
        view_day
      </i>
    </button>

    <!-- <button type="button" class="btn ripple" :class="btnCss('Notifications')"
            @click="goToPage('Notifications')"
    >
      <i class="icone material-icons">
        notifications
      </i>
    </button> -->

    <button type="button" class="btn ripple" :class="btnCss('SimuCast')"
            v-if="settings.hasSimuCast"
            @click="changeIsSimuCastView"
    >
      <i class="icone material-icons">
        phonelink
      </i>
    </button>

    <button type="button" class="btn ripple" :class="btnCss('Settings')"
            @click="changeIsSettingsView"
    >
      <i class="icone material-icons">
        person
      </i>
    </button>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data() {
    return {
      activeSimuCast: false
    };
  },
  computed: {
    ...mapState(['system', 'market', 'settings']),
    isDashboardEnabled() {
      const { markets } = this.market;
      return markets.find(market => market.tvNetworks.find(tvNetwork => tvNetwork.id === 'GLOBO'));
    },
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
    btnCss(routeName) {
      if(routeName==='SimuCast'){
        return {
          disabled:  false ,
          enabled: routeName === 'SimuCast' && this.activeSimuCast,
        };
      }else{
        return {
          disabled: routeName === 'Dashboards' && !this.isDashboardEnabled,
          enabled: this.$route.name === routeName,
        };
      }
    },
    changeIsHomeView() {
      this.$store.dispatch('selectIsInSimuCastView', false);
      this.activeSimuCast = false;
      this.goToPage('Home');
    },
    changeIsDashBoardView() {
      this.$store.dispatch('selectIsInSimuCastView', false);
      this.activeSimuCast = false;
      this.goToPage('Dashboards');
    },
    changeIsSimuCastView() {
      this.$store.dispatch('selectIsInSimuCastView', true);
      this.activeSimuCast = true;
      this.goToPage('Home');
    },
    changeIsSettingsView() {
      this.$store.dispatch('selectIsInSimuCastView', false);
      this.activeSimuCast = false;
      this.goToPage('Settings');
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.bottom_navigation {
  display: none;
  transition: transform 0.6s ease-in-out !important;

  &.hidden {
    transform: translateY(140px) !important;
  }
}

// SMARTPHONES
@media (max-width: 600px) {

  // Definições para o Bottom Navigation
  .bottom_navigation {
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: calc(env(safe-area-inset-bottom, 0) + 50px);
    background: $color-gray-lighter;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -2px 8px rgba($color-gray-darker, 0.08);

    // Botões
    .btn {
      flex-grow: 1;
      height: 56px;
      border: none;
      border-radius: 0;
      cursor: pointer;
      background-color: $color-gray-lighter;
      font-size: 0.88em;
      box-shadow: none;

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      // Ripple Effect
      &.ripple {
        background-position: center;
        transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

        & i {
          color: $color-gray-light;
          transition: color 0.56s ease-in-out;
        }

        &:hover {
          background: $color-gray-lighter radial-gradient(circle, transparent 1%, $color-gray-lighter 1%) center/15000%;
        }

        &:active {
          background-color: rgba($color-gray-back, 1);
          background-size: 100%;
          transition: background 0s;
        }

        &.enabled {
          i {
            color: $color-secondary;
          }
        }
      }

      i {
        margin-top: -2px;
        font-size: 1.8em;
      }
    }
  }
}

</style>
