<template>
  <div class="container">
    <SprintChart class="sprint-chart" :date="date" />
    <SprintDash class="sprint-dash" :date="date" />
    <div class="hour-divider">
      <HourNavigator class="hour-navigator" @group-selected="onHourSelected" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import device from '@/utils/device';
import HourNavigator from './Components/HourNavigator.vue';
import SprintDash from './Components/SprintDash.vue';
import SprintChart from './Components/SprintChart.vue';

export default {
  name: 'SprintTable',
  components: {
    HourNavigator,
    SprintDash,
    SprintChart,
  },
  computed: {
    ...mapGetters(['innerErrorOptions', 'tvNetworks_v2', 'globoDate']),
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
  },
  data() {
    return {
      date: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    if (this.isMobile || device.isApp()) {
      this.$store.dispatch('selectIsMinuteView', false);
      this.$store.dispatch('selectDetailsView', 'table');
    }
  },
  methods: {
    onHourSelected(value) {
      this.date = value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .container{
    background-color: #FFFFFF;
    max-height: 80%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
  }

  .sprint-chart{
    width:100%;
    height: 35vh;
    margin-top: 20px;
    flex-grow: 3;
  }

  .sprint-dash{
    width: 100%;
    height: 30vh;
    padding-right: 32px;
    padding-left: 32px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .hour-divider{
    margin-top: auto;
  }
</style>
