import { Plugins } from '@capacitor/core';
import { WebIntent } from '@ionic-native/web-intent';
import { Downloader, NotificationVisibility } from '@ionic-native/downloader';
import bffRealtime from '@/gateways/bff-realtime';


const { Device, App, Modals } = Plugins;

const FLAG_ACTIVITY_NEW_TASK = 268435456;

async function checkStoreApp(platform) {
  console.log('AutoUpdate: checkStoreApp: ', platform);
  const param = { os: platform };
  const { data } = await bffRealtime.getLatestAppInfoByOs(param);

  console.log('AutoUpdate: checkStoreApp: version: ', data);
  return data;
}

function updateAndroid(apkUrl) {
  console.log('AutoUpdate: updateAndroid: URL::: ', apkUrl);
  return Downloader.download({
    uri: apkUrl,
    title: 'Realtime',
    description: 'Baixando atualização',
    mimeType: '',
    visibleInDownloadsUi: true,
    notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
    destinationInExternalFilesDir: {
      dirType: 'Downloads',
      subPath: 'Realtime.apk',
    },
  }).then((location) => {
    console.log(`AutoUpdate: updateAndroid: Downloaded at location: ${location}`);
    WebIntent.startActivity({
      action: WebIntent.ACTION_VIEW,
      url: location,
      type: 'application/vnd.android.package-archive',
      flags: [FLAG_ACTIVITY_NEW_TASK],
    }).then(() => {
      console.log('AutoUpdate: install success');
    }).catch((e) => {
      console.log('AutoUpdate: install error', e.toLocaleString());
    });
  });
}

function updateIOS(installUrl) {
  console.log('AutoUpdate: updateIOS: version: ', installUrl);
  return App.openUrl({
    url: installUrl,
  });
}

export const getVersion = async () => {
  const res = await Device.getInfo();
  return res;
};


async function autoUpdate() {
  console.log('AutoUpdate Device', JSON.stringify(await Device.getInfo()));
  const { platform, appVersion } = await Device.getInfo();
  console.log(`Platform ${platform}`);

  const { version: remoteVersion, download: downloadPath } = await checkStoreApp(platform);
  console.log('remoteVersion::: ', remoteVersion);
  console.log('downloadPath::: ', downloadPath);

  if (appVersion === remoteVersion) {
    console.log('AutoUpdate: Não vai atualizar');
    return;
  }

  await Modals.alert({
    title: 'Auto Update',
    message: `Iniciando atualização para a versão ${remoteVersion}`,
  });
  console.log('platform :: ', platform.toLowerCase());
  switch (platform.toLowerCase()) {
    case 'android':
      updateAndroid(downloadPath);
      break;
    case 'ios':
      updateIOS(downloadPath);
      break;
    default:
      break;
  }
}

export { autoUpdate };
