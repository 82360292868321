<template>
  <div class="user-rules">
    <div class="middle">
      <transition name="fade">
        <div v-if="isLoading" class="loading">
          <div class="loader">
            <svg
              class="spinner"
              width="65px"
              height="65px"
              viewBox="0 0 66 66"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                class="path"
                fill="none"
                stroke-width="6"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              />
            </svg>
          </div>
        </div>
      </transition>
      <div class="input-group name">
        <div class="item full">
          <input v-model="nameProgram" type="text" :disabled="true" required>
          <span class="highlight" />
          <span class="bar" />
          <label>Nome do Programa</label>
        </div>
      </div>
      <div class="input-group name">
        <div class="item full">
          <input v-model="nameFrame" type="text" required>
          <span class="highlight" />
          <span class="bar" />
          <label>Nome do Quadro</label>
        </div>
      </div>
      <div class="option-group">
        <div class="head">
          <span>Horários</span>
        </div>
        <div class="input-group hours">
          <div class="item half">
            <input v-model="startTimeConvet" type="time">
            <span class="highlight" />
            <span class="bar" />
            <label />
          </div>
          <div class="item half">
            <input v-model="endTimeConvert" type="time">
            <span class="highlight" />
            <span class="bar" />
            <label />
          </div>
        </div>
      </div>
      <div class="option-group">
        <div class="input-group hours">
          <div class="body" />
        </div>
      </div>
    </div>
    <footer v-if="message || messageError" class="done" :class="{ error: messageError }">
      <div class="input-group">
        <div class="item full">
          <i class="icone material-icons">{{ messageError ? "mood_bad" : "mood" }}</i>
          <input v-model="searchInput" type="text" required>
          <span class="highlight" />
          <span class="bar" />
          <label>{{ message || messageError }}</label>
        </div>
      </div>
    </footer>
    <footer v-else>
      <!-- <button type="button"
              class="btn btn_secondary ripple"
              :class="{ disabled : !isValidate }"
              @click="clone"
      >
        Clonar
      </button>-->
      <button
        type="button"
        class="btn btn_primary ripple"
        :class="{ disabled : !isValidate }"
        @click="addFrame"
      >
        Salvar
      </button>
    </footer>
  </div>
</template>

<script>
import bffRealtime from '@/gateways/bff-realtime';
import dateUTC from '@/utils/dateUTC';
import moment from 'moment';

export default {
  mixins: [dateUTC],
  props: {
    program: {
      type: Object,
      default: null,
    },
    updateFrame: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      interval: { h: 1, m: 1, s: 10 },
      total: 0,
      active: true,
      searchInput: '',
      search: '',
      isLoading: false,
      activeUser: '',
      message: '',
      messageError: '',
      messageTimeout: null,
      id: null,
      idProgram: null,
      nameProgram: '',
      nameFrame: '',
      name: '',
      startTime: null,
      endTime: null,
      startTimeConvet: null,
      endTimeConvert: null,
      dateCurrent: null,
    };
  },
  computed: {
    minDate() {
      return 0;
    },
    statusName() {
      return this.active ? 'Ativo' : 'Inativo';
    },
    isValidate() {
      return this.name !== '' && this.startTimeConvet && this.endTimeConvert;
    },
    daySelected() {
      const { day } = this.$route.query;
      return Number(day || moment().format('e'));
    },
  },
  mounted() {
    this.setupProgram();
    if (this.id) {
      this.startTimeConvet = this.timeUTC(this.startTime);
      this.endTimeConvert = this.timeUTC(this.endTime);
    }
  },
  beforeDestroy() {
    clearTimeout(this.messageTimeout);
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    async setupProgram() {
      if (this.program) this.getProgram(this.program);
      else {
        const { network, market } = this.$route.query;
        this.nameFrame = this.program.nameFrame;
        this.network = network;
        this.market = market;
      }
    },
    async getProgram({
      id,
      name,
      startTime,
      endTime,
      nameFrame,
      nameProgram,
      idProgram,
    }) {
      this.message = '';
      this.messageError = '';
      this.id = id;
      this.name = name;
      this.startTime = startTime;
      this.endTime = endTime;
      this.nameProgram = nameProgram;
      this.nameFrame = nameFrame;
      this.idProgram = idProgram;
    },
    async addFrame() {
      clearTimeout(this.messageTimeout);
      this.isLoading = true;
      this.startTime = this.startTimeConvet;
      this.endTime = this.endTimeConvert;
      try {
        const { name, startTime, endTime } = this;
        const { network, market } = this.$route.query;

        const params = {
          name,
          startTime,
          endTime,
        };

        if (this.id) {
          params.name = this.nameFrame;
          await bffRealtime.updateFrame(
            this.idProgram,
            this.id,
            network,
            market,
            this.daySelected,
            params,
            bffRealtime.tokenRequest('controlPanel', 'updateFrame'),
          );

          this.program.name = this.nameFrame;
          this.program.nameFrame = this.nameFrame;
          this.program.startTime = this.timeUTCAdd(params.startTime, this.program.dateCurrent);
          this.program.endTime = this.timeUTCAdd(params.endTime, this.program.dateCurrent);
          console.log(this.program);
          this.message = 'Quadro alterado com sucesso!';
        } else {
          params.name = this.nameFrame;
          params.source = 'Manual';
          const { data } = await bffRealtime.addFrame(
            this.idProgram,
            network,
            market,
            this.daySelected,
            params,
            bffRealtime.tokenRequest('controlPanel', 'addFrame'),
          );

          this.id = data.id;
          this.nameFrame = data.name;
          this.active = true;
          this.message = 'Quadro adicionado com sucesso!';
        }

        if (this.updateFrame) {
          params.idProgram = this.idProgram;
          params.id = this.id;
          this.updateFrame(params);
        }
      } catch (error) {
        console.error(error);
        this.messageError = 'O horário preenchido se sobrepõe com o de outro quadro.';
      } finally {
        this.isLoading = false;
        this.messageTimeout = setTimeout(() => {
          this.message = '';
          this.messageError = '';
        }, 2000);
      }
    },
  },
};
</script>

<style lang='scss' scoped>
@import "@/assets/scss/variables.scss";
@import "@/assets/scss/sidebar-content.scss";
</style>
