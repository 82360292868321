import moment from "moment-timezone";
import _ from "lodash";
import { breakTimeRangeByLimit } from "@/utils/time-utils";
import bffRealtime from "@/gateways/bff-realtime";

// initial state
const state = {
  railAudiences: [],
  railPrograms: [],
  isLoading: false,
};

// getters
const getters = {
  railAudiences: (state) => state.railAudiences,
  railPrograms: (state) => state.railPrograms,
  isLoading: (state) => state.isLoading,
  
  railIsEmpty: (state) => {
    const  railAudienceValues = state.railAudiences
      .map((serie) => serie.data.map((d) => d.audience))
      .reduce((acc, item) => {
        return [...acc,...item];
      }, []);

    const railAudiencesIsEmpty =
      !state.railAudiences ||
      !state.railAudiences.length ||
      _.every(railAudienceValues, _.isNull);

    const railProgramsIsEmpty =
      !state.railPrograms ||
      !state.railPrograms.length;

    return railAudiencesIsEmpty && railProgramsIsEmpty;
  },
};

// actions
const actions = {
  resetRailChart({ commit }) {
    commit('SET_RAIL_AUDIENCES', []);
  },

  async getRailChart(
    { state, getters, rootState, rootGetters, commit, dispatch },
    { currentDate, handleLoading }
  ) {
    let marketSelectedId = rootState.settings.market.id;

    const tvNetworks = rootGetters.tvNetworks.map((item) => item.id);
    

    // Quebra o período selecionados em períodos menores
    // Necessário pela consulta trazer um volume muito grande de dados
    const rangeList = breakTimeRangeByLimit({
      startsIn: currentDate.startsIn,
      endsIn: currentDate.endsIn || moment.tz().format(),
      maxRangeInMinutes: 240,
    });

    for (let i = 0; i < rangeList.length; i++) {
      const params = {
        startDate: rangeList[i].start,
        endDate: rangeList[i].end,
        marketId: marketSelectedId,
        networks: tvNetworks,
        historicMark: rootState.settings.performanceHistory,
      };

      if (handleLoading && i === 0) commit('HANDLE_LOADING', true);
      
      try {
        const { data }  = await bffRealtime.getRailChart(
          params,
          bffRealtime.tokenRequest('rail', 'audiences')
        );

        commit('HANDLE_LOADING', false);
        
        if (!data || !data.length) continue;
        
        if (!state.railAudiences.length) {
          commit('SET_RAIL_AUDIENCES', data);
          continue;
        }

        const updatedSeries = data.map((serie) => {
          const storedSeries = getters.railAudiences
            .find(i => i.tvNetworkId === serie.tvNetworkId && i.isHistoricAverage === serie.isHistoricAverage);

          const currentSeriesData = storedSeries ? storedSeries.data : [];

          const completeSerieData = _.unionBy(serie.data, currentSeriesData, 'timestamp');

          const completeSerieDataSorted = _.sortBy(completeSerieData, ['timestamp']);

          return {
            ...serie,
            data: completeSerieDataSorted,
          };
        });

        commit('SET_RAIL_AUDIENCES', updatedSeries);
      } catch(error) {
        if (error !== 'Request Canceled') dispatch('showInnerError', { clicked: true }, { root: true });
        break;
      } finally {
        commit('HANDLE_LOADING', false);
      }
    }
  },

  // TODO: incluir tratamentos de erro
  async getRailPrograms(
    { rootState, commit, rootGetters, dispatch },
    { currentDate },
  ) {
    commit('SET_RAIL_PROGRAMS', []);

    commit('HANDLE_LOADING', true);

    let marketSelectedId = rootState.settings.market.id;
    let marketSelectedName = rootState.settings.market.name;
    

    const tvNetworks = rootGetters.tvNetworks.map((item) => item.id);

    const startDate = currentDate.startsIn;
    const endDate = currentDate.endsIn || moment.tz().format();

    const historicMark = rootState.settings.performanceHistory;

    const filterProgramAverages = {
      startDate,
      endDate,
      isRivals: true, // necessário para pegar a programação das concorrentes
      isCustom: rootState.settings.usePrograms && rootState.settings.customProgramsMarkets.includes(marketSelectedId),
      tvNetworks,
      marketName: marketSelectedName,
      marketId: marketSelectedId,
      historicMark,
    };

    try {
      const { data } = await bffRealtime.getRailProgramAverages(
        filterProgramAverages, bffRealtime.tokenRequest('rail', 'programs'),
      );
      commit('SET_RAIL_PROGRAMS', data);
    } catch(error) {
      if (error !== 'Request Canceled') dispatch('showInnerError', { clicked: true }, { root: true });
    } finally {
      commit('HANDLE_LOADING', false);
    }
  },
};

// mutations
const mutations = {
  SET_RAIL_AUDIENCES(state, data) {
    state.railAudiences = data;
  },
  SET_RAIL_PROGRAMS(state, data) {
    state.railPrograms = data;
  },
  HANDLE_LOADING(state, value) {
    state.isLoading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
