import axios, { CancelToken } from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import queryString from 'query-string';
import store from '@/store';

// write something before Promise
const errorIntercept = (error) => {
  if (axios.isCancel(error)) return Promise.reject(error.message);

  if (error.response && error.response.status === 401) store.dispatch('logoutSessionUser');

  return Promise.reject(error);
};

const bffRealtime = (isV2Routes) => {
  const req = axios.create({
    baseURL: isV2Routes ? process.env.BACKEND_API_URI_EXAMPLE.replace('v1', 'v2') : process.env.BACKEND_API_URI_EXAMPLE,
    headers: {
      token: window.localStorage.getItem('token'),
      'cache-control': 'no-cache, no-store',
      expires: 0,
    },
  });

  axiosRetry(req, {
    retries: 3,
    retryDelay: () => 1000,
    retryCondition: (error) => {
      const isLoginFailed = error.config
        && !error.config.url.match(/\/auth/)
        && error.response
        && error.response.status === 401;
      const isDependencyFailed = error.response && error.response.status === 424;
      return (
        isNetworkOrIdempotentRequestError(error)
        || isLoginFailed
        || isDependencyFailed
      );
    },
  });

  req.interceptors.response.use(r => r, errorIntercept);
  return req;
};

const tokenRequests = {};

export default {

  login(credentials, isV2 = false) {
    // mock auth when dev
    if (process.env.OPEN_CONNECT_API.mockEnabled === true) {
      return axios({
        method: 'get',
        url: 'auth',
        baseURL: process.env.OPEN_CONNECT_API.host,
      });
    }

    return bffRealtime(isV2).post('/auth', credentials);
  },

  logout(isV2 = true) {
    return bffRealtime(isV2).post('/logout', {
      refreshToken: window.localStorage.getItem('refreshToken'),
    });
  },

  getCurrentUser() {
    return bffRealtime().get('/current_user');
  },

  simulateUser(user) {
    return bffRealtime().post('/permissions/simulate-user', {
      email: user.email,
      name: user.name,
    });
  },

  updateCurrentUserPreferences(preferences) {
    return bffRealtime().put('/current_user/preferences', preferences);
  },

  getAudiences(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/audience?${paramsStr}`, { cancelToken });
  },

  getLastAudiences(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/audience/last?${paramsStr}`, { cancelToken });
  },

  getSimulcastAudiences(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/simulcast_audience?${paramsStr}`, { cancelToken });
  },

  getProgramAverages(params, cancelToken) {
    // params.isCustom = store.state.settings.usePrograms;
    // params.isRivals = store.state.settings.usePrograms;

    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });

    return bffRealtime(process.env.PROGRAM_AVERAGES_V2).get(`/audience/program_averages?${paramsStr}`, {
      cancelToken,
    });
  },

  getRangeAverages(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/audience/range_averages?${paramsStr}`, {
      cancelToken,
    });
  },

  getMarkets(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/market?${paramsStr}`);
  },

  getGloboSchedules(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/globo-schedule?${paramsStr}`);
  },

  getRivalsSchedules(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/rivals-schedule?${paramsStr}`);
  },

  getLastProgramAverage(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/audience/last_program_average?${paramsStr}`);
  },

  getScroll(scrollId, cancelToken) {
    return bffRealtime().get(`/scroll?scrollId=${scrollId}`, { cancelToken });
  },

  getSimulcastScroll(scrollId, cancelToken) {
    return bffRealtime().get(`/simulcast_audience/scroll?scrollId=${scrollId}`, { cancelToken });
  },

  getKpiConsolidateByRangeTime(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime(process.env.KPI_V2).get(`/kpi/by-range-time?${paramsStr}`, {
      cancelToken,
    });
  },

  getSprintProgramAverages(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/sprint/program_averages?${paramsStr}`, {
      cancelToken,
    });
  },

  getSprintChart(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/sprint/chart?${paramsStr}`, {
      cancelToken,
    });
  },

  getRailChart(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/rail/chart?${paramsStr}`, {
      cancelToken,
    });
  },

  getRailProgramAverages(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/rail/program_averages?${paramsStr}`, {
      cancelToken,
    });
  },

  getSprintSchedule(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/sprint/getSchedules?${paramsStr}`, {
      cancelToken,
    });
  },

  getKpiConsolidateByTime(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime(process.env.KPI_V2).get(`/kpi/by-time?${paramsStr}`, { cancelToken });
  },

  getNotification(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/notification/programs?${paramsStr}`, {
      cancelToken,
    });
  },

  updateNotification(params, cancelToken) {
    return bffRealtime().post('/notification/programs', params, {
      cancelToken,
    });
  },

  getPermissionUsers(cancelToken) {
    return bffRealtime().get('/permissions/users', { cancelToken });
  },

  getUsersPolicies(cancelToken) {
    return bffRealtime().get('/permissions/user/getUsersPolicies', { cancelToken });
  },

  getPermissionGroups(params, cancelToken) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/permissions/groups?${paramsStr}`, {
      cancelToken,
    });
  },

  getPermissionGroupUsers(group, cancelToken) {
    return bffRealtime().get(`/permissions/groups/${group}/show_users`, {
      cancelToken,
    });
  },

  addGroup(params, cancelToken) {
    return bffRealtime().post('/permissions/groups/', params, { cancelToken });
  },

  updateGroup(group, params, cancelToken) {
    return bffRealtime().put(`/permissions/groups/${group}/`, params, {
      cancelToken,
    });
  },

  removeUser(email, cancelToken) {
    return bffRealtime().delete('/permissions/user', {
      data: { email },
      cancelToken,
    });
  },

  removeRule(uuid, cancelToken) {
    return bffRealtime().delete(`/permissions/groups/${uuid}`, {
      data: { uuid },
      cancelToken,
    });
  },

  addUserGroup(group, params, cancelToken) {
    return bffRealtime().post(`/permissions/groups/${group}/add_user`, params, {
      cancelToken,
    });
  },

  removeUserGroup(group, email, cancelToken) {
    return bffRealtime().post(
      `/permissions/groups/${group}/remove_user`,
      { email },
      { cancelToken },
    );
  },

  updateUserState(email, active, cancelToken) {
    return bffRealtime().post(
      '/permissions/user/status',
      { email, active },
      { cancelToken },
    );
  },

  updateGroupState(uuid, active, cancelToken) {
    return bffRealtime().post(
      `/permissions/groups/${uuid}/set_status`,
      { active },
      { cancelToken },
    );
  },

  getPrograms(cancelToken) {
    return bffRealtime().get('/program/', { cancelToken });
  },

  addProgram(params, cancelToken) {
    return bffRealtime().post('/program/', params, { cancelToken });
  },

  updateProgram(id, params, cancelToken) {
    return bffRealtime().put(`/program/${id}`, params, {
      cancelToken,
    });
  },

  getProgramConfig(cancelToken) {
    return bffRealtime(true).get('/program/config', { cancelToken });
  },

  importPrograms(params, cancelToken) {
    return bffRealtime().post('/program/import', params, { cancelToken });
  },

  addFrame(idProgram, network, market, weekDay, params, cancelToken) {
    return bffRealtime().post(`/program/${idProgram}/program-schedule`, params, {
      data: {
        network,
        market,
        weekDay,
      },
      cancelToken,
    });
  },

  updateFrame(idProgram, idFrame, network, market, weekDay, params, cancelToken) {
    return bffRealtime().put(`/program/${idProgram}/program-schedule/${idFrame}`, params, {
      data: {
        network,
        market,
        weekDay,
      },
      cancelToken,
    });
  },

  removeProgram(id, market, network, weekDay, cancelToken) {
    return bffRealtime().delete('/program/', {
      data: {
        id, market, network, weekDay,
      },
      cancelToken,
    });
  },

  removeFrame(idProgram, idFrame, market, network, weekDay, cancelToken) {
    return bffRealtime().delete(`/program/${idProgram}/program-schedule/${idFrame}`, {
      data: {
        idProgram, idFrame, market, network, weekDay,
      },
      cancelToken,
    });
  },

  getSchedules(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/program/schedule?${paramsStr}`);
  },

  getlastProgramsByMarket(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/program/lastProgramsByMarket?${paramsStr}`);
  },

  getlastProgramsByNetwork(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/program/lastProgramsByNetwork?${paramsStr}`);
  },

  getLatestAppInfoByOs(params) {
    const paramsStr = queryString.stringify(params, { arrayFormat: 'bracket' });
    return bffRealtime().get(`/mobile/getLatestAppInfoByOs?${paramsStr}`);
  },

  tokenRequest(component, id) {
    if (!tokenRequests[component]) tokenRequests[component] = {};

    const tokenRequest = tokenRequests[component];
    if (tokenRequest[id]) tokenRequest[id].cancel('Request Canceled');
    tokenRequest[id] = CancelToken.source();

    return tokenRequest[id].token;
  },
  cancelRequests(component) {
    if (!tokenRequests[component]) tokenRequests[component] = {};

    const tokenRequest = tokenRequests[component];
    Object.keys(tokenRequest).forEach((id) => {
      if (tokenRequest[id]) {
        tokenRequest[id].cancel('Request Canceled');
        delete tokenRequest[id];
      }
    });
  },
};
