<template>
  <div class="container">
    <div v-if="isLoading" class="loading">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
    <!-- <div v-if="railIsEmpty && finishedLoadingData" class="empty-data-message">
      <ErrorState
        :insides="['notallowed_back', 'notallowed_item', 'notallowed_block']"
        :subtitle="'Não existem dados para essa consulta'"
        :style-container="{
          backgroundColor: 'transparent',
        }"
        :fullscreen="false"
      />
    </div> -->
    <div ref="ctx" class="chart"></div>
    <p class="grid-disclaimer">{{gridDisclaimerMessage}}</p>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import Highcharts from 'highcharts/highstock';
import moment from 'moment-timezone';
import { displayTvNetworksTooltip } from '../../../Utils/highchart-utils';
import bffRealtime from '@/gateways/bff-realtime';
import ErrorState from '@/components/system/ErrorState.vue';
import transformMixin from '@/utils/transformMixin';

export default {
  name: 'RailTable',
  
  mixins: [transformMixin],
  
  components: {
    ErrorState,
  },
  
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['tvNetworks', 'tvNetworks_v2', 'globoDate']),
    ...mapGetters('railChart', ['railAudiences', 'railPrograms', 'isLoading', 'railIsEmpty']),

    chartHeight() {
      const headerHeight = 80;
      const marketListHeight = 72;
      const footerHeight = 55;
      return this.windowHeight - headerHeight - marketListHeight - footerHeight;
    },

    gridDisclaimerMessage() {
      const marketsToHideMessage = ['RPP', 'RPT'];
      const gridName = this.settings.market.name === 'SP1' ? 'SP' : this.settings.market.name;
      return marketsToHideMessage.includes(this.settings.market.id) ?
        '' :
        `Programação de referência: ${gridName}`;
    },
  },

  data() {
    return {
      chart: null,
      config: null,
      autoUpdateInterval: null,
      windowHeight: 0,
      finishedLoadingData: false,
    }
  },

  watch: {
    settings: {
      deep: true,
      async handler() {
        this.resetRail();
        await this.getRailChartData(this.globoDate);
        this.setAutoUpdateInterval();
      },
    },

    railAudiences: {
      handler(value) {
        this.createOrUpdateChart(value);
      },
    },
  },

  async mounted() {
    this.windowHeight = window.innerHeight;
    this.config = this.generateStockOptions(this);
    await this.getRailChartData(this.globoDate);
    this.setAutoUpdateInterval();
  },

  beforeDestroy() {
    this.resetRail();
  },

  methods: {
    setAutoUpdateInterval() {
      this.finishedLoadingData = true;

      const { day, endTime } = this.settings.dateOptions;
      const isToday = !endTime && day === 0;

      if (isToday) {
        this.autoUpdateInterval = setInterval(() => {
          const minutesToUpdate = 30;

          const endDate = moment().seconds(0);
          const startDate = endDate.clone().subtract(minutesToUpdate, 'minutes');

          const range = {
            startsIn: startDate.format(),
            endsIn: endDate.format(),
          };

          this.getRailChartData(range, false);
        }, 60000)
      };
    },

    async getRailChartData(rangeTimeSelected, handleLoading = true) {
      await this.$store.dispatch(
        'railChart/getRailPrograms',
        { currentDate: this.globoDate },
      );

      await this.$store.dispatch(
        'railChart/getRailChart',
        { currentDate: rangeTimeSelected, handleLoading },
      );
    },

    resetRail() {
      bffRealtime.cancelRequests('rail');
      clearInterval(this.autoUpdateInterval);
      if (this.chart) this.chart.destroy();
      this.chart = null;
      this.$store.dispatch('railChart/resetRailChart');
      this.finishedLoadingData = false;
    },

    createOrUpdateChart(chartData) {
      // Não cria o gráfico se os dados foram resetados
      if (!(chartData && chartData.length)) {
        return;
      }

      this.config.yAxis = this.generateYAxisConfig({ programLinesCount: this.railPrograms.length });
      this.config.series = this.generateSeries(chartData, this.railPrograms);

      // se o gráfico já existir, atualiza os valores, senão, cria o gráfico
      if (!this.chart) {
        this.chart = Highcharts.stockChart(this.$refs.ctx, this.config);

        const startsIn = this.globoDate.startsIn;
        const endsIn = this.globoDate.endsIn || moment().format();

        const startMoment = moment(startsIn).seconds(0);
        const endMoment = moment(endsIn).seconds(0);

        const rangeSizeInMinutes = endMoment.diff(startMoment, 'minutes');
        const zoomSizeInMinutes = rangeSizeInMinutes < 240 ? rangeSizeInMinutes : 240;
        
        const max = endMoment.clone();
        const min = endMoment.clone().subtract(zoomSizeInMinutes, 'minutes');
        
        this.chart.xAxis[0].setExtremes(min.valueOf(), max.valueOf());
      } else {
        this.chart.update(this.config); 
      }
      const chartSeries = this.chart.series;
      const context = this;

      chartSeries.forEach((s) => {
        if (!s || !s.legendItem) return;
        const currentSerie = s;
        
        s.legendItem.on('mouseover', () => {
          context.highlightSeries(chartSeries, currentSerie.name);
        });
      });
    },

    generateSeries(chartData = [], programData = []) {
      // Define as séries desabilitadas
      const tvNetworksDisabledByDefault = process.env.RAIL_TV_NETWORKS_DISABLED_BY_DEFAULT || [];

      let invisibleSeries = tvNetworksDisabledByDefault;
      
      if (this.chart && this.chart.series && this.chart.series.length) {
        invisibleSeries = this.chart.series
          .filter((serie) => !serie.visible)
          .map((serie) => serie.name);
      }
      
      // formata as series de audiência minuto a minuto
      const chartSeries = chartData.map((serie) => ({
        color: this.getNetworkColor(serie.tvNetworkId),
        name: this.transNetworkNameById(serie.tvNetworkId),
        id: `${this.transNetworkNameById(serie.tvNetworkId)}${serie.isHistoricAverage ? '-H': ''}`,
        linkedTo: serie.isHistoricAverage ? this.transNetworkNameById(serie.tvNetworkId) : undefined,
        data: serie.data
          .map((i) => ([
            i.timestamp,
            i.audience,
          ])),
        dashStyle: serie.isHistoricAverage ? 'dot' : 'line',
        visible: !invisibleSeries.includes(this.transNetworkNameById(serie.tvNetworkId)),
        yAxis: 1,
      }));

      // formata as series de média de programação
      programData.forEach((schedule, scheduleIndex) => {
        const seriesData = [];
        schedule.programs
          .forEach((program, currentProgramIndex, programList) => {
            // se houver distancia entre programas, é gerado um ponto para o fim do programa
            // e outro, com valor null no minuto seguinte, para exibir o período inteiro do programa
            // e quebrar a linha no gráfico até o começo do próximo programa
            const previousProgram = programList[currentProgramIndex -1];
            if (previousProgram  && previousProgram.endsIn) {
              const programGap = moment(program.startsIn).diff(moment(previousProgram.endsIn), 'minutes');
              if (programGap > 1) {
                seriesData.push({
                  x: moment(previousProgram.endsIn).valueOf(),
                  y: 30 * (scheduleIndex + 1),
                  marker: {
                    enabled: true,
                    radius: 3,
                    symbol: 'square',
                  },
                });
                seriesData.push({
                  x: moment(previousProgram.endsIn).clone().add(1, 'minute').valueOf(),
                  y: null,
                  marker: {
                    enabled: false,
                  },
                });
              }
            }

            const fixedAudience = program.averages && typeof program.averages.audience === "number" ?
              program.averages.audience.toFixed(1) :
              "";
            
            const fixedHistoricAudience = program.averages && typeof program.averages.historicAudience === "number" ?
              program.averages.historicAudience.toFixed(1) :
              "";

            let label = `${program.program} ${fixedAudience} (MÉDIA HIST: ${fixedHistoricAudience})`;
            
            // Adiciona o símbolo representando os programas que começam ou terminam fora do limite da visualização
            if (program.startedBeforeGrid) {
              label = `<< ${label}`;
            }

            if (program.endedAfterGrid) {
              label = `${label} >>`;
            }

            seriesData.push({
              x: moment(program.startsIn).valueOf(),
              y: 30 * (scheduleIndex + 1),
              label: label.toUpperCase(),
              useHTML: true,
              marker: {
                enabled: !program.startedBeforeGrid,
              }
            });
          });

        // Inclui ponto vazio, para completar o fim do último programa
        const lastProgramIndex = schedule.programs.length - 1;
        const lastProgram = schedule.programs[lastProgramIndex];
        const lastProgramEnd = lastProgram && lastProgram.endsIn ? lastProgram.endsIn : null;

        if (lastProgramEnd) {
          const lastProgramEndUnix = moment(lastProgramEnd).seconds(0).valueOf();
          seriesData.push({
            x: lastProgramEndUnix,
            y: 30 * (scheduleIndex + 1),
            marker: {
              enabled: true,
              radius: 3,
              symbol: 'square',
            },
          });
        }

        chartSeries.push({
          color: this.getNetworkColor(schedule.tvNetworkId),
          lineWidth: 2,
          name: 'timeline',
          linkedTo: schedule.tvNetworkId,
          // showInLegend: false, // alternativa ao linkedTo
          dashStyle: schedule.isHistoricAverage ? 'dot' : 'line',
          marker: {
            enabled: true,
            symbol: 'circle',
            lineColor: this.getNetworkColor(schedule.tvNetworkId),
            radius: 5,
          },
          yAxis: 0,
          dataLabels: {
            enabled: true,
            align: 'left',
            y: 4,
            format: '{point.label}',
            color: this.getNetworkColor(schedule.tvNetworkId),
            style: {
              fontSize: 10,
            },
          },
          data: seriesData,
        });
      });

      return chartSeries;
    },

    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    
    getNetworkColor(networkId) {
      if (!(this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor)) {
        return '#000000';
      }
      return this.tvNetworks_v2[networkId].customColor;
    },

    highlightSeries(chartSeries, serieName) {
      chartSeries.forEach((series) => {
        series.setState('inactive');
      });

      const relativeCurrentSeries = this.chart.series.filter(s => s.name === serieName);

      relativeCurrentSeries.forEach((series) => {
        series.setState('hover'); 
      });
    },
    
    generateYAxisConfig({
      programLinesCount = 0,
      lineHeight = 20,
      chartHeight = this.windowHeight,
    }) {
      const programAxisHeight = lineHeight * programLinesCount;
      const minuteAxisTop = programAxisHeight + lineHeight;
      const minuteAxisHeight = chartHeight - minuteAxisTop - 330;

      return [
        {
          maxPadding: 0.25,
          endOnTick: false,
          minPadding: 0.05,
          startOnTick: false,
          height: programAxisHeight,
          gridLineWidth: 0,
          labels: {
            enabled: false,
          }
        },
        {
          height: minuteAxisHeight,
          top: minuteAxisTop,
          offset: 0,
          opposite: false,
          labels: {
            x: 0,
          },
        }
      ];
    },

    generateStockOptions(context) {
      return {
        chart: {
          marginLeft: 30,
          marginRight: 30,
        },
        navigator: {
          enabled: true,
          adaptToUpdatedData: true,
          height: 20,
          margin: 10,
          series: {
            // oculta a linha do navigator
            lineWidth: 0,
            fillOpacity: 0,
          },
          xAxis: {
            labels: {
              format: '{value:%H:%M}',
            },
          },
        },
        rangeSelector: {
          inputEnabled: false,
          enabled: false,
        },
        legend: {
          enabled: true,
          useHTML: true,
        },
        xAxis: {
          ordinal: false,
          type: 'datetime',
          maxRange: 12 * 3600000,
          labels: {
            format: '{value:%H:%M}',
          },
        },
        yAxis: [],
        time: {
          timezone: 'America/Sao_Paulo',
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          shadow: true,
          animation: false,
          borderWidth: 0,
          borderRadius: 4,
          padding: 16,
          useHTML: true,
          formatter: (a) => displayTvNetworksTooltip(a, context),
        },
        plotOptions: {
          series: {
            animation: false,
            marker: { enabled: false },
            events: {
              mouseOver() {
                context.highlightSeries(this.chart.series, this.name);
              },
            },
          },
        },
        exporting: {
          sourceWidth: 980,
          sourceHeight: 540,
          chartOptions: {
            navigator: {
              enabled: false,
            },
            scrollbar: {
              enabled: false,
            },
            legend: {
              margin: 10,
            },
          }
        },
        series: [],
        credits: { enabled: false },
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.container {
  background-color: #FFFFFF;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 80px - 72px - 55px);

  .chart {
    height: 100%;
  }

  .loading {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-gray-lighter, 0.8);

    .spinner {
      width: 40px;
      height: 40px;
      animation: rotator $duration linear infinite;

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

  .empty-data-message {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($color-gray-lighter, 1);
  }

  .grid-disclaimer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    font-family: "Roboto";
    color: #222;
  }
}
</style>
