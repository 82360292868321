<template>
  <div ref="ctx" class="chart" :class="{loading: isLoading}" />
</template>

<script>
import Highcharts from 'highcharts/highstock';
import moment from 'moment-timezone';
import { mapGetters, mapState } from 'vuex';
import _ from 'lodash';


export default {
  props: [
    'date',
  ],
  data() {
    return {
      chartObject: null,
      plotBands: null,
      config: {
        chart: {
          type: 'spline',
          zoomType: 'x',
          style: {
            fontFamily: 'Roboto',
          },
        },
        time: {
          timezone: 'America/Sao_Paulo',
        },
        scrollbar: { enabled: false },
        title: { text: '' },
        xAxis: {
          visible: !this.isMinimized,
          minRange: 5,
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: '%H:%M',
            second: '%H:%M',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%H:%M',
            week: '%H:%M',
            month: '%H:%M',
            year: '%H:%M',
          },
          plotBands: [],
        },

        tooltip: {
          shared: true,
          crosshairs: true,
          shadow: true,
          animation: false,
          valueDecimals: 2,
          borderWidth: 0,
          borderRadius: 4,
          padding: 16,
          backgroundColor: 'white',
          useHTML: true,
          formatter: (a) => {
            const { hoverPoint, hoverPoints } = a.chart;
            const { x } = hoverPoint;

            const date = moment.unix(x / 1000);
            const dateFormat = date.format('DD/MM/YYYY HH:mm');

            if (this.isMinimized) return dateFormat;

            const hoverPointsGroupeds = _.chain(hoverPoints)
              .groupBy('series.name')
              .map((value, key) => ({ tvNetworkId: key, hoverpoints: value }))
              .value();

            const html = hoverPointsGroupeds.map(({ hoverpoints, tvNetworkId }) => {
              const logo = this.getNetworkLogo(tvNetworkId);

              const points = _.chain(hoverpoints)
                .map(({ y, series }) => ({
                  isHistoric: series.options.dashStyle !== 'line',
                  name: series.name,
                  color: series.color,
                  data: y,
                }))
                .orderBy('isHistoric', 'asc')
                .value();

              return `
                <div style="
                       color: ${points[0].color};
                       border-radius: 8px;
                       margin: 4px;
                       background: white;
                       width: 82px;
                     "
                >
                  <div style="
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         margin-left: 4px;
                       "
                  >

                    <div>${logo ? `<img src="${logo}" height="25">` : points[0].name}</div>
                    <div style="
                           font-family: Audiowide;
                           font-weight: normal;
                           font-size: 1.2em;
                           margin-right: 8px;
                           text-align: right;
                           color: #666666;
                        "
                    >
                        ${points[0].data}
                    </div>
                  </div>
                  <div style="
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         padding: 8px;
                         font-size: 0.72em;
                         font-weight: 500;
                         border-top: solid 1px #F5F5F5;
                         color: #666666;
                       "
                  >
                    Média: ${typeof points[1] !== 'undefined' ? points[1].data : '0'}
                  </div>
                </div>
              `;
            }).join('');

            return `
              <div style="
                     margin: -16px;
                     width: 416px;
                     justify-content: space-around;
                   "
              >
                <div style="
                       font-weight: bold;
                       text-align: center;
                       background: #FFF;
                       padding: 8px;
                       z-index: 99;
                       position: relative;
                     "
                >
                  ${dateFormat}
                </div>
                <div style="
                       display: flex;
                       flex-wrap: wrap;
                       background: #F5F5F5;
                       padding: 8px;
                       z-index: 99;
                       position: relative;
                     "
                >
                  ${html}
                </div>
              </div>
            `;
          },
        },

        yAxis: {
          title: { text: 'Audiência' },
        },


        plotOptions: {
          series: {
            animation: false,
            marker: { enabled: false, symbol: 'circle' },

          },
        },
        series: [],
        legend: { enabled: true },
        credits: { enabled: false },
      },

    };
  },


  computed: {
    ...mapState(['settings']),
    ...mapGetters(['tvNetworks', 'tvNetworks_v2', 'globoDate']),
    ...mapGetters('sprintChart', ['sprintAudiences', 'isLoading']),
  },

  watch: {
    date: {
      deep: true,
      handler(value) {
        this.clearChart();
        this.$store.dispatch(
          'sprintChart/getSprintChart',
          { currentDate: value, handleLoading: !value.isAutoUpdate },
        );
      },
    },
    sprintAudiences: {
      deep: true,
      handler(value) {
        this.redrawChart(value);
      },
    },
  },

  methods: {


    getNetworkColor(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor : '';
    },

    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },

    getValueOfDateByMarket(date) {
      const dateTimezone = moment.tz(date, 'America/Sao_Paulo');
      if (this.settings.market.id === 'MAN') return dateTimezone.clone().subtract(1, 'hours').valueOf();
      return dateTimezone.clone().valueOf();
    },

    clearChart() {
      this.config.series = [];
      this.config.xAxis.plotBands = [];
    },

    redrawChart(value) {
      value.series.map((item) => {
        const serie = {
          pointStart: this.getValueOfDateByMarket(this.date.startsIn),
          pointInterval: 60 * 1000,
          pointRange: 60 * 1000,
          color: item.average !== true ? this.getNetworkColor(item.tvNetworkId) : `${this.getNetworkColor(item.tvNetworkId)}80`,
          name: item.tvNetworkId,
          data: item.data,
          dashStyle: item.average === true ? 'dot' : 'line',
          visible: (item.tvNetworkId !== 'TL' && item.tvNetworkId !== 'TLE'),
        };
        this.config.series.push(serie);
      });


      value.programs.map((item, idx) => {
        const text = `<div class='programChartLabels'><span class='programLabelLink'>${item.programName}</span></div>`;
        const style = {
          fontSize: '0.72em',
          backgroundColor: '#0025a0',
          color: 'white',
          padding: '4px 8px',
          lineHeight: '24px',
          borderRadius: '4px',
          opacity: '0.72',
        };

        this.config.xAxis.plotBands.push({
          from: this.getValueOfDateByMarket(item.startDate),
          to: this.getValueOfDateByMarket(item.endDate),
          color: idx % 2 === 0 ? '#fafafa' : '#ffffff',
          zIndex: 0,
          label: {
            text,
            style,
            useHTML: true,
          },
        });
      });

      this.setupChart();
    },

    setupChart() {
      this.chartObject = Highcharts.chart(this.$refs.ctx, this.config);
    },
  },
};
</script>

<style lang="scss" scoped>
  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }

  .chart{
    display: flex;
   
    &.loading{
      display: block;
      content: '';
      opacity: 0.5;
    }
  }

  .loading {
    &::after {
    display: block;
      content: '';
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(90deg, #33333308, rgba(255, 255, 255, 0.2), #33333308);
      animation: loading 1.5s infinite;
    }
  }
</style>
