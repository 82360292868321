<template>
  <div class="content">
    <div class="topo">
      <button type="button" class="btn ripple" @click="goToPage('Settings')">
        <i class="icone material-icons">
          keyboard_arrow_left
        </i>
      </button>
      <div class="title">
        Personalização
      </div>
    </div>
    <!-- // -->

    <div class="middle">
      <div class="description">
        Você pode escolher o tipo de informação que será exibida na tela, entre audiência e share.
      </div>

      <!-- Painel // Complementos -->
      <div class="panel">
        <div class="title">
          <div class="text">
            <!-- <i class="icone material-icons">
              add_box
            </i> -->
            Complementos
          </div>
          <div class="line" />
        </div>

        <!-- Itens de navegação -->
        <div v-if="settings.hasSampling" class="item" @click="changeShowSampling()">
          Amostra

          <label class="switch">
            <input :checked="isShowSampling"
                   type="checkbox"
            >
            <span class="slider round ripple" />
          </label>
        </div>

        <div class="item" @click="changeShowCompetitor()">
          Programação concorrente

          <label class="switch">
            <input :checked="isShowCompetitor"
                   type="checkbox"
            >
            <span class="slider round ripple" />
          </label>
        </div>
      </div>
      <!-- // -->

      <!-- Painel // Audiência / Share -->
      <div class="panel">
        <div class="title">
          <div class="text">
            <!-- <i class="icone material-icons">
              timelapse
            </i> -->
            Audiência / Share
          </div>
          <div class="line" />
        </div>

        <!-- Itens de navegação -->
        <div class="item" @click="changeDefaultValue('audience')">
          Audiência

          <label class="switch">
            <input :checked="defaultValue === 'audience'"
                   type="radio"
            >
            <span class="slider round ripple" />
          </label>
        </div>

        <div v-if="settings.hasTLE" class="item" @click="changeDefaultValue('shareTLE')">
          Share TLE

          <label class="switch">
            <input :checked="defaultValue === 'shareTLE'"
                   type="radio"
            >
            <span class="slider round ripple" />
          </label>
        </div>

        <div v-if="settings.hasTL" class="item" @click="changeDefaultValue('shareTL')">
          Share TL

          <label class="switch">
            <input :checked="defaultValue === 'shareTL'"
                   type="radio"
            >
            <span class="slider round ripple" />
          </label>
        </div>
      </div>
      <!-- // -->

      <!-- Painel // Configurações -->
      <!-- <div class="panel">
        <div class="title">
          <div class="text">
            <i class="icone material-icons">
              show_chart
            </i>
            Média Histórica
          </div>
          <div class="line" />
        </div>

        <div class="item">
          2 Semanas
          <label class="switch">
            <input :checked="performanceHistory === 2"
                   type="radio" @change="changePerformanceHistory(2)"
            >
            <span class="slider round ripple" />
          </label>
        </div>

        <div class="item">
          4 Semanas
          <label class="switch">
            <input :checked="performanceHistory === 4"
                   type="radio" @change="changePerformanceHistory(4)"
            >
            <span class="slider round ripple" />
          </label>
        </div>

        <div class="item">
          6 Meses
          <label class="switch">
            <input :checked="performanceHistory === 26"
                   type="radio" @change="changePerformanceHistory(26)"
            >
            <span class="slider round ripple" />
          </label>
        </div>

        <div class="item">
          12 Meses
          <label class="switch">
            <input :checked="performanceHistory === 52"
                   type="radio" @change="changePerformanceHistory(52)"
            >
            <span class="slider round ripple" />
          </label>
        </div>
      </div> -->

      <!-- // -->
    </div>
    <!-- // -->

    <div class="slice">
      <button type="button" class="btn btn_full btn_secondary ripple"
              @click="applyPreferences()"
      >
        Aplicar
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      defaultValue: '',
      performanceHistory: '',
      isShowSampling: false,
      isShowCompetitor: false,
    };
  },
  computed: {
    ...mapState(['settings']),
  },
  mounted() {
    this.$store.dispatch('hideMobileNavigation');
    this.defineEnableBtns();
  },
  beforeDestroy() {
    this.$store.dispatch('showMobileNavigation');
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
    applyPreferences() {
      this.$store.dispatch('selectDefaultValue', this.defaultValue);
      this.$store.dispatch('selectPerformanceHistory', this.performanceHistory);
      this.$store.dispatch('selectIsShowSampling', this.isShowSampling);
      this.$store.dispatch('selectIsShowCompetitor', this.isShowCompetitor);
      setTimeout(() => { // delay to not be too fast
        this.goToPage('Settings');
      }, 400);
    },
    defineEnableBtns() {
      this.defaultValue = this.settings.defaultValue;
      this.performanceHistory = this.settings.performanceHistory;
      this.isShowSampling = this.settings.isShowSampling;
      this.isShowCompetitor = this.settings.isShowCompetitor;
    },
    changeDefaultValue(defaultValue) {
      this.defaultValue = defaultValue;
    },
    changePerformanceHistory(performanceHistory) {
      this.performanceHistory = performanceHistory;
    },
    changeShowSampling() {
      this.isShowSampling = !this.isShowSampling;
    },
    changeShowCompetitor() {
      this.isShowCompetitor = !this.isShowCompetitor;
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

// SMARTPHONES
@media (max-width: 600px) {

  .content {
    position: relative;
    z-index: 999;
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    animation: fadeIn 0.8s ease-in-out forwards;

    .topo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 64px;
        background: linear-gradient(140deg, $color-primary-light, $color-secondary);
        background-size: 100% 100%;
        box-shadow: 0 8px 16px rgba($color-gray-darker, 0.16);

        // Botão
        .btn {
            width: 56px;
            height: 56px;
            margin: 0;
            padding: 0;
            box-shadow: none;

            i {
                margin: 4px 0 0 0;
                padding: 0;
            }

                // Ripple Effect
                &.ripple {
                    background-position: center;
                    background: rgba($color-gray-back, 0);
                    transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

                    & i {
                        color: $color-gray-lighter;
                        transition: color 0.56s ease-in-out;
                    }

                    &:hover {
                        background: rgba($color-gray-back, 0.04) radial-gradient(circle, transparent 1%, rgba($color-gray-back, 0.04) 1%) center/15000%;
                        color: $color-gray-main;

                        & i {
                            color: $color-gray-lighter;
                        }
                    }

                    &:active {
                        background-color: rgba($color-gray-lighter, 0.04);
                        background-size: 100%;
                        transition: background 0s;
                    }
                }
        }
        // ------------

        .title {
            font-family: $secondary-typo;
            font-weight: 500;
            font-size: 0.88em;
            text-transform: uppercase;
            color: $color-gray-lighter;
            margin: 0 0 0 8px;
        }
    }
    // ---------------

    .middle {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 0 156px 0;
        overflow-y: auto;
        background-color: $color-gray-back;

        // Description
        .description {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            padding: 16px;
            width: 100%;
            margin: 8px 0 16px 0;
            font-family: $secondary-typo;
            font-size: 0.88em;
            font-style: italic;
            text-align: justify;
            color: $color-gray-dark;

            &::after {
                content: "";
                display: block;
                width: 24px;
                height: 4px;
                margin: 16px 0 0 0;
                background-color: rgba($color-gray-light, 0.4);
            }
        }
        // --------------

        // Panel
        .panel {
            display: block;
            width: calc(100% - 32px);
            box-sizing: border-box;
            padding: 8px 0;
            margin: 0 auto 16px auto;
            background: $color-gray-lighter;
            border-radius: 4px;
            // box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
            // opacity: 0;
            // animation-delay: 1s !important;
            // animation: fadeInRight 1s ease-in-out forwards;

            .title {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin: 0 0 16px 0;
              padding: 8px 8px 16px 16px;
              border-bottom: solid 1px rgba($color-gray-light, 0.24);

              .text {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-family: $primary-typo;
                font-size: 0.72em;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.08em;
                color: $color-gray-dark;

                i {
                  font-size: 1.4em;
                  margin: 0 8px 0 8px;
                  color: $color-gray-light;
                }
              }
            }

            // Itens
            .item {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 100%;
              padding: 8px 16px;

              font-family: $secondary-typo;
              font-size: 0.88em;

              .category {
                background-color: $color-gray-back;
                padding: 4px;
                width: 48px;
                margin-right: 16px;
                text-align: center;
                font-weight: 600;
                font-size: 0.9em;
              }

              // Switch
              .switch {
                position: relative;
                display: inline-block;
                margin-left: auto;
                width: 48px;
                height: 28px;
                pointer-events: none;

                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: $color-gray-light;
                  transition: .4s;

                    &::before {
                      position: absolute;
                      content: "";
                      height: 22px;
                      width: 22px;
                      left: 3px;
                      bottom: 3px;
                      border-radius: 100%;
                      background-color: $color-gray-lighter;
                      box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
                      transition: .4s;
                    }

                    /* Rounded sliders */
                    &.round {
                      border-radius: 34px;
                    }
                }

                input:checked + .slider {
                  background-color: $color-secondary;
                }

                input:checked + .slider::before {
                  transform: translateX(20px);
                }
            }
        }
    }
    }
    // ---------------

    .slice {
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 56px;
        background-color: $color-gray-lighter;

        .btn {
            border: none;

            &:hover {
              border: none;
            }
        }
    }
    // ---------------
  }

}

</style>
