import bffRealtime from '@/gateways/bff-realtime';
import { set } from 'vue-gtag';

// initial state
const state = {
  currentUser: null,
  isLoggedIn: !!window.localStorage.getItem('token'),
  isSimulateUser: !!window.localStorage.getItem('manager-token'),
};

// getters
const getters = {
  currentUser: state => state.currentUser,
  isLoggedIn: state => state.isLoggedIn,
  isSimulateUser: state => state.isSimulateUser,
};

// actions
const actions = {
  async getCurrentUser({ commit }) {
    const { data } = await bffRealtime.getCurrentUser();
    commit('SET_CURRENT_USER', data);
    commit('SET_SETTINGS', data.preferences);
  },
  async loginAccounts({ commit }, credentials) {
    const { data } = await bffRealtime.login(credentials, true);
    commit('LOGIN_SUCCESS', data);
  },
  async login({ commit }, credentials) {
    const { data } = await bffRealtime.login(credentials, true);
    commit('LOGIN_SUCCESS', data.token);
  },
  async logoutSessionUser({ commit }) {
    commit('LOGOUT');
  },
  async logout({ commit }) {
    await bffRealtime.logout();
    commit('LOGOUT');
  },
  async simulateUser({ commit }, user) {
    const { data } = await bffRealtime.simulateUser(user);
    commit('SIMULATE_USER', data.token);
  },
  exitSimulateUser({ commit }) {
    commit('EXIT_SIMULATE_USER');
  },
};

// mutations
const mutations = {
  LOGIN_SUCCESS(state, data) {
    window.localStorage.setItem('token', data.token);
    window.localStorage.setItem('refreshToken', data.refreshToken);
    state.isLoggedIn = true;
  },
  LOGOUT(state) {
    window.localStorage.removeItem('token');
    window.localStorage.removeItem('refreshToken');
    state.currentUser = null;
    state.isLoggedIn = false;
  },
  SET_CURRENT_USER(state, user) {
    state.currentUser = {
      name: user.name,
      email: user.email,
      department: user.department,
    };

    set('userId', user.email);
    set('dimension1', user.email);
    set('dimension2', user.name);
    set('dimension3', user.department);
  },
  SIMULATE_USER(state, token) {
    const managerToken = window.localStorage.getItem('token');
    window.localStorage.setItem('manager-token', managerToken);
    window.localStorage.setItem('token', token);
    state.isSimulateUser = true;
  },
  EXIT_SIMULATE_USER() {
    const managerToken = window.localStorage.getItem('manager-token');
    window.localStorage.setItem('token', managerToken);
    window.localStorage.removeItem('manager-token');
    state.isSimulateUser = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
