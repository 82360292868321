<template>
  <div class="content">
    <div class="topo">
      <button type="button" class="btn ripple" @click="goToPage('Settings')">
        <i class="icone material-icons">
          keyboard_arrow_left
        </i>
      </button>
      <div class="title">
        Definir Emissoras
      </div>
    </div>
    <!-- // -->

    <div class="middle">
      <div class="description">
        Você pode ativar / desativar emissoras na lista abaixo, essa configuração será replicada em todo o app.
      </div>

      <!-- Painel // Configurações -->
      <div class="panel">
        <div class="title">
          <div class="text">
            <!-- <i class="icone material-icons">
              tv
            </i> -->
            Emissoras
          </div>
          <div class="line" />
        </div>

        <!-- Itens de navegação -->
        <draggable v-model="tvNetworkIdsEnabled"
                   :move="handleMove"
                   :options="{
                     draggable:'.draggableItem',
                     emptyInsertThreshold: 20,
                     removeCloneOnHide: true,
                     animation: 80,
                     bubbleScroll: true,
                     ghostClass: 'ghost-background',
                     delay: 200,
                   }"
                   @start="drag=true" @end="drag=false"
        >
          <div v-for="tvNetworkId in tvNetworksEnabled"
               :key="tvNetworkId"
               class="item"
               :class="{
                 especial: tvNetworkId === tvNetworksEnabled[0],
                 draggableItem: (tvNetworkId !== 'TL'
                   && tvNetworkId !== 'TLE')
               }"
               @click="changeTvNetworkStatus(tvNetworkId)"
          >

            <span class="category">
              {{ transNetworkNameById(tvNetworkId) }}
            </span>

            <label class="switch">
              <input :checked="true" type="radio">
              <span class="slider round ripple" />
            </label>
          </div>

          <!-- DISABLED -->
          <div v-for="tvNetworkId in tvNetworksDisabled"
               :key="tvNetworkId"
               class="item"
               @click="changeTvNetworkStatus(tvNetworkId)"
          >
            <span class="category">
              {{ transNetworkNameById(tvNetworkId) }}
            </span>

            <label class="switch">
              <input :checked="false" type="radio">
              <span class="slider round ripple" />
            </label>

          </div>
        </draggable>
      </div>
      <!-- // -->
    </div>
    <!-- // -->

    <div class="slice">
      <button type="button" class="btn btn_full btn_secondary ripple"
              @click="applyPreferences()"
      >
        Aplicar
      </button>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import draggable from 'vuedraggable';
import _ from 'lodash';
import transformMixin from '@/utils/transformMixin';


export default {
  components: {
    draggable,
  },
    mixins: [transformMixin],
  data() {
    return {
      marketIdsEnabled: [],
      tvNetworkIdsEnabled: [],
      allowedMainTvNetworkMoves: process.env.ALLOWED_MAIN_TV_NETWORKS_SETTINGS_PANEL_MOVE
    };
  },
  computed: {
    ...mapState(['system', 'settings', 'market']),
    ...mapGetters(['allTvNetworksId', 'tvNetworks']),
    tvNetworksAvailable() {
      return _.union(...this.marketsEnabled
        .map(({ tvNetworks }) => tvNetworks.map(({ id }) => id)));
    },
    tvNetworksDisabled() {
      const disabled = [
        ...this.tvNetworksAvailable.filter(id => (this.tvNetworksEnabled.indexOf(id) < 0)),
      ];
      return this.sortTvNetwork(disabled);
    },
    tvNetworksEnabled() {
      return [...this.tvNetworkIdsEnabled.filter(
        tvNetworkId => this.tvNetworksAvailable.indexOf(tvNetworkId) >= 0,
      )];
    },
    marketsEnabled() {
      return [...this.marketIdsEnabled.map(
        marketId => this.market.markets.find(market => market.id === marketId),
      ).filter(market => market instanceof Object)];
    },
  },
  mounted() {
    this.$store.dispatch('hideMobileNavigation');
    this.defineEnableBtns();
  },
  beforeDestroy() {
    this.$store.dispatch('showMobileNavigation');
  },
  methods: {
    handleMove(evt) {
      const { element: tvNetwork, futureIndex, index } = evt.draggedContext;
      if ((!this.allowedMainTvNetworkMoves.includes(tvNetwork) && futureIndex === 0) || (index === 0)) {
        return false;
      }
      return true;
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    applyPreferences() {
      this.$store.dispatch('selectTvNetworkIdOrder', this.tvNetworksEnabled);
      const networkExist = this.tvNetworks.find(({ id }) => (id === this.settings.tvNetworkId));
      if (!networkExist) this.$store.dispatch('selectTvNetworkId', this.tvNetworks[0].id);
      setTimeout(() => { // delay to not be too fast
        this.goToPage('Settings');
      }, 400);
    },
    defineEnableBtns() {
      this.marketIdsEnabled = [...this.settings.marketIdOrder];
      this.tvNetworkIdsEnabled = [...this.settings.tvNetworkIdOrder];
      this.usePrograms = this.settings.usePrograms;
    },
    changeTvNetworkStatus(id) {
      const mainTvNetwork = this.tvNetworksEnabled[0];
      if (id === 'GLOBO') return;
      if (id === mainTvNetwork) return;
      const index = this.tvNetworkIdsEnabled.indexOf(id);
      if (index > -1) {
        if (this.tvNetworksEnabled.length <= 5) {
          // show error message
        } else {
          // remove
          this.tvNetworkIdsEnabled.splice(index, 1);
        }
      } else {
        // append
        this.tvNetworkIdsEnabled.push(id);
        this.tvNetworkIdsEnabled = this.sortTvNetwork(this.tvNetworkIdsEnabled);
      }
    },
    sortTvNetwork(tvNetwork) {
      // put TL and TLE as the last elements of array
      const clone = [...tvNetwork];
      ['TLE', 'TL'].forEach((id) => {
        const index = clone.indexOf(id);
        if (index > -1) {
          clone.splice(index, 1);
          clone.push(id);
        }
      });
      return clone;
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

// SMARTPHONES
@media (max-width: 600px) {

  .content {
    position: relative;
    z-index: 999;
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    animation: fadeIn 0.8s ease-in-out forwards;

    .topo {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 64px;
        background: linear-gradient(140deg, $color-primary-light, $color-secondary);
        background-size: 100% 100%;
        box-shadow: 0 8px 16px rgba($color-gray-darker, 0.16);

        // Botão
        .btn {
            width: 56px;
            height: 56px;
            margin: 0;
            padding: 0;
            box-shadow: none;

            i {
                margin: 4px 0 0 0;
                padding: 0;
            }

                // Ripple Effect
                &.ripple {
                    background-position: center;
                    background: rgba($color-gray-back, 0);
                    transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

                    & i {
                        color: $color-gray-lighter;
                        transition: color 0.56s ease-in-out;
                    }

                    &:hover {
                        background: rgba($color-gray-back, 0.04) radial-gradient(circle, transparent 1%, rgba($color-gray-back, 0.04) 1%) center/15000%;
                        color: $color-gray-main;

                        & i {
                            color: $color-gray-lighter;
                        }
                    }

                    &:active {
                        background-color: rgba($color-gray-lighter, 0.04);
                        background-size: 100%;
                        transition: background 0s;
                    }
                }
        }
        // ------------

        .title {
            font-family: $secondary-typo;
            font-weight: 500;
            font-size: 0.88em;
            text-transform: uppercase;
            color: $color-gray-lighter;
            margin: 0 0 0 8px;
        }
    }
    // ---------------

    .middle {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 0 156px 0;
        overflow-y: auto;
        background-color: $color-gray-back;

        // Description
        .description {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            padding: 16px;
            width: 100%;
            margin: 8px 0 16px 0;
            font-family: $secondary-typo;
            font-size: 0.88em;
            font-weight: 400;
            font-style: italic;
            text-align: justify;
            color: $color-gray-dark;

            &::after {
                content: "";
                display: block;
                width: 24px;
                height: 4px;
                margin: 16px 0 0 0;
                background-color: rgba($color-gray-light, 0.4);
            }
        }
        // --------------

        // Panel
        .panel {
            display: block;
            width: calc(100% - 32px);
            box-sizing: border-box;
            padding: 8px 0;
            margin: 0 auto;
            background: $color-gray-lighter;
            border-radius: 4px;
            // box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
            // opacity: 0;
            // animation-delay: 1s !important;
            // animation: fadeInRight 1s ease-in-out forwards;

            .title {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin: 0 0 16px 0;
              padding: 8px 8px 16px 16px;
              border-bottom: solid 1px rgba($color-gray-light, 0.24);

              .text {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                font-family: $primary-typo;
                font-size: 0.72em;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 0.08em;
                color: $color-gray-dark;

                i {
                  font-size: 1.4em;
                  margin: 0 8px 0 8px;
                  color: $color-gray-light;
                }
              }
            }

            // Itens
            .item {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 100%;
              padding: 8px 16px;

              font-family: $secondary-typo;
              font-size: 0.88em;
              font-weight: 400;

              .category {
                background-color: $color-gray-back;
                padding: 4px;
                width: 48px;
                margin-right: 16px;
                text-align: center;
                font-weight: 600;
                font-size: 0.9em;
                color: $color-gray-main;
              }

              // Switch
              .switch {
                position: relative;
                display: inline-block;
                margin-left: auto;
                width: 48px;
                height: 28px;
                pointer-events: none;

                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: $color-gray-light;
                  transition: .4s;

                    &::before {
                      position: absolute;
                      content: "";
                      height: 22px;
                      width: 22px;
                      left: 3px;
                      bottom: 3px;
                      border-radius: 100%;
                      background-color: $color-gray-lighter;
                      box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
                      transition: .4s;
                    }

                    /* Rounded sliders */
                    &.round {
                      border-radius: 34px;
                    }
                }

                input:checked + .slider {
                  background-color: $color-secondary;
                }

                input:checked + .slider::before {
                  transform: translateX(20px);
                }
            }
        }
    }
    }
    // ---------------

    .slice {
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 56px;
        background-color: $color-gray-lighter;

        .btn {
            border: none;

            &:hover {
              border: none;
            }
        }
    }
    // ---------------
  }

}

</style>
