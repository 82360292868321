<template>
  <div>
    <div class="globo-programs-container">
      <div v-for="(item, idx) in sprintProgramAverages.dates" class="globo-program-item">
        <SprintItem :audience="item.globoProgram.audience"
                    :program-name="item.globoProgram.program"
                    :diff-audience-percent="item.globoProgram.diffAudienceInPercent"
                    :diff-audience-points="item.globoProgram.diffAudienceInPoints"
                    tv-network-id="GLOBO"
                    :show-logo="idx === 0"
                    :num-items="sprintProgramAverages.dates.length"
                    :is-winner="item.globoProgram.isWinner"
        />
      </div>
    </div>

    <div class="scroll-container">
      <div v-for="(item, idx) in sprintProgramAverages.dates" class="scroll-item">
        <SprintItem v-for="rival in item.rivalTvNetworks"
                    :audience="rival.averageAudience"
                    :program-name="getRivalProgramName(rival)"
                    :total-programs="getRivalPrograms(rival).length"
                    :rival-programs="getRivalPrograms(rival)"
                    :tv-network-id="rival.tvNetworkId"
                    :show-logo="idx === 0"
                    :is-winner="rival.averageIsWinner"
                    :item-selected="itemOver === rival.tvNetworkId"
                    :item-selected-color="getNetworkColor(rival.tvNetworkId)"
                    :item-position="idx"
                    :num-items="sprintProgramAverages.dates.length"
                    @sprint-item-over="onItemOver"
                    @sprint-item-out="onItemOut"
        />
      </div>
    </div>
    <div class="hour-main-container">
      <div v-for="hour in sprintProgramAverages.dates" class="hour-container">
        <div class="hour-item">
          <p>{{ getTimeFromDate(hour.startsIn) }}</p>
          <p style="margin-left: auto">
            {{ getTimeFromDate(hour.endsIn) }}
          </p>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapState } from 'vuex';
import SprintItem from './SprintItem.vue';


export default {
  components: {
    SprintItem,
  },
  props: [
    'date',
  ],
  data() {
    return {
      itemOver: '',
      temp: [1, 2, 3],
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters([
      'tvNetworks_v2',
    ]),
    ...mapGetters('sprintDash', ['sprintProgramAverages']),
    networksSelected() {
      const tvNetworksTemp = this.settings.market.tvNetworks.map(object => ({ ...object }));
      return this.settings.isSprintView ? tvNetworksTemp : this.tvNetworks;
    },
    idName() {
      return this.settings.isSprintView ? 'id' : 'exhibitionName';
    },
  },
  watch: {
    date: {
      deep: true,
      handler(value) {
        this.$store.dispatch(
          'sprintDash/getSprintProgramAverages',
          { currentDate: value, handleLoading: !value.isAutoUpdate },
        );
      },
    },
  },
  methods: {
    getRivalProgramName(rival) {
      return rival.totalPrograms > 0 ? rival.rivalPrograms[0].program : '--';
    },
    getRivalPrograms(rival) {
      if (rival.totalPrograms === 0) return [];

      return rival.rivalPrograms.slice(1);
    },
    getNetworkColor(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor : '';
    },
    onItemOver(value) {
      this.itemOver = value;
    },
    onItemOut() {
      this.itemOver = '';
    },
    getTimeFromDate(date) {
      return moment(date)
        .format('HH:mm');
    },
    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
  },
};

</script>
<style lang="scss" >
  .globo-programs-container{
    display: flex;

    .globo-program-item{
      flex: 1;
    }
  }

  .hour-main-container{
    display: flex;
    flex-direction: row;

    hr{
      margin-top: -1px;
    }

    .hour-container{
      flex: 1;
      margin-left: 8px
    }

    .hour-item{
      display: flex;
      flex-direction: row;
      font-family: Roboto;
      font-weight: 500;
      font-size: 12px;
      color: black;
    }
  }

  .scroll-container::-webkit-scrollbar {
    display: none;
  }

  .scroll-container {
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    height: 65%;

    .scroll-item{
      flex: 1
    }
  }

  .hour_divider{
    position: fixed;
    margin-top: -42%;
    width: 31.33%;
    margin-left: 0.6%;
  }
</style>
