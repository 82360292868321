import bffRealtime from '@/gateways/bff-realtime';

// initial state
const state = {
  isMinuteView: true, // table view selected
  isMarketView: true, // table view selected
  isSimuCastView: false,
  isSprintView: false,
  isRailView: false,
  isTableView: true,
  isDashView: false,
  isLoadingSpinner: false,
  isImportActivate: false,
  isLoading: false,
  isProgramRegister: false,
  isPermissionManager: false,
  hasSimuCast: false,
  hasSprint: false,
  isDescending: true,
  isShowSampling: false,
  isShowCompetitor: false,
  isButtonDownloadActivate: true,
  isKpi: false,
  isTipsTourDone: false, // if false: popup the tour when user enter in the system
  isMarketsWeightVisible: false,
  market: { // market selected
    id: 'RJ',
    name: 'RJ',
    exhibitionName: 'RJ',
  },
  tvNetworkId: 'GLOBO', // tvNetworkId selected
  amostraId: 'AMOSTRA',
  marketIdOrder: [], // order and show the markets in audience tables
  marketHidden: [],
  customProgramsMarkets: [],
  tvNetworkIdOrder: [], // order and show the tvnetworks in audience tables
  dateOptions: {
    day: 0,
    startTime: '',
    endTime: '',
  },
  defaultValue: 'audience',
  simulcastValue: 'audience',
  performanceHistory: 2,
};

// getters
const getters = {
  settings: state => state,
  tableView: state => (state.isMarketView
    ? { marketId: state.market.id }
    : { tvNetworkId: state.tvNetworkId }),
  userNetworksOder: state => state.tvNetworkIdOrder
    .map(((value, index) => ({ tvNetworkId: value, order: index }))),
  scheduleTvNetworkId: (state) => {
    const canViewOtherTvNetworkScheduleList = (
      !state.isMinuteView // É visão de programas
      && state.isMarketView // É visão por praças
      && state.isTableView // É visão por tabelas
    );

    if (canViewOtherTvNetworkScheduleList) {
      const position = 1;
      const firstTvNetwork = state.tvNetworkIdOrder.slice(position - 1, position + 1).shift();
      const availableTvNetworksAtMarket = state.market.tvNetworks.map(tvNetwork => tvNetwork.id);
      if (availableTvNetworksAtMarket.includes(firstTvNetwork)) {
        return firstTvNetwork;
      }
    }
    return 'GLOBO';
  },

  showCustomPrograms: (state) => {
    const isCustomMarket = state.customProgramsMarkets.includes(state.market.id);
    return state.usePrograms && isCustomMarket;
  },

  singleDefaultValue: (state) => {
    return state.defaultValue === 'audienceAndShareTLE' ? 'audience' : state.defaultValue;
  },

  dataTypeSelectedAsList: (state) => {
    if (state.defaultValue === 'audienceAndShareTLE') {
      return ['audience', 'shareTLE'];
    }
    return [state.defaultValue];
  },
};

// actions
const actions = {
  updatePreferencesDB({ state }) {
    bffRealtime.updateCurrentUserPreferences({ preferences: state });
  },
  selectMarket({ commit }, market) {
    commit('SET_MARKET', market);
  },
  selectTvNetworkId({ commit }, tvNetworkId) {
    commit('SET_TV_NETWORK_ID', tvNetworkId);
  },
  selectIsShowSampling({ commit }, isShowSampling) {
    commit('SET_IS_SHOW_SAMPLING', isShowSampling);
  },

  selectHasSimucast({ commit }, hasSimuCast) {
    commit('SET_HAS_SIMUCAST', hasSimuCast);
  },

  selectHasSprint({ commit }, hasSprint) {
    commit('SET_HAS_SPRINT', hasSprint);
  },

  selectIsShowCompetitor({ commit }, isShowCompetitor) {
    commit('SET_IS_SHOW_COMPETITOR', isShowCompetitor);
  },
  selectUsePrograms({ commit }, usePrograms) {
    commit('SET_USE_PROGRAMS', usePrograms);
  },
  selectUseSchedule({ commit }, useSchedule) {
    commit('SET_USE_SCHEDULE', useSchedule);
  },

  selectIsMinuteView({ commit }, isMinuteView) {
    commit('SET_IS_MINUTE_VIEW', isMinuteView);
  },
  selectIsMarketView({ commit }, isMarketView) {
    commit('SET_IS_MARKET_VIEW', isMarketView);
  },
  selectIsInSimuCastView({ commit }, isSimuCastView) {
    commit('SET_IS_SIMUCAST_VIEW', isSimuCastView);
  },

  selectIsInSprintView({ commit }, isSprintView) {
    commit('SET_IS_SPRINT_VIEW', isSprintView);
  },

  selectDetailsView({ commit }, view) {
    commit('SET_DETAILS_VIEW', view);
  },

  selectIsDescending({ commit }, isDescending) {
    commit('SET_IS_DESCENDING', isDescending);
  },
  selectIsKpi({ commit }, isKpi) {
    commit('SET_IS_KPI', isKpi);
  },
  selectMarketIdOrder({ commit }, marketIdOrder) {
    commit('SET_MARKET_ID_ORDER', marketIdOrder);
  },
  selectMarketHiden({ commit }, marketHidden) {
    commit('SET_MARKET_HIDDEN', marketHidden);
  },
  setCustomProgramsMarketsEnabled({ commit }, markets) {
    commit('SET_CUSTOM_PROGRAMS_MARKETS_ENABLED', markets);
  },
  selectTvNetworkIdOrder({ commit }, tvNetworkIdOrder) {
    commit('SET_TV_NETWORK_ID_ORDER', tvNetworkIdOrder);
  },
  selectDate({ commit }, date) {
    commit('SET_DATE', date);
  },
  selectDefaultValue({ commit }, defaultValue) {
    commit('SET_DEFAULT_VALUE', defaultValue);
  },

  selectSimiulcastValue({ commit }, simulcastValue) {
    commit('SET_SIMULCAST_VALUE', simulcastValue);
  },

  selectPerformanceHistory({ commit }, performanceHistory) {
    commit('SET_PERFORMANCE_HISTORY', performanceHistory);
  },
  selectIsTipsTourDone({ commit }, isTipsTourDone) {
    commit('SET_IS_TIPS_TOUR_DONE', isTipsTourDone);
  },
  selectIsLoading({ commit }, isLoading) {
    commit('SET_IS_LOADING', isLoading);
  },
  selectIsLoadingSpinner({ commit }, isLoadingSpinner) {
    commit('SET_IS_LOADING_SPINNER', isLoadingSpinner);
  },

  selectIsImportActivate({ commit }, isImportActivate) {
    commit('SET_IS_IMPORT_ACTIVATE', isImportActivate);
  },
  selectIsButtonDownloadActivate({ commit }, isButtonDownloadActivate) {
    commit('SET_IS_BUTTON_DOWNLOAD_ACTIVATE', isButtonDownloadActivate);
  },
  selectIsMarketsWeightVisible({ commit }, isMarketsWeightVisible) {
    commit('SET_IS_MARKETS_WEIGHT_VISIBLE', isMarketsWeightVisible);
  },


};

// mutations
const mutations = {
  SET_MARKET(state, market) {
    state.market = market;
  },
  SET_TV_NETWORK_ID(state, tvNetworkId) {
    state.tvNetworkId = tvNetworkId;
  },
  SET_IS_MINUTE_VIEW(state, isMinuteView) {
    state.isMinuteView = isMinuteView;
  },
  SET_IS_MARKET_VIEW(state, isMarketView) {
    state.isMarketView = isMarketView;
  },
  SET_IS_SIMUCAST_VIEW(state, isSimuCastView) {
    state.isSimuCastView = isSimuCastView;
    state.isMinuteView = false;
  },

  SET_IS_SPRINT_VIEW(state, isSprintView) {
    state.isSprintView = isSprintView;
    state.isMinuteView = false;
  },
  SET_DETAILS_VIEW(state, view) {
    if (view === 'sprint') {
      state.isSprintView = true;
      state.isTableView = false;
      state.isDashView = false;
      state.isRailView = false;
      state.isMarketView = true;
    } else if (view === 'table') {
      state.isTableView = true;
      state.isSprintView = false;
      state.isDashView = false;
      state.isRailView = false;
    } else if (view === 'dashboards') {
      state.isSprintView = false;
      state.isTableView = false;
      state.isDashView = true;
      state.isRailView = false;
    } else if (view === 'rail') {
      state.isRailView = true;
      state.isSprintView = false;
      state.isTableView = false;
      state.isDashView = false;
      state.isMarketView = true;
    }
  },

  SET_IS_DESCENDING(state, isDescending) {
    state.isDescending = isDescending;
  },
  SET_IS_KPI(state, isKpi) {
    state.isKpi = isKpi;
  },
  SET_IS_SHOW_SAMPLING(state, isShowSampling) {
    state.isShowSampling = isShowSampling;
  },
  SET_HAS_SIMUCAST(state, hasSprint) {
    state.hasSprint = hasSprint;
  },

  SET_IS_SHOW_COMPETITOR(state, isShowCompetitor) {
    state.isShowCompetitor = isShowCompetitor;
  },
  SET_USE_PROGRAMS(state, usePrograms) {
    state.usePrograms = usePrograms;
  },

  SET_USE_SCHEDULE(state, useSchedule) {
    state.useSchedule = useSchedule;
  },

  SET_TV_NETWORK_ID_ORDER(state, tvNetworkIdOrder) {
    state.tvNetworkIdOrder = tvNetworkIdOrder;
  },
  SET_DATE(state, date) {
    state.dateOptions = date;
  },
  SET_DEFAULT_VALUE(state, defaultValue) {
    state.defaultValue = defaultValue;
  },
  SET_SIMULCAST_VALUE(state, simulcastValue) {
    state.simulcastValue = simulcastValue;
  },

  SET_PERFORMANCE_HISTORY(state, performanceHistory) {
    state.performanceHistory = performanceHistory;
  },
  SET_MARKET_ID_ORDER(state, marketIdOrder) {
    state.marketIdOrder = marketIdOrder;
  },
  SET_MARKET_HIDDEN(state, marketHidden) {
    state.marketHidden = marketHidden;
  },

  SET_CUSTOM_PROGRAMS_MARKETS_ENABLED(state, markets) {
    state.customProgramsMarkets = markets;
  },
  SET_IS_TIPS_TOUR_DONE(state, isTipsTourDone) {
    state.isTipsTourDone = isTipsTourDone;
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_IS_LOADING_SPINNER(state, isLoadingSpinner) {
    state.isLoadingSpinner = isLoadingSpinner;
  },
  SET_IS_IMPORT_ACTIVATE(state, isImportActivate) {
    state.isImportActivate = isImportActivate;
  },
  SET_IS_BUTTON_DOWNLOAD_ACTIVATE(state, isButtonDownloadActivate) {
    state.isButtonDownloadActivate = isButtonDownloadActivate;
  },
  SET_IS_MARKETS_WEIGHT_VISIBLE(state, isMarketsWeightVisible) {
    state.isMarketsWeightVisible = isMarketsWeightVisible;
  },

  SET_SETTINGS(state, settings) {
    const { dateOptions, ...saveSettings } = settings;
    Object.keys(saveSettings).forEach((key) => {
      state[key] = settings[key];
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
