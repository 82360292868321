<template>
  <Modal :name="name"
         height="auto"
         :max-height="740"
         :width="isMinimized ? 200 : 950"
         class="modal-chart"
         :class="{ minimized: isMinimized }"
         :resizable="isMinimized"
         :click-to-close="!isMinimized"
         @before-open="beforeOpen"
         @before-close="beforeClose"
  >
    <div class="content">
      <div class="buttons">
        <button type="button" class="btn ripple" @click="toggleSize">
          <i class="icone material-icons">
            {{ isMinimized ? 'fullscreen' : 'fullscreen_exit' }}
          </i>
        </button>
        <button type="button" class="btn ripple" @click="closeModal">
          <i class="icone material-icons">
            close
          </i>
        </button>
      </div>
      <div class="head">
        <h1>
          Gráfico Realtime >
          <span>
            &nbsp;{{ dateSelected }}
          </span>
        </h1>

        <TagSelect :market-view="true" />
      </div>
      <!-- fim do topo -->

      <div class="middle">
        <div ref="ctx" style="width:100%; height:100%;" />
        <div v-if="loading" class="loading_small">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6"
                    stroke-linecap="round" cx="33" cy="33" r="30"
            />
          </svg>
        </div>
      </div>

      <div class="footer">
        <button type="button"
                class="btn btn_secondary btn_half ripple"
                @click="closeModal"
        >
          Voltar
        </button>
        <button type="button"
                class="btn btn_primary btn_half ripple"
                @click="toggleSize"
        >
          Versão Compacta
        </button>
      </div>
    </div>
    <!-- // -->
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import TagSelect from './Home/TagSelect.vue';

window.moment = moment;
Exporting(Highcharts);
Highcharts.setOptions({
  lang: {
    loading: 'Aguarde...',
    months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    weekdays: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
    shortMonths: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    exportButtonTitle: 'Exportar',
    printButtonTitle: 'Imprimir',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'Até',
    rangeSelectorZoom: 'Periodo',
    viewFullscreen: 'Exibir em tela cheia',
    printChart: 'Imprimir',
    downloadPNG: 'Baixar imagem PNG',
    downloadJPEG: 'Baixar imagem JPEG',
    downloadPDF: 'Baixar documento PDF',
    downloadSVG: 'Baixar imagem SVG',
    // resetZoom: 'Reset',
    // resetZoomTitle: 'Reset',
    // thousandsSep: '.',
    // decimalPoint: ',',
  },
});

export default {
  components: {
    TagSelect,
  },
  data() {
    return {
      getAudiencesTimeout: null,
      getProgramAveragesTimeout: null,
      currentAudienceTimeout: null,
      currentAudienceInterval: null,
      programAverages: null,
      loading: true,
      name: 'chart',
      config: null,
      chart: null,
      labels: null,
      datasets: null,
      plotBands: null,
      scrollId: null,
      isMinimized: false,
      isNetwokLegendHidden: {},
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['markets', 'tvNetworks', 'tvNetworks_v2', 'globoDate', 'marketDays', 'singleDefaultValue']),
    dateSelected() {
      const { day } = this.settings.dateOptions;
      if (this.marketDays[day]) {
        const { name } = this.marketDays[day];
        return name;
      }
      return '--';
    },
    isToday() {
      return this.settings.dateOptions.day === 0;
    },
  },
  methods: {
    beforeOpen() {
      this.isMinimized = false;
      this.$store.dispatch('hideMobileNavigation');
      this.getAudiences();

      const date = new Date();
      const startOfMinute = (60 - date.getSeconds()) * 1000;
      this.currentAudienceTimeout = setTimeout(() => {
        this.currentAudienceInterval = setInterval(() => {
          if (this.isToday) this.getAudiences();
        }, 30000);

        if (this.isToday) this.getAudiences();
      }, startOfMinute);

      this.unwatch = this.$store.watch(state => state.settings, () => {
        this.reloadChart();
        this.getAudiences();
      }, { deep: true });
    },
    beforeClose() {
      this.$store.dispatch('showMobileNavigation');
      this.reloadChart();
      this.unwatch();
      clearTimeout(this.getAudiencesTimeout);
      clearTimeout(this.getProgramAveragesTimeout);
      clearTimeout(this.currentAudienceTimeout);
      clearInterval(this.currentAudienceInterval);
    },
    closeModal() {
      setTimeout(() => {
        this.$modal.hide(this.name);
      }, 800);
    },
    reloadChart() {
      bffRealtime.cancelRequests('chart');
      this.loading = true;
      this.config = null;
      this.chart.destroy();
      this.chart = null;
      this.datasets = null;
      this.scrollId = null;
    },
    toggleSize() {
      this.config.legend.enabled = this.isMinimized;
      this.config.xAxis.visible = this.isMinimized;
      this.config.exporting.enabled = this.isMinimized;

      this.isMinimized = !this.isMinimized;
      this.config.yAxis.title.text = this.labelX();
      this.chart.update(this.config);
      if (this.isMinimized) {
        this.chart.setSize(518, 244);
      } else {
        this.chart.setSize(0, 0);
      }
    },
    async getAudiences() {
      clearTimeout(this.getAudiencesTimeout);

      const filterAudiences = {
        startDate: this.globoDate.startsIn,
        endDate: this.globoDate.endsIn || moment().format(),
        sortBy: ['-date'],
        size: 500,
        marketId: this.settings.market.id,
      };

      try {
        const { data } = await bffRealtime.getAudiences(
          filterAudiences, bffRealtime.tokenRequest('chart', 'audiences'),
        );
        this.updateChart(data.source);
        if (!this.scrollId) {
          this.scrollId = data.scrollId;
          this.getAudiencesByScrollId(this.scrollId);
        }

        await this.getProgramAverages();
        this.loading = false;
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.scrollId = null;
          this.getAudiencesTimeout = setTimeout(() => this.getAudiences(), 2000);
        }
      }
    },
    async getAudiencesByScrollId(scrollId) {
      const { data } = await bffRealtime.getScroll(
        scrollId, bffRealtime.tokenRequest('chart', 'audiencesByScrollId'),
      );
      if (data.source.length !== 0) {
        this.updateChart(data.source);
        this.getAudiencesByScrollId(scrollId);
      }
    },
    async getProgramAverages() {
      clearTimeout(this.getProgramAveragesTimeout);

      const filterProgramAverages = {
        startDate: this.globoDate.startsIn,
        endDate: this.globoDate.endsIn || moment().format(),
        marketName: this.settings.market.name,
        marketId: this.settings.market.id,
        isRivals: true,
        isCustom: this.settings.usePrograms,
      };

      try {
        const { data } = await bffRealtime.getProgramAverages(
          filterProgramAverages, bffRealtime.tokenRequest('chart', 'programAverages'),
        );
        this.programAverages = data;
        this.updatePlotBands();
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.getProgramAveragesTimeout = setTimeout(() => this.getProgramAverages(), 5000);
        }
      }
    },
    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    getValueOfDateByMarket(date) {
      if (this.settings.market.id === 'MAN' && this.settings.usePrograms) return moment(date).subtract(1, 'hours').valueOf();
      return moment(date).valueOf();
    },
    setupChart() {
      this.chart = Highcharts.chart(this.$refs.ctx, this.config);
      Object.keys(this.datasets).forEach((id) => {
        delete this.datasets[id].visible;
      });
      delete this.config.rangeSelector.selected;
    },
    setupConfig() {
      this.datasets = {};
      this.tvNetworks.forEach(({ id }) => {
        // if (id === 'TLE' || id === 'TL') return;
        this.datasets[id] = {
          pointStart: this.getValueOfDateByMarket(this.globoDate.startsIn),
          pointInterval: 60 * 1000,
          pointRange: 60 * 1000,
          color: this.getNetworkColor(id),
          name: id,
          data: [],
          visible: (id !== 'TL' && id !== 'TLE'),
        };
      });

      this.config = {
        chart: {
          type: 'line',
          zoomType: 'x',
          style: {
            fontFamily: 'Roboto',
          },
        },
        time: {
          timezone: 'America/Sao_Paulo',
          // useUTC: false,
        },
        scrollbar: { enabled: false },
        navigator: {
          enabled: true,
          margin: 10,
          height: 30,
        },
        title: { text: '' },
        xAxis: {
          visible: !this.isMinimized,
          minRange: 5,
          type: 'datetime',
          dateTimeLabelFormats: {
            millisecond: '%H:%M',
            second: '%H:%M',
            minute: '%H:%M',
            hour: '%H:%M',
            day: '%H:%M',
            week: '%H:%M',
            month: '%H:%M',
            year: '%H:%M',
          },
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          shadow: true,
          animation: false,
          valueDecimals: 2,
          borderWidth: 0,
          borderRadius: 4,
          padding: 16,
          backgroundColor: 'white',
          useHTML: true,
          formatter: (a) => {
            const { hoverPoint, hoverPoints } = a.chart;
            const { x } = hoverPoint;
            const date = moment.unix(x / 1000);
            const dateFormat = date.format('DD/MM/YYYY HH:mm');
            if (this.isMinimized) return dateFormat;

            const html = hoverPoints.map(({ y, series }) => {
              const { name, color } = series;
              const logo = this.getNetworkLogo(name);
              const programAvg = this.programByDate(name, date);
              const program = programAvg ? programAvg.program : '--';

              return `
                <div style="
                       color: ${color};
                       border-radius: 8px;
                       margin: 4px;
                       background: white;
                       width: 92px;
                     "
                >
                  <div style="
                         display: flex;
                         align-items: center;
                         justify-content: space-between;
                         margin-left: 4px;
                       "
                  >
                    <div>${logo ? `<img src="${logo}" height="25">` : name}</div>
                    <div style="
                           font-family: Audiowide;
                           font-weight: normal;
                           font-size: 1.2em;
                           margin-right: 8px;
                           text-align: right;
                           color: #666666;
                        "
                    >
                      ${y.toFixed(2)}
                    </div>
                  </div>
                  <div style="
                         overflow: hidden;
                         text-overflow: ellipsis;
                         white-space: nowrap;
                         padding: 8px;
                         font-size: 0.72em;
                         font-weight: 500;
                         border-top: solid 1px #F5F5F5;
                         color: #666666;
                       "
                  >
                    ${program}
                  </div>
                </div>
              `;
            }).join('');

            return `
              <div style="
                     margin: -16px;
                     width: 316px;
                     justify-content: space-around;
                   "
              >
                <div style="
                       font-weight: bold;
                       text-align: center;
                       background: #FFF;
                       padding: 8px;
                       z-index: 99;
                       position: relative;
                     "
                >
                  ${dateFormat}
                </div>
                <div style="
                       display: flex;
                       flex-wrap: wrap;
                       background: #F5F5F5;
                       padding: 8px;
                       z-index: 99;
                       position: relative;
                     "
                >
                  ${html}
                </div>
              </div>
            `;
          },
        },
        yAxis: {
          title: { text: this.labelX() },
        },
        rangeSelector: {
          enabled: true,
          inputEnabled: false,
          buttons: [{
            type: 'hour',
            count: 1,
            text: '1h',
          }, {
            type: 'hour',
            count: 3,
            text: '3h',
          }, {
            type: 'hour',
            count: 6,
            text: '6h',
          }, {
            type: 'hour',
            count: 12,
            text: '12h',
          }, {
            type: 'all',
            text: 'Tudo',
          }],
          selected: 0,
        },
        plotOptions: {
          series: {
            animation: false,
            marker: { enabled: false, symbol: 'circle' },
            events: {
              legendItemClick: (e) => {
                this.isNetwokLegendHidden[e.target.name] = !this.isNetwokLegendHidden[e.target.name] || false;
                this.updatePlotBands();
              },
            },
          },
        },
        series: [],
        legend: { enabled: !this.isMinimized },
        exporting: {
          enabled: !this.isMinimized,
          buttons: {
            contextButton: {
              menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF'],
              theme: {
                padding: 4,
              },
            },
          },
        },
        credits: { enabled: false },
      };
    },
    programByDate(networkId, date) {
      return this.programAverages.find(({ startsIn, endsIn, tvNetworkId }) => (
        tvNetworkId === networkId
                && moment(endsIn).isAfter(date)
                && moment(startsIn).isSameOrBefore(date)
      ));
    },
    updateChart(source) {
      if (!this.chart) this.setupConfig();
      source.forEach(({
        date, tvNetworkId, marketId, ...rest
      }) => {
        if (!this.datasets[tvNetworkId]) return;

        const { startsIn } = this.globoDate;
        const position = moment(date).diff(startsIn, 'minutes');
        const { data } = this.datasets[tvNetworkId];
        if (!data[position]) {
          for (let i = data.length; i < position + 1; i += 1) {
            data.push(0);
          }
        }

        data[position] = rest[this.singleDefaultValue];
      });

      this.config.series = [];
      Object.keys(this.datasets).forEach((id) => {
        this.config.series.push(this.datasets[id]);
      });

      if (!this.chart) this.setupChart();
      this.chart.update(this.config);
    },
    updatePlotBands() {
      this.config.xAxis.plotBands = [];
      this.config.xAxis.plotLines = [];
      let key = 0;
      this.programAverages.forEach(({
        startsIn, endsIn, program, tvNetworkId,
      }) => {
        const networkIndex = this.tvNetworks.findIndex(({ id }) => id === tvNetworkId);
        const isNetwordHidden = this.isNetwokLegendHidden[tvNetworkId] || false;
        
        const isProgramHidden = this.settings.marketHidden.indexOf(tvNetworkId);
                
        if (networkIndex === -1 || isNetwordHidden || isProgramHidden !== -1) return;
        
        const text = `<div class='programChartLabels'><span class='programLabelLink'>${program}</span></div>`;
        const style = {
          fontSize: '0.72em',
          backgroundColor: this.getNetworkColor(tvNetworkId),
          color: 'white',
          padding: '4px 8px',
          lineHeight: '24px',
          borderRadius: '4px',
          opacity: '0.72',
        };

        if (tvNetworkId === 'GLOBO') {
          this.config.xAxis.plotBands.push({
            from: this.getValueOfDateByMarket(startsIn),
            to: this.getValueOfDateByMarket(endsIn),
            color: key % 2 === 0 ? '#fafafa' : '#f2f2f2',
            zIndex: 0,
            label: {
              text,
              style,
              useHTML: true,
            },
          });
          key += 1;
        } else {
          this.config.xAxis.plotLines.push({
            value: moment(startsIn).valueOf(),
            color: this.getNetworkColor(tvNetworkId),
            dashStyle: 'Dot',
            width: 1,
            zIndex: 1,
            label: {
              text,
              style,
              verticalAlign: 'middle',
              useHTML: true,
            },
          });
        }
      });

      this.chart.update(this.config);
    },
    getNetworkColor(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor : '';
    },
    labelX() {
      if (this.isMinimized) return null;
      
      switch (this.singleDefaultValue) {
        case 'shareTLE': return 'Share TLE';
        case 'shareTL': return 'Share TL';
        default: return 'Audiência';
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/scss/variables.scss';

  .tv_network {
    background: red;

    img {
      width: 50px;
    }
  }

  // Modal
  .v--modal-overlay {
    background: linear-gradient(140deg, rgba($color-primary, 0.8), rgba($color-secondary, 0.96));
    background-size: 100% 100%;

    &.minimized {
      background: none;
      width: 540px;
      height: 324px;
      top: auto;
      left: auto;
      bottom: 25px;
      right: 5px;
      opacity: 0.8;

      .v--modal {
        height: 100% !important;

        .content {
          .buttons {
            display: block;
            position: absolute;
            top: 6px;
            right: 6px;
            z-index: 1;
            .btn {
              background: transparent;
              height: 36px;
            }
          }

          .head, .footer {
            display: none;
          }

          .middle {
            height: 100%;
          }
        }
      }
    }

    &.overlay--show {
      animation: fadeIn 0.8s ease-in-out forwards;
    }

    &.overlay--hide {
      animation: fadeOut 0.8s ease-in-out forwards;
    }
  }

  .v--modal {
    border-radius: 8px 8px 0 0;
    box-shadow: 0 48px 80px rgba($color-gray-dark, 0.48);
    animation: fadeInUp 0.8s ease-in-out forwards;

    &.modal--hide {
      animation: fadeInUp 0.8s ease-in-out forwards;
    }

    // LOADING
    .loading_small {
      position: absolute;
      top: 0;
      left: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 100%;
      width: 100%;
      opacity: 0.8;
      background: white;

      .spinner {
        position: relative;
        width: 40px;
        height: 40px;

        .path {
          stroke-dasharray: $offset;
          stroke-dashoffset: 0;
          transform-origin: center;
          animation:
                  dash $duration ease-in-out infinite,
                  colors ($duration*4) ease-in-out infinite;
        }
      }
    }

    // Content
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: auto;

      .buttons {
        display: none;
      }

      // TOPO
      .head {
        position: sticky;
        position: -webkit-sticky;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        text-align: center;
        background-color: $color-gray-lighter;
        box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

        h1 {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-family: $secondary-typo;
          font-size: 1em;
          font-weight: 400;
          color: $color-gray-dark;
          font-style: italic;
          text-align: left;
          margin: 0 0 auto 0;
          box-sizing: border-box;
          padding: 16px;
          background-color: rgba($color-gray-back, 0.8);

          span {
            font-weight: 600;
            color: $color-gray-dark;
          }
        }

        .tags {
          padding: 16px;
        }
        // -----
      }
      // -------------------------------------------------------

      // MEIO
      .middle {
        position: relative;
        width: 100%;
        text-align: center;
        margin: 0;
        padding: 0;
      }
      // -------------------------------------------------------

      .footer {
        position: sticky;
        position: -webkit-sticky;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        padding: 0;
        box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);

        .btn {
          &.btn_secondary {
            border: none !important;
          }
        }

      }
    }

    // Link
    .link {
      width: 100%;
      cursor: pointer;
      border: none;
      background-color: transparent;
      margin: 0;

      &.link--secondary {
        font-family: $secondary-typo;
        font-weight: 500;
        font-size: 1em;
        font-style: italic;
        text-transform: inherit;
        letter-spacing: 0.08em;
        color: rgba($color-secondary, 1);
        box-sizing: border-box;
        padding: 4px;
        border: none;

        &:hover {
          color: rgba($color-secondary, 0.72);
        }
        // -------------------------------------------------------
      }
    }
  }
  // -------------------------------------------------------

  // SMARTPHONES
  @media (max-width: 600px) {

    .v--modal {

      // Content
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        overflow-y: scroll;

        // TOPO
        .head {
          position: fixed;
          // z-index: 2;
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-around;
          // align-items: center;
          // width: 100%;
          // height: 240px;
          // text-align: center;
          // padding: 0;
          // background: $color-gray-lighter;
          // box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

          .programa {
            font-size: 0.8em;
          }

        }
        // -------------------------------------------------------

        .footer {
          position: fixed;
          bottom: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          width: 100%;
          padding: 0;
          box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);
          // -----------------

        }
      }
    }
  }

</style>
