import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment-timezone';

// initial state
const state = {
  lastSchedules: [],
  schedules: {},
  networks: {},
};

// getters
const getters = {
  lastSchedules: state => state.lastSchedules,
  schedules: state => Object.keys(state.schedules).reduce(
    (acc, date) => [...acc, ...state.schedules[date]],
    [],
  ),
  networks: state => state.networks,
};


// actions
const actions = {
  async getLastSchedules({
    rootState, commit,
  }) {
    const currentDatetime = moment.tz('America/Sao_Paulo').utc().format();
    const isCustom = rootState.settings.usePrograms;
    const marketId = rootState.settings.market.id;
    const networkId = rootState.settings.tvNetworkId;

    const params = {
      currentDatetime,
      isCustom,
      marketId,
      networkId,
    };

    const { data } = rootState.settings.isMarketView
      ? await bffRealtime.getlastProgramsByMarket(params)
      : await bffRealtime.getlastProgramsByNetwork(params);

    if (data.length !== 0) commit('SET_LAST_SCHEDULES', data);
  },
};

// mutations
const mutations = {
  SET_SCHEDULES(state, { date, schedules }) {
    state.schedules[date] = schedules;
  },
  SET_LAST_SCHEDULES(state, data) {
    state.lastSchedules = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
