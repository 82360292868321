import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';

// initial state
const state = {
  rivalsSchedules: [],
};

// getters
const getters = {
  rivalsSchedules: state => state.rivalsSchedules,
  programNow: (state, _, rootState) => (date, tvNetworkId) => {
    if (!process.env.MARKET_WITH_RIVALS.includes(rootState.settings.market.name)) return [];
    const dateTimestamp = date.format('X');
    return state.rivalsSchedules.find(schedule => (
      schedule.tvNetworkId === tvNetworkId
      && moment(schedule.endsIn).format('X') > dateTimestamp
      && moment(schedule.startsIn).format('X') < dateTimestamp
    ));
  },
};

// actions
const actions = {
  async getRivalsSchedules({ commit }) {
    const params = {
      startDate: moment().subtract(1, 'day').format(),
      endDate: moment().add(1, 'day').format(),
    };
    const { data } = await bffRealtime.getRivalsSchedules(params);
    commit('SET_RIVALS_SCHEDULES', data);
  },
};

// mutations
const mutations = {
  SET_RIVALS_SCHEDULES(state, rivalsSchedules) {
    state.rivalsSchedules = rivalsSchedules;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
