<template>
  <div class="user-rules">
    <div v-if="nextGroup && prevGroup" class="nav">
      <button type="button" class="btn btn_header ripple" @click="getPrev">
        &lt; Regra Anterior
      </button>
      <button type="button" class="btn btn_header ripple" @click="getNext">
        Regra Seguinte &gt;
      </button>
    </div>

    <div class="middle">
      <template v-if="!isEmpty">
        <template v-if="insertMode">
          <div class="input-group">
            <div class="item full">
              <i class="icone material-icons">
                security
              </i>
              <input v-model="name" type="text" required="">
              <span class="highlight" />
              <span class="bar" />
              <label>Nome</label>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- <div class="group head">
            <div>Nome</div>
            <div>Usuários</div>
          </div>
          <div class="group values" :class="{ active }">
            <div>{{ name || '--' }}</div>
            <div>{{ total }}</div>
          </div>
          <div class="divide" /> -->

          <div class="input-group name">
            <div class="item big">
              <input v-model="name" type="text" required="" disabled>
              <span class="highlight" />
              <span class="bar" />
              <label>Nome</label>
            </div>

            <div class="item small">
              <input v-model="total" type="text" required="" disabled>
              <span class="highlight" />
              <span class="bar" />
              <label>Usuários</label>
            </div>
          </div>
        </template>
      </template>

      <transition name="fade">
        <div v-if="isLoading" class="loading">
          <div class="loader">
            <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6"
                      stroke-linecap="round" cx="33" cy="33" r="30"
              />
            </svg>
          </div>
        </div>
      </transition>
      <div v-if="users && search === ''" class="scroll">
        <ErrorState v-if="users.length === 0" :insides="['globo_item']"
                    title="Xiiiiiiiiiiiiii"
                    subtitle="Não encontramos nenhuma regra"
                    :style-container="{
                      backgroundColor: 'transparent',
                    }"
                    :fullscreen="false"
        />

        <table v-else class="table users">
          <thead>
            <tr>
              <th class="rule">
                Email
              </th>
              <!-- <th class="date">
                Nome
              </th> -->
              <th class="more">
                Ações
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="user in users" :key="user.email">
              <div v-if="canUpdate(user.email)"
                   class="actions"
                   :class="{active: isCurrentUser(user.email)}"
              >
                <!-- <span>{{ user.email }}</span> -->

                <div class="icones">
                  <i class="icone material-icons" @click="removeUser(user.email)">
                    clear
                  </i>
                </div>
              </div>
              <td class="rule">
                {{ user.email }}
              </td>
              <!-- <td class="date">
                {{ user.name || '--' }}
              </td> -->
              <td class="more">
                <i v-if="canUpdate(user.email)" class="icone material-icons">
                  more_horiz
                </i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <template v-else-if="allUsers && (search !== '' || insertMode)">
        <ErrorState v-if="isEmpty" :insides="['globo_item']"
                    title="Xiiiiiiiiiiiiii"
                    :subtitle="[
                      'Não encontramos nenhum usuário cadastrado',
                      'com os termos da sua busca.',
                      'Deseja criar uma novo usuário?'
                    ]"
                    :buttons="[{
                      text: 'NÃO, QUERO VOLTAR',
                      class: 'btn_secondary active',
                      click: () => {
                        search = '';
                      },
                    }, {
                      text: 'Sim, Quero Criar',
                      click() {
                        openAddUserModal();
                      },
                    }]"
                    :style-container="{
                      backgroundColor: 'transparent',
                    }"
                    :fullscreen="false"
        />
        <template v-else>
          <div class="scroll">
            <table class="table">
              <thead>
                <tr>
                  <th class="action" />
                  <th class="rule">
                    Email
                  </th>
                  <th class="date">
                    Nome
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="user in usersFilteredBySearch()" :key="user.email">
                  <td class="action">
                    <label class="checkbox">
                      <input :id="user.email" v-model="selectedUsers" type="checkbox" :value="user.email">
                      <span />
                    </label>
                  </td>
                  <td class="rule">
                    <label :for="user.email">{{ user.email }}</label>
                  </td>
                  <td class="date">
                    {{ user.name }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button type="button"
                  class="btn btn_secondary ripple"
                  :class="{ disabled : selectedUsers.length === 0 }"
                  @click="addUsers"
          >
            Vincular
          </button>
        </template>
      </template>
      <ErrorState v-else-if="!isLoading" :insides="['globo_item']"
                  title="Oooops!"
                  :subtitle="[
                    'Essa regra ainda não possui usuários vinculados',
                    'Navegue entre outras regras utilizando os botões no topo ou pesquise por um usuário utilizando o campo abaixo :)'
                  ]"
                  :style-container="{
                    backgroundColor: 'transparent',
                  }"
                  :fullscreen="false"
      />
    </div>

    <footer class="footer" :class="{ done: message }">
      <div class="search">
        <div class="item full">
          <i v-if="search === ''" class="icone material-icons" @click="search = searchInput">
            {{ message ? 'mood' : 'search' }}
          </i>
          <i v-else class="icone material-icons" @click="clearValues()">
            close
          </i>
          <input v-model="searchInput" type="text" required="" @keyup.enter="search = searchInput">
          <span class="highlight" />
          <span class="bar" />
          <label>{{ message || 'Pesquisar usuário' }}</label>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import ErrorState from '@/components/system/ErrorState.vue';

export default {
  components: {
    ErrorState,
  },
  props: {
    group: {
      type: Object,
      default: null,
    },
    updateUser: {
      type: Function,
      default: null,
    },
    nextGroup: {
      type: Function,
      default: null,
    },
    prevGroup: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      total: 0,
      active: true,
      users: null,
      allUsers: null,
      searchInput: '',
      search: '',
      isLoading: true,
      isEmpty: false,
      selectedUsers: [],
      activeUser: '',
      message: '',
      messageTimeout: null,
      insertMode: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    search() {
      if (!this.insertMode) this.selectedUsers = [];
      this.isEmpty = false;
    },
  },
  mounted() {
    this.setupUsers();
  },
  beforeDestroy() {
    clearTimeout(this.messageTimeout);
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    async setupUsers() {
      await this.getAllUsers();
      if (this.group) await this.getUsers(this.group);
      else this.insertMode = true;
    },
    clone() {
      this.insertMode = true;
      this.name = '';
      this.active = true;
      this.search = '';
      this.selectedUsers = this.users.map(({ email }) => email);
      this.users = null;
    },
    clearValues() {
      this.search = '';
      this.searchInput = '';
      this.selectedUsers = [];
      this.isEmpty = false;
      this.activeUser = '';
      this.message = '';
    },
    getNext() {
      this.getUsers(this.nextGroup());
    },
    getPrev() {
      this.getUsers(this.prevGroup());
    },
    clearActiveGroup() {
      this.activeUser = '';
    },
    isCurrentUser(email) {
      return this.activeUser === email;
    },
    canUpdate(email) {
      const { SYSTEM_RULES } = process.env;

      return !(SYSTEM_RULES.includes(this.uuid) && email === this.currentUser.email);
    },
    async getUsers({ uuid, name, active }) {
      this.clearValues();
      this.uuid = uuid;
      this.name = name;
      this.active = active || false;

      this.users = null;
      this.isLoading = true;

      try {
        const { data } = await bffRealtime.getPermissionGroupUsers(
          this.uuid,
          bffRealtime.tokenRequest('controlPanel', 'getUsers'),
        );
        this.users = data;
        this.total = data.length;
        if (this.updateUser) {
          this.updateUser({
            name: this.name,
            email: this.email,
            total: this.total,
            active: this.active,
          });
        }

        this.insertMode = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getAllUsers() {
      this.isLoading = true;

      try {
        const { data } = await bffRealtime.getPermissionUsers(
          bffRealtime.tokenRequest('controlPanel', 'getAllUsers'),
        );
        this.allUsers = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    usersFilteredBySearch() {
      if (this.search === '') return this.allUsers;

      const string = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(string, 'ig');
      const users = this.allUsers.filter(({ email, name }) => {
        // It's better => regex.test(name.trim()) || regex.test(email.trim())
        // But the BFF don't return name always.
        let nameMatch = false;
        if (name) nameMatch = regex.test(name.trim());

        return (this.insertMode && this.selectedUsers.includes(email))
          || nameMatch || regex.test(email.trim());
      });
      this.isEmpty = users.length === 0;
      return users;
    },
    async addUsers() {
      clearTimeout(this.messageTimeout);
      this.isLoading = true;
      for (let i = 0; i < this.selectedUsers.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await bffRealtime.addUserGroup(
          this.uuid,
          { email: this.selectedUsers[i] },
          bffRealtime.tokenRequest('controlPanel', 'addUsers'),
        );
      }

      this.reloadUser();
      this.message = 'Usuários vinculados a regra com sucesso!';
      this.messageTimeout = setTimeout(() => {
        this.message = '';
      }, 2000);
    },
    async removeUser(email) {
      clearTimeout(this.messageTimeout);
      this.activeUser = email;
      this.isLoading = true;

      await bffRealtime.removeUserGroup(
        this.uuid,
        email,
        bffRealtime.tokenRequest('controlPanel', 'removeUser'),
      );

      this.reloadUser();
      this.message = 'Usuário removido com sucesso!';
      this.messageTimeout = setTimeout(() => {
        this.message = '';
      }, 2000);
    },
    reloadUser() {
      this.getUsers({
        uuid: this.uuid,
        name: this.name,
        active: this.active,
      });
    },
    getTime(times) {
      if (!times) return '--';
      if (times.find(time => time === '00:00:00-23:59:59')) return 'Tudo';

      return times.map((time) => {
        const [start, end] = time.split('-');

        return `${start.replace(/:\d{2}$/, '')} - ${end.replace(/:\d{2}$/, '')}`;
      }).join(', ');
    },
    openAddUserModal() {
      const vm = this;
      this.$modal.hide('sidebar');
      this.$modal.show('sidebar', {
        componentName: 'UserRules',
        title: 'Incluir Usuário',
        icon: 'person',
        onClose() {
          vm.openGroupRules(vm.group, false);
        },
      });
    },
    openGroupRules(group, editMode) {
      this.activeGroup = group.uuid;
      this.$modal.show('sidebar', {
        componentName: editMode ? 'CreateRule' : 'GroupRules',
        title: editMode ? 'Editar Regra' : 'Usuários',
        icon: editMode ? 'security' : 'person',
        onClose: this.clearActiveGroup,
        nextGroup: this.nextGroup,
        prevGroup: this.prevGroup,
        updateGroup: this.updateGroup,
        group,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/sidebar-content.scss';
</style>
