<template>
  <Modal :name="name"
         height="auto"
         :max-height="740"
         :width="200"
         class="modal-chart"
         :resizable="true"
         :click-to-close="false"
         @before-open="beforeOpen"
         @before-close="beforeClose"
  >
    <div class="content">
      <div class="head">
        <h1>
          Exportação de CSV
        </h1>
      </div>
      <!-- fim do topo -->

      <div class="middle">
        <VueCircle
          ref="progress"
          :progress="0"
          :size="100"
          :reverse="false"
          line-cap="round"
          empty-fill="rgba(0, 0, 0, .1)"
          :animation-start-value="0.0"
          :start-angle="0"
          insert-mode="append"
          :thickness="10"
          :show-percent="true"
        />
      </div>

      <div class="footer">
        <button type="button"
                class="btn btn_secondary btn_full ripple"
                @click="closeModal"
        >
          Cancelar
        </button>
      </div>
    </div>
    <!-- // -->
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';
import VueCircle from 'vue2-circle-progress';
import transformMixin from '@/utils/transformMixin';

export default {
  components: {
    VueCircle,
  },
  mixins: [transformMixin],
  data() {
    return {
      progress: 0,
      total: 0,
      obtained: 0,
      getUsersTimeout: null,
      getAudiencesTimeout: null,
      getProgramAveragesTimeout: null,
      averages: null,
      selectedAverages: null,
      name: 'export',
      rows: [],
      datasets: {},
      scrollId: null,
      marketsCP: null,
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['markets', 'tvNetworks', 'globoDate', 'scheduleTvNetworkId', 'showCustomPrograms']),
    isToday() {
      return this.settings.dateOptions.day === 0;
    },
    idName() {
      return this.settings.isMarketView ? 'id' : 'exhibitionName';
    },
  },
  methods: {
    changeColumnDecimalSeparator(column, separator, originSeparator = '.') {
      return `"${column.toString().replace(originSeparator, separator)}"`;
    },
    beforeOpen() {
      this.$store.dispatch('hideMobileNavigation');
      this.setupExport();

      this.unwatch = this.$store.watch(state => state.settings, () => {
        this.reloadExport();
        this.setupExport();
      }, { deep: true });
    },
    beforeClose() {
      this.$store.dispatch('showMobileNavigation');
      this.reloadExport();
      this.unwatch();
      clearTimeout(this.getUsersTimeout);
      clearTimeout(this.getAudiencesTimeout);
      clearTimeout(this.getProgramAveragesTimeout);
    },
    closeModal() {
      setTimeout(() => {
        this.$modal.hide(this.name);
      }, 800);
    },
    async setupExport() {
      const { isMarketView, dateOptions, isSimuCastView } = this.settings;
      if (this.$route.name === 'Users') {
        await this.getMarkets();
        this.getUsers();
      } else {
        const marketTemp = this.markets.map(object => ({ ...object }));

        let rows = isMarketView ? this.tvNetworks : marketTemp;

        if (this.settings.isShowSampling && isMarketView && this.settings.isMinuteView) {
          const tvNetworksTemp = [{
            id: 'AMOSTRA',
            isCalculated: false,
            isDefault: true,
            isRTDefault: true,
          }];
          const tvNetworksTempConcat = this.tvNetworks.concat(tvNetworksTemp);
          rows = this.settings.isMarketView ? tvNetworksTempConcat : this.markets;
        }


        if (!isMarketView && !isSimuCastView) {
          rows = rows.filter(item => item.exhibitionName != 'EPP');
          rows = rows.filter(item => item.exhibitionName != 'NVP');
          rows = rows.filter(item => item.exhibitionName != 'NVT');
          rows = rows.filter(item => item.exhibitionName != 'EPT');
        } else if (isSimuCastView) {
          marketTemp.forEach((element) => {
            if (element.id == 'BHZ') {
              element.id = 'BH';
            } else if (element.id == 'DFE') {
              element.id = 'DF';
            } else if (element.id == 'FLP') {
              element.id = 'FLO';
            } else if (element.id == 'CTA') {
              element.id = 'CUR';
            }
          });

          rows = rows.filter(item => item.exhibitionName != 'TVP');
          rows = rows.filter(item => item.exhibitionName != 'TVT');
          rows = rows.filter(item => item.exhibitionName != 'PNT');
          rows = rows.filter(item => item.exhibitionName != 'EPP');
          rows = rows.filter(item => item.exhibitionName != 'NVP');
          rows = rows.filter(item => item.exhibitionName != 'NVT');
          rows = rows.filter(item => item.exhibitionName != 'EPT');
        }

        this.rows = rows.map(({ id }) => id);

        if (!isSimuCastView) {
          await this.getDayAverages();
          if (dateOptions.startTime || dateOptions.endTime) {
            await this.getSelectedAverages();
          }
        }

        if (this.settings.isMinuteView) {
          this.getAudiences();
        } else if (isSimuCastView) {
          this.getAudienceSimulcast();
        } else {
          this.getProgramAverages();
        }
      }
    },

    reloadExport() {
      bffRealtime.cancelRequests('export');
      this.$refs.progress.updateProgress(0);
      this.total = 0;
      this.obtained = 0;
      this.averages = null;
      this.selectedAverages = null;
      this.rows = [];
      this.datasets = {};
      this.scrollId = null;
    },
    async getMarkets() {
      try {
        const { data } = await bffRealtime.getMarkets(
          { skipPermissions: true },
          bffRealtime.tokenRequest('controlPanel', 'getMarkets'),
        );
        this.marketsCP = data.source;
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    getMarket(markets) {
      return markets ? markets.map((marketID) => {
        // const { exhibitionName } = this.markets.find(market => market.id === marketID);

        const marketList = this.markets.find(market => market.id === marketID);
        const { exhibitionName } = marketList !== undefined ? marketList : [];

        return exhibitionName;
      }).join(', ') : '--';
    },
    getTime(times) {
      if (!times) return '--';
      if (times.find(time => time === '00:00:00-23:59:59')) return 'Tudo';

      return times.map((time) => {
        const [start, end] = time.split('-');

        return `${start.replace(/:\d{2}$/, '')} - ${end.replace(/:\d{2}$/, '')}`;
      }).join(', ');
    },
    async getGroups({ name, email, active }, i) { // I -> just because their is duplicated email
      try {
        const { data } = await bffRealtime.getPermissionGroups(
          { email },
          bffRealtime.tokenRequest('export', 'getGroups'),
        );

        data.forEach(({
          marketsId, tvNetworksId, times, weekDays,
        }, key) => {
          this.datasets[`${email}|||${i}|||${key}`] = [
            `"${name || ''}"`,
            email.trim(),
            `"${this.getMarket(marketsId)}"`,
            `"${tvNetworksId.join(',')}"`,
            `"${this.getTime(times)}"`,
            `"${weekDays.join(',')}"`,
          ];
        });
      } catch (error) {
        console.error(error);
      } finally {
      }
    },
    async getUsers() {
      clearTimeout(this.getUsersTimeout);

      try {
        const { data } = await bffRealtime.getPermissionUsers(
          bffRealtime.tokenRequest('export', 'getUsers'),
        );

        this.total = data.length;

        for (let i = 0; i < this.total; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await this.getGroups(data[i], i);

          const progress = Math.floor(i / this.total * 100);
          this.$refs.progress.updateProgress(progress > 100 ? 100 : progress);
        }

        this.downloadCSV();
        this.closeModal();
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.getUsersTimeout = setTimeout(() => this.getUsers(), 2000);
        }
      }
    },
    async getAudienceSimulcast() {
      clearTimeout(this.getAudiencesTimeout);

      const filterAudiences = {
        startDate: this.globoDate.startsIn,
        endDate: this.globoDate.endsIn || moment().format(),
        sortBy: ['-date'],
        size: 500,
        ...(this.settings.isMarketView
          ? { marketId: this.settings.market.id }
          : { tvNetworkId: this.settings.tvNetworkId }),
      };

      try {
        const { data } = await bffRealtime.getSimulcastAudiences(
          filterAudiences, bffRealtime.tokenRequest('export', 'audiences'),
        );

        const { total, source, scrollId } = data;
        this.total = total;
        this.updateMinutes(source);
        if (!this.scrollId) {
          this.scrollId = scrollId;
          this.getAudiencesByScrollId(scrollId);
        }
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.scrollId = null;
          this.getAudiencesTimeout = setTimeout(() => this.getAudiences(), 2000);
        }
      }
    },
    async getAudiences() {
      clearTimeout(this.getAudiencesTimeout);

      const filterAudiences = {
        startDate: this.globoDate.startsIn,
        endDate: this.globoDate.endsIn || moment().format(),
        sortBy: ['-date'],
        size: 500,
        ...(this.settings.isMarketView
          ? { marketId: this.settings.market.id }
          : { tvNetworkId: this.settings.tvNetworkId }),
      };

      try {
        const { data } = await bffRealtime.getAudiences(
          filterAudiences, bffRealtime.tokenRequest('export', 'audiences'),
        );

        const { total, source, scrollId } = data;
        this.total = total;
        this.updateMinutes(source);
        if (!this.scrollId) {
          this.scrollId = scrollId;
          this.getAudiencesByScrollId(scrollId);
        }
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.scrollId = null;
          this.getAudiencesTimeout = setTimeout(() => this.getAudiences(), 2000);
        }
      }
    },
    async getAudiencesByScrollId(scrollId) {
      const { isSimuCastView } = this.settings;
      let dataTemp = [];
      let lengthTemp = 0;

      if (!isSimuCastView) {
        dataTemp = await bffRealtime.getScroll(
          scrollId, bffRealtime.tokenRequest('export', 'audiencesByScrollId'),
        );
        lengthTemp = dataTemp.data.source.length;
      } else {
        try {
          const tokenSimulcastAudiencesByScrollId = await bffRealtime.tokenRequest('export', 'audiencesByScrollId');
          dataTemp = await bffRealtime.getSimulcastScroll(
            scrollId, tokenSimulcastAudiencesByScrollId,
          );
          lengthTemp = dataTemp.data.source.length;
        } catch (error) {
          lengthTemp = 0;

          console.log('error ::', error);
        }
      }

      const { data } = dataTemp;

      if (lengthTemp === 0) {
        this.downloadCSV();
        this.closeModal();
      } else {
        this.updateMinutes(data.source);
        this.getAudiencesByScrollId(scrollId);
      }
    },
    formatDateMS(date) {
      return moment(date).format('HH:mm');
    },
    generateHead() {
      if (this.$route.name === 'ControlPanel') return 'Nome,Email,Praças,Emissoras,Faixa,Dias\r\n';
      const name = this.settings.isMarketView ? this.settings.market.id : this.settings.tvNetworkId;

      let prefix = '';

      if (this.settings.isMinuteView || this.settings.isSimuCastView) {
        prefix = 'Horario,';
      } else if (!this.settings.isMinuteView || !this.settings.isSimuCastView) {
        prefix = `${name},Nome do Programa,Inicio,Fim,`;
      } else {
        prefix = `${name}`;
      }
      // prefix = this.settings.isMinuteView ? 'Horario,' : `${name},Nome do Programa,Inicio,Fim,`;
      // prefix = this.settings.isSimuCastView ? 'Horario,' : `${name},`;

      let markets = [];
      let rows = this.settings.isMarketView ? this.tvNetworks : this.markets;

      if (this.settings.isShowSampling && this.settings.isMarketView && this.settings.isMinuteView && this.$route.name !== 'Users') {
        const tvNetworksTemp = [{
          id: 'AMOSTRA',
          isCalculated: false,
          isDefault: true,
          isRTDefault: true,
        }];
        const tvNetworksTempConcat = this.tvNetworks.concat(tvNetworksTemp);
        rows = this.settings.isMarketView ? tvNetworksTempConcat : this.markets;
      }


      if (this.settings.isSimuCastView) {
        rows = rows.filter(item => item.exhibitionName != 'TVP');
        rows = rows.filter(item => item.exhibitionName != 'TVT');
        rows = rows.filter(item => item.exhibitionName != 'PNT');
        rows = rows.filter(item => item.exhibitionName != 'EPP');
        rows = rows.filter(item => item.exhibitionName != 'NVP');
        rows = rows.filter(item => item.exhibitionName != 'NVT');
        rows = rows.filter(item => item.exhibitionName != 'EPT');

        markets = rows.map(row => this.transNetworkNameByIdExport(row[this.idName])).join(',*,');
        return `${prefix}${markets},*\r\n`;
      }

      markets = rows.map(row => this.transNetworkNameByIdExport(row[this.idName])).join(',*,');
      return `${prefix}${markets},*\r\n`;
    },
    generateFoot() {
      if (this.$route.name === 'ControlPanel') return '';

      let foot = '';
      if (this.selectedAverages) {
        const startHour = this.formatDateMS(this.globoDate.startsIn);
        const endHour = this.formatDateMS(this.globoDate.endsIn || moment().format());
        const prefix = this.settings.isMinuteView
          ? `MEDIA ${startHour} a ${endHour},` : `,MEDIA ${startHour} a ${endHour},-,-,`;

        foot += `\r\n${prefix}${this.rows.map((id) => {
          const audience = this.changeColumnDecimalSeparator(this.findAverages(this.selectedAverages, 'audience', id), ',');
          const shareTLE = this.changeColumnDecimalSeparator(this.findAverages(this.selectedAverages, 'shareTLE', id), ',');
          return `${audience},${shareTLE}`;
        }).join(',')}`;
      }

      try {
        if (this.averages) {
          const prefix = this.settings.isMinuteView
            ? 'MEDIA 07:00 a 00:00,' : ',MEDIA 07:00 a 00:00,-,-,';

          const market = this.settings.market.id;

          foot += `\r\n${prefix}${this.rows.map((id) => {
            const audience = this.changeColumnDecimalSeparator(this.findAverages(this.averages, 'audience', id), ',');
            const shareTLE = this.changeColumnDecimalSeparator(this.findAverages(this.averages, 'shareTLE', id), ',');
            if (id === 'AMOSTRA') {
              const firstTvNetwork = Object.keys(this.averages[market])[0];
              const samplingAverage = this.averages[market][firstTvNetwork].sampling;
              return `${samplingAverage.toFixed(2)}`;
            }

            return `${audience},${shareTLE}`;
          }).join(',')}`;
        }
      } catch (error) {

      }

      return foot;
    },
    exportCSV() {
      const sorted = Object.keys(this.datasets).sort();
      if (this.settings.isDescending) {
        sorted.reverse();
      }

      const head = this.generateHead();
      const body = sorted.map((date) => {
        const values = this.datasets[date].join(',');

        if (this.$route.name === 'ControlPanel') return values;
        let prefix = '';
        if (this.settings.isMinuteView || this.settings.isSimuCastView) {
          prefix = this.formatDateMS(date);
        } else {
          prefix = '';
        }
        // const prefix = this.settings.isMinuteView ? this.formatDateMS(date) : '';
        return `${prefix},${values}`;
      }).join('\r\n');


      const foot = this.generateFoot();

      return `${head}${body}${foot}`;
    },
    downloadCSV() {
      const data = this.exportCSV();
      const blob = new Blob([data], { type: 'text/plain' });
      const e = document.createEvent('MouseEvents');

      const a = document.createElement('a');
      const date = moment(this.globoDate.startsIn).format('DDMMYYYY').toString();
      const name = this.settings.isMarketView ? this.settings.market.exhibitionName : this.settings.tvNetworkId;

      if (this.settings.isSimuCastView) {
        a.download = (this.$route.name === 'Users') ? `Usuarios_Realtime_${date}.csv` : `Dados_Simulcast_${date}.csv`;
      } else {
        a.download = (this.$route.name === 'Users') ? `Usuarios_Realtime_${date}.csv` : `PreviaDiaria_${name}_${date}.csv`;
      }

      a.href = window.URL.createObjectURL(blob);
      a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
      e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      a.dispatchEvent(e);
    },
    findAverages(averages, defaultValue, id) {
      let marketId = null;
      let networkId = null;
      const amostraId = null;

      if (this.settings.isMarketView) {
        marketId = this.settings.market.id;
        networkId = id;
      } else {
        marketId = id;
        networkId = this.settings.tvNetworkId;
      }

      return averages[marketId] && averages[marketId][networkId]
        ? averages[marketId][networkId][defaultValue].toFixed(2)
        : '-';
    },
    async getDayAverages() {
      const { day } = this.settings.dateOptions;
      const selectedDay = moment().subtract(day, 'days').tz('America/Sao_Paulo');
      const filterDayAverages = {
        startDate: selectedDay.set({
          hour: 7,
          minute: 0,
          second: 0,
          milisecond: 0,
        }).format(),
        endDate: selectedDay.endOf('day').add(1, 'seconds').format(),
        size: 1,
        ...(this.settings.isMarketView
          ? { marketId: this.settings.market.id }
          : { tvNetworkId: this.settings.tvNetworkId }),
      };
      try {
        const tokenRangeAverages = await bffRealtime.tokenRequest('export', 'dayAverages');
        const { data } = await bffRealtime.getRangeAverages(
          filterDayAverages, tokenRangeAverages, '',
        );

        this.averages = data.averages;
      } catch (error) {
        console.log('error :: ', error);
      }
    },
    async getSelectedAverages() {
      const startDate = this.globoDate.startsIn;
      const endDate = this.globoDate.endsIn || moment().format();

      const filterSelectedAverages = {
        startDate,
        endDate: moment(endDate).add(1, 'minutes').format(),
        size: 1,
        ...(this.settings.isMarketView
          ? { marketId: this.settings.market.id }
          : { tvNetworkId: this.settings.tvNetworkId }),
      };
      try {
        const { data } = await bffRealtime.getRangeAverages(
          filterSelectedAverages, bffRealtime.tokenRequest('export', 'selectedAverages'),
        );
        this.selectedAverages = data.averages;
      } catch (error) {
        console.log('error :: ', error);
      }
    },
    async getProgramAverages() {
      clearTimeout(this.getProgramAveragesTimeout);

      const filterProgramAverages = {
        startDate: this.globoDate.startsIn,
        endDate: this.globoDate.endsIn || moment().format(),
        marketName: this.settings.market.name,
        marketId: this.settings.market.id,
        isRivals: true,
        isCustom: this.showCustomPrograms,
        ...(this.settings.isMarketView
          ? { tvNetworkId: 'GLOBO' }
          : { tvNetworkId: this.settings.tvNetworkId }),
        scheduleTvNetworkId: this.scheduleTvNetworkId,
      };

      try {
        const { data } = await bffRealtime.getProgramAverages(
          filterProgramAverages, bffRealtime.tokenRequest('export', 'programAverages'),
        );
        this.updatePrograms(data);
        this.downloadCSV();
        this.closeModal();
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.getProgramAveragesTimeout = setTimeout(() => this.getProgramAverages(), 5000);
        }
      }
    },

    removeAcento(string) {
      return string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    updatePrograms(programs) {
      this.$refs.progress.updateProgress(100);

      programs.forEach(({
        program, startsIn, endsIn, averages,
      }) => {
        this.datasets[`${startsIn}|||${endsIn}|||${program}`] = [
          `"${this.removeAcento(program)}"`,
          this.formatDateMS(startsIn),
          this.formatDateMS(endsIn),
          this.rows.map((id) => {
            const audience = this.changeColumnDecimalSeparator(this.findAverages(averages, 'audience', id), ',');
            const shareTLE = this.changeColumnDecimalSeparator(this.findAverages(averages, 'shareTLE', id), ',');
            return `${audience},${shareTLE}`;
          }),
        ];
      });
    },
    updateMinutes(source) {
      this.obtained += source.length;
      const progress = Math.floor(this.obtained / this.total * 100);
      this.$refs.progress.updateProgress(progress > 100 ? 100 : progress);
      const { isMarketView, isSimuCastView, isMinuteView } = this.settings;
      source.forEach(({
        date, tvNetworkId, marketId, audience, users, shareTLE, sampling,
      }) => {
        const id = isMarketView ? tvNetworkId : marketId;
        const index = this.rows.findIndex(name => name === id);
        if (index > -1) {
          const row = this.generateColumn(date);

          if (!isMarketView && !isMinuteView) {
            const pos = index * 2;
            if (isSimuCastView) {
              row[pos + 1] = users !== undefined && users !== null ? users : '-';
              row[pos] = audience !== undefined && audience !== null ? this.changeColumnDecimalSeparator(audience, ',') : '-';
            }
          } else {
            const pos = index * 2;
            row[pos] = audience !== undefined && audience !== null ? this.changeColumnDecimalSeparator(audience.toFixed(2), ',') : '-';
            row[pos + 1] = shareTLE !== undefined && shareTLE !== null ? this.changeColumnDecimalSeparator(shareTLE.toFixed(2), ',')  : '-';
          }
          if (this.settings.isShowSampling && this.settings.isMarketView && this.settings.isMinuteView) {
            const idAmostra = this.settings.amostraId;
            const indexx = this.rows.findIndex(name => name === idAmostra);
            if (indexx > -1) {
              const row = this.generateColumn(date);
              const pos = indexx * 2;
              row[pos] = sampling !== undefined && sampling !== null ? sampling : '-';
            }
          }
        }
      });
    },
    generateColumn(date) {
      if (!this.isMarketView) {
        if (!this.datasets[date]) this.datasets[date] = new Array(this.rows.length);
      } else if (!this.datasets[date]) this.datasets[date] = new Array(this.rows.length * 2).fill('-');
      return this.datasets[date];
    },
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/scss/variables.scss';

  .tv_network {
    background: red;

    img {
      width: 50px;
    }
  }

  // Modal
  .v--modal-overlay {

    background: none;
    width: 240px;
    height: 324px;
    top: auto;
    left: 5px;
    bottom: 25px;
    right: auto;
    opacity: 0.8;

    .v--modal {
      height: 100% !important;
    }


    &.overlay--show {
      animation: fadeIn 0.8s ease-in-out forwards;
    }

    &.overlay--hide {
      animation: fadeOut 0.8s ease-in-out forwards;
    }
  }

  .v--modal {
    border-radius: 8px 8px 0 0;
    box-shadow: 0 48px 80px rgba($color-gray-dark, 0.48);
    animation: fadeInUp 0.8s ease-in-out forwards;

    &.modal--hide {
      animation: fadeInUp 0.8s ease-in-out forwards;
    }

    // Content
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: auto;

      .buttons {
        display: none;
      }

      // TOPO
      .head {
        position: sticky;
        position: -webkit-sticky;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        text-align: center;
        background-color: $color-gray-lighter;
        box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

        h1 {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-family: $secondary-typo;
          font-size: 1em;
          font-weight: 400;
          color: $color-gray-dark;
          font-style: italic;
          text-align: left;
          margin: 0 0 auto 0;
          box-sizing: border-box;
          padding: 16px;
          background-color: rgba($color-gray-back, 0.8);

          span {
            font-weight: 600;
            color: $color-gray-dark;
          }
        }

        .tags {
          padding: 16px;
        }
        // -----
      }
      // -------------------------------------------------------

      // MEIO
      .middle {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      // -------------------------------------------------------

      .footer {
        position: sticky;
        position: -webkit-sticky;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        padding: 0;
        box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);

        .btn {
          &.btn_secondary {
            border: none !important;
          }
        }

      }
    }

    // Link
    .link {
      width: 100%;
      cursor: pointer;
      border: none;
      background-color: transparent;
      margin: 0;

      &.link--secondary {
        font-family: $secondary-typo;
        font-weight: 500;
        font-size: 1em;
        font-style: italic;
        text-transform: inherit;
        letter-spacing: 0.08em;
        color: rgba($color-secondary, 1);
        box-sizing: border-box;
        padding: 4px;
        border: none;

        &:hover {
          color: rgba($color-secondary, 0.72);
        }
        // -------------------------------------------------------
      }
    }
  }
  // -------------------------------------------------------

  // SMARTPHONES
  @media (max-width: 600px) {

    .v--modal {

      // Content
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        overflow-y: scroll;

        // TOPO
        .head {
          position: fixed;
          // z-index: 2;
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-around;
          // align-items: center;
          // width: 100%;
          // height: 240px;
          // text-align: center;
          // padding: 0;
          // background: $color-gray-lighter;
          // box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

          .programa {
            font-size: 0.8em;
          }

        }
        // -------------------------------------------------------

        .footer {
          position: fixed;
          bottom: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          width: 100%;
          padding: 0;
          box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);
          // -----------------

        }
      }
    }
  }

</style>
