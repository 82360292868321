<template>
  <div class="vision" :class="visionClass">
    <template v-if="programAverages">
      <!-- Visão full de todas as praças -->
      <div v-if="isMobileView" ref="marketsFull" class="markets_full">
        <div class="notch" @click="extendMarket(false)">
          <div class="notch_icon">
            <i class="icone material-icons">
              keyboard_arrow_up
            </i>
          </div>
        </div>

        <!-- Content -->
        <div class="content_markets">
          <!-- Seta Esquerda -->
          <div class="arrow_left" :class="{ disabled: isFirst }" @click="slidePrev">
            <button type="button" class="btn btn_header ripple">
              <i class="icone material-icons">
                keyboard_arrow_left
              </i>
            </button>
          </div>

          <div class="content">
            <div class="title">
              Desempenho do Programa > <span>Praças</span>
            </div>

            <div v-if="minuteLines" class="market_vision">
              <div v-for="market in marketsWithGlobo"
                   :key="market.id"
                   class="card"
                   :class="{
                     selected: market.id === settings.market.id,
                     disabled: marketAverage(market.id) === '--'
                   }"
                   @click="selectMarket(market)"
              >
                <div v-if="marketAverage(market.id) === '--'" class="loading">
                  <div class="loader">
                    <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                      <circle class="path" fill="none" stroke-width="6"
                              stroke-linecap="round" cx="33" cy="33" r="30"
                      />
                    </svg>
                  </div>
                </div>

                <!-- MARKET NAME -->
                <div class="market_name">
                  {{ market.exhibitionName }}
                  <span v-if="settings.isMarketsWeightVisible" :title="market.id === 'RPP' ? 'Posse' : 'Peso'" class="tag-weight tag-vision">   
                    {{ market.weight ? market.weight : '-' }}
                  </span>
                </div>

                <!-- PROGRESS MINUTES -->
                <div v-if="marketAverages" class="progress_minutes">
                  <div v-for="(minuteLine, key) in minuteLines[market.id]"
                       :key="key" class="item" :class="minuteLine"
                  />
                </div>

                <!-- AVERAGE -->
                <div class="market_average" :class="marketClass(market.id)">
                  <AnimatedNumber :value="marketAverage(market.id)" />
                </div>

                <!-- MARKET SELECT -->
                <div class="market_select">
                  <div class="round">
                    <input :id="`button_${market.id}`"
                           :checked="market.id === settings.market.id"
                           type="radio"
                    >
                    <label :for="`button_${market.id}`" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Seta Direita -->
          <div class="arrow_right" :class="{ disabled: isLive }" @click="slideNext">
            <button type="button" class="btn btn_header ripple">
              <i class="icone material-icons">
                keyboard_arrow_right
              </i>
            </button>
          </div>
        </div>

        <!-- Resumo -->
        <div class="resume_bar">
          <div v-if="marketAverage(settings.market.id) === '--' || !lastMinute" class="loading">
            <div class="loader">
              <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6"
                        stroke-linecap="round" cx="33" cy="33" r="30"
                />
              </svg>
            </div>
          </div>
          <div class="content">
            <div class="line">
              <div class="item full">
                <i class="icone material-icons">
                  tv
                </i>
                <span>
                  {{ program }}
                </span>
              </div>
            </div>

            <div class="line">
              <div class="item">
                <i class="icone material-icons">
                  access_time
                </i>
                <span>
                  {{ lastMinute ? lastMinute : '--:--' }}
                </span>
              </div>

              <div v-if="isLive" class="item status no-ar">
                <i class="icone material-icons">
                  adjust
                </i>
                <span>
                  No Ar
                </span>
              </div>
              <div v-else class="item status exibido">
                <i class="icone material-icons">
                  check_circle
                </i>
                <span>
                  Exibido
                </span>
              </div>
            </div>
          </div>

          <div class="average" :class="marketClass(settings.market.id)">
            <div class="label">
              Média
            </div>
            <div class="value">
              <AnimatedNumber :value="marketAverage(settings.market.id)" />
            </div>
          </div>
        </div>
      </div>

      <!-- Esteira de Praças -->
      <div v-if="minuteLines" ref="marketVision" class="market_vision"
           :class="{ single: marketsWithGlobo.length === 1 }"
           @click="extendMarket(true)"
      >
        {{ setupGesture() }}
        <button v-if="isMobileView" type="button" class="btn btn_header ripple" @click="openUserPreferences()">
          <i class="icone material-icons">
            tune
          </i>
        </button>
        <div v-for="market in (isMobileView ? [settings.market] : marketsWithGlobo)"
             :key="market.id"
             class="card"
             :class="{
               selected: market.id === settings.market.id,
               disabled: marketAverage(market.id) === '--'
             }"
             @click="selectMarket(market)"
        >
          <div v-if="marketAverage(market.id) === '--'" class="loading">
            <div class="loader">
              <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6"
                        stroke-linecap="round" cx="33" cy="33" r="30"
                />
              </svg>
            </div>
          </div>

          <div v-if="isMobileView && marketsWithGlobo.length !== 1" class="notch_icon">
            <i class="icone material-icons">
              keyboard_arrow_down
            </i>
          </div>

          <!-- MARKET NAME -->
          <div class="market_name">
            {{ market.exhibitionName }}
            <span v-if="settings.isMarketsWeightVisible" :title="market.id === 'RPP' ? 'Posse' : 'Peso'" class="tag-weight tag-vision">   
              {{ market.weight ? market.weight : '-' }}
            </span>
          </div>

          <!-- PROGRESS MINUTES -->
          <div v-if="marketAverages" class="progress_minutes">
            <div v-for="(minuteLine, key) in minuteLines[market.id]"
                 :key="key" class="item" :class="minuteLine"
            />
          </div>

          <!-- AVERAGE -->
          <div class="market_average" :class="marketClass(market.id)">
            <AnimatedNumber :value="marketAverage(market.id)" />
          </div>

          <!-- MARKET SELECT -->
          <div v-if="!isMobileView" class="market_select">
            <div class="round">
              <input :id="`button_${market.id}`"
                     :checked="market.id === settings.market.id"
                     type="radio"
              >
              <label :for="`button_${market.id}`" />
            </div>
          </div>
        </div>
      </div>

      <div v-else class="market_vision">
        <div class="card">
          <div class="loading">
            <div class="loader">
              <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6"
                        stroke-linecap="round" cx="33" cy="33" r="30"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!-- // -->

      <!-- Visão de Programas -->
      <ProgramVisionMobile v-if="isMobileView"
                           :globo-programs="globoPrograms"
                           :last-minute-selected-program="lastMinuteSelectedProgram"
                           :last-minute-selected-class="lastMinuteSelectedClass"
                           :last-minute-previous-program="lastMinutePreviousProgram"
                           :last-minute-previous-class="lastMinutePreviousClass"
                           :last-minute="lastMinute"
                           @on-selected-program-change="changeProgram"
                           @swiper="setSwiper"
      />
      <ProgramVision v-else
                     :globo-programs="globoPrograms"
                     :last-minute-selected-program="lastMinuteSelectedProgram"
                     :last-minute-selected-class="lastMinuteSelectedClass"
                     :last-minute-previous-program="lastMinutePreviousProgram"
                     :last-minute-previous-class="lastMinutePreviousClass"
                     :last-minute="lastMinute"
                     @on-selected-program-change="changeProgram"
      />
      <!-- // -->

      <!-- Esteira de Concorrentes -->
      <div class="rivals_vision">
        <!-- ITEM -->
        <template v-if="tvNetworkAverages">
          <div v-for="tvNetwork in tvNetworkAveragesFiltered"
               :key="tvNetwork.id"
               class="card"
          >
            <!-- Emissora -->
            <div class="emissora">
              <img v-if="getNetworkLogo(tvNetwork.id)" :src="getNetworkLogo(tvNetwork.id)">
              <span v-else>
                {{ tvNetwork.id }}
              </span>
            </div>

            <!-- Content -->
            <div class="content">
              <!-- Bottom -->
              <div class="bottom">
                <div class="primary" :class="tvNetworkClass(tvNetwork.id)">
                  <AnimatedNumber :value="tvNetwork[singleDefaultValue]" />
                  <span>
                    {{ audienceTypeLabel }}
                  </span>
                </div>
              </div>
            </div>

            <!-- Line -->
            <div class="emissora_line" :style="{ backgroundColor: getNetworkColor(tvNetwork.id) }" />
          </div>
        </template>
        <div v-for="key in [1,2,3,4,5,6,7]" v-else :key="key" class="card">
          <div class="loading">
            <div class="loader">
              <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6"
                        stroke-linecap="round" cx="33" cy="33" r="30"
                />
              </svg>
            </div>
          </div>

          <!-- Emissora -->
          <div class="emissora" />

          <!-- Content -->
          <div class="content">
            <!-- Bottom -->
            <div class="bottom">
              <div class="primary">
                --
                <span>
                  {{ audienceTypeLabel }}
                </span>
              </div>
            </div>
          </div>

          <!-- Line -->
          <div class="emissora_line" />
        </div>
        <!-- ////////////////////////////////////////// -->
      </div>
      <!-- // -->
    </template>

    <div v-else class="loading_inner">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';
import _ from 'lodash';
import AnimatedNumber from '@/components/modules/AnimatedNumber.vue';
import ProgramVision from './ProgramVision.vue';
import ProgramVisionMobile from './ProgramVisionMobile.vue';
import 'hammerjs';

export default {
  components: {
    AnimatedNumber,
    ProgramVision,
    ProgramVisionMobile,
  },
  data() {
    return {
      scrollId: null,
      getLastMinuteTimeout: null,
      getAudiencesTimeout: null,
      changeProgramTimeout: null,
      currentAudienceTimeout: null,
      currentAudienceInterval: null,
      firstLoading: true,
      didFinsh: false,
      minuteLines: null,
      marketAverages: {},
      programAverages: null,
      tvNetworkWinners: {},
      duration: 0,
      program: '',
      programKey: null,
      indexSelected: null,
      startsIn: null,
      endsIn: null,
      lastMinuteSelectedProgram: null,
      lastMinuteSelectedClass: '',
      lastMinutePreviousProgram: null,
      lastMinutePreviousClass: '',
      lastMinute: null,
      lastMinuteData: null,
      skipWatch: false,
      isExtended: null,
      swiper: null,
      direction: null,
    };
  },
  computed: {
    ...mapState(['settings', 'system']),
    ...mapGetters(['markets', 'tvNetworks', 'tvNetworks_v2',
      'programByDate', 'marketDays', 'globoDate', 'isMobileView',
      'networks', 'singleDefaultValue']),
    marketsWithGlobo() {
      return this.markets.filter(market => market.tvNetworks.find(tvNetwork => tvNetwork.id === 'GLOBO'));
    },
    tvNetworkAveragesFiltered() {
      //TODO: rever essa logica - atualmente está apenas tirando o TL. deveria tirar os totalizadores em caso de share?
      return this.tvNetworkAverages.filter(tvNetwork => (
        (tvNetwork.id !== 'TL' && this.singleDefaultValue !== 'TL')
        || !(tvNetwork.id !== 'TLE' && this.singleDefaultValue !== 'TLE')
      ));
    },
    tvNetworkAverages() {
      const marketAverage = this.marketAverages[this.settings.market.id];
      if (!marketAverage) return null;
      const average = Object.keys(marketAverage)
        .filter(tvNetwork => !['GLOBO', 'TLE', 'TL'].includes(tvNetwork))
        .map((tvNetwork) => {
          marketAverage[tvNetwork].id = tvNetwork;
          return marketAverage[tvNetwork];
        });

      const averageTL = Object.keys(marketAverage)
        .filter(tvNetwork => ['TLE', 'TL'].includes(tvNetwork))
        .map((tvNetwork) => {
          marketAverage[tvNetwork].id = tvNetwork;
          return marketAverage[tvNetwork];
        });

      return _.unionBy(
        _.orderBy(average, ['audience'], ['desc']).splice(0, 5),
        _.orderBy(averageTL, ['id'], ['desc']),
        'id',
      );
    },
    globoPrograms() {
      return this.programAverages
        ? this.programAverages.filter(({ marketName, tvNetworkId }) => {
          if (this.settings.usePrograms && this.networks[tvNetworkId] && this.networks[tvNetworkId].includes(marketName)) {
            return tvNetworkId === 'GLOBO';
          }

          return marketName === this.settings.market.name && tvNetworkId === 'GLOBO';
        })
        : null;
    },
    isToday() {
      return this.settings.dateOptions.day === 0;
    },
    visionClass() {
      return {
        active: this.isExtended === false,
        inactive: this.isExtended,
      };
    },
    isLive() {
      return moment().isBefore(this.endsIn);
    },
    isFirst() {
      return this.swiper && this.swiper.activeIndex === 0;
    },
    audienceTypeLabel() {
      return this.singleDefaultValue === 'shareTLE' ? 'Share' : 'Audiência';
    }
  },
  watch: {
    isMobileView() {
      this.setupGesture();
    },
  },
  mounted() {
    if (this.isMarketsWithGlobo()) {
      if (!this.marketsWithGlobo.find(({ id }) => id === this.settings.market.id)) {
        this.selectMarket(this.marketsWithGlobo[0]);
      }
      this.getProgram();
    }

    const date = new Date();
    const startOfMinute = (60 - date.getSeconds()) * 1000;
    this.currentAudienceTimeout = setTimeout(() => {
      this.currentAudienceInterval = setInterval(() => {
        if (this.isToday) this.getProgram();
      }, 30000);

      if (this.isToday) this.getProgram();
    }, startOfMinute);

    this.unwatch = this.$store.watch(state => state.settings, () => {
      if (this.isMarketsWithGlobo()) {
        if (this.skipWatch) {
          this.skipWatch = false;
        } else {
          this.programKey = null;
          this.startsIn = null;
          this.endsIn = null;
          this.programAverages = null;
          this.marketAverages = {};
          this.tvNetworkWinners = {};
          this.getLastMinute();
          this.getProgram();
        }
      }
    }, { deep: true });
  },
  beforeDestroy() {
    bffRealtime.cancelRequests('vision');
    this.unwatch();
    this.$store.dispatch('showMobileNavigation');
    clearTimeout(this.getLastMinuteTimeout);
    clearTimeout(this.getAudiencesTimeout);
    clearTimeout(this.changeProgramTimeout);
    clearTimeout(this.currentAudienceTimeout);
    clearInterval(this.currentAudienceInterval);
  },
  methods: {
    openUserPreferences() {
      this.$store.dispatch('openUserPreferences');
    },
    setupGesture() {
      if (!this.isMobileView || !this.$refs.marketVision || !this.$refs.marketVision) return;

      const marketsFull = new Hammer(this.$refs.marketsFull, {
        touchAction: 'pan-y',
      });
      marketsFull.on('panleft press panright panup panend', ({ type }) => {
        if (type === 'panend') {
          if (this.direction === 'panright') {
            this.slidePrev();
          } else if (this.direction === 'panleft') {
            this.slideNext();
          } else if (this.direction === 'panup') {
            this.extendMarket(false);
          }
        } else {
          this.direction = type;
        }
      });

      const marketVision = new Hammer(this.$refs.marketVision, {
        touchAction: 'pan-x',
      });
      marketVision.on('pandown', () => this.extendMarket(true));
    },
    isMarketsWithGlobo() {
      if (this.marketsWithGlobo.length === 0) {
        this.$store.dispatch('showInnerError', {
          insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
          subtitle: 'As praças PAYTV não estão disponíveis na visão do Dashboard de Programas. Por favor, clique no botão abaixo para selecionar uma praça de TV ABERTA.',
          buttons: [{
            text: 'Preferências',
            click: () => this.openUserPreferences(),
          }],
        });

        return false;
      }

      return true;
    },
    goToPage(name) {
      this.$router.push({ name });
    },
    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    getNetworkColor(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor : '';
    },
    marketAverage(market) {
      const marketAverage = this.marketAverages[market];
      return marketAverage && marketAverage.GLOBO ? marketAverage.GLOBO[this.singleDefaultValue] : '--';
    },
    marketClass(market) {
      const tvNetworkWinner = this.tvNetworkWinners[market];
      return tvNetworkWinner ? this.indicadoresAudience('GLOBO', tvNetworkWinner) : '';
    },
    tvNetworkClass(tvNetwork) {
      const tvNetworkWinner = this.tvNetworkWinners[this.settings.market.id];
      return tvNetworkWinner ? this.indicadoresAudience(tvNetwork, tvNetworkWinner) : '';
    },
    // value(tvNetwork) {
    //   return this.settings.defaultValue === 'audience' ? tvNetwork.shareTL : tvNetwork.audience;
    // },
    setSwiper(swiper) {
      this.swiper = swiper;
    },
    slideNext() {
      if (this.swiper) this.swiper.slideNext();
    },
    slidePrev() {
      if (this.swiper) this.swiper.slidePrev();
    },
    async changeProgram(programData) {
      const {
        startsIn, endsIn, marketName, program, indexSelected,
      } = programData;
      const programKey = `${marketName}${indexSelected}`;

      const newProgram = this.programKey !== programKey;
      if (newProgram) {
        bffRealtime.cancelRequests('vision');

        this.scrollId = null;
        this.marketAverages = {};
        this.tvNetworkWinners = {};
        this.lastMinuteSelectedProgram = null;
        this.lastMinutePreviousProgram = null;
        this.lastMinute = null;
        this.programKey = programKey;
        this.indexSelected = indexSelected;
        this.startsIn = startsIn;
        this.endsIn = endsIn;
        this.program = program;
        this.duration = moment(endsIn).diff(startsIn, 'minutes');

        const minuteLines = {};
        this.markets.forEach((market) => {
          minuteLines[market.id] = [];
          for (let i = 0; i < this.duration; i += 1) {
            minuteLines[market.id].push('');
          }
        });
        this.minuteLines = minuteLines;
      }

      clearTimeout(this.changeProgramTimeout);
      this.changeProgramTimeout = setTimeout(() => {
        if (newProgram) {
          this.lastMinuteData = null;
          this.getLastMinute();
        }

        this.getAudiences();
        try {
          this.getRangeAverages();
        } catch (error) {
          console.log('error :: ', error);
        }

      }, 2000);
    },
    getProgram() {
      this.getAudiences();
      try {
        this.getRangeAverages();
      } catch (error) {
        console.log('error :: ', error);
      }
      this.getProgramAverages();
    },
    async getRangeAverages() {
      if (this.indexSelected === null || !this.globoPrograms[this.indexSelected]) return;

      this.marketAverages = this.globoPrograms[this.indexSelected].averages;
      this.tvNetworkWinners = this.globoPrograms[this.indexSelected].tvNetworkWinners;
    },
    async getProgramAverages() {
      const filterProgramAverages = {
        startDate: this.globoDate.startsIn,
        endDate: this.globoDate.endsIn || moment().format(),
        marketName: this.settings.market.name,
        marketId: this.settings.market.id,
        isRivals: true,
        isCustom: false,
      };

      try {
        const { data } = await bffRealtime.getProgramAverages(
          filterProgramAverages, bffRealtime.tokenRequest('vision', 'programAverages'),
        );
        if (data.length === 0) {
          this.$store.dispatch('showInnerError', {
            clicked: true,
          });
        } else {
          this.programAverages = data;
        }
      } catch (error) {
        if (error !== 'Request Canceled') {
          if (error.response.status === 405) {
            this.$store.dispatch('showInnerError', {
              insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
              subtitle: 'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
            });
          }
        }
      } finally {
        this.$store.dispatch('updateLastTime');
      }
    },
    async getLastMinute() {
      clearTimeout(this.getLastMinuteTimeout);
      if (!this.startsIn) return;

      const startDate = moment(this.startsIn).subtract(1, 'minutes').format();
      const filterAudiences = {
        startDate,
        endDate: this.startsIn,
        sortBy: ['-date'],
        size: 500,
        tvNetworkId: 'GLOBO',
      };

      try {
        const { data } = await bffRealtime.getAudiences(filterAudiences, bffRealtime.tokenRequest('vision', 'lastMinute'));
        const lastMinute = data.source.find(({ marketId }) => marketId === this.settings.market.id);
        if (!lastMinute) return;

        this.lastMinutePreviousProgram = lastMinute[this.singleDefaultValue];
        this.lastMinutePreviousClass = this.indicadoresAudience('GLOBO', lastMinute.tvNetworkWinners);
      } catch (error) {
        console.error(error);
        this.getLastMinuteTimeout = setTimeout(() => this.getLastMinute(), 2000);
      }
    },
    async getAudiences() {
      if (!this.startsIn || !this.endsIn) return;
      clearTimeout(this.getAudiencesTimeout);

      const filterAudiences = {
        startDate: this.startsIn,
        endDate: this.endsIn,
        sortBy: ['+date'],
        size: 500,
        tvNetworkId: 'GLOBO',
      };

      try {
        const { data } = await bffRealtime.getAudiences(filterAudiences, bffRealtime.tokenRequest('vision', 'audiences'));
        this.updateMinuteLine(data.source);
        if (this.scrollId) {
          this.updateLastMinute();
        } else {
          this.scrollId = data.scrollId;
          await this.getAudiencesByScrollId(this.scrollId);
        }
      } catch (error) {
        if (error !== 'Request Canceled') {
          console.error(error);
          this.scrollId = null;
          this.getAudiencesTimeout = setTimeout(() => this.getAudiences(), 2000);
        }
      }
    },
    async getAudiencesByScrollId(scrollId) {
      const { data } = await bffRealtime.getScroll(scrollId, bffRealtime.tokenRequest('vision', 'audiencesByScrollId'));
      if (data.source.length === 0) {
        this.updateLastMinute();
      } else {
        this.updateMinuteLine(data.source);
        await this.getAudiencesByScrollId(scrollId);
      }
    },
    updateLastMinute() {
      if (!this.lastMinuteData) return;

      const { lastMinute, lastMinuteSelectedProgram, lastMinuteSelectedClass } = this.lastMinuteData;
      this.lastMinute = lastMinute;
      this.lastMinuteSelectedProgram = lastMinuteSelectedProgram;
      this.lastMinuteSelectedClass = lastMinuteSelectedClass;
    },
    updateMinuteLine(source) {
      // trick - making a deep copy of small array
      const copy = JSON.parse(JSON.stringify(this.minuteLines));

      source.forEach(({
        date, marketId, tvNetworkWinners, ...rest
      }) => {
        if (!this.minuteLines[marketId]) return;

        const position = this.duration - moment(this.endsIn).diff(date, 'minutes');
        copy[marketId][position] = this.indicadoresAudience('GLOBO', tvNetworkWinners);

        if (marketId === this.settings.market.id) {
          this.lastMinuteData = {
            lastMinute: moment(date).format('HH:mm'),
            lastMinuteSelectedProgram: rest[this.singleDefaultValue],
            lastMinuteSelectedClass: copy[marketId][position],
          };
        }
      });
      this.minuteLines = copy;
    },
    indicadoresAudience(tag, tvNetworkWinners) {
      if (tvNetworkWinners && tvNetworkWinners.includes(tag)) {
        return (tvNetworkWinners.length === 1)
          ? 'won'
          : 'drew';
      }
      if (tvNetworkWinners && tag === 'GLOBO') return 'lost';
      return '';
    },
    getProgramName(networkId) {
      // REMOVE THIS BAND
      // eslint-disable-next-line no-param-reassign
      if (networkId === 'BAND') networkId = 'BAN';

      const programAverage = this.programAverages.find(({
        marketName, tvNetworkId, startsIn, endsIn,
      }) => (
        marketName === this.settings.market.name
        && tvNetworkId === networkId
        && moment(endsIn).isAfter(this.startsIn)
        && moment(startsIn).isSameOrBefore(this.endsIn)
      ));
      return programAverage ? programAverage.program : '--';
    },
    getProgramTime(networkId) {
      // REMOVE THIS BAND
      // eslint-disable-next-line no-param-reassign
      if (networkId === 'BAND') networkId = 'BAN';

      const programAverage = this.programAverages.find(({
        marketName, tvNetworkId, startsIn, endsIn,
      }) => (
        marketName === this.settings.market.name
        && tvNetworkId === networkId
        && moment(endsIn).isAfter(this.startsIn)
        && moment(startsIn).isSameOrBefore(this.endsIn)
      ));
      return programAverage ? moment(programAverage.startsIn).format('HH:mm') : '--:--';
    },
    selectMarket(market) {
      this.skipWatch = !this.settings.usePrograms;
      this.getLastMinute();
      this.$store.dispatch('selectMarket', market);
    },
    extendMarket(isExtended) {
      if (this.marketsWithGlobo.length === 1) return;

      const { isUserPreferencesOpen } = this.system;
      if (!isUserPreferencesOpen) {
        this.$store.dispatch(isExtended ? 'hideMobileNavigation' : 'showMobileNavigation');
        this.isExtended = isExtended;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.vision {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;

  .loading_inner {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    background: rgba($color-gray-lighter, 1);

    .spinner {
      position: relative;
      width: 40px;
      height: 40px;

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

  .markets_full {
    display: none;
  }

  // Loading
  .market_vision, .rivals_vision {
    .card {
      &.disabled {
        pointer-events: none;
      }

      .loading {
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background-color: rgba($color-gray-lighter, 0.8);

        &.in {
          animation: fadeIn 0.4s ease-in-out forwards;
        }

        &.out {
          animation: fadeOut 0.4s ease-in-out forwards;
        }

        .loader {
          width: 24px;
          height: 24px;

          .spinner {
            width: 100%;
            height: 100%;
            animation: rotator $duration linear infinite;
          }

          .path {
            stroke-dasharray: $offset;
            stroke-dashoffset: 0;
            transform-origin: center;
            animation:
              dash $duration ease-in-out infinite,
              colors ($duration*4) ease-in-out infinite;
          }
        }
      }
      // ------
    }
  }

  // Market Vision
  .market_vision {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: space-between;
    width: 224px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .notch_icon {
      display: none;
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      flex-grow: 1;
      height: auto;
      min-height: 32px;
      max-height: 48px;
      margin: 4px 0;

      &:first-child {
        margin: 0 0 4px 0;
      }

      // Alinhamento para os filhos
      .market_name,
      .progress_minutes,
      .market_average,
      .market_select {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: auto;
        min-height: 32px;
        max-height: 48px;
      }

      // Market Name
      .market_name {
        align-items: center;
        width: 24px;
        height: auto;
        min-height: 32px;
        max-height: 48px;
        font-size: 0.8em;
        font-style: italic;
        font-weight: 500;
        color: $color-gray-dark;
        margin: 0 0 0 4px;
        transition: all 0.4s ease-in-out;
        justify-content: center;
      }

      .progress_minutes {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        width: calc(100% - 124px);
        height: 100%;
        background: $color-gray-lighter;
        margin-left: 20px;
        transition: all 0.4s ease-in-out;

        .item {
          display: flex;
          flex-wrap: wrap;
          flex-grow: 1;
          height: auto;
          min-height: 32px;
          max-height: 48px;
          margin: 0;
          background-color: rgba($color-gray-light, 0.32);
          transition: background-color 0.4s ease-in-out;

          &.won {
            background-color: $color-status-won;
          }

          &.drew {
            background-color: $color-status-drew;
          }

          &.lost {
            background-color: $color-status-lost;
          }
        }
      }

      .market_average {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: auto;
        min-height: 32px;
        max-height: 48px;
        font-weight: 600;
        font-size: 0.88em;
        letter-spacing: 0;
        box-sizing: border-box;
        padding: 2px;
        margin: 0 4px;
        border-radius: 4px;
        color: $color-gray-lighter;
        background-color: $color-gray-lighter;
        transition: color 0.4s ease-in-out;

        &.won {
          color: $color-status-won;
        }

        &.drew {
          color: $color-status-drew;
        }

        &.lost {
          color: $color-status-lost;
        }
      }

      .market_select {
        align-items: center;
        width: 20px;
        margin: 0 4px;
        height: auto;
        min-height: 32px;
        max-height: 48px;

        // Checkbox
        .round {
          position: relative;

          label {
            background-color: $color-gray-lighter;
            border: 2px solid rgba($color-gray-main, 0.24);
            border-radius: 100%;
            cursor: pointer;
            width: 20px;
            height: 20px;
            left: 0;
            position: absolute;
            top: 0;
            box-shadow: 0 4px 8px rgba($color-gray-darker, 0);
            transition: all 0.24s ease-in;

            &:hover {
              border: 2px solid rgba($color-gray-main, 0.56);
              background-color: $color-gray-back;
            }

            &:after {
              content: "";
              position: absolute;
              top: 3px;
              left: 3px;
              height: 10px;
              width: 10px;
              opacity: 0;
              border-radius: 100%;
              background-color: $color-gray-lighter;
              transition: all 0.24s ease-in;
            }
          }

          // Input
          input[type="radio"] {
            visibility: hidden;
          }

          input[type="radio"]:checked + label {
            background-color: $color-secondary;
            border-color: $color-secondary;
          }

          input[type="radio"]:checked + label:after {
            opacity: 1;
          }
        }
      }

      // Tratamento para card selecionado
      &.selected {
        box-shadow: 0 2px 8px rgba($color-gray-dark, 0.16);
        border-bottom: solid 2px $color-secondary;
        border-radius: 4px 4px 0 0;
      }

      // Tratamento para card hover
      &:hover {
        box-shadow: 0 2px 8px rgba($color-gray-dark, 0.16);
      }
    }
  }
  // ------------

  .market_vision,
  .rivals_vision {

    .card {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-content: center;
      width: 100%;
      min-height: 40px;
      height: auto;
      box-sizing: border-box;
      padding: 8px;
      border-radius: 4px;
      background-color: $color-gray-lighter;
      border-radius: 4px;

      font-family: $secondary-typo;
      font-weight: 400;
      font-size: 0.88em;

      cursor: pointer;
      transition: box-shadow 0.4s ease-in-out;
    }
  }

  // Rivals Vision
  .rivals_vision {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: space-between;
    width: 140px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      height: auto;
      min-height: 80px;
      margin: 4px 0;

      // Tratamentos para primeiro e último filhos
      &:first-child {
        margin: 0 0 8px 0;
      }

      &:last-child {
        margin: 8px 0 0 0;
      }

      // Emissora + Content
      .emissora,
      .content {
        display: inline-block;
      }

      // Emissora
      .emissora {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 40px;
        height: 100%;
        margin: 0 8px 0 0;

        img {
          width: 40px;
          height: 40px;
        }
      }

      // Content
      .content {
        display: block;
        width: calc(100% - 56px);
        flex-wrap: wrap;
        height: 100%;

        // Top
        .top {
          display: block;
          width: 100%;
          height: 40%;
          border-bottom: solid 1px rgba($color-gray-light, 0.4);

          .name,
          .time {
            display: inline-block;
            height: 100%;
            padding: 12px 0 0 0;
            font-family: $secondary-typo;
            font-size: 0.88em;
            color: $color-gray-dark;
          }

          .name {
            float: left;
            width: 64%;
            font-weight: 400;
            text-transform: uppercase;

            overflow: hidden;
            min-width: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .time {
            float: right;
            width: 32%;
            font-weight: 600;
            font-style: italic;
            text-align: right;
            box-sizing: border-box;
            padding: 12px 16px 0 0;
          }

        }

        // Top
        .bottom {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          height: 100%;
          box-sizing: border-box;
          padding: 0 16px 0 0;

          .primary,
          .secondary {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            padding: 0;
            font-family: $primary-typo;
            font-weight: 500;
            font-size: 1.4em;
            color: $color-gray-dark;

            &.won {
              color: $color-status-won;
            }

            &.drew {
              color: $color-status-drew;
            }

            &.lost {
              color: $color-status-lost;
            }

            span {
              width: 100%;
              margin: 4px 0 0 0;
              font-family: $secondary-typo;
              font-size: 0.4em;
              font-style: italic;
              font-weight: 500;
              text-transform: uppercase;
              text-align: right;
              letter-spacing: 0.08em;
              color: $color-gray-main;
            }
          }
        }
      }

      // Line
      .emissora_line {
        position: absolute;
        top: 8px;
        right: 8px;
        width: 4px;
        height: calc(100% - 16px);
        background-color: $color-gray-main;
        transition: background-color 0.4s ease-in-out;
      }
    }
  }
}

// MEDIUM DEVICES
@media (max-width: 1200px) {
  .vision {

    // Market Vision
    .market_vision {
      position: relative;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
      height: 56px;
      min-height: 56px;
      box-sizing: border-box;
      padding: 0 0 16px 0;
      margin: auto 0 8px 0;
      overflow-x: auto;

      .card {
        width: 240px;
        margin: 0 4px;

        &:first-child {
          margin: 0 4px 0 0;
        }

        &:last-child {
          margin: 0 0 0 4px;
        }

        &.selected {
          box-shadow: 0 2px 4px rgba($color-gray-dark, 0.08);
        }
      }
    }
    // --------------
    // --------------

    // Program Vision
    .program_vision {
      width: 100%;
      margin: -16px 0 auto 0;
      height: calc(100% - 124px);
    }
    // --------------
    // --------------

    // Rivals Vision
    .rivals_vision {
      position: relative;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      height: 64px;
      min-height: 64px;
      box-sizing: border-box;
      padding: 0 0 16px 0;
      margin: 8px 0 auto 0;
      overflow-x: auto;

      .card {
        width: 140px;
        height: 64px;
        min-height: 64px;
        margin: 0 4px;

        &:first-child {
          margin: 0 4px 0 0;
        }

        &:last-child {
          margin: 0 0 0 4px;
        }

        .emissora {
          margin: 0;
        }

        .content {
          display: flex;
          justify-content: space-around;

          .bottom {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 24px 0 0;

            .primary,
            .secondary {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-end;
              text-align: right;
              width: 100%;

              span {
                margin: 2px 0 0 0;
              }
            }
          }
        }
      }
    }
    // --------------
    // --------------

  }
}

// SMARTPHONES
@media (max-width: 600px) {

  .vision {

    // Visão Full de todas as Praças
    .markets_full {
      display: flex;
      position: fixed;
      z-index: 999999;
      width: 100%;
      height: 100%;
      background-color: $color-gray-dark;
      transform: translateY(-100vh);
      opacity: 0;

      .notch {
        position: fixed;
        bottom: 0;
        z-index: 9999;
        width: 100%;
        margin: 0;
        padding: 0 16px;
        height: 40px;
        animation: fadeInUp 0.8s ease-in-out forwards;
        touch-action: pan-x;

        // Background Notch
        &::before {
          position: absolute;
          z-index: 2;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 24px;
          background-color: $color-gray-back;
          box-shadow: 0 0 16px rgba($color-gray-darker, 0.16);
        }

        // Button Notch
        &::after {
          position: fixed;
          z-index: 2;
          left: 50%;
          top: 4px;
          margin: 0 0 0 -40px;
          display: block;
          content: "";
          width: 80px;
          height: 32px;
          border-radius: 12px;
          border-top: solid 3px rgba($color-gray-light, 0.24);
          background-color: $color-gray-back;
        }

        .notch_icon {
          position: absolute;
          z-index: 3;
          top: 8px;
          left: 0;
          display: flex;
          width: 100%;

          i {
            margin: 0 auto;
            font-size: 1.4em;
            color: $color-gray-main;
          }
        }
      }

      // Content Market
      .content_markets {
        position: relative;
        z-index: 0;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        background: $color-gray-dark;

        .arrow_left,
        .arrow_right {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 10%;
          height: 100vh;

          &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }

          .btn {

            // Ripple Effect
              &.ripple {
                width: 100%;
                border-radius: 0;
                background-position: center;
                background: $color-gray-dark;
                transition: background 0.4s ease-in-out, opacity 0.8s ease-in-out, box-shadow 0.4s ease-in-out;

                & i {
                  position: relative;
                  color: $color-gray-lighter;
                  transition: color 0.4s ease-in-out;
                }

                &:hover {
                  background: $color-gray-dark radial-gradient(circle, transparent 1%, $color-gray-dark 1%) center/15000%;
                  color: $color-secondary;
                  box-shadow: 0 4px 8px rgba($color-gray-darker, 0.08);

                  & i {
                    color: $color-gray-lighter;
                  }
                }

                &:active {
                  background-color: rgba($color-gray-darker, 0.9);
                  background-size: 100%;
                  transition: background 0s;
                }
              }
          }
        }

        .content {
          display: block;
          width: 80%;
          margin: 24px 0 0 0;
          padding: 0 0 24px 0;
          box-sizing: border-box;
          height: calc(100% - 132px);

          .title {
            display: block;
            width: 100%;
            height: 24px;
            font-family: $secondary-typo;
            font-size: 0.88em;
            font-style: italic;
            font-weight: 400;
            color: $color-gray-light;

            span {
              font-weight: 600;
              color: $color-gray-lighter;
            }
          }

          .market_vision {
            position: relative;
            display: block;
            width: 100%;
            height: 100%;
            margin: 4px 0 0 0;
            padding: 0;
            opacity: 1;
            animation: none;
            overflow-y: auto;
            touch-action: pan-y;
            background: transparent;

            .card {
              width: 100%;
              margin: 0 0 8px 0;

              .loading {
                border-radius: 4px;
              }

              .market_name {
                width: 40px;
              }

              .progress_minutes {
                width: calc(100% - 148px);
              }

              .market_average {
                width: 48px;
              }
            }

            &::before,
            &::after {
              display: none;
            }

            .notch_icon {
              display: none;
            }
          }
        }
      }

      // Resume Bar
      .resume_bar {
        position: fixed;
        z-index: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        height: 108px;
        background-color: $color-gray-lighter;
        box-shadow: 0 -4px 24px rgba($color-gray-dark, 0.24);
        touch-action: pan-x;

        // Loading
        .loading {
          z-index: 2;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(100% - 24px);
          background-color: rgba($color-gray-lighter, 0.8);

          &.in {
            animation: fadeIn 0.4s ease-in-out forwards;
          }

          &.out {
            animation: fadeOut 0.4s ease-in-out forwards;
          }

          .loader {
            width: 24px;
            height: 24px;

            .spinner {
              width: 100%;
              height: 100%;
              animation: rotator $duration linear infinite;
            }

            .path {
              stroke-dasharray: $offset;
              stroke-dashoffset: 0;
              transform-origin: center;
              animation:
                dash $duration ease-in-out infinite,
                colors ($duration*4) ease-in-out infinite;
            }
          }
        }
        // ------

        .content,
        .average {
          height: 100%;
        }

        .content {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          padding: 20px 16px;
          box-sizing: border-box;
          width: 70%;

          .line {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            flex-direction: row;
            width: 100%;
            margin: 0 0 8px 0;

            .item {
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-items: center;
              flex-direction: row;
              width: 30%;
              margin: 0 16px 0 0;
              font-family: $secondary-typo;
              font-size: 0.8em;
              font-weight: 500;
              color: $color-gray-main;

              &.full {
                width: 100%;

                span {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }

              i {
                margin: 0 8px 0 0;
                font-size: 1.2em;
                color: $color-gray-light;
              }

              &.status {
                text-transform: uppercase;
                font-weight: 500;

                &.exibido{
                  color: $color-status-noar;

                  i {
                    color: rgba($color-status-noar, 0.4);
                  }
                }

                &.no-ar {
                  color: $color-status-exibido;

                  i {
                    color: rgba($color-status-exibido, 0.4);
                  }
                }
              }
            }
          }
        }

        .average {
          position: relative;
          display: block;
          width: 30%;
          background-color: $color-gray-main;

          .label,
          .value {
            width: 100%;
            text-align: center;
            padding: 0 0 0 16px;
          }

          .label {
            width: 100%;
            height: 16px;
            margin: 20px 0 0 0;
            font-family: $secondary-typo;
            font-weight: 600;
            font-style: italic;
            font-size: 0.64em;
            text-transform: uppercase;
            color: rgba($color-gray-lighter, 0.8);
          }

          .value {
            width: 100%;
            height: 40px;
            font-family: $primary-typo;
            font-size: 1.2em;
            color: $color-gray-lighter;
          }

          &::before {
            content: "";
            position: absolute;
            left: -px;
            width: 0;
            height: 0;
            border-top: 88px solid $color-gray-lighter;
            border-right: 16px solid transparent;
          }

          &.won {
            background-color: $color-status-won;
          }

          &.drew {
            background-color: $color-status-drew;
          }

          &.lost {
            background-color: $color-status-lost;
          }
        }
      }
    }
    // --------------

    .market_vision,
    .rivals_vision {
      transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
    }

    .program_vision {
      transition: transform 0.8s ease-in-out, opacity 0.8s ease-in-out;
    }

    .card {
      pointer-events: none;
    }

    // Transitions

    &.active {

      // Exibe os elementos da tela de dashboard
      .program_vision {
        transform: translateY(0);
        opacity: 1;
      }

      .market_vision {
        animation: fadeInDown 0.6s ease-in-out forwards;
      }

      .rivals_vision {
        animation: fadeInUp 0.8s ease-in-out forwards;
      }

      // Esconde o notch com detalhamento de praças
      .markets_full {
        transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
        transform: translateY(-100vh);
        opacity: 0;
      }
    }

    &.inactive {

      // Exibe os elementos da tela de dashboard
      .program_vision {
        transform: translateY(140vh);
        opacity: 0;
      }

      .market_vision {
        animation: fadeOutUp 0.4s ease-in-out forwards;
      }

      .rivals_vision {
        animation: fadeOutDown 0.24s ease-in-out forwards;
      }

      // Exibe o notch com detalhamento de praças
      .markets_full {
        transition: transform 0.6s ease-in-out, opacity 0.6s ease-in-out;
        transform: translateY(0);
        opacity: 1;
      }

      .card {
        pointer-events: auto;
      }
    }

    .market_vision {
      position: fixed;
      top: env(safe-area-inset-top, 0);
      z-index: 2;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-around;
      flex-direction: row;
      margin: 0;
      padding: 56px 16px 0 4px;
      height: 132px;
      animation-delay: $delay-time;
      animation: fadeInDown 0.8s ease-in-out forwards;

      // Background Notch
      &::before {
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 116px;
        background-color: $color-gray-back;
        box-shadow: 0 0 16px rgba($color-gray-darker, 0.16);
      }

      // // Button Notch
      &::after {
        position: fixed;
        z-index: 1;
        left: 50%;
        bottom: 8px;
        margin: 0 0 0 -40px;
        display: block;
        content: "";
        width: 80px;
        height: 32px;
        border-radius: 12px;
        border-bottom: solid 3px rgba($color-gray-light, 0.24);
        background-color: $color-gray-back;
      }

      &.single {
        &::after {
          display: none;
        }
      }

      .notch_icon {
        position: absolute;
        z-index: 1;
        bottom: -24px;
        left: -22px;
        display: flex;
        width: 100%;

        i {
          margin: 0 auto;
          font-size: 1.4em;
          color: $color-gray-main;
        }
      }

      .btn {
        margin: 0 4px;

        i {
          position: relative;
          margin: 0;
          top: inherit;
          left: inherit;
          padding: 0;
          margin: 0;
          font-size: 1.6em;
        }
      }

      .card {
        position: relative;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        padding: 4px 0;

        height: 40px;
        min-height: 40px;
        max-height: 40px;

        border-radius: 4px;

        &:first-child {
          margin: 0 0 0 auto;
        }

        &.selected {
          border: none;
          border-radius: 4px;
        }

        // -----------------------------

        // Market Name
        .market_name {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 40px;
          box-sizing: border-box;
          padding: 0 0 0 8px;
        }

        // Progress Minutes
        .progress_minutes {
          width: calc(100% - 100px);
          height: 24px;
          margin: 0 0 0 4px;
          padding-left: 8px;

          .item {
            margin: 0;
          }
        }

        // Market Average
        .market_average {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          width: 48px;
          margin: 0 0 0 auto;
        }

        .market_select {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          margin: 0 0 0 auto;
          background-color: $color-gray-lighter;
          border-radius: 0 4px 4px 0;
          box-shadow: inset 0 4px 8px rgba($color-gray-dark, 0.08);
        }
      }
    }

    .program_vision {
      position: relative;
      z-index: 0;
      margin: 0;
      height: 100vh;
      border-radius: 0;
      opacity: 0;
      animation-delay: $delay-time;
      animation: fadeIn 0.8s ease-in-out forwards;
    }

    .rivals_vision {
      position: fixed;
      z-index: 2;
      bottom: env(safe-area-inset-bottom, 0);
      left: 0;
      display: flex;
      padding: 8px 16px;
      margin: auto 0 48px 0;
      height: 74px;
      background-color: $color-gray-back;

      box-shadow: 0 -2px 16px rgba($color-gray-darker, 0.24);
      opacity: 0;
      animation-delay: $delay-time;
      animation: fadeInUp 0.8s ease-in-out forwards;

      .card {
        width: 140px;
        height: 56px;
        min-height: 56px;
        max-height: 56px;

        &:last-child {
          margin: 0 30px 0 8px;
        }

        .content {
          .bottom {
            padding: 0 16px 0 0;
            justify-content: flex-end;
            align-items: center;
            height: 100%;

            .primary {
              width: 100%;
              font-size: 1.2em;

              div {
                font-family: $primary-typo;
              }

              span {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

// iPhone 5
@media screen and (device-aspect-ratio: 40/71) {

  .vision {

    .markets_full {
      .content_markets {
        .arrow_left,
        .arrow_right {
          width: 12%;
        }

        .content {
          width: 76%;
        }
      }
    }
  }

}
 
</style>
