<template>
  <div ref="content" class="table_container">
    <transition name="fade">
      <div v-if="isLoading" class="loading">
        <div class="loader">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6"
                    stroke-linecap="round" cx="33" cy="33" r="30"
            />
          </svg>
        </div>
      </div>
    </transition>
    <template v-if="groups">
      <ErrorState v-if="groupsList.length === 0" :insides="['globo_item']"
                  title="Xiiiiiiiiiiiiii"
                  subtitle="Não encontramos nenhuma regra"
                  :buttons="[{
                    text: 'QUERO VOLTAR',
                    click: () => {
                      this.$router.push({ name: 'ControlPanel' });
                    },
                  }]"
                  :style-container="{
                    backgroundColor: 'transparent',
                  }"
                  :fullscreen="false"
      />
      <template v-else>
        <table class="table">
          <thead>
            <tr>
              <th class="rule">
                Regra
              </th>
              <th class="market">
                Praças
              </th>
              <th class="date">
                Faixa
              </th>
              <th class="date">
                Dias
              </th>
              <th class="status">
                Status
              </th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(group, key) in groupsList" :key="key">
              <div class="actions" :class="{active: isCurrentGroup(group.uuid)}">
                <!-- <span>{{ group.name }}</span> -->

                <div class="icones">

                  <transition name="fade">
                    <i v-if="!group.active" class="icone material-icons tool" data-tip="Deletar Regra"
                       @click="removeRule(group.uuid)">
                      delete
                    </i>
                  </transition>

                  <i v-if="canUpdate(group.uuid)"
                     class="icone material-icons tool"
                     data-tip="Mudar Status"
                     @click="toggelStatus(group)"
                  >
                    archive
                  </i>

                  <i class="icone material-icons tool" data-tip="Associar Usuário" @click="openGroupRules(group)">
                    person
                  </i>

                  <i class="icone material-icons tool"
                     data-tip="Editar Regra"
                     @click="openGroupRules(group, true)"
                  >
                    mode_edit
                  </i>
                </div>
              </div>
              <td class="rule">
                <i
                  v-if="group.hidden"
                  data-tip="Esta regra está oculta do Acesso Globo"
                  class="icone material-icons"
                  style="margin-right: 8px"
                >
                  lock
                </i>
                {{ group.name }}
              </td>
              <td class="market">
                {{ getMarket(group.marketsId) }}
              </td>
              <td class="date">
                {{ getTime(group.times) }}
              </td>
              <td class="date" v-html="getDays(group.weekDays)" />
              <td class="status" :class="{ active: group.active }">
                {{ group.active ? 'Ativo' : 'Inativo' }}
              </td>
              <td>
                <i class="icone material-icons">more_horiz</i>
              </td>
            </tr>
          </tbody>
        </table>
        <paginate ref="pagination"
                  :page-count="pages"
                  :click-handler="pageHandler"
                  first-button-text="Primeiro"
                  last-button-text="Último"
                  :first-last-button="true"
                  prev-text="Anterior"
                  next-text="Próximo"
                  container-class="paginate"
        />
      </template>
    </template>
    <!-- fim do conteudo com tabela + pagination -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import ErrorState from '@/components/system/ErrorState.vue';

export default {
  components: {
    Paginate,
    ErrorState,
  },
  data() {
    return {
      groups: null,
      pageNumber: 1,
      perPage: 15,
      activeGroup: '',
      isLoading: true,
      markets: null,
      days: [
        { short: 'D', name: 'DOMINGO' },
        { short: 'S', name: 'SEGUNDA' },
        { short: 'T', name: 'TERCA' },
        { short: 'Q', name: 'QUARTA' },
        { short: 'Q', name: 'QUINTA' },
        { short: 'S', name: 'SEXTA' },
        { short: 'S', name: 'SABADO' },
      ],
    };
  },
  computed: {
    ...mapState(['settings', 'system']),
    pages() {
      return Math.ceil(this.groupsFiltered.length / this.perPage);
    },
    groupsFiltered() {
      const {
        search,
        marketsId, tvNetworksId,
        weekDays, time,
        // selectedPermissions,
      } = this.$route.query;

      return this.groups.filter((group) => {
        return (
          this.isMatch(group.name, search)
          && this.isInclude(group.marketsId, marketsId)
          && this.isInclude(group.tvNetworksId, tvNetworksId)
          && this.isInclude(group.weekDays, weekDays)
        );
      });
    },
    groupsList() {
      const begin = (this.pageNumber - 1) * this.perPage;
      const end = begin + this.perPage;
      return this.groupsFiltered.slice(begin, end);
    },
  },
  watch: {
    '$route.query': {
      handler() {
        // Back to page 0
        this.goToFirstPage();
      },
    },
  },
  mounted() {
    this.setupGroup();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    async setupGroup() {
      window.addEventListener('resize', this.handleResize);
      await this.getMarkets();
      this.setPerPage();
      this.getGroups();
    },
    isInclude(object, source) {
      if (source) {
        if (!object) return false;
        let match = true;
        source.forEach((val) => {
          if (!object.includes(val)) match = false;
        });

        if (!match) return false;
      }

      return true;
    },
    isMatch(text, search) {
      if (!search || search === '') return true;

      const string = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(string, 'ig');
      return regex.test(text.trim());
    },
    setPerPage() {
      const columnHeight = 48; //  This number should be the same height has been defined in css
      const paginationHeight = 64; // This number should be the same height has been defined in css
      const contentHeight = this.$refs.content.clientHeight;

      this.perPage = Math.floor((contentHeight - paginationHeight - 32) / columnHeight);
      this.goToFirstPage();
    },
    handleResize() {
      this.$modal.hide('sidebar');
      this.setPerPage();
    },
    goToFirstPage() {
      if (!this.$refs.pagination) return;
      this.$refs.pagination.selectFirstPage();
    },
    pageHandler(page) {
      this.pageNumber = page;
    },
    canUpdate(uuid) {
      const { SYSTEM_RULES } = process.env;

      return !SYSTEM_RULES.includes(uuid);
    },
    getDate(unixtime) {
      return moment.unix(unixtime).format('DD/MM/YYYY HH:mm');
    },
    async getMarkets() {
      this.isLoading = true;

      try {
        const { data } = await bffRealtime.getMarkets(
          { skipPermissions: true },
          bffRealtime.tokenRequest('controlPanel', 'getMarkets'),
        );
        this.markets = data.source;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },


    getMarket(markets) {
      return markets ? markets.map((marketID) => {
        const marketList = this.markets.find(market => market.id === marketID);
        const {exhibitionName} = marketList !== undefined ? marketList : [];
        return exhibitionName;
      }).join(', ') : '--';
    },


    getTime(times) {
      if (!times) return '--';
      if (times.find(time => time === '00:00:00-23:59:59')) return 'Tudo';

      return times.map((time) => {
        const [start, end] = time.split('-');

        return `${start.replace(/:\d{2}$/, '')} - ${end.replace(/:\d{2}$/, '')}`;
      }).join(', ');
    },
    getDays(days) {
      if (!days) return '--';

      return this.days.map(({ short, name }) => `<span class="${days.includes(name) ? 'active' : ''}">${short}</span>`).join('');
    },
    async getGroups() {
      this.isLoading = true;
      try {
        const { data } = await bffRealtime.getPermissionGroups(
          {},
          bffRealtime.tokenRequest('controlPanel', 'getGroups'),
        );
        this.groups = data;
      } catch (error) {
        if (error && error.response && error.response.status === 405) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle: 'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    clearActiveGroup() {
      this.activeGroup = '';
      this.message = '';
    },
    isCurrentGroup(uuid) {
      return this.activeGroup === uuid;
    },
    updateGroup(data) {
      const index = this.groups.findIndex(({ uuid }) => uuid === data.uuid);
      if (index > -1) {
        const { active } = this.groups[index];
        this.groups.splice(index, 0); // This is needed to update the variable by Vue
        this.groups[index] = data;
      } else this.groups.push(data);
    },
    nextGroup() {
      let nextId = this.groupsList.findIndex(({ uuid }) => uuid === this.activeGroup) + 1;
      if (nextId === this.groupsList.length) {
        this.$refs.pagination.nextPage();
        nextId = 0;
      }

      const group = this.groupsList[nextId];
      this.activeGroup = group.uuid;

      return group;
    },
    prevGroup() {
      let prevId = this.groupsList.findIndex(({ uuid }) => uuid === this.activeGroup) - 1;
      if (prevId === -1) {
        this.$refs.pagination.prevPage();
        prevId = this.groupsList.length - 1;
      }

      const group = this.groupsList[prevId];
      this.activeGroup = group.uuid;

      return group;
    },
    async toggelStatus({ uuid, active }) {
      this.isLoading = true;
      try {
        const status = !active;
        await bffRealtime.updateGroupState(
          uuid,
          status,
          bffRealtime.tokenRequest('controlPanel', 'toggelStatus'),
        );

        const group = this.groups.find(grp => grp.uuid === uuid);
        if (group) group.active = status;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    openGroupRules(group, editMode) {

      this.activeGroup = group.uuid;
      this.$modal.show('sidebar', {
        componentName: editMode ? 'CreateRule' : 'GroupRules',
        title: editMode ? 'Editar Regra' : 'Usuários',
        icon: editMode ? 'security' : 'person',
        onClose: this.clearActiveGroup,
        nextGroup: this.nextGroup,
        prevGroup: this.prevGroup,
        updateGroup: this.updateGroup,
        group,
      });
    },

    async removeRule(uuid) {
      this.showLoading();

      try {
        await bffRealtime.removeRule(
          uuid,
          bffRealtime.tokenRequest('controlPanel', 'removeRule'),
        );

     this.groups = this.groups.filter(grp => grp.uuid !== uuid);
     this.reloadGroups();

      } catch (error) {
        throw error;
      } finally {
        this.hideLoading();
      }
    },
     showLoading() {
      this.isLoading = true;
    },
     hideLoading() {
      this.isLoading = false;
    },
    reloadGroups() {
      this.getGroups({
        uuid: this.uuid,
        active: this.active,
      });
      },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.table_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  /*== start of code for tooltips ==*/
  .tool {
      position: relative;
      z-index: 9999;

      &::before,
      &::after {
          left: 50%;
          opacity: 0;
          position: absolute;
          z-index: -100;
      }

      &:hover::before,
      &:focus::before,
      &:hover::after,
      &:focus::after {
          animation-delay: 0.4s !important;
          animation: fadeInDown 0.4s ease-in-out forwards;
          z-index: 100;
      }

      // Balão
      &::after {
        display: block;
        content: attr(data-tip);
        background: rgba($color-gray-lighter, 0.88);
        bottom: 120%;
        padding: 8px;
        box-sizing: border-box;
        margin-left: -54px;
        width: 108px;
        font-family: $secondary-typo;
        font-size: 0.48em;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-secondary;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
        opacity: 0;
        pointer-events: none;
        animation: fadeOutUp 0.4s ease-in-out forwards;
    }

    &:hover::after,
    &:focus::after  {
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .loading {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-gray-lighter, 0.8);

    &.in {
      animation: fadeIn 0.4s ease-in-out forwards;
    }

    &.out {
      animation: fadeOut 0.4s ease-in-out forwards;
    }

    .loader {
      width: 24px;
      height: 24px;

      .spinner {
        width: 100%;
        height: 100%;
        animation: rotator $duration linear infinite;
      }

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

  .table {
    overflow: hidden;
    border-radius: 4px;
    border-spacing: 0;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba($color-gray-darker, 0.04);

    tr {
      display: flex;
      height: 45px;
      position: relative;

      .actions {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        // Gradient
        background: rgba(255,255,255,0.24);
        background: -moz-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0.24)), color-stop(48%, rgba(255,255,255,0.81)), color-stop(57%, rgba(255,255,255,0.92)));
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: -o-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: -ms-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: linear-gradient(to right, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );

        border-bottom: 2px solid $color-secondary;
        display: block;
        justify-content: flex-end;
        align-items: center;
        padding: 0 48px 0 24px;
        animation: fadeOut 0.24s ease-in forwards;

        &:hover {
          animation: fadeIn 0.4s ease-in forwards;
          cursor: pointer;
        }

        span {
          flex: 1;
        }

        .icones {
          position: absolute;
          top: 12px;
          right: 48px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin: 0;
          animation: fadeInRight 0.4s ease-in-out forwards;

          i {
            color: $color-gray-main;
            font-size: 20px;
            margin-right: 16px;
            cursor: pointer;
            transition: color 0.4s ease-in-out;

            &:hover {
              color: $color-secondary;
            }
          }

        }

        &.active {
          display: flex;
        }
      }

      &:hover {
        .actions {
          display: flex;
        }
      }

      th, td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-gray-dark;
        padding: 0 24px;
        text-align: center;

        &.rule {
          flex-grow: 1;
          width: 0;
        }
        &.date { width: 250px; }
        &.market { width: 30%; }
        &.status { width: 100px; }
        &:last-child {
          width: 100px;
          justify-content: flex-end;
        }
      }
    }

    thead {
      box-shadow: 0 2px 24px rgba($color-gray-darker, 0.08);

      tr {
        background-color: $color-gray-lighter;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;

      }
    }
    tbody {
      tr {
        background-color: $color-gray-lighter;
        font-size: 14px;
        position: relative;

        td {
          &.date {
            /deep/ span {
              padding: 4px;
              text-transform: uppercase;
              color: $color-secondary-darker;

              &.active {
                color: #31ba1f;
              }
            }
          }

          &.status {
            color: $color-secondary-darker;
            font-weight: 500;
            text-transform: uppercase;

            &.active {
              color: #31ba1f;
            }
          }
        }

        &:nth-child(odd) {
          background: $color-table-odd;
        }

        &:nth-child(even) {
          background: $color-table-even;
        }
      }
    }
  }

  & /deep/ .paginate {
    list-style: none;
    justify-content: center;
    display: flex;
    margin: 0 0 24px 0;

    li {
      display: inline-block;
      padding: 8px;

      a {
        display: block;
        line-height: 46px;
        min-width: 46px;
        border-radius: 4px;
        padding: 0 16px;
        text-align: center;
        color: $color-gray-main;
        border: solid 1px $color-gray-lighter;
        font-size: 14px;
        transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out, color 0.56s ease-in-out;

        &:hover {
          background-color: $color-gray-lighter;
        }
      }

      &.active {
        a {
          font-weight: 500;
          color: $color-gray-lighter;
          border: solid 1px $color-secondary-darker;
          background-color: $color-secondary-darker;

          &:hover {
            background-color: $color-secondary;
          }
        }
      }

      &.disabled {
        a {
          opacity: 0.4;
          background-color: $color-gray-lighter;
          border: solid 1px $color-gray-lighter;
          pointer-events: none;
        }
      }
    }
  }
}

</style>
