<template>
  <ErrorState :insides="['badrequest_item', 'badrequest_back']"
              subtitle="Tivemos um problema no servidor.
              Por favor, tente novamente em alguns instantes :)"
  />
</template>

<script>
import ErrorState from './ErrorState.vue';

export default {
  components: {
    ErrorState,
  },
};
</script>
