<template>
  <div class="timebar" :class="{ hidden: isMobileView && !system.isMobileNavigation }">
    <div v-if="lastProgramOnAir" class="label_now">
      <i class="material-icons">
        fiber_manual_record
      </i>
      No Ar
    </div>
    <div v-else class="label_now">
      Último Programa
    </div>

    <div v-if="globoSchedule.lastProgram.program" class="programacao">
      <i class="icone material-icons">
        tv
      </i>
      <span class="nome">({{ globoSchedule.lastProgram.tvNetworkId }}) {{ globoSchedule.lastProgram.program }} </span>
      <span class="horario">- {{ lastProgramStartsIn }}</span>
    </div>
    <div v-else class="programacao">
      -
    </div>

    <div class="atualizacao">
      <i class="icone material-icons">
        update
      </i>
      <span class="nome">
        Última atualização -
      </span>
      <span class="horario">
        {{ lastTimeUpdated.format('HH:mm') }}
      </span>
    </div>

    <div class="data">
      {{ timeNow.format('dddd') }},
      <span class="dia-semana">{{ timeNow.format('D') }}</span> de
      <span class="dia-mes">{{ timeNow.format('MMMM') }}</span> de
      <span class="dia-ano">{{ timeNow.year() }}</span>
    </div>

    <div class="relogio">
      <span class="horas">
        {{ timeNow.format('HH') }}
      </span>
      <span class="minutos">
        {{ timeNow.format('mm') }}
      </span>
      <span class="segundos">
        {{ timeNow.format('ss') }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      timeNow: moment().locale('pt-BR'),
    };
  },
  computed: {
    ...mapState(['system', 'settings', 'globoSchedule']),
    ...mapGetters(['isMobileView', 'tvNetworks', 'lastTimeUpdated']),
    lastProgramStartsIn() {
      return moment(this.globoSchedule.lastProgram.startsIn).format('HH:mm');
    },
    lastProgramOnAir() {
      return moment().isBefore(this.globoSchedule.lastProgram.endsIn);
    },
  },
  created() {
    setInterval(() => {
      this.timeNow = moment().locale('pt-BR');
    }, 1000);
  },
};
</script>

<style lang="scss" scopped>

@import '@/assets/scss/variables.scss';

// TIMEBAR
.timebar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 0;
  display: flex;
  flex-basis: 100%;
  flex-wrap: wrap;
  width: 100%;
  height: 48px;
  border-radius: 0;
  background-color: $color-gray-dark;
  opacity: 0;
  animation-delay: $delay-time !important;
  animation: fadeInUp 1.6s ease-in-out forwards;
  transition: transform 0.6s ease-in-out;

  &.hidden {
    transform: translateY(-48px);
  }

  // LABEL NOW
  .label_now {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 124px;
    height: 48px;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: $color-gray-darker;
    border-radius: 4px 0 0 4px;
    font-family: $primary-typo;
    font-size: 0.72em;
    color: $color-primary-light;
    text-transform: uppercase;

    &::after {
      position: absolute;
      top: 0;
      right: -24px;
      content: "";
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 24px solid transparent;
      border-right: 24px solid transparent;
      border-top: 48px solid $color-gray-dark;
    }

    i {
      font-size: 1.4em;
      margin-right: 8px;
      animation: flash 2.4s infinite;
    }
  }

  // PROGRAMACAO + UPDATE + DATA
  .programacao,
  .atualizacao,
  .data {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    width: auto;
    margin: 0 24px;

    font-family: $secondary-typo;
    font-size: 0.72em;
    font-weight: 400;
    font-style: italic;
    color: $color-gray-lighter;

    .nome {
      font-family: $secondary-typo;
      font-size: 1em;
      font-weight: 400;
      font-style: italic;
      color: $color-gray-lighter;
    }

    .horario {
      font-weight: 600;
      font-style: normal;
      padding: 0 4px;
      text-transform: capitalize;
      white-space: nowrap;
    }

    i {
      font-size: 1.4em;
      color: $color-gray-main;
      margin-right: 8px;
    }

    &.data {
      font-size: 0.8em;
      margin-left: auto;

      .dia-semana,
      .dia-mes,
      .dia-ano {
        font-weight: 600;
        margin: 0 4px;
      }
    }
  }

  .atualizacao, .relogio, data {
    margin: auto 16px auto 0;
    flex-shrink: 0;
  }

  // RELÓGIO
  .relogio {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    margin-left: 0;

    font-family: $secondary-typo;
    font-size: 1.2em;
    font-weight: 600;
    color: $color-primary-light;

    &::before {
      content: "//";
      display: inline-block;
      margin-right: 8px;
      opacity: 0.4;
    }

    .horas,
    .minutos,
    .segundos {
      position: relative;
      width: 32px;
      padding-right: 4px;
      margin: 0;
      text-align: center;

      &.horas,
      &.minutos {
        &::before {
          content: ":";
          position: absolute;
          top: -1px;
          right: -1px;
          opacity: 0.48;
        }
      }
    }
  }
}

// ------------------------------------
// Breakpoints
// ------------------------------------

      // First Break : 1140px
      @media (max-width: 1140px) {
        .timebar {
          .data {
            display: none;
          }

          .programacao {
            flex: 1;
          }

        }
      }

      @media (max-width: 720px) {
        .data,
        .relogio {
          display: none !important;
        }
      }

      // SMARTPHONES
      @media (max-width: 600px) {
        // TIMEBAR
        .timebar {
          position: fixed;
          z-index: 1;
          top: 0;
          left: 0;
          width: 100%;
          padding-top: env(safe-area-inset-top, 0);
          height: calc(env(safe-area-inset-top, 0) + 40px);
          border-radius: 0;
          background-color: $color-gray-darker;
          box-shadow: 0 4px 16px rgba($color-gray-darker, 0.4);
          overflow: hidden;
          opacity: 1;
          animation: none;
          justify-content: space-between;
          flex-wrap: nowrap;

          // LABEL NOW
          .label_now {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 30%;
            flex-shrink: 0;
            height: 100%;
            box-sizing: border-box;
            padding: 0 16px 0 16px;
            background-color: $color-gray-darker;
            border-radius: 4px 0 0 4px;
            font-family: $primary-typo;
            font-size: 0.64em;
            color: $color-primary-light;
            text-transform: uppercase;
            overflow: hidden;

            &::after {
              display: none;
            }

            i {
              font-size: 1.4em;
              margin-right: 8px;
              animation: flash 2.4s infinite;
            }
          }

          // PROGRAMACAO + UPDATE + DATA
          .programacao {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: center;
            padding: 0 16px 0 0;
            font-size: 0.72em;
            width: 70%;
            margin: 0;

            .nome {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              text-transform: uppercase;
              text-align: right;
              margin: 0 0 0 auto;
            }

            .horario {
              margin: 0 0 0 4px;
              padding: 0;
            }

            i {
              display: none;
            }
          }

          // RELÓGIO
          .data,
          .atualizacao,
          .relogio {
            display: none;
          }
        }
      }


</style>
