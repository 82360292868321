<template>
  <div class="select_dropdown" :class="{ disabled }">
    <div v-if="optionsIsOpen" class="resetDate" @click="resetDate" />
    <div class="dropdown-menu ripple" @click="openOptions()">
      {{ getWeekDay(daySelected, 'dddd, L') }}
    </div>

    <div v-if="optionsIsOpen" class="options">
      <h1>Selecione o dia</h1>

      <div v-for="d in [0, 1, 2, 3, 4, 5, 6]" :key="d"
           class="days" :class="{'selected': getDay(d, 'e') === daySelected}"
           @click="changeDay(getDay(d, 'e'))"
      >
        {{ getDay(d, 'dddd') }}
        <span>[{{ getDay(d, 'L') }}]</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import moment from 'moment';

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOptions: false,
      optionsIsOpen: false,
      day: 0,
    };
  },
  computed: {
    ...mapState(['settings']),
    daySelected() {
      const { day } = this.$route.query;
      return day || moment().format('e');
    },
  },
  methods: {
    getDay(day, format) {
      return moment().add(day, 'days').format(format);
    },
    getWeekDay(weekDay, format) {
      const todayWeekDay = moment().format('e');
      const day = weekDay < todayWeekDay ? Number(weekDay) + 7 : weekDay;
      return moment().day(day).format(format);
    },
    openOptions() {
      this.optionsIsOpen = true;
    },
    closeOptions() {
      this.optionsIsOpen = false;
    },
    changeDay(day) {
      const query = { ...this.$route.query, day };
      if (day === moment().format('e')) delete query.day;
      this.$router.push({ query });
      this.closeOptions();
    },
    resetDate() {
      this.closeOptions();
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

  .resetDate {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
  }

  // Select
    .select_dropdown {
      position: relative;
      height: 40px;
      box-shadow: 0 4px 24px rgba($color-gray-darker, 0.08);

      // DISABLED
      &.disabled {
        pointer-events: none;
        background-color: #CCCCCC;
        opacity: 0.2;
      }
      // ---------------------------

      .dropdown-menu {
        display: flex;
        flex-wrap: wrap;
        align-content: center;
        height: 100%;
        padding: 12px 16px;
        width: 216px;
        border-radius: 4px;

        font-family: $primary-typo;
        font-weight: 500;
        font-size: 0.64em;
        color: $color-gray-dark;
        cursor: pointer;
        background-position: center;
        background: $color-gray-lighter;
        transition: background 0.4s ease-in-out, color 0.4s ease-in-out;

        &:hover {
          background: $color-gray-lighter radial-gradient(circle, transparent 1%, $color-gray-lighter 1%) center/15000%;
          color: $color-secondary;
        }

        &:active {
          background-color: rgba($color-gray-light, 0.48);
          background-size: 100%;
          transition: background 0s;
        }

        i {
          font-size: 1.8em;
          margin: -2px 0 0 8px;
          opacity: 0.4;
        }
      }
      // Fim do Dropdown-Menu

      .options {
        position: absolute;
        top: 48px;
        left: 0;
        z-index: 6;
        width: 324px;
        box-sizing: border-box;
        background-color: $color-gray-lighter;
        border-radius: 4px;
        box-shadow: 0 24px 80px rgba($color-gray-darker, 0.4);
        animation: fadeIn 0.4s ease-in-out forwards;

        // Títulos
        h1 {
          width: 100%;
          box-sizing: border-box;
          padding: 16px 16px 0 16px;
          font-family: $secondary-typo;
          font-weight: 500;
          font-size: 0.64em;
          font-style: italic;
          color: $color-gray-darker;
          text-transform: uppercase;

          &::after {
            content: "";
            width: 16px;
            height: 2px;
            display: block;
            margin: 4px 0 0 0;
            background: rgba($color-gray-light, 0.64);
          }
        }

        .days {
          padding: 16px;
          display: flex;
          justify-content: space-between;
          font-family: $secondary-typo;
          font-weight: 400;
          font-size: 0.8em;
          color: $color-gray-main;
          border-bottom: solid 2px $color-gray-back;
          background-position: center;
          transition: background 0.4s ease-in-out, border-bottom 0.4s ease-in-out, color 0.4s ease-in-out;
          cursor: pointer;

          &:hover {
            background: $color-gray-back radial-gradient(circle, transparent 1%, $color-gray-back 1%) center/15000%;
            border-bottom: solid 2px $color-gray-light;
            color: $color-gray-dark;
          }

          &:active {
            background-color: rgba($color-gray-light, 0.48);
            background-size: 100%;
            transition: background 0s;
          }

          &.selected {
            font-weight: 600;
            color: $color-secondary;
          }
        }

        .time {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 16px;

          .item {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 48%;

            i {
              position: absolute;
              font-size: 1em;
              color: $color-gray-main;
              left: 8px;
            }

            input {
              width: 100%;
              background: $color-gray-lighter;
              box-shadow: 0 2px 16px rgba($color-gray-darker, 0.16);
              border: none;
              border-radius: 4px;
              padding: 12px 8px 12px 32px;
              font-family: $secondary-typo;
              font-size: 0.88em;
            }

          }
        }

        .button {
          position: relative;
          width: 100%;

          .btn {
            border-radius: 0 0 4px 4px;
          }

          // Feedback
          .feedback {
            display: flex;
            align-items: center;
            border-radius: 0 0 4px 4px;
            text-align: center;
            font-size: 0.8em;
            height: 56px;
            padding: 0 24px;
          }
        }
      }
      // Fim do options

      &:after {
        content: " ";
        position: absolute;
        top: 50%;
        margin-top: -2px;
        right: 16px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid $color-gray-main;
        pointer-events: none;
      }
    }
    // Fim do Select --------------

    // Second Break : 850px
    @media (max-width: 850px) {

      .header {
        .box {
          .navigation {

              // Select
              .select_dropdown {

                .dropdown-menu {
                  padding: 14px 8px 12px 8px;
                  width: 156px;
                  font-size: 0.56em;

                  i {
                    font-size: 1.6em;
                    margin: -1px 0 0 8px;
                  }
                }
                // Fim do Dropdown-Menu

                .options {
                  left: inherit;
                  right: 0;
                }
                // Fim do options
              }
              // Fim do Select --------------
          }
        }
      }
    }

</style>
