<template>
  <div ref="content" class="table_container">
    <transition name="fade">
      <div v-if="isLoading" class="loading">
        <div class="loader">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6"
                    stroke-linecap="round" cx="33" cy="33" r="30"
            />
          </svg>
        </div>
      </div>
    </transition>
    <template v-if="users">
      <ErrorState v-if="usersList.length === 0" :insides="['globo_item']"
                  title="Xiiiiiiiiiiiiii"
                  subtitle="Não encontramos nenhum usuário"
                  :buttons="[{
                    text: 'QUERO VOLTAR',
                    click: () => {
                      this.$router.push({ name: 'ControlPanel' });
                    },
                  }]"
                  :style-container="{
                    backgroundColor: 'transparent',
                  }"
                  :fullscreen="false"
      />
      <template v-else>
        <table class="table">
          <thead>
            <tr>
              <th class="email">
                Email
              </th>
              <th class="name">
                Nome
              </th>
              <th class="date">
                Data de Inclusão
              </th>
              <th class="date">
                Data de Alteração
              </th>
              <th class="rules">
                Regras
              </th>
              <th class="status">
                Status
              </th>
              <th>Ações</th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="user in usersList" :key="user.email">
              <div class="actions" :class="{active: isCurrentUser(user.email)}">
                <!-- <span>{{ user.email }}</span> -->

                <div class="icones">
                  <transition name="fade">
                    <i v-if="!user.active" class="icone material-icons tool" data-tip="Deletar Usuário" @click="removeUser(user.email)">
                      delete
                    </i>
                  </transition>
                  <i v-if="user.email !== currentUser.email"
                    class="icone material-icons tool" data-tip="Mudar Status"
                    @click="toggelStatus(user)"
                  >
                    archive
                  </i>
                  <i class="icone material-icons tool" data-tip="Associar Grupo" @click="openUserRules(user)">
                    security
                  </i>
                  <i class="icone material-icons tool" data-tip="Simular Usuário" @click="simulateUser(user)">
                    people
                  </i>
                </div>
              </div>
              <td class="email">
                {{ user.email }}
              </td>
              <td class="name">
                {{ user.name || '--' }}
              </td>
              <td class="date">
                {{ getDate(user.createdAt) }}
              </td>
              <td class="date">
                {{ getDate(user.updatedAt) }}
              </td>
              <td class="rules">
                {{ user.total || 0 }}
              </td>
              <td class="status" :class="{ active: user.active }">
                {{ user.active ? 'Ativo' : 'Inativo' }}
              </td>
              <td>
                <i class="icone material-icons">more_horiz</i>
              </td>
            </tr>
          </tbody>
        </table>
        <paginate ref="pagination"
                  :page-count="pages"
                  :click-handler="pageHandler"
                  first-button-text="Primeiro"
                  last-button-text="Último"
                  :first-last-button="true"
                  prev-text="Anterior"
                  next-text="Próximo"
                  container-class="paginate"
        />
      </template>
    </template>
    <!-- fim do conteudo com tabela + pagination -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';
import Paginate from 'vuejs-paginate';
import ErrorState from '@/components/system/ErrorState.vue';

export default {
  components: {
    Paginate,
    ErrorState,
  },
  data() {
    return {
      users: null,
      pageNumber: 1,
      perPage: 15,
      activeUser: '',
      isLoading: true,
    };
  },
  computed: {
    ...mapState(['settings', 'system']),
    ...mapGetters(['currentUser']),
    pages() {
      return Math.ceil(this.usersFilteredBySearch.length / this.perPage);
    },
    usersFilteredBySearch() {
      const { search } = this.$route.query;
      if (!search) return this.users;
      return this.users.filter(({ name, email }) => (
        this.isTextMatch(search, name) || this.isTextMatch(search, email)
      ));
    },
    usersList() {
      const begin = (this.pageNumber - 1) * this.perPage;
      const end = begin + this.perPage;
      return this.usersFilteredBySearch.slice(begin, end);
    },
  },
  watch: {
    '$route.query': {
      handler() {
        // Back to page 0
        this.goToFirstPage();
      },
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.setPerPage();
    this.getUsers();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    isTextMatch(search, text) {
      const string = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(string, 'ig');
      return text && regex.test(text.trim());
    },
    setPerPage() {
      const columnHeight = 48; //  This number should be the same height has been defined in css
      const paginationHeight = 64; // This number should be the same height has been defined in css
      const contentHeight = this.$refs.content.clientHeight;

      this.perPage = Math.floor((contentHeight - paginationHeight - 32) / columnHeight);
      this.goToFirstPage();
    },
    handleResize() {
      this.$modal.hide('sidebar');
      this.setPerPage();
    },
    goToFirstPage() {
      if (!this.$refs.pagination) return;
      this.$refs.pagination.selectFirstPage();
    },
    pageHandler(page) {
      this.pageNumber = page;
    },
    getDate(unixtime) {
      return unixtime ? moment.unix(unixtime).format('DD/MM/YYYY HH:mm') : '--';
    },
    async getUsers() {
      this.isLoading = true;
      try {
        const { data } = await bffRealtime.getPermissionUsers(
          bffRealtime.tokenRequest('controlPanel', 'getUsers'),
        );
        this.users = data;
      } catch (error) {
        if (error && error.response && error.response.status === 405) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle: 'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    clearActiveUser() {
      this.activeUser = '';
    },
    isCurrentUser(email) {
      return this.activeUser === email;
    },
    updateUser(data) {
      const user = this.users.find(({ email }) => email === data.email);
      if (user) user.total = data.total;
      else this.users.push(data);
    },
    nextUser() {
      let nextId = this.usersList.findIndex(({ email }) => email === this.activeUser) + 1;
      if (nextId === this.usersList.length) {
        this.$refs.pagination.nextPage();
        nextId = 0;
      }

      const {
        name, email, total, active,
      } = this.usersList[nextId];
      this.activeUser = email;

      return {
        name: name || '', // BFF don't return name always
        email,
        total,
        active: active || false,
      };
    },
    prevUser() {
      let prevId = this.usersList.findIndex(({ email }) => email === this.activeUser) - 1;
      if (prevId === -1) {
        this.$refs.pagination.prevPage();
        prevId = this.usersList.length - 1;
      }

      const {
        name, email, total, active,
      } = this.usersList[prevId];
      this.activeUser = email;

      return {
        name: name || '', // BFF don't return name always
        email,
        total,
        active: active || false,
      };
    },
    async removeUser(email) {
      this.showLoading();
      try {
        await bffRealtime.removeUser(
          email,
          bffRealtime.tokenRequest('controlPanel', 'removeUser'),
        );

        this.users = this.users.filter(usr => usr.email !== email);
      } catch (error) {
        throw error;
      } finally {
        this.hideLoading();
      }
    },
    async toggelStatus({ email, active }) {
      this.showLoading();
      try {
        const status = !active;
        await bffRealtime.updateUserState(
          email,
          status,
          bffRealtime.tokenRequest('controlPanel', 'toggelStatus'),
        );

        const user = this.users.find(usr => usr.email === email);
        if (user) user.active = status;
      } catch (error) {
        throw error;
      } finally {
        this.hideLoading();
      }
    },
    openUserRules({
      name, email, total, active,
    }) {
      this.activeUser = email;
      this.$modal.show('sidebar', {
        componentName: 'UserRules',
        title: 'Regras',
        icon: 'security',
        onClose: this.clearActiveUser,
        updateUser: this.updateUser,
        nextUser: this.nextUser,
        prevUser: this.prevUser,
        user: {
          name: name || '', // BFF don't return name always
          email,
          total,
          active: active || false,
        },
      });
    },
    async simulateUser(user) {
      this.showLoading();
      try {
        await this.$store.dispatch('simulateUser', user);
        this.$router.push({ name: 'Home' });
        this.$router.go();
      } catch (error) {
        throw error;
      } finally {
        this.hideLoading();
      }
    },
    showLoading() {
      this.isLoading = true;
    },
    hideLoading() {
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.table_container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  /*== start of code for tooltips ==*/
  .tool {
      position: relative;
      z-index: 9999;

      &::before,
      &::after {
          left: 50%;
          opacity: 0;
          position: absolute;
          z-index: -100;
      }

      &:hover::before,
      &:focus::before,
      &:hover::after,
      &:focus::after {
          animation-delay: 0.4s !important;
          animation: fadeInDown 0.4s ease-in-out forwards;
          z-index: 100;
      }

      // Balão
      &::after {
        display: block;
        content: attr(data-tip);
        background: rgba($color-gray-lighter, 0.88);
        bottom: 120%;
        padding: 8px;
        box-sizing: border-box;
        margin-left: -54px;
        width: 108px;
        font-family: $secondary-typo;
        font-size: 0.48em;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.02em;
        color: $color-secondary;
        border-radius: 4px;
        box-shadow: 0 2px 8px rgba($color-gray-darker, 0.08);
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26) .2s;
        opacity: 0;
        pointer-events: none;
        animation: fadeOutUp 0.4s ease-in-out forwards;
    }

    &:hover::after,
    &:focus::after  {
        transition: all .65s cubic-bezier(.84,-0.18,.31,1.26);
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .loading {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color-gray-lighter, 0.8);

    &.in {
      animation: fadeIn 0.4s ease-in-out forwards;
    }

    &.out {
      animation: fadeOut 0.4s ease-in-out forwards;
    }

    .loader {
      width: 24px;
      height: 24px;

      .spinner {
        width: 100%;
        height: 100%;
        animation: rotator $duration linear infinite;
      }

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

  .table {
    overflow: hidden;
    border-radius: 4px;
    border-spacing: 0;
    width: 100%;
    box-shadow: 0 2px 16px 0 rgba($color-gray-darker, 0.04);

    tr {
      display: flex;
      height: 45px;
      position: relative;

      .actions {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        // Gradient
        background: rgba(255,255,255,0.24);
        background: -moz-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255,255,255,0.24)), color-stop(48%, rgba(255,255,255,0.81)), color-stop(57%, rgba(255,255,255,0.92)));
        background: -webkit-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: -o-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: -ms-linear-gradient(left, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        background: linear-gradient(to right, rgba(255,255,255,0.24) 0%, rgba(255,255,255,0.81) 48%, rgba(255,255,255,0.92) 57%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff', GradientType=1 );

        border-bottom: 2px solid $color-secondary;
        display: block;
        justify-content: flex-end;
        align-items: center;
        padding: 0 48px 0 24px;
        animation: fadeOut 0.24s ease-in forwards;

        &:hover {
          animation: fadeIn 0.4s ease-in forwards;
          cursor: pointer;
        }

        span {
          flex: 1;
        }

        .icones {
          position: absolute;
          top: 12px;
          right: 48px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          margin: 0;
          animation: fadeInRight 0.4s ease-in-out forwards;

          i {
            color: $color-gray-main;
            font-size: 20px;
            margin-right: 16px;
            cursor: pointer;
            transition: color 0.4s ease-in-out;

            &:hover {
              color: $color-secondary;
            }
          }

        }

        &.active {
          display: flex;
        }
      }

      &:hover {
        .actions {
          display: flex;
        }
      }

      th, td {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-gray-dark;
        padding: 0 24px;
        text-align: center;

        &.name { width: 20%; }
        &.email {
          flex-grow: 1;
          width: 0;
        }
        &.date { width: 15%; }
        &.rules { width: 10%; }
        &.status { width: 100px; }
        &:last-child {
          width: 100px;
          justify-content: flex-end;
        }
      }
    }

    thead {
      box-shadow: 0 2px 24px rgba($color-gray-darker, 0.08);

      tr {
        background-color: $color-gray-lighter;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;

      }
    }
    tbody {
      tr {
        background-color: $color-gray-lighter;
        font-size: 14px;
        position: relative;

        td {
          &.status {
            color: $color-inactive;
            font-weight: 500;
            text-transform: uppercase;

            &.active {
              color: $color-active;
            }
          }
        }

        &:nth-child(odd) {
          background: $color-table-odd;
        }

        &:nth-child(even) {
          background: $color-table-even;
        }
      }
    }
  }

  & /deep/ .paginate {
    list-style: none;
    justify-content: center;
    display: flex;
    margin: 0 0 16px 0;

    li {
      display: inline-block;
      padding: 8px;

      a {
        display: block;
        line-height: 46px;
        min-width: 46px;
        border-radius: 4px;
        padding: 0 16px;
        text-align: center;
        color: $color-gray-main;
        border: solid 1px $color-gray-lighter;
        font-size: 14px;
        transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out, color 0.56s ease-in-out;

        &:hover {
          background-color: $color-gray-lighter;
        }
      }

      &.active {
        a {
          font-weight: 500;
          color: $color-gray-lighter;
          border: solid 1px $color-secondary-darker;
          background-color: $color-secondary-darker;

          &:hover {
            background-color: $color-secondary;
          }
        }
      }

      &.disabled {
        a {
          opacity: 0.4;
          background-color: $color-gray-lighter;
          border: solid 1px $color-gray-lighter;
          pointer-events: none;
        }
      }
    }
  }
}

</style>
