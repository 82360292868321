<template>
  <Modal :name="name"
         height="auto"
         :max-height="740"
         :width="200"
         class="modal-chart"
         :resizable="true"
         :click-to-close="false"
         @before-open="beforeOpen"
         @before-close="beforeClose"
  >
    <div class="content">
      <div class="head">
        <h1>
          Exportação de CSV
        </h1>
      </div>
      <!-- fim do topo -->

      <div class="middle">
        <VueCircle
          ref="progress"
          :progress="0"
          :size="100"
          :reverse="false"
          line-cap="round"
          empty-fill="rgba(0, 0, 0, .1)"
          :animation-start-value="0.0"
          :start-angle="0"
          insert-mode="append"
          :thickness="10"
          :show-percent="true"
        />
      </div>

      <div class="footer">
        <button type="button"
                class="btn btn_secondary btn_full ripple"
                @click="closeModal"
        >
          Cancelar
        </button>
      </div>
    </div>
    <!-- // -->
  </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VueCircle from 'vue2-circle-progress';
import transformMixin from '@/utils/transformMixin';
import _ from 'lodash';
import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';

export default {
  components: {
    VueCircle,
  },
  mixins: [transformMixin],
  data() {
    return {
      name: 'export-groups',
      marketMapper: {
        SP1: 'SP',
        DFE: 'DF',
        RPP: 'TVP',
        RPT: 'TVT',
      },
      headKeys: [
        'GrupoId',
        'Regra',
        'Praças',
        'Emissoras',
        'Faixa',
        'Dias',
        'Ativo',
        'Amostra',
        'Simucast',
        'TL',
        'TLE',
        'Gerente',
        'Cadastro de Programa',
      ],
      dataKeys: [
        'uuid',
        'name',
        'marketsId',
        'tvNetworksId',
        'times',
        'weekDays',
        'active',
        'hasSampling',
        'hasSimuCast',
        'hasTL',
        'hasTLE',
        'isPermissionManager',
        'isProgramRegister',
      ],
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['markets', 'tvNetworks', 'globoDate']),
  },
  methods: {
    async beforeOpen() {
      await this.$store.dispatch('hideMobileNavigation');
      await this.downloadCSV();
      this.closeModal();
    },
    beforeClose() {
      this.$store.dispatch('showMobileNavigation');
    },
    closeModal() {
      setTimeout(() => {
        this.$modal.hide(this.name);
      }, 800);
    },

    generateHead() {
      return `${this.headKeys.join(',')}\r\n`;
    },
    mapGroups(groups) {
      return groups.map((value) => {
        value.marketsId = value.marketsId.map(k => this.marketMapper[k] || k);
        return value;
      });
    },
    async exportCSV() {
      const groups = await bffRealtime.getPermissionGroups(
        bffRealtime.tokenRequest('export', 'getGroups'),
      ).then(({ data }) => this.mapGroups(data));

      this.$refs.progress.updateProgress(50);

      const body = _.reduce(groups, (result, value) => {
        result.push(_.chain(this.dataKeys).reduce((r, k) => {
          if (typeof value[k] === 'object') {
            r.push(`"${value[k].join(',')}"`);
            return r;
          }

          r.push(value[k]);
          return r;
        }, [])
          .join(',')
          .value());

        return result;
      }, []);

      this.$refs.progress.updateProgress(100);
      return `${this.generateHead()}${body.join('\r\n')}`;
    },
    async downloadCSV() {
      const data = await this.exportCSV();

      const blob = new Blob([data], { type: 'text/plain' });
      const event = document.createEvent('MouseEvents');

      const element = document.createElement('a');
      const date = moment(this.globoDate.startsIn)
        .format('DDMMYYYY')
        .toString();

      element.download = `Grupos_Realtime_${date}.csv`;

      element.href = window.URL.createObjectURL(blob);
      element.dataset.downloadurl = ['text/json', element.download, element.href].join(':');

      event.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
      element.dispatchEvent(event);
    },
  },
};
</script>

<style lang="scss" scoped>

  @import '@/assets/scss/variables.scss';

  .tv_network {
    background: red;

    img {
      width: 50px;
    }
  }

  // Modal
  .v--modal-overlay {

    background: none;
    width: 240px;
    height: 324px;
    top: auto;
    left: 5px;
    bottom: 25px;
    right: auto;
    opacity: 0.8;

    .v--modal {
      height: 100% !important;
    }


    &.overlay--show {
      animation: fadeIn 0.8s ease-in-out forwards;
    }

    &.overlay--hide {
      animation: fadeOut 0.8s ease-in-out forwards;
    }
  }

  .v--modal {
    border-radius: 8px 8px 0 0;
    box-shadow: 0 48px 80px rgba($color-gray-dark, 0.48);
    animation: fadeInUp 0.8s ease-in-out forwards;

    &.modal--hide {
      animation: fadeInUp 0.8s ease-in-out forwards;
    }

    // Content
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 100%;
      overflow: auto;

      .buttons {
        display: none;
      }

      // TOPO
      .head {
        position: sticky;
        position: -webkit-sticky;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0;
        text-align: center;
        background-color: $color-gray-lighter;
        box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

        h1 {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-family: $secondary-typo;
          font-size: 1em;
          font-weight: 400;
          color: $color-gray-dark;
          font-style: italic;
          text-align: left;
          margin: 0 0 auto 0;
          box-sizing: border-box;
          padding: 16px;
          background-color: rgba($color-gray-back, 0.8);

          span {
            font-weight: 600;
            color: $color-gray-dark;
          }
        }

        .tags {
          padding: 16px;
        }
        // -----
      }
      // -------------------------------------------------------

      // MEIO
      .middle {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      // -------------------------------------------------------

      .footer {
        position: sticky;
        position: -webkit-sticky;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
        padding: 0;
        box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);

        .btn {
          &.btn_secondary {
            border: none !important;
          }
        }

      }
    }

    // Link
    .link {
      width: 100%;
      cursor: pointer;
      border: none;
      background-color: transparent;
      margin: 0;

      &.link--secondary {
        font-family: $secondary-typo;
        font-weight: 500;
        font-size: 1em;
        font-style: italic;
        text-transform: inherit;
        letter-spacing: 0.08em;
        color: rgba($color-secondary, 1);
        box-sizing: border-box;
        padding: 4px;
        border: none;

        &:hover {
          color: rgba($color-secondary, 0.72);
        }
        // -------------------------------------------------------
      }
    }
  }
  // -------------------------------------------------------

  // SMARTPHONES
  @media (max-width: 600px) {

    .v--modal {

      // Content
      .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        overflow-y: scroll;

        // TOPO
        .head {
          position: fixed;
          // z-index: 2;
          // display: flex;
          // flex-wrap: wrap;
          // justify-content: space-around;
          // align-items: center;
          // width: 100%;
          // height: 240px;
          // text-align: center;
          // padding: 0;
          // background: $color-gray-lighter;
          // box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

          .programa {
            font-size: 0.8em;
          }

        }
        // -------------------------------------------------------

        .footer {
          position: fixed;
          bottom: 0;
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          width: 100%;
          padding: 0;
          box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);
          // -----------------

        }
      }
    }
  }

</style>
