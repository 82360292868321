<template>
  <component :is="tag">
    {{ isNaN(value) ? value : formatValue(animatedValue) }}
  </component>
</template>

<script>
import anime from 'animejs';

export default {
  props: {
    value: {
      type: [Number, String],
      default: '0',
    },
    formatValue: {
      type: Function,
      default: value => (value === 100 ? 100 : Number(value).toFixed(2)),
    },
    easing: {
      type: String,
      default: 'linear',
    },
    duration: {
      type: Number,
      default: 500,
    },
    update: Function,
    begin: Function,
    complete: Function,
    run: Function,
    delay: {
      type: Number,
      default: 0,
    },
    round: {
      default: null,
    },
    tag: {
      default: 'div',
    },
  },
  data() {
    return {
      msg: 'Use uma função, não um objeto diretamente',
      animatedValue: 0,
    };
  },
  watch: {
    value(value) {
      this.animateValue(Number(value));
    },
  },
  mounted() {
    this.animateValue(this.value);
  },
  methods: {
    animateValue(value) {
      const {
        begin,
        easing,
        duration,
        complete,
        update,
        run,
        delay,
        round,
      } = this;
      anime({
        targets: this,
        animatedValue: value,
        duration,
        easing,
        update,
        begin,
        complete,
        run,
        delay,
        round,
      });
    },
  },
};
</script>
