<template>
  <div class="main-container">
    <div v-for="item in schedulesGroup" ref="itemContainer" class="item-container">
      <div ref="item">
        {{ getFormatedHour(item.startsIn) }}
      </div>

      <div ref="tooltip" class="ui-tooltip">
        <p v-for="value in item.programs">
          {{ value.program }}
        </p>
      </div>

      <div class="item-right">
        {{ getFormatedHour(item.endsIn) }}
      </div>
    </div>
    <div ref="selectBox" :style="{width: `${selectBoxWidth}px`}" class="select-box" @mousedown="dragMouseDown" @mouseup="dragMouseUp" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';


export default {
  name: 'SprintTable',
  data() {
    return {
      selectBoxWidth: 0,
      datesGroupsPositions: [],
      isOnRealTime: true,
      positions: {
        clientX: undefined,
        movementX: 0,
      },
    };
  },
  computed: {
    ...mapGetters('hourNavigator', ['schedulesGroup']),
    ...mapState(['settings']),
  },
  watch: {
    settings: {
      deep: true,
      handler() {
        this.isOnRealTime = true;
        this.$store.dispatch('hourNavigator/getSchedulesGroup');
      },
    },
  },
  async beforeCreate() {
    await this.$store.dispatch('hourNavigator/getSchedulesGroup');
  },
  updated() {
    this.update(this.isOnRealTime);
  },
  mounted() {
    this.setupInterval();
  },
  beforeDestroy() {
    clearTimeout(this.getSchedulesGroupInterval);
  },
  methods: {
    setupInterval() {
      this.getSchedulesGroupInterval = setInterval(() => {
        this.$store.dispatch('hourNavigator/getSchedulesGroup');
      }, 120000);
    },
    update(updateCursorPosition = true) {
      this.datesGroupsPositions = this.$refs.itemContainer.map((value, idx) => ({ position: value.offsetLeft, idx }));
      this.selectBoxWidth = this.$refs.itemContainer[0].offsetWidth;

      if (updateCursorPosition) this.setSelectGroupByPosition(this.datesGroupsPositions.slice().pop().position);
    },
    getFormatedHour(date) {
      return moment(date).format('HH:mm');
    },
    setSelectGroupByPosition(position) {
      const closestGroup = this.getClosestGroup(position);
      this.isOnRealTime = this.schedulesGroup[closestGroup.idx].isOnView;

      this.$refs.selectBox.style.left = `${closestGroup.position}px`;
      this.$emit('group-selected', this.schedulesGroup[closestGroup.idx]);
    },
    dragMouseUp(event) {
      event.preventDefault();
      this.handleTooltipVisibility(null);
      this.setSelectGroupByPosition(this.$refs.selectBox.offsetLeft);

      document.onmouseup = null;
      document.onmousemove = null;
    },
    dragMouseDown(event) {
      event.preventDefault();
      this.positions.clientX = event.clientX;
      document.onmousemove = this.elementDrag;
      document.onmouseup = this.dragMouseUp;
    },
    handleTooltipVisibility(visiblePosition) {
      this.$refs.tooltip.map((value, idx) => (idx === visiblePosition ? value.style.visibility = 'visible' : value.style.visibility = 'hidden'));
    },
    elementDrag(event) {
      event.preventDefault();
      this.positions.movementX = this.positions.clientX - event.clientX;
      this.positions.clientX = event.clientX;

      const position = this.$refs.selectBox.offsetLeft - this.positions.movementX;
      this.$refs.selectBox.style.left = `${position}px`;

      const closestGroup = this.getClosestGroup(position);

      this.handleTooltipVisibility(closestGroup.idx);
    },
    getClosestGroup(currentPosition) {
      return this.datesGroupsPositions.reduce((prev, curr) => (
        Math.abs(curr.position - currentPosition) < Math.abs(prev.position - currentPosition) ? curr : prev
      ));
    },
  },
};
</script>

<style lang="scss" scoped>
  .select-box{
    position: absolute;
    height: 33px;
    cursor: ew-resize;
    background-color: rgba(102,133,194,0.3)
  }

  .ui-tooltip {
    margin-top: -40px;
    margin-bottom: 40px;
    visibility: hidden;
    display:inline-block;
    padding:.5em 1em;
    position:fixed;
    text-align:center;
    border-radius:4px ;
    background-color:#666666;
    color: white!important;
    font-family: Roboto;
    font-style: italic;
    font-weight: 500;
    font-size:10px!important;

    @media (max-height: 760px)
    {
      margin-top: -60px !important
    }
  }
  .ui-tooltip:after, {
    content:"\25B8";
    display:block;
    font-size:2em;
    height:0;
    line-height:0;
    position:absolute;
  }
  .ui-tooltip:after {
    color:#666666;
    bottom:0;
    left:1px;
    text-align:center;
    -o-transform:rotate(90deg);
    -moz-transform:rotate(90deg);
    -khtml-transform:rotate(90deg);
    -webkit-transform:rotate(90deg);
    width:100%;
  }


  .main-container{
    height: 33px;
    display: flex;
    border-top: 1px solid rgb(204, 204, 204);
  }

  .item-container{
    flex: 1;
    border-left: 1px solid rgb(204, 204, 204);
    border-right: 1px solid rgb(204, 204, 204);
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    width: 100%;
    height: 100%;
    margin-top: auto;
    justify-content: center;

    div{
        color:#999999;
        cursor:default;
        font-size:11px;
        fill:#999999;
        align-self: flex-end;
        padding-left: 5px;
        padding-right: 5px;
    }

    .item-right{
      margin-left: auto;
    }

    &:first-of-type{
      margin-left: 0!important;
    }
  }

</style>
