<template>
  <ErrorState :insides="['notfound_item', 'notfound_back']"
              subtitle="Essa tela ou funcionalidade não foi encontrada :/"
              :buttons="[{
                text: 'Voltar para home',
                click: () => goToPage('Home'),
              }]"
  />
</template>

<script>
import ErrorState from './ErrorState.vue';

export default {
  components: {
    ErrorState,
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
  },
};
</script>
