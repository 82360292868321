<template>
  <div :class="{mobile_tips: isTourOpen}">
    <!-- 01) Tip inicial: Usuário vai escolher se quer ou não iniciar o tour -->
    <Tips
      ref="tipStartTutorial"
      title="Bem-vindo"
      :contents="[
        'Deseja iniciar o tutorial? Em 3 minutos faremos um tour mostrando seu novo app. Se preferir fazer em outro momento, o tutorial estará disponível na tela de configurações, último botão da barra de navegação :)'
      ]"
      :is-filled="true"
      :has-cancel-btn="true"
      @on-cancel="finishTipsTour()"
      @on-open="goToPage('Home')"
      @on-confirm="openTip('tipTutorial')"
    />

    <Tips
      ref="tipTutorial"
      title="Tutorial"
      :contents="[
        'Vamos iniciar o tour pelo app! Utilize os botões ( <  > ) para retroceder e avançar pelas dicas. Durante o tutorial as telas estarão bloqueadas, impedindo a interação com os elementos da interface.',
      ]"
      :is-filled="true"
      :has-cancel-btn="true"
      cancel-icon="keyboard_arrow_left"
      confirm-icon="keyboard_arrow_right"
      @on-open="goToPage('Home')"
      @on-confirm="openTip('tipHome')"
      @on-cancel="openTip('tipStartTutorial')"
    />

    <Tips
      ref="tipHome"
      title="Tabelas"
      :contents="[
        'A tabela exibe os dados de audiência ou share em tempo real. Ao realizar gestos verticais, é possível visualizar mais minutos. Já com os gestos horizontais, visualizar mais emissoras.',
        'No topo do app é possível visualizar o programa que está no ar e sua média atualizada em tempo real. Caso o seu usuário tenha restrições de horário, será exibido o último programa dentro da regra.',
        'Ainda no topo, mas na esquerda, temos o botão de filtros. Vamos conhecer um pouco mais dessa funcionalidade.'
      ]"
      :is-filled="true"
      :has-cancel-btn="false"
      cancel-icon="keyboard_arrow_left"
      confirm-icon="keyboard_arrow_right"
      @on-open="goToPage('Home')"
      @on-confirm="openTip('tipFilter')"
    />

    <Tips
      ref="tipFilter"
      title="Filtros"
      :contents="[
        'Na tela de filtros é possível alternar entre as visões de minutos e programas, além da navegação por praças e emissoras.',
        'Nessa tela também é possível aplicar filtros de dias e horários, além de definir suas preferências para dados e indicadores que serão exibidos na aplicação.',
        'Já passamos pela tela de tabelas e como configurar suas visões. Vamos agora conhecer um pouco mais do Dashboard de Programas.'
      ]"
      confirm-icon="keyboard_arrow_right"
      cancel-icon="keyboard_arrow_left"
      :is-filled="true"
      :has-cancel-btn="false"
      @on-open="openUserPreferences()"
      @on-close="closeUserPreferences()"
      @on-confirm="openTip('tipDashboards')"
    />

    <Tips
      ref="tipDashboards"
      title="Dashboard"
      :contents="[
        'O Dashboard de Programas oferece uma alternativa visual para consumir os dados de audiência. Acompanhe o desempenho dos programas por praça no topo do app. Ao tocar no botão com a seta para baixo, o conteúdo será ampliado na tela, oferecendo uma visão de todas as praças.',
        'Visualize em tempo real o desempenho dos programas através da esteira. É possível voltar para os programas anteriores e ter acesso às informações de entrega e média.',
        'Utilize a navegação de cards na parte de baixo da tela para comparar o desempenho dos programas com a programação das emissoras concorrentes.',
        'Já passamos pelas tabelas e dashboard, vamos agora para o último passo do tutorial: a tela de configurações.'
      ]"
      confirm-icon="keyboard_arrow_right"
      cancel-icon="keyboard_arrow_left"
      :is-filled="true"
      :has-cancel-btn="false"
      @on-open="goToPage('Dashboards')"
      @on-confirm="openTip('tipSettings')"
    />

    <!-- 03.2) Tips do settings (audiencia / share) -->
    <Tips
      ref="tipSettings"
      title="Dicas"
      :contents="[
        'Na tela de configurações é possível ativar ou desativar as praças e emissoras que estiverem disponíveis para o seu perfil.'
      ]"
      confirm-icon="keyboard_arrow_right"
      cancel-icon="keyboard_arrow_left"
      :is-filled="true"
      :has-cancel-btn="false"
      @on-open="goToPage('Settings')"
      @on-confirm="openTip('tipMarket')"
    />

    <!-- 03.2) Tips do settings (audiencia / share) -->
    <Tips
      ref="tipMarket"
      title="Dicas"
      :contents="[
        'Utilize o botão de switch para ligar ou desligar uma praça. Para mudar a ordem, basta segurar de forma prolongada o item e arrastar para cima ou para baixo. Salve suas alterações tocando no botão de aplicar.'
      ]"
      confirm-icon="keyboard_arrow_right"
      cancel-icon="keyboard_arrow_left"
      :is-filled="true"
      :has-cancel-btn="true"
      @on-open="goToPage('MarketPreferences')"
      @on-confirm="openTip(isApp ? 'tipNotification' : 'tipHome3')"
      @on-cancel="openTip('tipSettings')"
    />

    <Tips
      ref="tipNotification"
      title="Dicas"
      :contents="[
        'Utilize o botão de switch para selecionar as praças e ligar ou desligar o envio de notificação com sua audiência ao término do programa.'
      ]"
      confirm-icon="keyboard_arrow_right"
      cancel-icon="keyboard_arrow_left"
      :is-filled="true"
      :has-cancel-btn="true"
      @on-open="goToPage('Notifications')"
      @on-confirm="openTip('tipHome3')"
      @on-cancel="openTip('tipSettings')"
    />

    <Tips
      ref="tipHome3"
      title="Parabéns"
      :contents="[
        'Você concluiu o tutorial com sucesso! Caso precise visualizar as dicas novamente, acesse a tela de configurações.',
      ]"
      cancel-icon="keyboard_arrow_left"
      :has-cancel-btn="false"
      :is-filled="true"
      @on-open="goToPage('Home')"
      @on-confirm="finishTipsTour()"
    />
  </div>
</template>

<script>
import Tips from '@/components/modules/Tips.vue';
import device from '@/utils/device';

export default {
  components: {
    Tips,
  },
  data() {
    return {
      isTourOpen: false,
      isApp: device.isApp(),
    };
  },
  mounted() {
    this.initTipsTour();
  },
  methods: {
    initTipsTour() {
      this.isTourOpen = true;
      this.openTip('tipStartTutorial');
    },
    openTip(tipName) {
      this.$refs[tipName].open();
    },
    openUserPreferences() {
      this.$store.dispatch('openUserPreferences');
    },
    closeUserPreferences() {
      this.$store.dispatch('closeUserPreferences');
    },
    finishTipsTour() {
      this.isTourOpen = false;
      this.$store.dispatch('selectIsTipsTourDone', true);
      if (this.$route.name !== 'Home') this.goToPage('Home');
    },
    goToPage(name) {
      this.$router.push({ name });
    },
  },
};
</script>


<style lang="scss">
@import '@/assets/scss/variables.scss';

.mobile_tips {
  opacity: 0;
  pointer-events: none;
}

// SMARTPHONES
@media (max-width: 600px) {
  .mobile_tips {
    opacity: 1;
    pointer-events: all;
    width: 100vw;
    height: 100vh;
    background-color: rgba($color-gray-lighter, 0.8);
  }
}
</style>
