import moment from 'moment';
import 'moment-timezone';
import bffRealtime from '@/gateways/bff-realtime';
import lodash from 'lodash';

// initial state
const state = {
  audiences: [],
  simulcastAudiences: [],
  programAverages: [],
  scrollId: null,
  dayAverages: null,
  selectedAverages: null,
  programNowAverages: null,
  lastMinute: null,
  isLoadingByScroll: false,
  lastTimeUpdated: moment(),
};

// getters
const getters = {
  audiences: state => state.audiences,
  simulcastAudiences: state => state.simulcastAudiences,
  programAverages: state => state.programAverages,
  scrollId: state => state.scrollId,
  dayAverages: state => state.dayAverages,
  selectedAverages: state => state.selectedAverages,
  programNowAverages: state => state.programNowAverages,
  lastMinute: state => state.lastMinute,
  isLoadingByScroll: state => state.isLoadingByScroll,
  lastTimeUpdated: state => state.lastTimeUpdated.clone().locale('pt-BR'),
  tableViewParams: (_, __, rootState) => (rootState.settings.isMarketView
    ? { marketId: rootState.settings.market.id }
    : { tvNetworkId: rootState.settings.tvNetworkId }
  ),
};

// actions
const actions = {
  async getProgramNowAverage({
    // eslint-disable-next-line no-shadow
    rootState, dispatch, commit, getters,
  }) {
    // TvNetworkAverages
    const now = moment().utc();
    if (rootState.globoSchedule.globoSchedules.length === 0) await dispatch('getGloboSchedules');
    const programNow = getters.programByDate(now);
    if (!programNow) return;
    const filterTvNetworkAverages = {
      startDate: programNow.startsIn,
      endDate: programNow.endsIn,
      size: 1,
      ...(rootState.settings.isMarketView
        ? { marketId: rootState.settings.market.id }
        : { tvNetworkId: rootState.settings.tvNetworkId }),
    };
    try {
      const { data } = await bffRealtime.getRangeAverages(
        filterTvNetworkAverages, bffRealtime.tokenRequest('audience', 'programNowAverage'),
      );
      commit('SET_PROGRAM_NOW_AVERAGES', data);
    } catch (error) {
      console.log('error :: ', error);
    }
  },
  // eslint-disable-next-line no-shadow
  async getDayAverages({ rootState, commit }) {
    try {
      const { day } = rootState.settings.dateOptions;
      const selectedDay = moment().subtract(day, 'days').tz('America/Sao_Paulo');
      const filterDayAverages = {
        startDate: selectedDay.set({
          hour: 7,
          minute: 0,
          second: 0,
          milisecond: 0,
        }).format(),
        endDate: selectedDay.endOf('day').add(1, 'seconds').format(),
        size: 1,
        ...(rootState.settings.isMarketView
          ? { marketId: rootState.settings.market.id }
          : { tvNetworkId: rootState.settings.tvNetworkId }),
      };

      const token = await bffRealtime.tokenRequest('audience', 'dayAverages');

      try {
        const { data } = await bffRealtime.getRangeAverages(
          filterDayAverages, token,
        );

        if (data.length !== 0) {
          commit('SET_DAY_AVERAGES', data);
          commit('SET_LAST_TIME_UPDATED');
        }
      } catch (error) {
        console.log('error :: ', error);
      }
    } catch (error) {
      const result = undefined;
      commit('SET_DAY_AVERAGES', result);
      commit('SET_LAST_TIME_UPDATED');
    }
  },
  // eslint-disable-next-line no-shadow
  async getSelectedAverages({ rootState, commit, getters }) {
    const { startTime, endTime } = rootState.settings.dateOptions;
    if (!startTime && !endTime) {
      commit('SET_SELECTED_AVERAGES', null);
    } else {
      const startDate = getters.globoDate.startsIn;
      const endDate = getters.globoDate.endsIn || moment().format();

      const filterSelectedAverages = {
        startDate,
        endDate: moment(endDate).add(1, 'minutes').format(),
        size: 1,
        ...(rootState.settings.isMarketView
          ? { marketId: rootState.settings.market.id }
          : { tvNetworkId: rootState.settings.tvNetworkId }),
      };

      try {
        const { data } = await bffRealtime.getRangeAverages(
          filterSelectedAverages, bffRealtime.tokenRequest('audience', 'selectedAverages'),
        );

        if (data.length !== 0) commit('SET_SELECTED_AVERAGES', data);
      } catch (error) {
        console.log('error :: ', error);
      }
    }
  },
  updateLastTime({ commit }) {
    commit('SET_LAST_TIME_UPDATED');
  },
  async getLastMinute({ rootState, commit }) {
    const { day } = rootState.settings.dateOptions;
    if (day === 0) {
      const filterAudiences = {
        startDate: moment().subtract(5, 'minutes').format(),
        endDate: moment().format(),
        sortBy: ['-date'],
        size: 500,
        ...(rootState.settings.isMarketView
          ? { marketId: rootState.settings.market.id }
          : { tvNetworkId: rootState.settings.tvNetworkId }),
        ...(rootState.settings.isMarketView
          ? { collapseBy: 'tvNetworkIdNumber' }
          : { collapseBy: 'marketIdNumber' }),
      };
      const { data } = await bffRealtime.getLastAudiences(
        filterAudiences, bffRealtime.tokenRequest('audience', 'lastMinute'),
      );

      if (data.source.length !== 0) commit('SET_LAST_MINUTE', data.source || null);
    } else {
      commit('SET_LAST_MINUTE', null);
    }
  },
  async getProgramAverages({
    // eslint-disable-next-line no-shadow
    rootState, commit, getters, dispatch, rootGetters,
  }) {
    const startDate = getters.globoDate.startsIn;
    const endDate = getters.globoDate.endsIn;

    if (!startDate) {
      dispatch('showError', {
        clicked: true,
      });
      return null;
    }

    const filterProgramAverages = {
      startDate,
      endDate: endDate || moment().format(),
      isRivals: rootState.settings.isShowCompetitor,
      scheduleTvNetworkId: rootGetters.scheduleTvNetworkId,
    };

    if (rootState.settings.isMarketView) {
      filterProgramAverages.marketName = rootState.settings.market.name;
      filterProgramAverages.marketId = rootState.settings.market.id;
    } else {
      const market = rootState.market.markets.find(
        item => (item.id === rootState.settings.marketIdOrder[0]),
      );

      filterProgramAverages.marketName = market.name;
      filterProgramAverages.marketId = market.id;
      filterProgramAverages.tvNetworkId = rootState.settings.tvNetworkId;
    }

    filterProgramAverages.isCustom = rootState.settings.usePrograms
      && rootState.settings.customProgramsMarkets.includes(filterProgramAverages.marketId);

    const { data } = await bffRealtime.getProgramAverages(
      filterProgramAverages, bffRealtime.tokenRequest('audience', 'programAverages'),
    );

    if (data.length !== 0) {
      const now = moment().tz('America/Sao_Paulo');
      const ret = lodash.chain(data).filter(value => moment(value.startsIn).tz('America/Sao_Paulo').isSameOrBefore(now)).value();

      ret.forEach((program) => {
        const typeTemp = program.type ? program.type : 'P';
        program.program = typeTemp === 'Q' ? `<i class='icone material-icons icon-segment'>keyboard_arrow_right</i><div class='row_program'>${program.program}</div>` : program.program;
      });

      commit('SET_PROGRAM_AVERAGES', ret);
      return ret;
    }

    return [];
  },
  async getAudiences({
    // eslint-disable-next-line no-shadow
    commit, rootState, getters, dispatch,
  }) {
    const startDate = getters.globoDate.startsIn;
    const endDate = getters.globoDate.endsIn;

    if (!startDate) {
      dispatch('showError', {
        clicked: true,
      });
      return null;
    }

    const filterAudiences = {
      startDate,
      endDate: endDate || moment().format(),
      sortBy: ['-date'],
      size: 500,
      ...(rootState.settings.isMarketView
        ? { marketId: rootState.settings.market.id }
        : { tvNetworkId: rootState.settings.tvNetworkId }),
    };

    const { data } = await bffRealtime.getAudiences(
      filterAudiences, bffRealtime.tokenRequest('audience', 'audiences'),
    );

    commit('SET_AUDIENCES', data);
    return data;
  },

  async getSimulcastAudiences({
    // eslint-disable-next-line no-shadow
    commit, rootState, getters, dispatch,
  }) {
    const startDate = getters.globoDate.startsIn;
    const endDate = getters.globoDate.endsIn;
    const marketSimulcast = rootState.settings.marketIdOrder;

    const manyMarketsId = marketSimulcast.map((item) => {
      let marketId = item;
      if (marketId === 'BHZ') {
        marketId = 'BH';
      } else if (marketId === 'DFE') {
        marketId = 'DF';
      } else if (marketId === 'FLP') {
        marketId = 'FLO';
      } else if (marketId === 'CTA') {
        marketId = 'CUR';
      }
      return marketId;
    });

    if (!startDate) {
      dispatch('showError', {
        clicked: true,
      });
      return null;
    }


    const filterSimulcastAudiences = {
      startDate,
      endDate: endDate || moment().format(),
      sortBy: ['-date'],
      size: 500,
      manyMarketsId,
    };

    const { data } = await bffRealtime.getSimulcastAudiences(
      filterSimulcastAudiences, bffRealtime.tokenRequest('audience', 'simulcastAudiences'),
    );

    commit('SET_SIMULCAST_AUDIENCES', data);
    return data;
  },

  async getAudiencesByScrollId({ commit }) {
    if (!state.scrollId) return;
    if (!state.isLoadingByScroll) {
      commit('SET_LOADING_SCROLL', true);
      const tokenAudiencesByScrollId = await bffRealtime.tokenRequest('audience', 'audiencesByScrollId');
      const { data } = await bffRealtime.getScroll(
        state.scrollId, tokenAudiencesByScrollId,
      );
      commit('SET_LOADING_SCROLL', false);
      commit('SET_AUDIENCES', data);
    }
  },
  setScrollId({ commit }, scrollId) {
    commit('SET_SCROLLID', scrollId);
  },
  async getSimulcastAudiencesByScrollId({ commit }) {
    if (!state.scrollId) return;
    if (!state.isLoadingByScroll) {
      commit('SET_LOADING_SCROLL', true);
      const tokenSimulcastAudiencesByScrollId = await bffRealtime.tokenRequest('simulcastAudience', 'audiencesByScrollId');

      try {
        const { data } = await bffRealtime.getSimulcastScroll(
          state.scrollId, tokenSimulcastAudiencesByScrollId,
        );
        commit('SET_LOADING_SCROLL', false);
        commit('SET_SIMULCAST_AUDIENCES', data);
      } catch (error) {
        commit('SET_LOADING_SCROLL', false);
        console.log('Erro ', error);
      }
    }
  },

  setSimulcastScrollId({ commit }, simulcastScrollId) {
    commit('SET_SIMULCAST_SCROLLID', simulcastScrollId);
  },

  clearAverages({ commit }) {
    commit('SET_PROGRAM_NOW_AVERAGES', null);
    commit('SET_DAY_AVERAGES', null);
    commit('SET_SELECTED_AVERAGES', null);
  },

  async cancelRequests() {
    bffRealtime.cancelRequests('audience');
  },

};

// mutations
const mutations = {
  SET_LAST_TIME_UPDATED(state) {
    state.lastTimeUpdated = moment();
  },
  SET_AUDIENCES(state, data) {
    state.audiences = data;
  },
  SET_SIMULCAST_AUDIENCES(state, data) {
    state.simulcastAudiences = data;
  },
  SET_PROGRAM_AVERAGES(state, data) {
    state.programAverages = data;
  },
  SET_LOADING_SCROLL(state, isLoadingByScroll) {
    state.isLoadingByScroll = isLoadingByScroll;
  },
  SET_SCROLLID(state, scrollId) {
    state.scrollId = scrollId;
  },
  SET_SIMULCAST_SCROLLID(state, simulcastScrollId) {
    state.simulcastScrollId = simulcastScrollId;
  },
  SET_DAY_AVERAGES(state, data) {
    state.dayAverages = data;
  },
  SET_SELECTED_AVERAGES(state, data) {
    state.selectedAverages = data;
  },
  SET_LAST_MINUTE(state, data) {
    state.lastMinute = data;
  },
  SET_PROGRAM_NOW_AVERAGES(state, data) {
    state.programNowAverages = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
