import crypto from 'crypto';
import { RedirectionError, UndetectedRedirection } from '../Utils/application-erros.utils';

function base64URLEncode(str) {
  return str.toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function sha256(buffer) {
  return crypto.createHash('sha256').update(buffer).digest('hex');
}

export default class AuthService {
  constructor() {
    this.codeVerifier = base64URLEncode(crypto.randomBytes(32));
    this.challenge = sha256(this.codeVerifier);

    this.host = process.env.OPEN_CONNECT_API.host;
    this.clientID = process.env.OPEN_CONNECT_API.clientID;
    this.redirectURI = process.env.OPEN_CONNECT_API.callbackURI;
  }

  generateAuthRedirection() {
    const url = new URL(this.host);
    url.searchParams.set('scope', 'openid');
    url.searchParams.set('response_type', 'code');
    url.searchParams.set('client_id', this.clientID);
    url.searchParams.set('redirect_uri', this.redirectURI);
    url.searchParams.set('scope', 'openid profile email');

    return url;
  }

  checkSigninRedirectCallback(query) {

    return new Promise((resolve, reject) => {

      const testProps = ['session_state', 'code'];
      if (testProps.reduce((i, j) => i && j in query, true)) {
        return resolve({
          code: query.code,
          codeVerifier: this.codeVerifier,
        });
      }

      const testErrorProps = ['error'];

      if (testErrorProps.reduce((i, j) => i && j in query, true)) {
        return reject(new RedirectionError(query.error, query.error_description || ''));
      }

      return reject(new UndetectedRedirection());
    });
  }
}
