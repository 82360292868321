import moment from 'moment-timezone';
import bffRealtime from '@/gateways/bff-realtime';

// initial state
const state = {
  sprintAudiences: [],
  currentDate: null,
  isLoading: false,
};

// getters
const getters = {
  sprintAudiences: state => state.sprintAudiences,
  isLoading: state => state.isLoading,
};

// actions
const actions = {
  async getSprintChart({
    rootState, rootGetters, commit, dispatch,
  }, { currentDate, handleLoading }) {
    let marketSelectedId = rootState.settings.market.id;
    let marketSelectedName = rootState.settings.market.name;
    const tvNetworks = rootGetters.tvNetworks.map(item => item.id);

    if (marketSelectedId === 'RPT'
      //|| marketSelectedId === 'PNT'
      || marketSelectedId === 'RPP') {
      marketSelectedId = rootGetters.markets[0].id;
      marketSelectedName = rootGetters.markets[0].name;
    }

    const isCustom = rootState.settings.usePrograms
      && rootState.settings.customProgramsMarkets.includes(marketSelectedId);

    const params = {
      startDate: currentDate.startsIn,
      endDate: currentDate.endsIn || moment.tz().format(),
      marketId: marketSelectedId,
      marketName: marketSelectedName,
      networks: tvNetworks,
      isCustom,
      historicMark: rootState.settings.performanceHistory,
    };

    if (handleLoading) commit('HANDLE_LOADING', true);

    bffRealtime.getSprintChart(
      params, bffRealtime.tokenRequest('chart', 'sprintAudiences'),
    )
      .then(({ data }) => {
        commit('SET_SPRINT_AUDIENCES', data);
      })
      .catch((error) => {
        if (error !== 'Request Canceled') dispatch('showInnerError', { clicked: true }, { root: true });
      })
      .finally(() => commit('HANDLE_LOADING', false));
  },
};

// mutations
const mutations = {
  SET_SPRINT_AUDIENCES(state, data) {
    state.sprintAudiences = data;
  },
  HANDLE_LOADING(state, value) {
    state.isLoading = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
