<template>
  <div :class="!settings.isSimuCastView ? 'container' : 'container_simulcast'" style="padding-bottom: calc(env(safe-area-inset-bottom, 0) + env(safe-area-inset-top, 0)) ">
    <RouterView />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  computed: {
    ...mapState(['settings'])
  }
}
</script>
<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 64px);
    height: calc(100vh - 80px);
    min-height: 540px;
    padding: 0;
    margin: 0 auto;
}

.container_simulcast {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: calc(100% - 64px);
    height: calc(100vh - 0px);
    min-height: 540px;
    padding: 0;
    margin: 0 auto;
}

// ------------------------------------
// Breakpoints
// ------------------------------------

        // First Break : 1140px
        @media (max-width: 1140px) {
          .container {
            width: 96%;
            margin: 0 auto;
            padding: 0;
          }
        }

        // ------------------------------------

        // SMARTPHONES
        @media (max-width: 600px) {
          .container {
            position: fixed;
            z-index: 0;
            width: 100%;
            padding: 0;
            height: 100vh;
          }
        }

// ------------------------------------

</style>
