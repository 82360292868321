// initial state
import moment from 'moment';
import 'moment-timezone';
import bffRealtime from '@/gateways/bff-realtime';
import lodash from 'lodash';

const state = {
  sprintProgramAverages: { dates: [] },
  isLoading: false,
};

// getters
const getters = {
  sprintProgramAverages: state => state.sprintProgramAverages,
  isLoading: state => state.isLoading,
};

// actions
const actions = {
  async getSprintProgramAverages(
    {
      dispatch, commit, rootState, rootGetters,
    },
    { currentDate, handleLoading },
  ) {
    let marketSelectedId = rootState.settings.market.id;
    let marketSelectedName = rootState.settings.market.name;
    const tvNetworks = rootGetters.tvNetworks.map(item => item.id);

    if (marketSelectedId === 'RPT'
     // || marketSelectedId === 'PNT'
      || marketSelectedId === 'RPP') {
      marketSelectedId = rootGetters.markets[0].id;
      marketSelectedName = rootGetters.markets[0].name;
    }

    const isCustom = rootState.settings.usePrograms
      && rootState.settings.customProgramsMarkets.includes(marketSelectedId);

    const params = {
      startDate: currentDate.startsIn,
      endDate: currentDate.endsIn || moment().utc().format(),
      marketId: marketSelectedId,
      marketName: marketSelectedName,
      networks: tvNetworks,
      historicMark: rootState.settings.performanceHistory,
      isCustom,
    };

    if (handleLoading) commit('HANDLE_LOADING', true);

    bffRealtime.getSprintProgramAverages(params)
      .then(({ data }) => {
        const dates = data.data.dates.map(date => ({
          ...date,
          rivalTvNetworks: lodash.chain(date.rivalTvNetworks)
            .map(rival => ({
              ...rival,
              order: rootGetters.userNetworksOder
                .find(value => value.tvNetworkId === rival.tvNetworkId).order,
            })).orderBy('order', 'asc').value(),
        }));

        commit('SET_SPRINT_PROGRAM_AVERAGES', { dates });
      })
      .catch((error) => {
        if (error !== 'Request Canceled') dispatch('showInnerError', { clicked: true }, { root: true });
      })
      .finally(() => commit('HANDLE_LOADING', false));
  },
};

// mutations
const mutations = {
  SET_SPRINT_PROGRAM_AVERAGES(state, data) {
    state.sprintProgramAverages = data;
  },
  HANDLE_LOADING(state, data) {
    state.isLoading = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
