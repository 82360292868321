<template>
  <div
    id="scrollArea"
    ref="scrollingTable"
    class="table_container"
    :class="{
         loading: firstLoading, 'kpi_on': true,
         desc: settings.isDescending,
         asc: !settings.isDescending }"
  >
    <div class="loading_inner">
      <svg
        class="spinner"
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>

     <table class="table minutes-view shadow" :class="tableCss">
      <thead>
      
        <tr>
          <th>
            <button
              class="btn sort ripple"
              :class="{'descending': settings.isDescending}"
              @click="changeIsDescending"
            >
              <div class="chevron" />
              <i class="icone material-icons">access_time</i>
            </button>
            <!-- fim da ordenação -->
          </th>
          
          <!-- Header de topo -->

           <th v-if="showTsiColumn">
            <div>
              Total
            </div>
          </th>

          <th v-for="column in marketsColumns" :key="column[idName]"> 
            <img v-if="getNetworkLogo(column[idName])" :src="getNetworkLogo(column[idName])" />
           <div
              v-else
              :style="{ color: getNetworkColor(column[idName]) }" >
                {{(column[idName]) }}
           </div>
          </th>

        </tr>
        
        <!--  SEPARED -->
         <tr>
          <th />
         <th v-for="column in marketsColumns" :key="column[idName]"> 
          </th>
        </tr> 
      </thead>
      <tbody id="contentArea"/>  
     
    </table>
    <div v-if="!firstLoading && isLoadingByScroll && isScrollInBottom()" class="loading_small">
      <svg
        class="spinner"
        width="65px"
        height="65px"
        viewBox="0 0 66 66"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          class="path"
          fill="none"
          stroke-width="6"
          stroke-linecap="round"
          cx="33"
          cy="33"
          r="30"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import moment from "moment";
import Clusterize from "clusterize.js";
import _ from "lodash";
import AnimatedNumber from "@/components/modules/AnimatedNumber.vue";
import transformMixin from "@/utils/transformMixin";

export default {
  components: {
    AnimatedNumber
  },
  mixins: [transformMixin],
  data() {
    return {
      clusterize: null,
      currentAudienceTimeout: null,
      currentAudienceInterval: null,
      firstLoading: true,
      didFinsh: false,
      minuteLines: null,
      scrollLength: 0,
      currentScrollTimeout: null,
      lastScrollTop: 0,
      isScrollHorizontal: null,
      currentScrollHorizontalTimeout: null,
      lastScrollLeft: 0,
      marketsColumns: [],
    };
  },
  computed: {
    ...mapState(["settings"]),
    ...mapGetters([
      "simulcastAudiences",
      "scrollId",
      "simulcastScrollId",
      "markets",
      "tvNetworks",
      "marketDays",
      "isLoadingByScroll",
      "tvNetworks_v2",
      "globoDate",
      "networks",
    ]),

    secondaryColumns() {
      return  this.marketsColumns;
    },

    idName() {
      return false ? "id" : "exhibitionName";
    },

    tableCss() {
      return {
        "market-view": false,
        in: this.isScrollHorizontal,
        out: this.isScrollHorizontal === false
      };
    },
    showTsiColumn() {
      return true;
    }
  },
  watch: {
    simulcastAudiences(newValue) {
      const { source, sourceLength, scrollId } = newValue;
      if (!this.scrollId) this.$store.dispatch("setScrollId", scrollId);
      if (source.length === 0) {
        this.didFinsh = true;
      } else {
        this.syncAudienceDataIntoMinuteLineTable(source);  
        if (scrollId === this.scrollId) {
          this.scrollLength += source.length;
          if (source.length && this.scrollLength < sourceLength) {
            this.getScroll();
          } else {
            this.scrollLength = 0;
          } 
        }
      }
    },
    minuteLines(newValue, oldValue) {
      const table = this.$refs.scrollingTable;
      const { scrollTop, scrollHeight } = table;
      this.syncMinuteLineTableToClusterize(oldValue, newValue);
      if (!this.settings.isDescending) {
        this.lastScrollTop = scrollTop + table.scrollHeight - scrollHeight;
        table.scrollTop = this.lastScrollTop;
      }
    }
  },
  mounted() {
    this.setupClusterize();
    this.setupCurrentAudienceOnTop();
    this.clearAndGetNewAudiences();
    this.unwatch = this.$store.watch(
      state => state.settings,
      () => {
        this.firstLoading = true;
        this.didFinsh = false;
        this.clearAndGetNewAudiences();
      },
      { deep: true }
    );
  },
  beforeDestroy() {
    //this.$modal.hide("simulcastDetails");
    this.$store.dispatch("setFullSizeView", false);
    this.unwatch();
    clearTimeout(this.currentAudienceTimeout);
    clearInterval(this.currentAudienceInterval);
  },
  methods: {
    getNetworkLogo(networkId, skipCheck) {
      if (true && !skipCheck) return "";
      return this.tvNetworks_v2[networkId] &&
        this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath
        : "";
    },
    getNetworkColor(networkId, skipCheck) {
      if (true && !skipCheck) return "";
      return this.tvNetworks_v2[networkId] &&
        this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor
        : "";
    },
    formatDateMS(date) {
      return moment(date).format("HH:mm");
    },
    async clearAndGetNewAudiences() {
      this.$store.dispatch('selectIsMarketView', false);
      this.setUpColumns();
      this.$store.dispatch("cancelRequests");
      this.$store.dispatch("setScrollId", null);
      this.$store.dispatch('setSimulcastScrollId', null);

      this.separateLines();
      this.clusterize.clear();
      await Promise.all([
        this.$store.dispatch("getSimulcastAudiences"),
      ])
        .then(res => {
          if (res[0].source && res[0].source.length === 0) {
            this.$store.dispatch("showInnerError", { clicked: true });
          } else {
            this.setProgramsLastLine();
          }
        })
        .catch(error => {
          let statusErrorCode;
          if(error.response.status===undefined){
            statusErrorCode = 405;
          }else{
            statusErrorCode = error.response.status
          }

          if (statusErrorCode === 405) {
            this.$store.dispatch("showInnerError", {
              insides: [
                "notallowed_back",
                "notallowed_item",
                "notallowed_block"
              ],
              subtitle:
                "Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário."
            });
          } else if (error.response && error.response.config) {
            if (/audience/.test(error.response.config.url)) {
              this.$store.dispatch("showInnerError", { clicked: true });
            }
          }
        });

      this.firstLoading = false;
    },
    separateLines() {
      this.minuteLines = {};
      const timenow = moment().startOf("day");
      const timeDiff = timenow.diff(
        moment(`${timenow.format("YYYY-MM-DD")}T00:00:00Z`).local(),
        "hours"
      );
      const colspan = this.secondaryColumns.length;

      const { startsIn, endsIn } = this.marketDays[
        this.settings.dateOptions.day
      ];
      [startsIn, endsIn].forEach((time, key) => {
        if (time) {
          const date = moment(time).add(timeDiff, "hours");
          if (key === 1) date.add(1, "minutes");
          this.minuteLines[
            `${date.format("YYYY-MM-DDTHH:mm:SS")}`
          ] = `<td><span></span>${date
            .subtract(1, "days")
            .format(
              "dddd, LL"
            )}</td><td></td><td></td><td colspan="${colspan}"></td>`;
        }
      });
    },
    changeIsDescending() {
      this.$store.dispatch("selectIsDescending", !this.settings.isDescending);
    },
    selectScrollDirection() {
      const table = this.$refs.scrollingTable;
      const timeout = 500;

      const sl = table.scrollLeft;
      if (sl) {
        if (sl !== this.lastScrollLeft) {
          this.isScrollHorizontal = true;
          clearTimeout(this.currentScrollHorizontalTimeout);
          this.currentScrollHorizontalTimeout = setTimeout(() => {
            this.isScrollHorizontal = false;
            const { scrollLeft } = table;
            this.lastScrollLeft = scrollLeft <= 0 ? 0 : scrollLeft; // For Mobile or negative scrolling
          }, timeout);
        }
      }

      const st = table.scrollTop;
 
      if (st === this.lastScrollTop) return;
      const diff = st - this.lastScrollTop;
      const changeStatus = this.settings.isDescending
        ? diff > 116 || diff < -400
        : diff > 400 || diff < -116;
      if (changeStatus) {
        const isScrollDown = this.settings.isDescending
          ? st > this.lastScrollTop
          : st < this.lastScrollTop;

        this.$store.dispatch("setFullSizeView", isScrollDown);
      }

      clearTimeout(this.currentScrollTimeout);
      this.currentScrollTimeout = setTimeout(() => {
        const { scrollTop, scrollHeight, clientHeight } = table;
        if (
          (this.settings.isDescending && scrollTop === 0) ||
          (!this.settings.isDescending &&
            scrollTop + clientHeight === scrollHeight)
        ) {
          this.$store.dispatch("setFullSizeView", false);
        }
        this.lastScrollTop = scrollTop <= 0 ? 0 : scrollTop; // For Mobile or negative scrolling
        this.setProgramsLastLine();
      }, timeout);
    },
    isScrollInBottom() {
      const table = this.$refs.scrollingTable;
      if (this.settings.isDescending) {
        return (
          table.scrollTop + table.clientHeight >= table.scrollHeight - 1300
        );
      }

      return table.scrollTop <= 1300;
    },

    setProgramsLastLine() {
    },
    getScroll() {
      if (!this.didFinsh) {
        try {
          //this.$store.dispatch("getAudiencesByScrollId");
          this.$store.dispatch('getSimulcastAudiencesByScrollId');
        } catch (error) {
          this.$store.dispatch("showInnerError", { clicked: true });
        }
      }
    },

    setUpColumns(){
      
      let marketTemp = this.markets.map(object => ({ ...object }))  
      
      marketTemp = marketTemp.filter(item => 
        (item.exhibitionName == 'SP') ||
        (item.exhibitionName == 'BH') ||
        (item.exhibitionName == 'BEL') ||
        (item.exhibitionName == 'RJ') ||
        (item.exhibitionName == 'MAN') ||
        (item.exhibitionName == 'CUR') ||
        (item.exhibitionName == 'DF') ||
        (item.exhibitionName == 'FLO') ||
        (item.exhibitionName == 'POA') ||
        (item.exhibitionName == 'FOR' && item.id != 'FOT') ||
        (item.exhibitionName == 'GOI') ||
        (item.exhibitionName == 'REC') ||
        (item.exhibitionName == 'SAL') ||
        (item.exhibitionName == 'VIT') ||
        (item.exhibitionName == 'BAU') ||
        (item.exhibitionName == 'SJP') ||
        (item.exhibitionName == 'MAC') ||
        (item.exhibitionName == 'RB') ||
        (item.exhibitionName == 'CAM') ||
        (item.exhibitionName == 'ITA') ||
        (item.exhibitionName == 'PVE') ||
        (item.exhibitionName == 'BVI') ||
        (item.exhibitionName == 'SOR') ||
        (item.exhibitionName == 'CAB') ||
        (item.exhibitionName == 'SLU') //|| (item.exhibitionName == 'TSI')
      );

      marketTemp = marketTemp.filter(item => item.exhibitionName != 'TVP');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'PNT');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'EPP');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'NVP');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'NVT');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'EPT');
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'TVT'); 
      marketTemp = marketTemp.filter(item => (item.id != 'FOT')); 

      if(marketTemp.length == 0){
        this.$store.dispatch("selectIsButtonDownloadActivate", false);       
        this.$store.dispatch("showInnerError", {
              insides: [
                "notallowed_back",
                "notallowed_item",
                "notallowed_block"
              ],
              subtitle:
                "Você deve ter em suas preferências ao menos uma praça contemplada para o Simulcast Ex:(RJ, SP ...)."
            });

      }else{
        this.$store.dispatch("selectIsButtonDownloadActivate", true);   
      }
       
        this.marketsColumns =  marketTemp;
    },

    setupClusterize() {
      this.clusterize = new Clusterize({        
        rows: [],
        scrollId: "scrollArea",
        contentId: "contentArea",
        rows_in_block: 20,
        callbacks: {
          scrollingProgress: () => {
            this.selectScrollDirection();
            if (this.isScrollInBottom()) this.getScroll();
          }
        }
      });
    },
    setupCurrentAudienceOnTop() {
      const date = new Date();
      const startOfMinute = (60 - date.getSeconds()) * 1000;
      this.currentAudienceTimeout = setTimeout(() => {
        this.currentAudienceInterval = setInterval(() => {
          this.getData();
        }, 30000);
        this.getData();
      }, startOfMinute);
    },
    getData() {
      const { day, endTime } = this.settings.dateOptions;
      if (day > 0 || endTime) return;
      this.$store.dispatch("getSimulcastAudiences");
    },
    indicadoresAudience(tvNetworkId, tvNetworkWinners) {
      let className = " ";
      
      // if (tvNetworkWinners.includes(tvNetworkId)) {
      //   className = tvNetworkWinners.length === 1 ? "won" : "drew";
      // } else if (tvNetworkId === "GLOBO") {
      //   className = "lost";
      // }
      return "";

    },
    columnTemplateHtml(value = "", tvNetworkId = "", className = "", kpi = "") {
      return `<td tvNetworkId="${tvNetworkId}" class="${className}">`
        .concat(value)
        .concat('<span class="kpi">')
        .concat(kpi)
        .concat("</span></td>");
    },
    populateAudiencePerNetwork(
      minuteLine,
      tvNetworkId,
      points,
    //  tvNetworkWinners
    ) {
      // DANGER IS CHANGE THE VALUE INSIDE THE FUNCTION
      const networkInMinute = minuteLine.find(item => item.tv === tvNetworkId);
       
      if (networkInMinute) {
        const { simulcastValue } = this.settings;
        // Check status
        const tvNetwork = this.settings.tvNetworkId;
        // const className = this.indicadoresAudience(tvNetwork, tvNetworkWinners);
        const className = this.indicadoresAudience(tvNetwork);
        const value = points[simulcastValue];
        networkInMinute.points = points;
        networkInMinute.column = this.columnTemplateHtml(
          value,
          tvNetworkId,
          className,
          "2pts"
        );
      }
    },
    generateClusterizeTableView(minuteLines) {
      // iterate in evey minute to make table line -> | minute | tv1 | tv2 | tv3 | ...
      const rows = Object.keys(minuteLines).map(minute => {
        if (minuteLines[minute] instanceof Object) {
          const tableLine = `<tr date="${minute}" ><td>${this.formatDateMS(
            minute
          )}</td>{{networkColumns}}</tr>`;
          const networkColumns = minuteLines[minute].reduce(
            (acc, curr) => acc + curr.column,
            ""
          );
          const tableLineView = tableLine.replace(
            "{{networkColumns}}",
            networkColumns
          );
          return tableLineView;
        }
        return `<tr class="date">${minuteLines[minute]}</tr>`;
      });

      return rows;
    },
    mergeMinuteLineTable(newMinuteLineValue, oldMinuteTableValue) {
      if (!oldMinuteTableValue) return newMinuteLineValue;

      newMinuteLineValue.forEach((minute, index) => {
        if (minute.points !== "-") {
          oldMinuteTableValue[index] = minute;
        }
      });
      return oldMinuteTableValue;
    },
    updateMinuteLineTable(newMinuteLineValues) {
      // if there is no minuteLline return fresh one
      if (!this.minuteLines) return this.sortLineTable(newMinuteLineValues);
      // check if minuteTable is equal
      if (_.isEqual(this.minuteLines, newMinuteLineValues)) {
        return this.minuteLines;
      }
      // copy minuteLine and update values to trigger watcher
      const copy = JSON.parse(JSON.stringify(this.minuteLines));
      Object.keys(newMinuteLineValues).forEach(minute => {
        copy[minute] = this.mergeMinuteLineTable(
          newMinuteLineValues[minute],
          this.minuteLines[minute]
        );
      });
      return this.sortLineTable(copy);
    },
    sortLineTable(newMinuteLineValues) {
      const sorted = Object.keys(newMinuteLineValues).sort();
      if (this.settings.isDescending) {
        sorted.reverse();
      }
      const ordered = sorted.reduce((acc, key) => {
        acc[key] = newMinuteLineValues[key];
        return acc;
      }, {});
      return ordered;
    },
    generateMinuteLines(audienceData, initialLinesPerMinute) {      
      
      const lines = audienceData.reduce(
        (
          acc,
          { date,  marketId, ...rest }
        ) => {
          const id = marketId;
          initialLinesPerMinute.forEach(element => {
            if(element.tv == "BHZ"){
               element.tv = "BH"
            }else if(element.tv == "DFE"){
                element.tv = "DF"
            }else if(element.tv == "FLP"){
               element.tv = "FLO"
            }else if(element.tv == "CTA"){
               element.tv = "CUR"
            }
          });          

          // trick - making a deep copy of small array
          const copy = JSON.parse(JSON.stringify(initialLinesPerMinute));
          
          acc[date] = acc[date] || copy;
          
          // fill the audience for the respected networkId per minute
          this.populateAudiencePerNetwork(
            acc[date],
            id,
            rest,
           // tvNetworkWinners
          );
          return acc;
        },
        {}
      );

      return lines;
    },

    syncAudienceDataIntoMinuteLineTable(data) {      
    
      const networks = this.secondaryColumns.map(item => item.id);
      const initialNetworkLinesPerMinute = networks.map(item => ({
        tv: item,
        points: "-",
        column: "<td>-</td>"
      }));

      if (this.showTsiColumn) {
        initialNetworkLinesPerMinute.unshift({
          tv: "TSI",
          points: "-",
          column: "<td>-</td>"
        });
      }

      const minuteLines = this.generateMinuteLines(
        data,
        initialNetworkLinesPerMinute
      );   
      this.minuteLines = this.updateMinuteLineTable(minuteLines); 
    },

    syncMinuteLineTableToClusterize(oldMinuteTable, newMinuteTable) {
      const oldMinuteTableKeys = _.keys(oldMinuteTable);
      const newMinuteTableKeys = _.keys(newMinuteTable);
      const diff = _.difference(newMinuteTableKeys, oldMinuteTableKeys);
      // update clusterize only if diff exists
      if (diff.length > 0) {
        const clusterizeNewData = this.generateClusterizeTableView(
          newMinuteTable
        );
        this.clusterize.update(clusterizeNewData);
      }
    },
    tbodyMouseClick(event) {
  
    },
    tbodyMouseOver(event) {
      if (!event.target.parentNode.attributes.date) return;
      const dateStr = event.target.parentNode.attributes.date.value;
      const date = moment(dateStr); 
    },
    tbodyMouseLeave() {
      this.programsMouseOver = null;
    },
  }
};
</script>
<style scoped>
    @media (max-width: 600px) {
      thead {
            top: 88px !important;
      }
    }
</style>