var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('div',{staticClass:"topo"},[_c('button',{staticClass:"btn ripple",attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPage('Settings')}}},[_c('i',{staticClass:"icone material-icons"},[_vm._v("\n        keyboard_arrow_left\n      ")])]),_vm._v(" "),_c('div',{staticClass:"title"},[_vm._v("\n      Definir Emissoras\n    ")])]),_vm._v(" "),_c('div',{staticClass:"middle"},[_c('div',{staticClass:"description"},[_vm._v("\n      Você pode ativar / desativar emissoras na lista abaixo, essa configuração será replicada em todo o app.\n    ")]),_vm._v(" "),_c('div',{staticClass:"panel"},[_vm._m(0),_vm._v(" "),_c('draggable',{attrs:{"move":_vm.handleMove,"options":{
                   draggable:'.draggableItem',
                   emptyInsertThreshold: 20,
                   removeCloneOnHide: true,
                   animation: 80,
                   bubbleScroll: true,
                   ghostClass: 'ghost-background',
                   delay: 200,
                 }},on:{"start":function($event){_vm.drag=true},"end":function($event){_vm.drag=false}},model:{value:(_vm.tvNetworkIdsEnabled),callback:function ($$v) {_vm.tvNetworkIdsEnabled=$$v},expression:"tvNetworkIdsEnabled"}},[_vm._l((_vm.tvNetworksEnabled),function(tvNetworkId){return _c('div',{key:tvNetworkId,staticClass:"item",class:{
               especial: tvNetworkId === _vm.tvNetworksEnabled[0],
               draggableItem: (tvNetworkId !== 'TL'
                 && tvNetworkId !== 'TLE')
             },on:{"click":function($event){return _vm.changeTvNetworkStatus(tvNetworkId)}}},[_c('span',{staticClass:"category"},[_vm._v("\n            "+_vm._s(_vm.transNetworkNameById(tvNetworkId))+"\n          ")]),_vm._v(" "),_c('label',{staticClass:"switch"},[_c('input',{attrs:{"type":"radio"},domProps:{"checked":true}}),_vm._v(" "),_c('span',{staticClass:"slider round ripple"})])])}),_vm._v(" "),_vm._l((_vm.tvNetworksDisabled),function(tvNetworkId){return _c('div',{key:tvNetworkId,staticClass:"item",on:{"click":function($event){return _vm.changeTvNetworkStatus(tvNetworkId)}}},[_c('span',{staticClass:"category"},[_vm._v("\n            "+_vm._s(_vm.transNetworkNameById(tvNetworkId))+"\n          ")]),_vm._v(" "),_c('label',{staticClass:"switch"},[_c('input',{attrs:{"type":"radio"},domProps:{"checked":false}}),_vm._v(" "),_c('span',{staticClass:"slider round ripple"})])])})],2)],1)]),_vm._v(" "),_c('div',{staticClass:"slice"},[_c('button',{staticClass:"btn btn_full btn_secondary ripple",attrs:{"type":"button"},on:{"click":function($event){return _vm.applyPreferences()}}},[_vm._v("\n      Aplicar\n    ")])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('div',{staticClass:"text"},[_vm._v("\n          Emissoras\n        ")]),_vm._v(" "),_c('div',{staticClass:"line"})])}]

export { render, staticRenderFns }