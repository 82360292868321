<template>
  <Modal :name="name"
         :height="'auto'"
         :max-height="640"
         :width="880"
         :class="'modal-details'"
         @before-open="beforeOpen"
         @before-close="beforeClose"
  >
    <div v-if="data" class="content">
      <div class="head">
        <h1>
          Comparação >
          <span v-if="settings.isMarketView">
            &nbsp;Visão de Praças
          </span>
          <span v-else>
            &nbsp;Visão de Emissoras
          </span>
        </h1>

        <div class="thumb">
          <img v-if="!settings.isMarketView && getNetworkLogo(firstColumn[idName])" :src="getNetworkLogo(firstColumn[idName])">
          <span v-else>
            {{ firstColumn[idName] }}
          </span>
        </div>

        <div class="value" :class="indicadoresAudience(firstColumn[idName], firstColumn.id)">
          {{ value(firstColumn.id) }}
        </div>

        <div class="programa">
          {{ program }}
        </div>

        <div class="info">
          <img v-if="settings.isMarketView && getNetworkLogo(networkId)" :src="getNetworkLogo(networkId)">
          <span v-else>
            {{ market ? market.exhibitionName : networkId }}
          </span>
        </div>
      </div>
      <!-- fim do topo -->

      <div class="middle">
        <ul class="grid">
          <!-- ITEM -->
          <li v-for="column in restColumns" :key="column.id">
            <!-- Content com imagem da emissora e pontos de audiência -->
            <div class="content">
              <div class="tv_network">
                <img v-if="!settings.isMarketView && getNetworkLogo(column[idName])" :src="getNetworkLogo(column[idName])">
                <span v-else>
                  {{ column[idName] }}
                </span>
              </div>

              <div class="value"
                   :class="indicadoresAudience(column[idName], column.id)"
              >
                {{ value(column.id) }}
              </div>
            </div>
          </li>
          <!-- // -->
        </ul>
      </div>

      <div class="footer">
        <button type="button" name="entendi" class="btn btn_primary btn_full ripple" @click="closeModal()">
          Voltar
        </button>
      </div>
    </div>
    <div v-else class="loading_small">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6"
                stroke-linecap="round" cx="33" cy="33" r="30"
        />
      </svg>
    </div>
    <!-- // -->
  </Modal>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';

export default {
  data() {
    return {
      name: 'programDetails',
      data: null,
      program: null,
      startsIn: null,
      endsIn: null,
      networkId: null,
      market: null,
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['markets', 'tvNetworks', 'tvNetworks_v2', 'scheduleTvNetworkId', 'showCustomPrograms', 'singleDefaultValue']),
    columns() {
      let marketTemp = this.markets.map(object => ({ ...object }))
        marketTemp = marketTemp.filter(item => item.exhibitionName != 'FLO');
        marketTemp = marketTemp.filter(item => item.id != 'FOT');
      return this.settings.isMarketView ? marketTemp
        : this.tvNetworks;
    },
    firstColumn() {
      return this.columns[0];
    },
    restColumns() {
      return this.columns.slice(0).splice(1);
    },
    idName() {
      return this.settings.isMarketView ? 'exhibitionName' : 'id';
    },
  },
  methods: {
    async beforeOpen(event) {
      this.$store.dispatch('hideMobileNavigation');

      const { startsIn, endsIn, networkId, program } = event.params;
      this.startsIn = startsIn;
      this.endsIn = endsIn;
      this.program = program;
      this.networkId = networkId;

      const filterAudiences = {
        startDate: startsIn,
        endDate: endsIn,
        tvNetworkId: 'GLOBO',
        isRivals: true,
        isCustom: this.showCustomPrograms,
        scheduleTvNetworkId: this.scheduleTvNetworkId,
      };
      if (this.settings.isMarketView) {
        filterAudiences.marketName = this.settings.market.name;
        filterAudiences.marketId = this.settings.market.id;
        filterAudiences.tvNetworkId = networkId;
      } else {
        this.market = this.markets.find(item => item.id === networkId);
        filterAudiences.marketName = this.market.name;
        filterAudiences.marketId = this.market.id;
        filterAudiences.tvNetworkId = this.settings.tvNetworkId;
      }

      const { data } = await bffRealtime.getProgramAverages(
        filterAudiences, bffRealtime.tokenRequest('programDetails', 'programAverages'),
      );
      const { averages, tvNetworkWinners } = data.filter(p => p.program === program)[0];
      const dataObj = {};
      if (this.settings.isMarketView) {
        Object.keys(averages).forEach((marketId) => {
          if (averages[marketId][filterAudiences.tvNetworkId]) {
            dataObj[marketId] = averages[marketId][filterAudiences.tvNetworkId];
            dataObj[marketId].tvNetworkWinners = tvNetworkWinners[marketId];
          }
        });
      } else if (averages[filterAudiences.marketId]) {
        Object.keys(averages[filterAudiences.marketId]).forEach((marketId) => {
          dataObj[marketId] = averages[filterAudiences.marketId][marketId];
          dataObj[marketId].tvNetworkWinners = tvNetworkWinners[filterAudiences.marketId];
        });
      }
      this.data = dataObj;
    },
    beforeClose() {
      this.$store.dispatch('showMobileNavigation');
      bffRealtime.cancelRequests('programDetails');
      this.data = null;
      this.market = null;
    },
    closeModal() {
      setTimeout(() => {
        this.$modal.hide(this.name);
      }, 800);
    },
    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    value(id) {
      return this.data[id] && this.data[id][this.singleDefaultValue] !== undefined
        ? this.data[id][this.singleDefaultValue].toFixed(2) : '--';
    },
    indicadoresAudience(tag, id) {
      if (!this.data[id]) return '';
      const { tvNetworkWinners } = this.data[id];
      const tvNetworkId = this.settings.isMarketView ? this.networkId : tag;
      if (tvNetworkWinners && tvNetworkWinners.includes(tvNetworkId)) {
        return (tvNetworkWinners.length === 1)
          ? 'won'
          : 'drew';
      }
      if (tvNetworkWinners && tvNetworkId === 'GLOBO') return 'lost';
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';


// Modal
.v--modal-overlay {
  background: linear-gradient(140deg, rgba($color-primary, 0.8), rgba($color-secondary, 0.96));
  background-size: 100% 100%;

  &.overlay--show {
    animation: fadeIn 0.8s ease-in-out forwards;
  }

  &.overlay--hide {
    animation: fadeOut 0.8s ease-in-out forwards;
  }
}

.v--modal {
  border-radius: 8px 8px 0 0;
  box-shadow: 0 48px 80px rgba($color-gray-dark, 0.48);
  animation: fadeInUp 0.8s ease-in-out forwards;

  &.modal--hide {
    animation: fadeInUp 0.8s ease-in-out forwards;
  }

  // LOADING
  .loading_small {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 200px;

    .spinner {
      position: relative;
      width: 40px;
      height: 40px;

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

  // Content
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: auto;

    // TOPO
    .head {
      position: sticky;
      position: -webkit-sticky;
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 188px;
      padding: 0;
      text-align: center;
      background-color: $color-gray-lighter;
      box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

      h1 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        font-family: $secondary-typo;
        font-size: 1em;
        font-weight: 400;
        color: $color-gray-dark;
        font-style: italic;
        text-align: left;
        margin: 0 0 auto 0;
        box-sizing: border-box;
        padding: 16px;
        background-color: rgba($color-gray-back, 0.8);

        span {
          font-weight: 600;
          color: $color-gray-dark;
        }
      }

      // Thumb
      .thumb {
        display: flex;
        width: 64px;
        height: 64px;
        margin: 8px 8px auto 8px;
        border-radius: 8px;
        background-color: $color-gray-lighter;
        box-shadow: 0 4px 16px rgba($color-gray-dark, 0.1);

        span {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 100%;

          font-family: $secondary-typo;
          font-weight: 600;
          font-size: 1.2em;
          font-style: italic;
          color: $color-gray-dark;
          text-transform: uppercase;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
      // -----

      // Value
      .value {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 96px;
        height: 64px;
        margin: 8px 8px auto 8px;
        border-radius: 8px;
        background-color: $color-gray-lighter;
        border: solid 2px rgba($color-gray-light, 0.24);

        font-family: $primary-typo;
        font-weight: normal;
        font-size: 1.2em;
        text-align: right;
        color: $color-gray-main;

        &.won {
            color: $color-status-won;
        }

        &.lost {
            color: $color-status-lost;
        }

        &.drew {
            color: $color-status-drew;
        }
      }
      // -----

      // Nome do programa relacionado ao minuto
      .programa {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        margin: auto 0 0 0;
        border-top: solid 1px rgba($color-gray-light, 0.4);
        font-family: $secondary-typo;
        font-weight: 500;
        font-size: 0.88em;
        font-style: italic;
        color: $color-gray-main;
      }
      // -----

      // Info de praça / emissora
      .info {
        position: absolute;
        top: 80px;
        right: 16px;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: $color-gray-lighter;
        box-shadow: 0 4px 16px rgba($color-gray-dark, 0.16);

        img {
          width: 100%;
          height: 100%;
        }

        span {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          font-family: $secondary-typo;
          font-weight: 600;
          font-size: 0.88em;
          font-style: italic;
          color: $color-gray-dark;
          text-transform: uppercase;
        }
      }
      // -----
    }
    // -------------------------------------------------------

    // MEIO
    .middle {
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 0;

      // Grid
      .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-content: flex-start;
        width: 100%;
        max-height: 640px;
        min-height: auto;
        margin: 0;
        padding: 16px;
        background: $color-gray-back;
        list-style: none;

        li {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 32%;
          height: 80px;
          margin: 8px 0.6%;
          background: $color-gray-lighter;
          border-radius: 8px;
          box-shadow: 0 0 16px rgba($color-gray-dark, 0.08);

          &.active {
            box-shadow: 0 8px 24px rgba($color-gray-dark, 0.4);
          }

          // Bottom
          .bottom {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 32px;
            margin: 0 0 auto 0;
            border-top: solid 1px rgba($color-gray-back, 1);

            .line {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              width: 100%;

              .item {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                width: 100%;

                padding: 0 8px;
                font-family: $secondary-typo;
                font-size: 0.72em;
                font-weight: 500;
                color: $color-gray-main;

                i {
                  display: inline-block;
                  height: 100%;
                  font-size: 1em;
                  margin: 0 8px 0 8px;
                }

                span {
                  display: inline-block;
                  width: 80%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  padding: 0;
                }
              }
            }
          }
          // -----

          // Content
          .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 48px;

            .tv_network {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;
              align-items: center;
              width: 48px;
              height: 48px;
              margin: 0 0 0 8px;

              span {
                font-family: $secondary-typo;
                font-weight: 500;
                font-size: 0.88em;
                font-style: italic;
                color: $color-gray-dark;
                text-transform: uppercase;
                text-align: left;
                padding: 0 0 0 8px;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            .value {
              font-family: $primary-typo;
              font-weight: normal;
              font-size: 1.2em;
              margin: 0 12px 0 0;
              text-align: right;
              color: $color-gray-main;

              &.won {
                  color: $color-status-won;
              }

              &.lost {
                  color: $color-status-lost;
              }

              &.drew {
                  color: $color-status-drew;
              }
            }
          }

          // -----
        }
      }
    }
    // -------------------------------------------------------

    .footer {
      position: sticky;
      position: -webkit-sticky;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      padding: 0;
      box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);

      .btn {
        &.btn_secondary {
          width: 100%;
          border: none !important;
        }
      }
      // -----------------

    }
  }

  // Link
  .link {
    width: 100%;
    cursor: pointer;
    border: none;
    background-color: transparent;
    margin: 0;

    &.link--secondary {
      font-family: $secondary-typo;
      font-weight: 500;
      font-size: 1em;
      font-style: italic;
      text-transform: inherit;
      letter-spacing: 0.08em;
      color: rgba($color-secondary, 1);
      box-sizing: border-box;
      padding: 4px;
      border: none;

      &:hover {
        color: rgba($color-secondary, 0.72);
      }
      // -------------------------------------------------------
    }
  }
}
// -------------------------------------------------------

// SMARTPHONES
@media (max-width: 600px) {

  .v--modal {

    // Content
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      overflow-y: scroll;

      // TOPO
      .head {
        position: fixed;
        // z-index: 2;
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-around;
        // align-items: center;
        // width: 100%;
        // height: 240px;
        // text-align: center;
        // padding: 0;
        // background: $color-gray-lighter;
        // box-shadow: 0 8px 16px rgba($color-gray-dark, 0.08);

        .programa {
          font-size: 0.8em;
        }

    }
    // -------------------------------------------------------

    // MEIO
    .middle {

      // Grid
      .grid {
        justify-content: space-between;
        margin: 0;
        padding: 200px 16px 72px 16px;
        max-height: 100%;
        min-height: 100%;
        height: 100%;

        li {
          width: 48%;
          height: 80px;

          // Bottom
          .bottom {

            .line {

              .item {
                font-size: 0.64em;
              }
            }
          }
          // -----


          // -----
        }
      }
    }
    // -------------------------------------------------------

    .footer {
      position: fixed;
      bottom: 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      padding: 0;
      box-shadow: 0 -8px 32px rgba($color-gray-dark, 0.16);

      .btn {
        &.btn_primary {
          width: 100%;
          border: none !important;
        }
      }
      // -----------------

    }
  }
  }
}

</style>
