<template>
  <div :class="{ 'landscape-active': isLandscape }">
    <ErrorState v-if="isLocked"
                :insides="['landscape_block', 'landscape_item', 'landscape_back']"
                subtitle="Para garantir a melhor experiência, utilize o Realtime no
                  modo portrait. Por favor, vire seu dispositivo para a
                  posição vertical :)"
    />
    <slot v-else />
  </div>
</template>

<script>
import ErrorState from './ErrorState.vue';
import device from '@/utils/device';

export default {
  components: {
    ErrorState,
  },
  data() {
    return {
      isLandscape: device.isLandscape(),
    };
  },
  computed: {
    isLocked() {
      return this.isLandscape && this.$route.name !== 'Home';
    },
  },
  mounted() {
    window.addEventListener('orientationchange', this.handleOrientation);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.handleOrientation);
  },
  methods: {
    handleOrientation() {
      // This should be run ater few moments to make sure the browser has took the effect
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        this.isLandscape = device.isLandscape();
      }, 100);
    },
  },
};
</script>


<style lang="scss">
@import '@/assets/scss/variables.scss';
@import "@/assets/scss/audience.scss";
@import "@/assets/scss/audience-responsive.scss";
@import "@/assets/scss/audience-fullscreen.scss";


// Tratamento para esconder elementos no landscape

.landscape-active {

  // System
  .system {
    .sidebar,
    .filtros,
    .brand,
    .bottom_navigation,
    .navigation_mobile,
    .timebar,
    .header {
      display: none;
    }

    // Container
    .container {
      width: 100%;
      height: 100% !important;
      min-height: 100% !important;

      .home {

        // Tags
        .tags {
          padding: 16px;
          width: calc(100% - 16px);
          font-size: 0.88em;
        }

        // Table Container
        .table_container {
          max-height: calc(100% - 72px);
          font-size: 1em;

          &.loading {
            height: calc(100% - 40px);
          }
        }

        // Loading Small
        .loading_small {
          bottom: 0;
          height: 88px;

          .spinner {
            width: 24px;
            height: 24px;
          }
        }
      }
    }



  }

}


</style>
