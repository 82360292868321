import moment from 'moment';
import 'moment-timezone';

const dateUTC = {
  methods: {
    formatDate(dataCurrent) {
      let dataLocal = String(dataCurrent).substring(0, 10);
      dataLocal = dataLocal.replace('-', '/');
      dataLocal = dataLocal.replace('-', '/');
      return dataLocal;
    },

    timeUTC(time) {
      const timeDecrease = moment.duration('03:00:00');
      const [HH, mm] = time.split(':');
      const localDate = moment()
        .tz('America/Sao_Paulo')
        .set({ hour: parseInt(HH, 10), minute: parseInt(mm, 10) });

      const dateUTC = localDate.subtract(timeDecrease);
      return dateUTC.format('HH:mm');
    },

    timeUTCAdd(time, dateCurrent) {
      const timeAdd = moment.duration('03:00:00');
      let localDate = this.formatDate(dateCurrent);
      localDate = `${localDate} ${time}:00`;
      localDate = new Date(localDate);
      const dateUTC = moment(localDate.toISOString());
      dateUTC.add(timeAdd);
      return dateUTC.format('HH:mm');
    },

  },
};

export default dateUTC;
