<template>
  <div class="content">
    <div class="topo">
      <button type="button" class="btn ripple" @click="getBack">
        <i class="icone material-icons">
          keyboard_arrow_left
        </i>
      </button>
      <div class="title">
        {{ selectedProgram ? 'Selecionar praças' : 'Notificações' }}
      </div>
    </div>
    <!-- // -->

    <div v-if="!data" class="loading">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
      </svg>
    </div>
    <template v-else-if="selectedProgram">
      <div ref="middle" class="middle">
        <!-- Painel // Configurações -->
        <div class="panel inside">
          <div class="title">
            <div class="text">
              <span>
                {{ selectedProgram.program }}
              </span>
              <div class="actions">
                <button type="button"
                        class="btn btn_header ripple"
                        @click="updateProgram(selectedProgram.markets, true)"
                >
                  <i class="icone material-icons">
                    select_all
                  </i>
                </button>

                <button type="button"
                        class="btn btn_header ripple"
                        @click="updateProgram(selectedProgram.markets, false)"
                >
                  <i class="icone material-icons">
                    close
                  </i>
                </button>
              </div>
            </div>
            <div class="line" />
          </div>

          <div v-for="market in markets"
               :key="market"
               class="item"
               @click="toggelValue(selectedProgram.markets, market)"
          >
            <span class="category">
              {{ market }}
            </span>
            {{ marketName(market) }}

            <label class="switch">
              <input :checked="selectedProgram.markets[market]" type="radio">
              <span class="slider round ripple" />
            </label>
          </div>
        </div>
        <!-- // -->
      </div>

      <div class="slice small">
        <div class="feedback" :class="cssFeedBack">
          Ooops, tivemos um problema ao salvar suas preferências. Por favor, tente novamente :)
        </div>
        <button type="button" class="btn btn_full btn_secondary ripple"
                :disabled="isDisabled"
                @click="applyPreferences"
        >
          <svg v-if="isLoading" class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <template v-else>
            Aplicar
          </template>
        </button>
      </div>
    </template>
    <template v-else>
      <div ref="middle" class="middle">
        <template v-if="isExists">
          <div class="description">
            Escolha um período do dia para listar os programas disponíveis. Toque em um programa e selecione ao menos uma praça para habilitar as notificações.
          </div>

          <div v-if="periods" class="panel notification">
            <template v-for="(period, key) in periods">
              <div v-if="data[key]"
                   v-show="programExists(key) > 0"
                   :key="key"
                   :class="key"
              >
                <input :id="`period${key}`"
                       v-model="period.active"
                       :name="`accordion${key}`"
                       type="checkbox"
                >
                <label :for="`period${key}`">
                  <div class="segment-line" />
                  <div class="segment">
                    {{ period.name }}
                  </div>

                  <div class="signs">
                    <div class="item">
                      <i class="icone material-icons">tv</i> {{ programExists(key) }}
                    </div>

                    <div class="item">
                      <i class="icone material-icons">check_circle</i> {{ programSelected(key) }}
                    </div>
                  </div>

                  <i v-show="search === ''" class="icone material-icons">
                    {{ period.active ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
                  </i>
                </label>

                <template v-for="{ program, markets } in data[key]">
                  <div v-show="search === '' || matcher(program)"
                       :key="program"
                       class="dropdown"
                       :class="{ active: isSelected(markets) }"
                       @click="selectProgram(program, markets)"
                  >
                    <div class="content">
                      <div class="item">
                        <div class="line">
                          <div class="name">
                            {{ program }}
                          </div>
                          <div class="actions">
                            <i class="icone material-icons">
                              chevron_right
                            </i>
                          </div>
                        </div>
                      </div>
                      <!-- // -->
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <!-- // -->
          </div>
        </template>

        <!-- Empty State // Ghost -->
        <div v-else class="error-state">
          <div class="inside">
            <div class="notfound_item" />
            <div class="notfound_back" />
          </div>

          <div class="text">
            <div class="title">
              Ooops!
            </div>
            <div class="subtitle">
              <span>Não encontramos nenhum resultado para sua busca.</span>
              Por favor, tente pesquisar por outros termos ou navegue pelas opções da tela :)
            </div>
          </div>
        </div>
      </div>

      <div class="slice">
        <div class="search">
          <div class="item full">
            <i v-if="search === ''" class="icone material-icons">
              search
            </i>
            <i v-else class="icone material-icons" @click="search = ''">
              close
            </i>
            <input v-model="search" type="text" required="" @keyup.enter="$event.target.blur()">
            <span class="highlight" />
            <span class="bar" />
            <label>Pesquisar programas</label>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import bffRealtime from '@/gateways/bff-realtime';
import _ from 'lodash';
import { getToken } from '@/utils/firebase-notification';
import { event } from 'vue-gtag';

export default {
  data() {
    return {
      firebaseToken: null,
      data: null,
      originalData: null,
      isLoading: false,
      search: '',
      periods: {
        morning: { name: 'Manhã', active: false },
        afternoon: { name: 'Tarde', active: false },
        evening: { name: 'Noite', active: false },
        night: { name: 'Madrugada', active: false },
        archive: { name: 'Arquivado', active: false },
      },
      feedBackOpen: null,
      selectedProgram: null,
    };
  },
  computed: {
    ...mapState(['settings', 'market']),
    ...mapGetters(['currentUser']),
    isExists() {
      return Object.keys(this.periods).find(period => this.programExists(period) > 0);
    },
    isDisabled() {
      return _.isEqual(this.data, this.originalData);
    },
    cssFeedBack() {
      return {
        'feedback--show': this.feedBackOpen === true,
        'feedback--hide': this.feedBackOpen === false,
      };
    },
    markets() {
      const { marketIdOrder } = this.settings;
      const { markets } = this.selectedProgram;
      const orderd = marketIdOrder.filter(marketId => markets[marketId] !== undefined);
      return [
        ...orderd,
        ...Object.keys(markets).filter(marketId => orderd.indexOf(marketId) < 0),
      ];
    },
  },
  watch: {
    search(newVal, oldVal) {
      if ((newVal === '' && oldVal === '') || (newVal !== '' && oldVal !== '')) return;
      this.scrollToTop();
      Object.keys(this.periods).forEach((key) => {
        this.periods[key].active = newVal !== '';
      });
    },
  },
  beforeCreate() {
    event('enter-page', 'Notifications');
  },
  mounted() {
    this.$store.dispatch('hideMobileNavigation');
    // this.defineEnableBtns();
    this.setupNotification();
  },
  beforeDestroy() {
    this.$store.dispatch('showMobileNavigation');
    bffRealtime.cancelRequests('notification');
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
    getBack() {
      if (this.selectedProgram) {
        this.selectedProgram = null;
        this.scrollToTop();
      } else {
        this.goToPage('Settings');
      }
    },
    scrollToTop() {
      if (this.$refs.middle) this.$refs.middle.scrollTo(0, 0);
    },
    marketName(marketId) {
      const { exhibitionName } = this.market.markets.find(({ id }) => id === marketId);
      return exhibitionName;
    },
    matcher(match) {
      const string = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      const regex = new RegExp(string, 'ig');
      return regex.test(match.trim());
    },
    async setupNotification() {
      try {
        this.firebaseToken = await getToken();

        const { data } = await bffRealtime.getNotification(
          { platformToken: this.firebaseToken },
          bffRealtime.tokenRequest('notification', 'setupNotification'),
        );
        this.data = data;
        this.originalData = _.cloneDeep(data);
      } catch (error) {
        this.$store.dispatch('showError', { clicked: true });
      }
    },
    async applyPreferences() {
      this.isLoading = true;
      const programs = [];
      Object.keys(this.data).forEach((period) => {
        this.data[period].forEach(({ program, markets }) => {
          const selectedMarkets = [];
          Object.keys(markets).forEach((market) => {
            if (markets[market]) selectedMarkets.push(market);
          });

          if (selectedMarkets.length > 0) {
            programs.push({
              name: program,
              markets: selectedMarkets,
            });
          }
        });
      });

      try {
        await bffRealtime.updateNotification(
          { programs, platformToken: this.firebaseToken },
          bffRealtime.tokenRequest('notification', 'applyPreferences'),
        );
        event('user-click', 'notifications', 'applyBtn');
        this.originalData = _.cloneDeep(this.data);
        this.getBack();
      } catch (error) {
        this.animateValidationBox();
      }
      this.isLoading = false;
    },
    closeValidation() {
      if (this.feedBackOpen) {
        this.feedBackOpen = false;
      }
    },
    openValidation() {
      this.feedBackOpen = true;
    },
    animateValidationBox() {
      // Show the validation box -> start gradient animation -> close
      this.openValidation();
      clearTimeout(this.timerToHideValidation);
      this.isValidationShow = true;
      setTimeout(() => {
        this.isValidationShow = false;
        this.timerToHideValidation = setTimeout(() => {
          this.closeValidation();
        }, 3000);
      }, 750);
    },
    updateProgram(markets, value) {
      Object.keys(markets).forEach((market) => {
        markets[market] = value;
      });
    },
    selectProgram(program, markets) {
      this.feedBackOpen = null;
      this.selectedProgram = { program, markets };
      console.log(this.settings.marketIdOrder, markets, this.markets);
      this.scrollToTop();
    },
    toggelValue(markets, market) {
      markets[market] = !markets[market];
    },
    programExists(period) {
      if (!this.data[period]) return 0;

      if (this.search === '') {
        return this.data[period].length;
      }

      let count = 0;
      this.data[period].forEach(({ program }) => {
        if (this.matcher(program)) count += 1;
      });
      return count;
    },
    programSelected(period) {
      let count = 0;
      this.data[period].forEach(({ program, markets }) => {
        if (this.search === '' || this.matcher(program)) {
          if (Object.keys(markets).find(market => markets[market])) count += 1;
        }
      });
      return count;
    },
    isSelected(markets) {
      return Object.keys(markets).find(market => markets[market]);
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

// SMARTPHONES
@media (max-width: 600px) {

  .content {
    position: relative;
    z-index: 999;
    display: block;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    animation: fadeIn 0.8s ease-in-out forwards;

    // Loading
    .loading {
      z-index: 2;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color-gray-lighter, 0.8);

      .spinner {
        width: 40px;
        height: 40px;
        animation: rotator $duration linear infinite;

        .path {
          stroke-dasharray: $offset;
          stroke-dashoffset: 0;
          transform-origin: center;
          animation:
            dash $duration ease-in-out infinite,
            colors ($duration*4) ease-in-out infinite;
        }
      }
    }

    // -----------------------

    .topo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 64px;
      background: linear-gradient(140deg, $color-primary-light, $color-secondary);
      background-size: 100% 100%;
      box-shadow: 0 8px 16px rgba($color-gray-darker, 0.16);

      // Botão
      .btn {
        width: 56px;
        height: 56px;
        margin: 0;
        padding: 0;
        box-shadow: none;

        i {
            margin: 4px 0 0 0;
            padding: 0;
        }

        // Ripple Effect
        &.ripple {
          background-position: center;
          background: rgba($color-gray-back, 0);
          transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

          & i {
              color: $color-gray-lighter;
              transition: color 0.56s ease-in-out;
          }

          &:hover {
              background: rgba($color-gray-back, 0.04) radial-gradient(circle, transparent 1%, rgba($color-gray-back, 0.04) 1%) center/15000%;
              color: $color-gray-main;

              & i {
                  color: $color-gray-lighter;
              }
          }

          &:active {
              background-color: rgba($color-gray-lighter, 0.04);
              background-size: 100%;
              transition: background 0s;
          }
        }
        }
        // ------------

        .title {
            font-family: $secondary-typo;
            font-weight: 500;
            font-size: 0.88em;
            text-transform: uppercase;
            color: $color-gray-lighter;
            margin: 0 0 0 8px;
        }
    }
    // ---------------

    .middle {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0 0 140px 0;
        overflow-y: auto;
        background-color: $color-gray-back;

        .error-state {
          position: fixed;
          z-index: 2;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: calc(100% - 140px);
          overflow: hidden;
          background-color: $color-gray-back;
          animation: fadeIn 0.8s ease-in-out forwards;

          .inside {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin: auto 0 0 0;
            box-sizing: border-box;

            .notfound_back {
              position: relative;
              z-index: 1;
              background-image: url("~@/assets/img/nowifi-back.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              width: 100%;
              height: 100%;
              animation: fadeIn 1.6s ease-in-out forwards, pulse 1.6s ease-in-out infinite;
            }

            // Rocket Item
            .notfound_item {
              position: absolute;
              z-index: 3;
              top: 20%;
              width: 48%;
              height: 48%;
              background-image: url("~@/assets/img/ghost-item.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              animation: ghost 2.4s ease-in-out infinite;

              @keyframes ghost {
                0% {
                opacity: 1;
                transform: rotate(0);
                }

                40% {
                opacity: 1;
                transform: rotate(8deg);
                }

                80% {
                opacity: 1;
                transform: rotate(-8deg);
                }

                100% {
                opacity: 1;
                transform: rotate(0);
                }
              }
            }
          }

          .text {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            box-sizing: border-box;
            padding: 16px 24px;
            margin: 0 0 auto 0;

            .title,
            .subtitle {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              width: 100%;
              font-family: $secondary-typo;
              text-align: center;
            }

            .title {
              display: block;
              width: 100%;
              font-size: 1.4em;
              font-weight: 600;
              font-style: italic;
              text-transform: uppercase;
              color: $color-gray-dark;

              &::after {
                content: "";
                display: block;
                margin: 8px auto 0 auto;
                width: 24px;
                height: 2px;
                background-color: rgba(34, 34, 34, 0.16);
              }
            }

            .subtitle {
              font-size: 0.88em;
              font-weight: 400;
              font-style: italic;
              color: $color-gray-main;
              margin: 16px 0 0 0;

              span {
                display: block;
                width: 100%;
                font-weight: 600;
                margin: 0 0 8px 0;
              }
            }
          }
        }
        // ------------

        // Description
        .description {
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            padding: 16px;
            width: 100%;
            margin: 8px 0 16px 0;
            font-family: $secondary-typo;
            font-size: 0.88em;
            font-style: italic;
            text-align: justify;
            color: $color-gray-dark;
            animation: fadeIn 0.4s ease-in-out forwards;

            &::after {
                content: "";
                display: block;
                width: 24px;
                height: 4px;
                margin: 16px 0 0 0;
                background-color: rgba($color-gray-light, 0.4);
            }
        }
        // --------------

        // Panel
        .panel {
            display: block;
            width: calc(100% - 32px);
            box-sizing: border-box;
            padding: 8px 0;
            margin: 0 auto 16px auto;
            background: $color-gray-lighter;
            border-radius: 4px;
            animation: fadeIn 0.8s ease-in-out forwards;

            &.inside {
              margin: 24px auto;
            }

            // Accordion - Notificações
            &.notification {
              position: relative;
              width: 92%;
              margin: 0 auto 30px auto;
              text-align: left;
              background-color: $color-gray-back;

              label {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                height: 64px;
                padding: 0 16px;
                margin: 0 auto 8px auto;
                background-color: $color-gray-lighter;
                border-radius: 4px;
                border-bottom: solid 1px $color-gray-lighter;
                box-shadow: 0 2px 4px rgba($color-gray-dark, 0.08);
                transition: all 0.4s ease-in-out;

                .segment-line {
                  position: absolute;
                  top: 8px;
                  left: 0;
                  width: 4px;
                  height: 48px;
                  background-color: $color-gray-main;
                }

                .segment {
                  pointer-events: none;
                  font-family: $primary-typo;
                  font-size: 0.8em;
                  font-weight: normal;
                  text-transform: uppercase;
                  color: $color-gray-dark;
                }

                .signs {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  width: 32%;
                  margin: 0 0 0 auto;
                  font-family: $secondary-typo;
                  font-size: 1em;
                  pointer-events: none;

                  .item {
                    flex: 1;
                    padding: 0;
                    margin: 0;
                    font-weight: 600;
                    color: $color-gray-main;

                    i {
                      font-size: 1em;
                      padding: 0;
                      margin: 0 4px 0 0;
                      color: $color-gray-light;
                    }
                  }
                }

                i {
                  margin: 0 0 0 8px;
                  font-size: 1.2em;
                  color: rgba($color-gray-main, 0.56);
                }
              }

              input:checked + label,
              input:checked + label:hover {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                height: 64px;
                padding: 0 16px;
                margin: 0 auto 8px auto;
                border-radius: 4px 4px 0 0;
                border-bottom: solid 1px $color-gray-back;
              }

              input {
                display: none;
              }

              .dropdown {
                margin: -8px 0 8px 0;
                overflow: hidden;
                height: 0;
                min-height: 0;
                position: relative;
                z-index: 0;
                border: none;
                background-color: $color-gray-lighter;
                transition: all 0.4s ease-in-out;

                &.active {

                  .content {
                    .item {
                      .line {
                        .name {
                          color: $color-secondary;
                        }
                      }

                      .markets {
                        border-bottom: solid 2px rgba($color-secondary, 0.16);
                      }
                    }
                  }
                }

                .content {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: flex-start;
                  width: 100%;
                  height: 100%;
                  padding: 16px;
                  font-family: $secondary-typo;
                  font-size: 0.8em;
                  border-bottom: solid 1px $color-gray-back;

                  .item {
                    padding: 0;

                    .line {
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: space-between;
                      align-items: center;
                      width: 100%;

                      .name {
                        width: 88%;
                        font-family: $secondary-typo;
                        font-size: 1.1em;
                        font-style: italic;
                        font-weight: 400;
                        color: $color-gray-dark;
                        text-transform: uppercase;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                      }

                      .actions {

                        i {
                          position: relative;
                          display: flex;
                          flex-wrap: wrap;
                          align-items: center;
                          justify-content: center;
                          padding: 0;
                          margin: 0;
                          left: inherit;
                          top: inherit;
                          font-size: 2em;
                          color: $color-gray-light;
                        }
                      }
                    }
                  }
                }

                .markets {
                  display: block;
                  width: 100%;
                  box-sizing: border-box;
                  overflow-x: scroll;
                  white-space: nowrap;
                  margin: 16px 0 0 0;
                  padding: 0 0 16px 0;
                  border-bottom: solid 2px $color-gray-back;

                  // Botao
                  .btn {
                    display: inline-block;
                    width: 64px;
                    height: 48px;
                    margin: 0 8px 0 0;
                    border-radius: 4px;
                    box-shadow: none;
                    font-size: 1.2em;
                    font-weight: 500;
                    letter-spacing: 0.08em;
                    color: $color-gray-dark;

                    &:last-child {
                      margin-right: 0;
                    }

                    // Icons
                    i {
                      width: auto;
                      padding: 0;
                      margin: 0;
                      background-color: transparent;
                      font-size: 2em;
                      line-height: 2em;
                    }

                    // Text
                    &.day {
                      padding: 0;
                      margin: 0;
                      font-family: $primary-typo;
                      font-size: 1em;
                      text-align: center;
                      letter-spacing: 0;
                    }

                    &.selected {
                      &.ripple {
                        background-position: center;
                        background: $color-secondary;
                        border: solid 2px $color-secondary;
                        color: $color-gray-lighter;
                        transition: background 0.4s ease-in-out, opacity 0.8s ease-in-out, color 0.8s ease-in-out;

                        & i,
                        &.day {
                          color: rgba($color-gray-lighter, 1);
                          transition: color 0.4s ease-in-out;
                        }

                        &:hover {
                          border: solid 2px $color-secondary;
                          background: $color-secondary radial-gradient(circle, transparent 1%, $color-secondary 1%) center/15000%;

                          & i,
                          &.day {
                            color: $color-gray-lighter;
                          }
                        }

                        &:active {
                          background-color: rgba($color-secondary-dark, 0.64);
                          background-size: 100%;
                          transition: background 0s;
                        }
                      }
                    }
                    // --------
                    // --------

                    &.option {
                      &.ripple {
                        background-position: center;
                        background: $color-gray-lighter;
                        border: solid 2px rgba($color-secondary, 0.24);
                        color: rgba($color-secondary, 0.8);
                        transition: background 0.4s ease-in-out, opacity 0.8s ease-in-out, color 0.8s ease-in-out;

                        & i,
                        &.day {
                          color: rgba($color-secondary, 0.8);
                          transition: color 0.4s ease-in-out;
                        }

                        &:hover {
                          border: solid 2px rgba($color-secondary, 0.24);
                          background: $color-gray-back radial-gradient(circle, transparent 1%, $color-gray-back 1%) center/15000%;

                          & i,
                          &.day {
                            color: rgba($color-secondary, 0.8);
                          }
                        }

                        &:active {
                          background-color: rgba($color-gray-light, 1);
                          background-size: 100%;
                          transition: background 0s;
                        }
                      }
                    }
                    // --------
                    // --------
                  }
                }
              }

              input:checked ~ .dropdown {
                transition: all 0.4s ease-in-out;
                height: auto;
                animation: fadeIn 0.8s forwards;
              }

              // Periodos do Dia - Cores
              .morning {
                .segment-line {
                  background-color: $color-segment-morning;
                }
              }

              .afternoon {
                .segment-line {
                  background-color: $color-segment-afternoon;
                }
              }

              .evening {
                .segment-line {
                  background-color: $color-segment-evening;
                }
              }

              .night {
                .segment-line {
                  background-color: $color-segment-night;
                }
              }
            }
            // -------------
            // -------------

            .title {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              width: 100%;
              margin: 0 0 16px 0;
              padding: 8px 8px 16px 16px;
              border-bottom: solid 1px rgba($color-gray-light, 0.24);

              .text {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                span {
                  display: inline-block;
                  width: 60%;
                  font-family: $primary-typo;
                  font-size: 0.72em;
                  font-weight: 500;
                  text-transform: uppercase;
                  letter-spacing: 0.08em;
                  color: $color-gray-dark;

                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }

                .actions {
                  margin: 0;

                  .btn {
                    justify-content: center;
                    align-items: center;

                    i {
                      position: relative;
                      margin: 4px 0 0 0;
                      padding: 0;
                      top: inherit;
                      left: inherit;
                      color: $color-gray-dark;
                    }
                  }
                }

                i {
                  font-size: 1.4em;
                  margin: 0 8px 0 8px;
                  color: $color-gray-light;
                }
              }
            }

            // Itens
            .item {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              width: 100%;
              padding: 8px 16px;

              font-family: $secondary-typo;
              font-size: 0.88em;

              .category {
                background-color: $color-gray-back;
                padding: 4px;
                width: 48px;
                margin-right: 16px;
                text-align: center;
                font-weight: 600;
                font-size: 0.9em;
              }

              // Switch
              .switch {
                position: relative;
                display: inline-block;
                margin-left: auto;
                width: 48px;
                height: 28px;
                pointer-events: none;

                input {
                  opacity: 0;
                  width: 0;
                  height: 0;
                }

                .slider {
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  background-color: $color-gray-light;
                  transition: .4s;

                    &::before {
                      position: absolute;
                      content: "";
                      height: 22px;
                      width: 22px;
                      left: 3px;
                      bottom: 3px;
                      border-radius: 100%;
                      background-color: $color-gray-lighter;
                      box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
                      transition: .4s;
                    }

                    /* Rounded sliders */
                    &.round {
                      border-radius: 34px;
                    }
                }

                input:checked + .slider {
                  background-color: $color-secondary;
                }

                input:checked + .slider::before {
                  transform: translateX(20px);
                }
            }
        }
    }
    }
    // ---------------

    .slice {
        position: fixed;
        z-index: 3;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: $color-gray-lighter;
        animation: fadeInUp 0.8s ease-in-out forwards;

        &.small {
          height: 56px;
        }

        // Validation
        .feedback {
          position: absolute;
          bottom: 0;
          z-index: 4;
          width: 100%;
          height: 64px;
          opacity: 0;
          pointer-events: none;
          box-sizing: border-box;
          padding: 16px 14px;

          font-family: $secondary-typo;
          font-size: 0.8em;
          font-weight: 500;
          line-height: 1.4em;
          color: rgba($color-gray-lighter, 1);
          border-radius: 0;

          background: linear-gradient(233deg, #630d58, #a8203c);
          background-size: 100% 100%;

            // Show
            &.feedback--show {
              animation: fadeInUp 0.8s ease-in-out forwards;
            }

            // Hide
            &.feedback--hide {
              animation: fadeOutDown 0.8s ease-in-out forwards;
            }
        }

        .search {
          position: relative;
          z-index: 0;
          background: $color-gray-lighter;
          height: 100%;
          padding: 12px 16px;
          box-sizing: border-box;
          box-shadow: -2px 0 24px rgba($color-gray-dark, 0.16);

          // Configurações de itens de formulário
          .item {
            display: inline-block;
            position:relative;
            height: 100%;
            margin: 0 24px 8px 0;

            font-family: $secondary-typo;
            font-weight: 400;
            font-size: 0.88em;
            color: $color-gray-main;

            &.full {
              width: 100%;
            }

            i {
              position: absolute;
              top: 16px;
              font-size: 1.4em;
              right: 0;
            }

            input {
              display: block;
              width: 100%;
              border: none;
              padding: 16px 32px 8px 0;
              font-weight: 400;
              font-size: 1.2em;
              background: transparent;
              border-bottom: solid 1px $color-gray-light;

              &:focus {
                outline: none;
              }
            }

            label {
              position: absolute;
              pointer-events: none;
              font-style: italic;
              left: 0;
              top: 16px;
              transition: 0.2s ease all;
            }

            // Bottom Bars
            .bar {
              position: relative;
              display: block;
              width: 100%;

              &:before,
              &:after {
                position: absolute;
                bottom: 0;
                content: '';
                width: 0;
                height: 1px;
                background-color: $color-secondary;
                transition: 0.2s ease all;
              }

              &:before {
                left: 50%;
              }

              &:after {
                right: 50%;
              }
            }

            // Highlight
            .highlight {
              position: absolute;
              height: 45px;
              width: 100%;
              top: 0;
              left: 0;
              pointer-events: none;
              opacity: 0.24;
            }

            // Active State
            input:focus ~ label, input:valid ~ label 		{
              top: 0;
              font-size: 0.8em;
              color: $color-secondary;
            }

            input:focus ~ .bar:before,
            input:focus ~ .bar:after {
              width: 50%;
            }

            input:focus ~ .highlight {
              animation: inputHighlighter 0.4s ease;
            }

            // Animations
            @keyframes inputHighlighter {
              from {
                background: $color-secondary;
              }

              to {
                  width: 0;
                  background: transparent;
              }
            }
          }
        }

        .btn {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            bottom: 0;
            z-index: 1;
            border: none;

            &:disabled {
              opacity: 0.4;
              pointer-events: none;
            }

            &:hover {
              border: none;
            }

            .spinner {
              width: 24px;
              height: 24px;
              animation: rotator $duration linear infinite;

              .path {
                stroke-dasharray: $offset;
                stroke-dashoffset: 0;
                transform-origin: center;
                animation:
                  dash $duration ease-in-out infinite,
                  colors ($duration*4) ease-in-out infinite;
              }
            }
        }
    }
    // ---------------
  }
}

// iPhone 5
@media screen and (device-aspect-ratio: 40/71) {
  .content {

    .middle {

      .error-state {

        .inside {
          margin: 0 0 0 0;
        }

        .text {
          padding: 0 40px;
          margin: -56px 0 auto 0;

          .title {
            font-size: 1em;
          }

          .subtitle {
            font-size: 0.8em;
          }
        }
      }
    }
  }

}

</style>
