<template>
  <!-- Sidebar -->
  <div class="sidebar" :class="sideBarCss">
    <div class="overlay">
      <div class="content">
        <!-- Header -->
        <div class="header">
          <button type="button" name="open-sidebar" class="btn ripple" @click="openSideBar(false)">
            <i class="material-icons">keyboard_arrow_right</i>
          </button>
          <div class="brand">
            <div class="logo" />
            <div class="logotipo">REALTIME</div>
          </div>

          <div class="user">
            <div class="name">
              Olá,
              <span>{{ user.currentUser.name }}</span>
            </div>
            <div class="avatar" :style="{ backgroundColor: hslColor }">{{ initialsName }}</div>
          </div>
        </div>
        <!-- // -->

        <div class="buttons">
          <div class="item">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="goToPage('Home')"
            >
              <i class="material-icons">home</i>
              Home
            </button>
          </div>

          <div v-if="settings.isPermissionManager" class="item">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="goToPage('Users')"
            >
              <i class="material-icons">security</i>
              Autorização
            </button>
          </div>

          <div v-if="settings.isProgramRegister" class="item">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="goToPage('Programs')"
            >
              <i class="material-icons">tv</i>
              Cadastro de Programas
            </button>
          </div>

          <div class="item hide-lg">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="openChart()"
            >
              <i class="material-icons">show_chart</i>
              Desempenho Histórico
            </button>
          </div>

          <div class="item hide-lg">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="openUserPreferences()"
            >
              <i class="material-icons">tune</i>
              Preferências
            </button>
          </div>

          <div v-if="isButtonCsvEnabled" class="item hide-lg">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="downloadCsv()"
            >
              <i class="material-icons">file_download</i>
              Download
            </button>
          </div>

          <div v-if="!user.isSimulateUser" class="item">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="logout()"
            >
              <i class="material-icons">power_settings_new</i>
              Sair
            </button>
          </div>

          <div v-else class="item">
            <button
              type="button"
              name="open-sidebar"
              class="btn btn_full btn--icon_outline ripple"
              @click="exitSimulateUser()"
            >
              <i class="material-icons">power_settings_new</i>
              Sair da Simulação
            </button>
          </div>
        </div>
        <!-- // -->

        <div class="footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import initials from "initials";
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {
      isOpen: null
    };
  },
  computed: {
    ...mapState(["user", "settings"]),
    ...mapGetters(["currentUser"]),
    sideBarCss() {
      return {
        in: this.isOpen,
        out: this.isOpen === false
      };
    },
    initialsName() {
      const { name, email } = this.currentUser;
      return initials(name ? name : email)
        .toUpperCase()
        .substr(0, 2);
    },
    hslColor() {
      const { name } = this.currentUser;
      return this.stringToHslColor(name, 100, 20);
    },
    isButtonCsvEnabled() {
      return this.isInHomePage;
    },
    isInHomePage() {
      return this.$route.name === "Home";
    }
  },
  methods: {
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      const h = hash % 360;
      return `hsl(${h},${s}%,${l}%)`;
    },
    openSideBar(isOpen) {
      this.isOpen = isOpen;
    },
    goToPage(name) {
      this.openSideBar(false);
      this.$router.push({ name });
    },
    async logout() {
      await this.$store.dispatch("logout");
    },
    exitSimulateUser() {
      this.$store.dispatch("exitSimulateUser");
      this.$router.push({ name: "Users" });
      this.$router.go();
    },
    openUserPreferences() {
      this.openSideBar(false);
      this.$store.dispatch("openUserPreferences");
    },
    openChart() {
      this.openSideBar(false);
      this.$modal.show("chart");
    },
    downloadCsv() {
      this.openSideBar(false);
      this.$modal.show("export");
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.sidebar {
  display: inline-block;
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 100vh;

  font-family: $secondary-typo;
  font-size: 1em;
  font-weight: 400;

  pointer-events: none;

  .overlay {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    background-color: rgba($color-gray-darker, 0.8);
    opacity: 0;

    .content {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      position: relative;
      width: 500px;
      height: 100vh;
      padding: 0;
      background-color: $color-gray-back;
      box-shadow: -24px 0 80px rgba($color-gray-darker, 0.4);
      overflow-y: auto;
      opacity: 0;

      // Header
      .header {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        z-index: 1;
        width: 100%;
        height: 80px;
        padding: 0 16px 0 8px;
        background-color: $color-gray-back;
        box-shadow: 0 8px 24px rgba($color-gray-darker, 0.08);

        .btn {
          box-shadow: none;
          background-color: $color-gray-back;

          i {
            margin-top: 4px;
          }

          // Ripple Effect
          &.ripple {
            background-position: center;
            transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out;

            & i {
              color: $color-gray-main;
              transition: color 0.56s ease-in-out;
            }

            &:hover {
              background: $color-gray-back
                radial-gradient(circle, transparent 1%, $color-gray-back 1%)
                center/15000%;
              color: $color-secondary;

              & i {
                color: $color-secondary;
              }
            }

            &:active {
              background-color: transparent;
              background-size: 100%;
              transition: background 0s;
            }
          }
          // -------------
        }

        // Brand
        .brand {
          display: flex;
          align-items: center;
          position: static;
          z-index: 1;
          top: 0;
          left: 0;
          width: 140px;
          height: 80px;
          margin: 0;
          background-color: $color-gray-back;
          box-shadow: none;
          transition: none;
          overflow: hidden;

          &::after {
            display: none;
          }

          &:hover {
            box-shadow: none;
          }

          // Sidebar Button {
          .btn {
            display: none;
          }

          // Logo
          .logo {
            @include backLogin($icon-primary);
            background-position: center;
            background-size: 64% 64%;
            width: 72px;
            height: 100%;
            margin: 0;
            transition: background 0.8s ease-in-out,
              background-size 0.8s ease-in-out;
            cursor: pointer;
            overflow: hidden;

            &:hover {
              @include backLogin($icon-primary);
              background-position: center;
              background-size: 64% 64%;
              width: 72px;
              height: 100%;
              margin: 0;
              cursor: pointer;
            }
          }

          // Logotipo
          .logotipo {
            display: flex;
            align-items: center;
            height: 80px;
            margin: 0;
            font-family: $primary-typo;
            font-size: 1em;
            font-weight: normal;
            color: $color-gray-dark;
            text-transform: uppercase;
          }
        }
        // ----------------

        // User Actions
        .user {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          margin-left: auto;

          // Avatar
          .avatar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            border-radius: 100px;
            background-color: $color-gray-darker;
            box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
            font-size: 0.8em;
            font-family: $primary-typo;
            color: $color-gray-lighter;
          }
          // -----------------

          .name {
            width: 140px;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 16px;
            font-family: $secondary-typo;
            font-size: 0.88em;
            font-weight: 400;
            font-style: italic;
            color: $color-gray-main;
            text-align: right;
            text-overflow: ellipsis;

            span {
              font-weight: 500;
              color: $color-gray-dark;
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 50%;
          height: 100%;
          margin: 0 0 40px auto;
          padding: 0;

          .btn {
            width: 40%;
            height: 40px;

            &:first-child {
              margin-right: 16px;
            }
          }
        }
      }
      // -------

      // Itens
      .buttons {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 92%;
        margin: 0 0 auto 44px;

        .item {
          display: inline-block;
          width: 100%;
          height: 56px;
          margin: 16px 0;

          .btn {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;
            padding: 8px;
            margin: 0 8px;
            border-radius: 4px;
            box-shadow: none;
            background-color: $color-gray-back;
            font-weight: 500;
            color: $color-gray-dark;

            i {
              width: 40px;
              margin: 0 8px 0 0;
            }

            // Ripple Effect
            &.ripple {
              background-position: center;
              box-shadow: 0 4px 16px rgba($color-gray-darker, 0);
              transition: background 0.56s ease-in-out, opacity 0.8s ease-in-out,
                box-shadow 0.8s ease-in-out;

              & i {
                color: $color-gray-main;
                transition: color 0.56s ease-in-out;
              }

              &:hover {
                background: $color-gray-lighter
                  radial-gradient(
                    circle,
                    transparent 1%,
                    $color-gray-lighter 1%
                  )
                  center/15000%;
                box-shadow: 0 4px 16px rgba($color-gray-darker, 0.08);
                color: $color-secondary;

                & i {
                  color: $color-secondary;
                }
              }

              &:active {
                background-color: rgba($color-gray-light, 0.08);
                background-size: 100%;
                transition: background 0s;
              }
            }
            // -------------
          }

          // Remoção dos botões de ação do usuário
          &.hide-lg {
            display: none;
          }
          // ------
        }
      }

      .footer {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 80%;
        margin: auto 0 80px auto;
        opacity: 0.64;
        transition: opacity 0.4s ease-in-out;

        img {
          margin: 0 16px 0 0;
        }
      }
    }
  }

  // Entrada
  &.in {
    pointer-events: visible;

    .overlay {
      animation: fadeIn 1s ease-in-out forwards;

      .content {
        animation-delay: 0.5s !important;
        animation: fadeInRight 0.8s ease-in-out forwards;

        .buttons {
          animation: fadeInRight 1.6s ease-in-out forwards;
        }
      }
    }
  }

  // Saída
  &.out {
    pointer-events: none;

    .overlay {
      opacity: 1;
      animation-delay: 0.4s !important;
      animation: fadeOut 1s ease-in-out forwards;

      .content {
        opacity: 1;
        animation: slideOutRight 0.8s ease-in-out forwards;
      }
    }
  }
  // -------------
}
// ------------------------------------

// Tratamento do footer para o sidebar, em alturas menores que 580px ele desaparece.
@media (max-height: 580px) {
  .sidebar {
    .overlay {
      .content {
        .footer {
          opacity: 0;
        }
      }
    }
  }
}

// Second Break : 850px
@media (max-width: 850px) {
  .sidebar {
    .overlay {
      .content {
        .buttons {
          .item {
            // Adição dos botões de ação do usuário
            &.hide-lg {
              display: block;
            }
          }
        }
      }
    }
  }
}
</style>
