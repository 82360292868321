import Vue from 'vue';
import VueRouter from 'vue-router';
import System from '@/components/System.vue';
import Home from '@/components/system/container/Home.vue';
import Dashboards from '@/components/system/container/Dashboards.vue';
import Notifications from '@/components/system/container/Notifications.vue';
import Settings from '@/components/system/container/Settings.vue';
import MarketPreferences from '@/components/system/container/Settings/MarketPreferences.vue';
import TvNetworkPreferences from '@/components/system/container/Settings/TvNetworkPreferences.vue';
import CustomProgramsMarketsPreferences from '@/components/system/container/Settings/CustomProgramsMarketsPreferences.vue';
import KpiPreferences from '@/components/system/container/Settings/KpiPreferences.vue';
import NetworkError from '@/components/system/NetworkError.vue';
import PageNotFound from '@/components/system/PageNotFound.vue';

// Contrl Panel
import ControlPanel from '@/components/system/container/ControlPanel.vue';
import Users from '@/components/system/container/ControlPanel/Users.vue';
import PermissionGroups from '@/components/system/container/ControlPanel/PermissionGroups.vue';
import Programs from '@/components/system/container/ControlPanel/Programs.vue';

// Fullscreen
import Fullscreen from '@/components/Fullscreen.vue';
import EmadRealtimeTables from '@/components/system/container/Home/EmadRealtimeTables.vue';
import RealtimeProgramsTables from '@/components/system/container/Home/RealtimeProgramsTables.vue';
import SimulcastTable from '@/components/system/container/Home/SimulcastTable.vue';

Vue.use(VueRouter);

export default new VueRouter({
  routes: [
    {
      path: '',
      component: System,
      children: [
        {
          name: 'Home',
          path: '',
          component: Home,
        },
        {
          name: 'Dashboards',
          path: 'dashboards',
          component: Dashboards,
        },
        {
          name: 'Notifications',
          path: 'notifications',
          component: Notifications,
        },
        {
          name: 'Settings',
          path: 'settings',
          component: Settings,
        },
        {
          name: 'MarketPreferences',
          path: 'settings/market_preferences',
          component: MarketPreferences,
        },
        {
          name: 'TvNetworkPreferences',
          path: 'settings/tv_network_preferences',
          component: TvNetworkPreferences,
        },
        {
          name: 'CustomProgramsMarketsPreferences',
          path: 'settings/custom_programs_markets_preferences',
          component: CustomProgramsMarketsPreferences,
        },
        {
          name: 'KpiPreferences',
          path: 'settings/kpi_preferences',
          component: KpiPreferences,
        },
        {
          name: 'ControlPanel',
          path: 'control_panel',
          component: ControlPanel,
          children: [
            {
              name: 'PermissionGroups',
              path: 'permission_groups',
              component: PermissionGroups,
            },
            {
              name: 'Users',
              path: 'users',
              component: Users,
            },
            {
              name: 'Programs',
              path: 'programs',
              component: Programs,
            },
          ],
        },
      ],
    },
    {
      path: '/fullscreen',
      component: Fullscreen,
      children: [
        {
          name: 'EmadRealtimeTables',
          path: 'minutes',
          component: EmadRealtimeTables,
        },
        {
          name: 'RealtimeProgramsTables',
          path: 'programs',
          component: RealtimeProgramsTables,
        },
        {
          name: 'SimulcastTable',
          path: 'simulcast',
          component: SimulcastTable,
        },
      ],
    },
    {
      name: 'NetworkError',
      path: 'error',
      component: NetworkError,
    },
    {
      path: '*',
      component: PageNotFound,
    },
  ],
});
