<template>
  <div class="program_vision">
    <!-- Swiper -->
    <!-- {{ covers }} -->
    <div class="esteira swiper-container">
      <div class="swiper-wrapper" />
    </div>
    <!-- // -->
  </div>
</template>

<script>
import anime from 'animejs';
import Swiper from 'swiper/dist/js/swiper.min';
import 'swiper/dist/css/swiper.min.css';
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import globoImg from '../../../../../static/tv-network/brand-globo-white.png';
import bffRealtime from '@/gateways/bff-realtime';

export default {
  props: {
    globoPrograms: {
      type: Array,
      default: () => [],
    },
    lastMinute: {
      type: String,
      default: '',
    },
    lastMinutePreviousProgram: {
      type: Number,
      default: null,
    },
    lastMinutePreviousClass: {
      type: String,
      default: '',
    },
    lastMinuteSelectedProgram: {
      type: Number,
      default: null,
    },
    lastMinuteSelectedClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      covers: [],
      swiper: null,
      indexSelected: 0,
      prevIndex: null,
      kpiSelectedProgramLastMinute: null,
      kpiSelectedProgram: null,
      lastMarketIdSelected: null,
      values: {},
    };
  },
  computed: {
    marketSelected() {
      return this.settings.market;
    },
    previousProgram() {
      const previousIndex = this.indexSelected - 1;
      return this.globoPrograms[previousIndex];
    },
    selectedProgram() {
      const { indexSelected, globoPrograms } = this;

      return {
        indexSelected,
        ...globoPrograms[indexSelected],
      };
    },
    nextProgram() {
      const nextIndex = this.indexSelected + 1;
      return this.globoPrograms[nextIndex];
    },
    allPrograms() {
      return this.isToday ? [...this.globoPrograms, this.futureProgram] : this.globoPrograms;
    },
    isToday() {
      const { day, endTime } = this.settings.dateOptions;
      return day === 0 && !endTime;
    },
    currentProgram() {
      if (!this.globoPrograms || !this.isToday) return null;
      return this.globoPrograms[this.globoPrograms.length - 1];
    },
    futureProgram() {
      if (!this.currentProgram) return null;
      const unknowProgram = {
        program: 'Em breve',
        startsIn: this.currentProgram.endsIn,
        marketName: this.settings.market.name,
      };
      // the next line is not necessary when the back pass numSeqExib in programAverages
      const futureProgram = this.getNextProgram(this.currentProgram) || unknowProgram;
      return futureProgram;
    },
    isFutureProgramExists() {
      return this.futureProgram && this.futureProgram.endsIn;
    },
    isSelectedInCurrentProgram() {
      return this.indexSelected === this.globoPrograms.length - 1;
    },
    isSelectedInFirstProgram() {
      return this.indexSelected === 0;
    },
    ...mapState(['settings']),
    ...mapGetters(['programByDate', 'getNextProgram', 'programAverages', 'programImg', 'singleDefaultValue']),
  },
  watch: {
    globoPrograms(newVal, oldVal) {
      if (!newVal || !oldVal) return;
      this.updateIndexSelected(newVal, oldVal);
    },
    selectedProgram() {
      this.updateValues();
    },
    lastMinutePreviousProgram(newVal) {
      if (newVal) this.updateValues();
    },
    lastMinuteSelectedProgram(newVal) {
      if (newVal) this.updateValues();
    },
  },
  mounted() {
    this.initSwiper();
    this.indexSelected = (this.isToday ? this.globoPrograms.length - 1 : 0);
    this.remountEverything();
  },
  methods: {
    kpiDefaultValue(kpi) {
      const valueKey = {
        audience: 'audience',
        shareTL: 'share',
        shareTLE: 'shareE',
      }[this.singleDefaultValue];
      return kpi[valueKey];
    },
    openTipProgramVision() {
      this.$refs.tipProgramVision.open();
    },
    programStatusCss(program) {
      if (!program
        || !program.tvNetworkWinners
        || !program.tvNetworkWinners[this.settings.market.id]
      ) return {};
      const winners = program.tvNetworkWinners[this.settings.market.id];
      const hasGloboInWinners = winners.includes('GLOBO');
      const hasMoreThanOneWinner = winners.length > 1;
      return {
        won: hasGloboInWinners && !hasMoreThanOneWinner,
        drew: hasGloboInWinners && hasMoreThanOneWinner,
        lost: !hasGloboInWinners,
      };
    },
    kpiStatusCss(program, kpi) {
      if (!program || !kpi) return {};
      const points = this.getProgramValue(program) - kpi;
      return {
        won: points > 0,
        drew: points === 0,
        lost: points < 0,
      };
    },
    updateIndexSelected(newVal, oldVal) {
      const marketChanged = (this.lastMarketIdSelected !== this.marketSelected.id);
      const forceUpdate = (newVal.length !== oldVal.length || marketChanged);
      if (forceUpdate) {
        if (marketChanged) {
          const { startsIn } = oldVal[this.indexSelected];
          const programs = newVal.map((globoProgram, index) => ({
            index,
            diff: Math.abs(moment(globoProgram.startsIn).diff(startsIn, 'minutes')),
          }));

          this.indexSelected = _.orderBy(programs, ['diff'], ['asc'])[0].index;
        } else {
          const wasInTheLastSlide = (this.indexSelected === oldVal.length - 1);
          const defaultIndexDay = (this.isToday ? newVal.length - 1 : 0);
          this.indexSelected = (wasInTheLastSlide) ? defaultIndexDay : this.indexSelected;
        }
        this.remountEverything();
      }
    },
    getProgramValue(program) {
      if (!program
        || !program.averages
        || !program.averages[this.marketSelected.id]
        || !program.averages[this.marketSelected.id].GLOBO
      ) return null;
      return program.averages[this.marketSelected.id].GLOBO[this.singleDefaultValue];
    },
    percentPoints(program, kpi) {
      if (!program) return null;

      const programValue = this.getProgramValue(program);
      if (!programValue || !kpi) return null;

      return (100 * programValue / kpi) - 100;
    },
    diffPoints(program, kpi) {
      if (!program) return null;

      const programValue = this.getProgramValue(program);
      if (!programValue || !kpi) return null;

      return programValue - kpi;
    },
    remountEverything() {
      this.prevIndex = null;
      this.lastMarketIdSelected = this.marketSelected.id;
      this.removeAllSlide();
      this.appendAllPrograms(this.indexSelected);
      this.goToSlide(this.indexSelected);
    },
    slidePrimaryClassCss(selectedIndex, index) {
      if (selectedIndex > index) return 'past';
      if (selectedIndex === index) return 'now';
      return 'future';
    },
    slideSecondaryClassCss(index) {
      if (index === this.globoPrograms.length - 1) return 'current';
      if (index === this.allPrograms.length - 1) return 'last';
      return '';
    },
    getSlideClassName(selectedIndex, index) {
      const primaryClass = this.slidePrimaryClassCss(selectedIndex, index);
      const secondaryClass = this.slideSecondaryClassCss(index);
      return `${primaryClass} ${secondaryClass}`;
    },
    appendAllPrograms(indexSelected) {
      this.allPrograms.forEach((program, index) => {
        const imgUrl = this.programImg(program);
        const className = this.getSlideClassName(indexSelected, index);
        const programName = program && program.program ? program.program : null;
        this.appendSlide(className, imgUrl, index, programName);
      });
    },
    liveProgramHtmlTemplate() {
      return `
        <div class="now">
          <i class="material-icons">
            fiber_manual_record
          </i>
          No Ar
        </div>
      `;
    },
    programNameOnCoverHtmlTemplate(programName) {
      if (!programName) return '';

      return `
        <div class="program-name">
          ${programName}
        </div>
      `;
    },
    slideHtmlTemplate(className, imgUrl, index, programName) {
      const isLive = index === (this.globoPrograms.length - 1) && this.isToday;
      const programHasNoImage = imgUrl.includes('/default.jpg');

      return `
        <div class="cover" style="background-image: url('${imgUrl}')">
          ${isLive ? this.liveProgramHtmlTemplate() : ''}
          ${programHasNoImage ? this.programNameOnCoverHtmlTemplate(programName) : ''}
        </div>
      `;
    },
    slideTapumeHtmlTemplate(className, index) {
      const isLive = index === (this.globoPrograms.length - 1) && this.isToday;
      const feedbackText = 'A imagem será carregada assim que o programa entrar no ar';
      const programTime = moment(this.allPrograms[index].startsIn).format('HH:mm');
      return `
        <div class="placeholder">
          ${isLive ? this.liveProgramHtmlTemplate() : ''}
          <div class="brand">
            <img src="${globoImg}"/>
          </div>
          <div class="program-name">
            ${this.allPrograms[index].program}
          </div>
          <div class="time">
            ${programTime}
          </div>
          <div class="feedback">
            ${feedbackText}
          </div>
          <div class="icon">
            <i class="icone material-icons">
              broken_image
            </i>
          </div>
        </div>
      `;
    },
    appendSlide(className, imgUrl, index, programName) {
      const isLive = index === (this.globoPrograms.length - 1) && this.isToday;

      const contentTemplate = (imgUrl)
        ? this.slideHtmlTemplate(className, imgUrl, index, programName)
        : this.slideTapumeHtmlTemplate(className, index);
      const programTime = moment(this.allPrograms[index].startsIn).format('HH:mm');
      const slideTemplate = `
        <div id="slide${index}" class="swiper-slide ${className}">
          ${contentTemplate}
          <div class="program-time">${programTime}</div>

          <div class="slots">
            <div class="delivery">
              <div class="loading">
                <div class="loader">
                  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"
                    />
                  </svg>
                </div>
              </div>
              <div class="label">recebeu</div>
              <div class="value">--</div>
            </div>

            <div class="average">
              <div class="loading">
                <div class="loader">
                  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"
                    />
                  </svg>
                </div>
              </div>
              <div class="label">média</div>
              <div class="value">--</div>
            </div>

            <div class="kpi">
              <div class="loading">
                <div class="loader">
                  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"
                    />
                  </svg>
                </div>
              </div>
              <div class="label">Média Histórica</div>
              <div class="value">--</div>
              <div class="tag">${this.settings.performanceHistory} semanas</div>
            </div>

            <div class="delivery ${isLive ? 'history' : 'entrega'}">
              <div class="loading">
                <div class="loader">
                  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"
                    />
                  </svg>
                </div>
              </div>
              <div class="label">${isLive ? 'entrega histórica' : 'entrega'}</div>
              <div class="value">--</div>
            </div>
          </div>
        </div>
      `;
      this.swiper.appendSlide(slideTemplate);
    },
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
        direction: 'vertical',
        slidesPerView: 2,
        spaceBetween: 8,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
      this.$emit('swiper', this.swiper);
      this.swiper.on('slideChangeTransitionStart', this.onSlideChange);
    },
    changeCssOfAllSlides(indexSelected) {
      this.allPrograms.forEach((program, index) => {
        const className = this.getSlideClassName(indexSelected, index);
        const element = document.getElementById(`slide${index}`);
        if (element) element.className = `swiper-slide ${className}`;
      });
    },
    onSlideChange() {
      // the last slide can't be active
      if (this.swiper.activeIndex >= this.globoPrograms.length) {
        return this.goToSlide(this.globoPrograms.length - 1);
      }

      if (this.swiper.activeIndex === this.prevIndex) {
        return null;
      }

      this.prevIndex = this.swiper.activeIndex;
      this.indexSelected = this.swiper.activeIndex;
      bffRealtime.cancelRequests('programVision');
      this.setKpis();
      this.$emit('on-selected-program-change', this.selectedProgram);
      return this.changeCssOfAllSlides(this.indexSelected);
    },
    setKpis() {
      this.setKpiSelectedProgramLastMinute();
      this.setKpiSelectedProgram();
    },
    updateSwipe() {
      this.swiper.update();
    },
    removeAllSlide() {
      this.swiper.removeAllSlides();
    },
    goToSlide(index) {
      this.swiper.slideTo(index);
      if (index === 0) {
        this.$emit('on-selected-program-change', this.selectedProgram);
      }
    },
    async setKpiSelectedProgramLastMinute() {
      this.kpiSelectedProgramLastMinute = null;
      if (!this.selectedProgram) return;
      this.kpiSelectedProgramLastMinute = await this.getKpiProgramLastMinute(this.selectedProgram);
    },
    async setKpiSelectedProgram() {
      this.kpiSelectedProgram = null;
      if (!this.selectedProgram) return;
      this.kpiSelectedProgram = await this.getKpiProgram(this.selectedProgram);
    },
    async getKpiProgram(program) {
      const params = {
        startDateTime: program.startsIn,
        endDateTime: program.endsIn,
        tvNetworks: 'GLOBO',
        markets: this.marketSelected.id,
        historicMark: this.settings.performanceHistory,
        compiledAvg: true,
      };
      try {
        const { data } = await bffRealtime.getKpiConsolidateByRangeTime(
          params, bffRealtime.tokenRequest('programVision', `kpiConsolidateByRangeTime${program.startsIn}${program.endsIn}`),
        );
        const kpi = data[0].markets[0].tvNetworksAudiences[0];
        return this.kpiDefaultValue(kpi);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getKpiProgramLastMinute(program) {
      const params = {
        dateTime: program.endsIn,
        tvNetworks: 'GLOBO',
        markets: this.marketSelected.id,
        historicMark: this.settings.performanceHistory,
      };
      try {
        const { data } = await bffRealtime.getKpiConsolidateByTime(
          params, bffRealtime.tokenRequest('programVision', `kpiConsolidateByTime${program.endsIn}`),
        );
        const kpi = data.markets[0].tvNetworksAudiences[0];
        return this.kpiDefaultValue(kpi);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    updateValues() {
      const { indexSelected } = this;
      if (!this.values[indexSelected]) {
        this.values[indexSelected] = {
          lastMinutePreviousProgram: 0,
          average: 0,
          diffPoints: 0,
          percentPoints: 0,
          kpiSelectedProgramLastMinute: 0,
          lastMinuteSelectedProgram: 0,
        };
      }
      const values = this.values[indexSelected];

      const nowElement = document.querySelector('.now');
      const delivery = nowElement.querySelector('.delivery');
      const average = nowElement.querySelector('.average');
      const kpi = nowElement.querySelector('.kpi');
      const history = nowElement.querySelector('.history');
      const entrega = nowElement.querySelector('.entrega');

      anime({
        targets: values,
        lastMinutePreviousProgram:
          this.lastMinutePreviousProgram || values.lastMinutePreviousProgram,
        average: this.getProgramValue(this.selectedProgram) || this.getProgramValue(this.previousProgram),
        diffPoints: this.diffPoints(this.selectedProgram, this.kpiSelectedProgram) || values.diffPoints,
        percentPoints: this.percentPoints(this.selectedProgram, this.kpiSelectedProgram) || values.percentPoints,
        kpiSelectedProgramLastMinute:
          this.kpiSelectedProgramLastMinute || values.kpiSelectedProgramLastMinute,
        lastMinuteSelectedProgram:
          this.lastMinuteSelectedProgram || values.lastMinuteSelectedProgram,
        duration: 500,
        easing: 'linear',
        update: () => {
          if (indexSelected !== this.indexSelected) return;
          delivery.querySelector('.value').innerText = values.lastMinutePreviousProgram.toFixed(2);
          average.querySelector('.value').innerText = values.average.toFixed(2);

          const diffPoints = values.diffPoints.toFixed(2);
          const percentPoints = values.percentPoints.toFixed(0);
          const showPercent = this.singleDefaultValue === 'audience';
          kpi.querySelector('.value').innerText = (showPercent)
            ? `${diffPoints}pts ${percentPoints}%`
            : `${diffPoints}pts`;
          if (history) {
            history.querySelector('.value').innerText = values.kpiSelectedProgramLastMinute.toFixed(2);
          } else {
            entrega.querySelector('.value').innerText = values.lastMinuteSelectedProgram.toFixed(2);
          }
        },
        complete: () => {
          if (indexSelected !== this.indexSelected) return;

          this.setClassList(delivery.querySelector('.value'), {
            won: this.lastMinutePreviousClass === 'won',
            drew: this.lastMinutePreviousClass === 'drew',
            lost: this.lastMinutePreviousClass === 'lost',
          });
          this.setClassList(average, (this.getProgramValue(this.selectedProgram))
            ? this.programStatusCss(this.selectedProgram) : this.programStatusCss(this.previousProgram));
          this.setClassList(kpi, this.kpiStatusCss(this.selectedProgram, this.kpiSelectedProgram));
          if (history) {
            // this.setClassList(history.querySelector('.value'), this.kpiStatusCss(this.selectedProgram, this.kpiSelectedProgramLastMinute));
          } else {
            this.setClassList(entrega.querySelector('.value'), {
              won: this.lastMinuteSelectedClass === 'won',
              drew: this.lastMinuteSelectedClass === 'drew',
              lost: this.lastMinuteSelectedClass === 'lost',
            });
          }

          if (this.selectedProgram) {
            average.querySelector('.loading').style.display = 'none';
          }
          if (this.kpiSelectedProgram) {
            kpi.querySelector('.loading').style.display = 'none';
          }
          if (this.lastMinutePreviousProgram) {
            delivery.querySelector('.loading').style.display = 'none';
          }
          if (history && this.kpiSelectedProgramLastMinute) {
            history.querySelector('.loading').style.display = 'none';
          } else if (entrega && this.lastMinuteSelectedProgram) {
            entrega.querySelector('.loading').style.display = 'none';
          }
        },
        delay: 0,
        round: null,
      });
    },
    setClassList(element, list) {
      if (!element || !list) return;

      Object.keys(list).forEach((className) => {
        if (list[className]) {
          element.classList.add(className);
        } else {
          element.classList.remove(className);
        }
      });
    },
  },
};
</script>

<style lang="scss">

  @import '@/assets/scss/variables.scss';

// SMARTPHONES
@media (max-width: 600px) {

  .dashboard {
    .program_vision {

      .esteira {
        padding: 0 16px;
        background-color: $color-gray-back;

        &.swiper-container {
          margin: 0;
          height: 100%;

          .swiper-slide {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            background: $color-gray-lighter;
            box-shadow: 0 4px 8px rgba($color-gray-dark, 0.08);
            border-radius: 4px;
            padding: 0;

            .cover {
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              padding: 8px;
              z-index: 2;
              width: 70%;
              height: 100%;
              background-size: cover;
              border-radius: 4px 0 0 4px;
              background-color: $color-secondary;
              box-shadow: 4px 4px 16px rgba($color-gray-dark, 0.16);

              .program-name {
                background-color: #fff;
                border-radius: 4px;
                box-shadow: 0px 4px 24px rgba($color-gray-dark, 0.56);
                padding: 10px;
                font-family: "Roboto";
                font-size: 13px;
                font-style: italic;
                font-weight: 600;
                text-transform: uppercase;
                color: $color-gray-dark;
              }
            }

            .slots {
              position: relative;
              display: flex;
              flex-wrap: wrap;
              flex-direction: row;
              align-content: space-between;
              width: 30%;
              height: 100%;
              margin: 0;
              bottom: inherit;

              .delivery,
              .average,
              .kpi {
                position: relative;
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                width: 100%;
                border-radius: 0;

                // Loading
                .loading {
                  z-index: 2;
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  align-items: center;
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  background-color: rgba($color-gray-lighter, 0.8);
                  border-radius: 0;

                  &.in {
                    animation: fadeIn 0.4s ease-in-out forwards;
                  }

                  &.out {
                    animation: fadeOut 0.4s ease-in-out forwards;
                  }

                  .loader {
                    width: 24px;
                    height: 24px;

                    .spinner {
                      width: 100%;
                      height: 100%;
                      animation: rotator $duration linear infinite;
                    }

                    .path {
                      stroke-dasharray: $offset;
                      stroke-dashoffset: 0;
                      transform-origin: center;
                      animation:
                        dash $duration ease-in-out infinite,
                        colors ($duration*4) ease-in-out infinite;
                    }
                  }
                }
                // ------

                .label {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: center;
                  width: 100%;
                  font-family: $secondary-typo;
                  font-size: 0.32em;
                  font-style: italic;
                  color: $color-gray-main;
                  text-align: center;
                  text-transform: uppercase;
                }

                .value {
                  width: 100%;
                  font-family: $primary-typo;
                  font-size: 0.8em;
                  color: $color-gray-main;
                  text-align: center;
                }
              }

              .delivery {
                background-color: $color-gray-lighter;
                height: 18%;

                &:first-child {
                  border-radius: 0 4px 0 0;
                }

                &:last-child {
                  border-radius: 0 0 4px 0;
                }

                .value {
                  margin: -24px 0 0 0;
                  font-size: 0.56em;
                  color: $color-gray-main;
                  transition: color 0.8s ease-in-out;

                  &.won {
                    color: $color-status-won;
                  }

                  &.drew {
                    color: $color-status-drew;
                  }

                  &.lost {
                    color: $color-status-lost;
                  }
                }
              }

              .average,
              .kpi {
                background-color: $color-gray-main;
                transition: background-color 0.8s ease-in-out;

                .value {
                  margin: 0 0 auto 0;
                  font-size: 0.88em;
                }

                .label,
                .value {
                  color: $color-gray-lighter;
                }

                .label {
                  opacity: 0.8;
                  margin: auto 0 0 0;
                }
              }

              // Status para cores da média
              .average {
                position: relative;
                z-index: 1;
                height: 32%;
                box-shadow: -8px 4px 16px rgba($color-gray-dark, 0.24);

                &.won {
                  background-color: $color-status-won;
                }

                &.drew {
                  background-color: $color-status-drew;
                }

                &.lost {
                  background-color: $color-status-lost;
                }
              }

              // Status para cores do KPI
              .kpi {
                position: relative;
                z-index: 0;
                height: 32%;
                padding: 0 5px;

                .label {
                  margin: -4px 0 0 0;
                }

                .value {
                  font-family: $secondary-typo;
                  font-style: italic;
                  font-weight: 500;
                  font-size: 0.48em;
                  margin: -56px 0 0 0;
                }

                .tag {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  padding: 8px 4px;
                  background-color: rgba($color-gray-dark, 0.48);

                  font-family: $secondary-typo;
                  font-weight: 500;
                  font-size: 0.32em;
                  font-style: italic;
                  color: $color-gray-lighter;
                  text-transform: uppercase;
                }

                &.won {
                  background-color: darken($color-status-won, 16%);
                }

                &.drew {
                  background-color: darken($color-status-drew, 16%);
                }

                &.lost {
                  background-color: darken($color-status-lost, 16%);
                }
              }
            }

            .now {
              top: 8px;
              right: 8px;
              font-size: 0.32em;
            }

            .program-time {
              position: absolute;
              top: 8px;
              left: 8px;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              padding: 8px;
              border-radius: 4px;
              background-color: rgba($color-gray-dark, 0.88);
              font-family: "Audiowide";
              font-size: 0.32em;
              text-transform: uppercase;
              color: $color-gray-lighter;
              box-shadow: 0 4px 16px rgba($color-gray-darker, 0.24);
              z-index: 3;
            }

            .placeholder {
              position: relative;
              z-index: 2;
              width: 70%;
              height: 100%;
              background-size: cover;
              border-radius: 4px 0 0 4px;
              box-shadow: 4px 4px 16px rgba($color-gray-dark, 0.16);
              border: none;

              .brand {
                img {
                  width: 25%;
                  opacity: 0.56;
                  visibility: hidden;
                }
              }

              .program-name {
                font-size: 0.56em;
                word-wrap: normal;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .time {
                font-size: 0.48em;
                font-weight: 500;

                &::before {
                  margin: 24px auto;
                }
              }

              .feedback {
                font-size: 0.4em;
                font-weight: 500;
              }
            }


            &.past, &.future {
              .slots {
                .delivery,
                .average,
                .kpi {
                  .loading {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// iPhone 5
@media screen and (device-aspect-ratio: 40/71) {

  .dashboard {
    .program_vision {

      .esteira {

        &.swiper-container {
          margin: 8px 0 0 0;
          height: 100%;

          .swiper-slide {
            .slots {
              .average {
                .value {
                  font-size: 0.72em;
                }
              }

              .kpi {
                .label {
                  font-size: 0.32em;
                  font-weight: 500;
                }
                .value {
                  font-size: 0.4em;
                }
              }

              .delivery {
                .label {
                  font-size: 0.24em;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>
