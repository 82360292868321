<template>
  <div class="control-panel">
    <div v-if="settings.isLoadingSpinner&&!settings.isLoading" class="loading">
      <div class="loader">
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6"
                  stroke-linecap="round" cx="33" cy="33" r="30"
          />
        </svg>
      </div>
    </div>
    <div v-if="!settings.isLoadingSpinner&&settings.isLoading" class="loading">
      {{ disableLoading() }}
      <div class="loader">
        <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6"
                  stroke-linecap="round" cx="33" cy="33" r="30"
          />
        </svg>
      </div>
    </div>
    <template v-else>
      <RouterView />
    </template>
  </div>


</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['settings']),
    isInPermissionGroup() {
      return this.$route.name === 'PermissionGroups';
    },
    isInPrograms() {
      return this.$route.name === 'Programs';
    },
  },
  beforeMount() {
    if (this.isInPrograms) {
      if (!this.settings.isProgramRegister) this.goToHome();
    } else if (!this.settings.isPermissionManager) this.goToHome();
  },
  methods: {
    // this is just a hack to force component reload instead of using this.$router.go()
    disableLoading() {
      setTimeout(() => this.$store.dispatch('selectIsLoading', false), 1000);
      return '';
    },
    goToHome() {
      this.$router.push({ name: 'Home' });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.control-panel {
  padding: 16px 0 48px;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: $secondary-typo;

  .loading {
    z-index: 2;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.in {
      animation: fadeIn 0.4s ease-in-out forwards;
    }

    &.out {
      animation: fadeOut 0.4s ease-in-out forwards;
    }

    .loader {
      width: 24px;
      height: 24px;

      .spinner {
        width: 100%;
        height: 100%;
        animation: rotator $duration linear infinite;
      }

      .path {
        stroke-dasharray: $offset;
        stroke-dashoffset: 0;
        transform-origin: center;
        animation:
          dash $duration ease-in-out infinite,
          colors ($duration*4) ease-in-out infinite;
      }
    }
  }

}
</style>
