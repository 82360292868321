import moment from "moment-timezone";

const generateTimeRanges = (minutesInterval = 15, starTime = 0, endTime = 24) => {
  const timeRanges = [];
  for (let hour = starTime; hour < endTime; hour += 1) {
    for (let minuteQuarter = 0; minuteQuarter < (60 / minutesInterval); minuteQuarter += 1) {
      const minute = minutesInterval * minuteQuarter;
      timeRanges.push(`${hour.toString().length === 1 ? `0${hour}` : hour}:${minute.toString().length === 1 ? `0${minute}` : minute}`);
    }
  }
  return timeRanges;
};

const breakTimeRangeByLimit = ({
  startsIn,
  endsIn,
  maxRangeInMinutes = 240,
}) => {
  if (!startsIn || !endsIn) return [];

  const startDate = startsIn;
  const endDate = endsIn;

  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  startMoment.seconds(0);
  endMoment.seconds(0);

  const rangeSizeInMinutes = endMoment.diff(startMoment, 'minutes');

  if (rangeSizeInMinutes <= maxRangeInMinutes) {
    return [{
      start: startDate,
      end: endDate,
    }];
  }
  
  let datesList = [];

  let currentStartDate = endMoment.clone().subtract(maxRangeInMinutes - 1, 'minutes');
  let currentEndDate = endMoment.clone();

  datesList.push({
    start: currentStartDate.format(),
    end: currentEndDate.format(),
  });

  let exceededLimit = false;

  while (!exceededLimit) {
    currentEndDate = currentStartDate.clone().subtract(1, 'minute');
    currentStartDate = currentEndDate.clone().subtract(maxRangeInMinutes - 1, 'minutes');

    if (currentStartDate.format() <= startMoment.format()) {
      currentStartDate = startMoment.clone();
      exceededLimit = true;
    }

    datesList.push({
      start: currentStartDate.format(),
      end: currentEndDate.format(),
    });
  }

  return datesList;
}

module.exports = {
  generateTimeRanges,
  breakTimeRangeByLimit,
};
