<template>
  <div class="user-rules">
    <div class="middle">
      <transition name="fade">
        <div v-if="isLoading" class="loading">
          <div class="loader">
            <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6"
                      stroke-linecap="round" cx="33" cy="33" r="30"
              />
            </svg>
          </div>
        </div>
      </transition>

      <div class="text">
        Utilize os filtros abaixo para refinar os resultados da tabela,
        clique no botão APLICAR para salvar as alterações.
      </div>

      <div class="option-group">
        <div class="head">
          Praças
        </div>
        <div class="body">
          <label v-for="market in markets" :key="market.id" class="checkbox market">
            <input v-model="marketsId" type="checkbox" :value="market.id">
            <span>
              {{ market.exhibitionName }}
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div>

      <transition name="fade">
        <div v-if="tvNetworks.length > 0" class="option-group">
          <div class="head">
            Emissoras
          </div>
          <transition-group name="fade" tag="div" class="body">
            <label v-for="tvNetwork in tvNetworks" :key="tvNetwork" class="checkbox market">
              <input v-model="tvNetworksId" type="checkbox" :value="tvNetwork">
              <span>
                {{ tvNetwork }}
                <i class="icone material-icons" />
              </span>
            </label>
          </transition-group>
        </div>
      </transition>

      <div class="option-group">
        <div class="head">
          Dias
        </div>
        <div class="body">
          <label v-for="day in days" :key="day.name" class="checkbox day">
            <input v-model="weekDays" type="checkbox" :value="day.name">
            <span>
              {{ day.short }}
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div>

      <!-- <div class="option-group">
        <div class="head">
          Horários
        </div>
        <div class="body">
          <vue-slider v-model="time"
                      :min-range="1"
                      :data="timeData"
          />
        </div>
        <div class="input-group hours">
          <div v-for="hour in time" :key="hour" class="item half">
            <input type="text" required="" :value="hour" disabled>
          
            <span class="highlight" />
            <span class="bar" />
            <label />
          </div>
        </div>
      </div> -->

      <!-- <div class="option-group">
        <div class="head">
          Permissões
        </div>
        <div class="body">
          <label v-for="permission in permissions" :key="permission.id" class="checkbox permission">
            <input v-model="selectedPermissions" type="checkbox" :value="permission.id">
            <span>
              {{ permission.name }}
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div> -->
    </div>

    <footer>
      <button type="button"
              class="btn btn_secondary ripple"
              @click="filterGroup"
      >
        Aplicar
      </button>
    </footer>
  </div>
</template>

<script>
import bffRealtime from '@/gateways/bff-realtime';
import _ from 'lodash';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';

export default {
  components: {
    VueSlider,
  },
  data() {
    return {
      total: 0,
      active: true,
      searchInput: '',
      search: '',
      isLoading: true,
      activeUser: '',
      message: '',

      markets: null,
      days: [
        { short: 'D', name: 'DOMINGO' },
        { short: 'S', name: 'SEGUNDA' },
        { short: 'T', name: 'TERCA' },
        { short: 'Q', name: 'QUARTA' },
        { short: 'Q', name: 'QUINTA' },
        { short: 'S', name: 'SEXTA' },
        { short: 'S', name: 'SABADO' },
      ],
      timeData: [
        '00:00', '00:30',
        '01:00', '01:30',
        '02:00', '02:30',
        '03:00', '03:30',
        '04:00', '04:30',
        '05:00', '05:30',
        '06:00', '06:30',
        '07:00', '07:30',
        '08:00', '08:30',
        '09:00', '09:30',
        '10:00', '10:30',
        '11:00', '11:30',
        '12:00', '12:30',
        '13:00', '13:30',
        '14:00', '14:30',
        '15:00', '15:30',
        '16:00', '16:30',
        '17:00', '17:30',
        '18:00', '18:30',
        '19:00', '19:30',
        '20:00', '20:30',
        '21:00', '21:30',
        '22:00', '22:30',
        '23:00', '23:30',
        '23:59',
      ],
      permissions: [
        { id: 'hasTL', name: 'Share TL' },
        { id: 'hasTLE', name: 'Share TLE' },
        { id: 'hasSimuCast', name: 'Simulcast' },
        { id: 'hasSampling', name: 'Amostra' },
        { id: 'isPermissionManager', name: 'Gerente' },
      ],
      uuid: null,
      name: '',
      marketsId: [],
      tvNetworksId: [],
      weekDays: [],
      time: ['00:00', '23:59'],
      selectedPermissions: [],
    };
  },
  computed: {
    tvNetworks() {
      if (this.marketsId.length === 0) return [];

      return _.union(
        ...this.marketsId.map((marketId) => {
          const market = this.markets.find(({ id }) => id === marketId);
          return market.tvNetworks.map(({ id }) => id).filter(marketId => marketId !== 'TLE' && marketId !== 'TL');
        }),
        ['TLE', 'TL'],
      );
    },
  },
  watch: {
    marketsId(newValue, oldValue) {
      if (!oldValue) return;

      // Remove networks not exists
      this.tvNetworksId = this.tvNetworksId.filter(
        networkId => this.tvNetworks.find(id => id === networkId),
      );
    },
  },
  mounted() {
    this.setupGroup();
  },
  beforeDestroy() {
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    async setupGroup() {
      await this.getMarkets();

      const {
        marketsId, tvNetworksId,
        weekDays, time,
      } = this.$route.query;

      this.marketsId = marketsId || [];
      this.tvNetworksId = tvNetworksId || [];
      this.weekDays = weekDays || [];
      this.time = time || ['00:00', '23:59'];
    },
    async getMarkets() {
      this.isLoading = true;

      try {
        const { data } = await bffRealtime.getMarkets(
          { skipPermissions: true },
          bffRealtime.tokenRequest('controlPanel', 'getMarkets'),
        );
        this.markets = data.source;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async filterGroup() {
      const {
        marketsId, tvNetworksId,
        weekDays, time,
        selectedPermissions,
      } = this;

      const query = { ...this.$route.query, marketsId, tvNetworksId, weekDays, time, selectedPermissions };
      if (time[0] === '00:00' && time[1] === '23:59') delete query.time;
      this.$router.push({ name: 'PermissionGroups', query });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/sidebar-content.scss';
</style>
