<template>
  <div>
    <slot v-if="isOnline" />
    <ErrorState v-else
                :insides="['nowifi_item', 'nowifi_back']"
                title="Offline"
                subtitle="Não encontramos uma conexão de rede ativa no momento. Por favor, verifique sua conexão e tente novamente :)"
    />
  </div>
</template>

<script>
import ErrorState from './ErrorState.vue';

export default {
  components: {
    ErrorState,
  },
  data() {
    return {
      isOnline: true,
    };
  },
  mounted() {
    window.addEventListener('online', this.handleConnection);
    window.addEventListener('offline', this.handleConnection);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleConnection);
    window.removeEventListener('offline', this.handleConnection);
  },
  methods: {
    handleConnection() {
      this.isOnline = navigator.onLine || false;
    },
  },
};
</script>
