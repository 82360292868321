<template>
  <div class="loading" :class="loadingCss">
    
    <div class="border">
      <div class="icon" />
      <div class="shape" />
    </div>
    <div class="stripes" />
    <div class="bar">
      <div class="progress_bar" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    condition: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loadingCss() {
      return {
        show: this.condition,
        hide: !this.condition,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

  // Loader
  .loading {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background: rgba($color-gray-back, 1);
    background-size: 100% 100%;

    animation-delay: $delay-time !important;
    animation: fadeOut 0.8s ease-in-out forwards;
    pointer-events: none;
    overflow: hidden;


    .border {
      position: relative;
      z-index: 4;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 96px;
      padding: 8px;
      border-radius: 100px;
      box-shadow: 0 16px 40px rgba($color-gray-darker, 0.24);
      background: $color-gray-lighter;
      animation: pulseColor 2.4s infinite;

      @keyframes pulseColor {
        from {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
          border: solid 4px rgba($color-primary, 0.80);
        }

        50% {
          -webkit-transform: scale3d(1.05, 1.05, 1.05);
          transform: scale3d(1.05, 1.05, 1.05);
          border: solid 4px rgba($color-secondary, 0.88);
        }

        to {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
          border: solid 4px rgba($color-primary, 0.80);
        }
      }

      .icon {
        position: absolute;
        width: 96px;
        height: 64px;
        background-image: url("~@/assets/img/sia-icon-loader.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100% 100%;
        animation: fadeIn 1.6s ease-in-out forwards;
      }

      .shape {
        position: absolute;
        z-index: 5;
        width: 80px;
        height: 80px;
        padding: 0;
        border: solid 4px rgba($color-gray-back, 1);
        background-color: transparent;
        border-radius: 100px;
        animation: pulse 1.6s infinite;
      }
    }

    // Barra de progresso
    .bar {
      position: absolute;
      z-index: 3;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background-color: $color-gray-light;

      .progress_bar {
        display: block;
        width: 0;
        height: 100%;
        background: linear-gradient(140deg, $color-primary-light, $color-secondary);
        background-size: 100% 100%;
        animation: progress $delay-time ease-in-out forwards;

        // Progress Animation
        @keyframes progress {
          from {
            width: 0;
          }

          to {
            width: 100%;
          }
        }
      }
    }
  }
</style>
