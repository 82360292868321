<template>
  <div class="error-state" :class="{fullscreen: fullscreen}" :style="styleContainer">
    <div class="inside">
      <div v-for="(name, id) in insides" :key="id" :class="name" />
    </div>

    <div class="middle">
      <div class="title">
        {{ title }}
      </div>

      <div class="subtitle">
        <template v-if="typeof subtitle === 'object'">
          <div v-for="(_title, key) in subtitle" :key="key">
            {{ _title }}
          </div>
        </template>
        <div v-else>
          {{ subtitle }}
        </div>
      </div>

      <div class="slice">
        <button v-if="clicked"
                type="button"
                class="btn btn_primary ripple"
                @click="hideError"
        >
          Tentar Novamente
          <div v-if="countDown" class="circle">
            <div class="count">
              {{ countDown }}
            </div>
            <div class="l-half" />
            <div class="r-half" />
          </div>
        </button>

        <button v-for="(button, id) in buttons" :key="id"
                type="button"
                class="btn ripple"
                :class="button.class || 'btn_primary'"
                @click="button.click"
        >
          {{ button.text }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    insides: {
      type: Array,
      default: () => ['badrequest_item', 'badrequest_back'],
    },
    title: {
      type: String,
      default: 'Ooops!',
    },
    subtitle: {
      type: [String, Array],
      default: 'Tivemos um problema no servidor. Por favor, tente novamente em alguns instantes :)',
    },
    clicked: {
      type: Boolean,
      default: false,
    },
    buttons: {
      type: Array,
      default: () => [],
    },
    styleContainer: {
      type: Object,
      default: () => ({}),
    },
    fullscreen: {
      type: Boolean,
      default: true,
    },
    counter: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      counterInterval: null,
      countDown: 0,
    };
  },
  computed: mapGetters(['errorOptions']),
  mounted() {
    this.runCounter();
  },
  beforeDestroy() {
    clearInterval(this.counterInterval);
  },
  methods: {
    hideError() {
      this.$store.dispatch('hideError');
    },
    runCounter() {
      if (!this.clicked || this.counter < 1) return;
      this.countDown = this.counter;
      this.counterInterval = setInterval(() => {
        if (this.countDown === 1) {
          this.$store.dispatch('hideError');
        } else {
          this.countDown -= 1;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

$d: 30;
$w: 4;
$t: 10;


.error-state {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: $color-gray-lighter;
  opacity: 0;
  animation: fadeIn 0.8s ease-in-out forwards;
  overflow: hidden;

  &.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
  }

  .inside {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 24vh;
      margin: auto 0 0 0;
      box-sizing: border-box;
      animation: fadeIn 1s ease-in-out forwards;

      // Empty State // Sem Conexão
      .nowifi_back {
          position: relative;
          z-index: 1;
          background-image: url("~@/assets/img/nowifi-back.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          animation: pulse 1.6s ease-in-out infinite;
          opacity: 0.64;
      }

      // Rocket Item
      .nowifi_item {
          position: absolute;
          z-index: 3;
          top: 16%;
          width: 56%;
          height: 56%;
          background-image: url("~@/assets/img/nowifi-item.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          animation: pulse 2.4s ease-in-out infinite;
      }
      // ------------

      // Empty State // Bad Request
      .badrequest_back {
          position: relative;
          z-index: 1;
          background-image: url("~@/assets/img/nowifi-back.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          animation: pulse 1.6s ease-in-out infinite;
          opacity: 0.64;
      }

      // Server Item
      .badrequest_item {
          position: absolute;
          z-index: 3;
          top: 16%;
          width: 56%;
          height: 56%;
          background-image: url("~@/assets/img/server-item.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
      }
      // ------------

      // Empty State // Landscape
      .landscape_back {
          position: relative;
          z-index: 1;
          background-image: url("~@/assets/img/nowifi-back.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          animation: fadeIn 1.6s ease-in-out forwards, pulse 1.6s ease-in-out infinite;
      }

      // TV Item
      .landscape_item {
          position: absolute;
          z-index: 3;
          top: 12%;
          width: 64%;
          height: 64%;
          background-image: url("~@/assets/img/notallowed-phone.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          animation: phone 2.4s ease-in-out infinite;

          @keyframes phone {
            0% {
              opacity: 1;
              transform: rotate(0);
            }

            40% {
              opacity: 1;
              transform: rotate(8deg);
            }

            80% {
              opacity: 1;
              transform: rotate(-8deg);
            }

            100% {
              opacity: 1;
              transform: rotate(0);
            }
          }
      }
      // ------------

      // Block Item
      .landscape_block {
          position: absolute;
          z-index: 4;
          top: 12%;
          width: 64%;
          height: 64%;
          background-image: url("~@/assets/img/notallowed-block.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          animation: fadeIn 1.6s ease-in-out forwards;
          display: none;
      }
      // ------------

      // Empty State // Sem permissão
      .notallowed_back {
          position: relative;
          z-index: 1;
          background-image: url("~@/assets/img/nowifi-back.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          animation: pulse 1.6s ease-in-out infinite;
          opacity: 0.64;
      }

      // TV Item
      .notallowed_item {
          position: absolute;
          z-index: 3;
          top: 16%;
          width: 56%;
          height: 56%;
          background-image: url("~@/assets/img/notallowed-tv.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          animation: jackInTheBox 2.4s ease-in-out forwards;

          @keyframes phone {
            0% {
              opacity: 1;
              transform: rotate(0);
            }

            40% {
              opacity: 1;
              transform: rotate(8deg);
            }

            80% {
              opacity: 1;
              transform: rotate(-8deg);
            }

            100% {
              opacity: 1;
              transform: rotate(0);
            }
          }
      }
      // ------------

      // Block Item
      .notallowed_block {
          position: absolute;
          z-index: 4;
          top: 12%;
          width: 64%;
          height: 64%;
          background-image: url("~@/assets/img/notallowed-block.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          animation: fadeIn 1.6s ease-in-out forwards;
          display: none;
      }
      // ------------

      // Empty State // 404
      .notfound_back {
          position: relative;
          z-index: 1;
          background-image: url("~@/assets/img/nowifi-back.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          width: 100%;
          height: 100%;
          animation: pulse 1.6s ease-in-out infinite;
          opacity: 0.64;
      }

      // Rocket Item
      .notfound_item {
          position: absolute;
          z-index: 3;
          top: 16%;
          width: 56%;
          height: 56%;
          background-image: url("~@/assets/img/ghost-item.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          animation: ghost 3s ease-in-out infinite;

      @keyframes ghost {
          20% {
          opacity: 1;
          transform: rotate(0);
          }

          40% {
          opacity: 1;
          transform: rotate(8deg);
          }

          60% {
          opacity: 1;
          transform: rotate(-8deg);
          }

          80% {
          opacity: 1;
          transform: rotate(0);
          }
      }
      }
      // ------------

      .globo_item {
          position: absolute;
          z-index: 3;
          top: 16%;
          width: 56%;
          height: 56%;
          background-image: url("~@/assets/img/globo-item.svg");
          background-repeat: no-repeat;
          background-size: 100% 100%;
      }
  }

  .middle {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin: 0 0 auto 0;

    // CIRCLE
    .circle {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: $d+px;
      height: $d+px;
      border-radius: 999px;
      margin: 0 -24px 0 8px;
      
      box-shadow: inset 0 0 0 $w+px rgba($color-gray-lighter, 0.4);
    }

    .l-half, .r-half {
      float: left;
      width: 50%;
      height: 100%;
      overflow: hidden;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        border: $w+px solid $color-gray-lighter;

        // Animations
        -webkit-animation-duration: $t+s;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-timing-function: linear;
        -webkit-animation-fill-mode: forwards;
      }
    }

    .l-half:before {
      border-right: none;
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
      -webkit-transform-origin: center right;
      -webkit-animation-name: l-rotate;
    }

    .r-half:before {
      border-left: none;
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
      -webkit-transform-origin: center left;
      -webkit-animation-name: r-rotate;
    }

    // TIMER
    .count {
      position: absolute;
      width: 100%;
      line-height: $d+px;
      text-align: center;
      letter-spacing: 0;
      font-weight: 800;
      font-size: (($d - ($w * 2)) / 2)+px;
      font-family: $primary-typo;
      color: $color-gray-lighter;
      z-index: 2;
      -webkit-animation: fadeout .5s ($t + 1)+s 1 linear;
      -webkit-animation-fill-mode: forwards;
    }

      .title {
        position: relative;
        display: inline-block;
        margin: 8px 0 24px 0;
        width: 100%;
        box-sizing: border-box;
        padding: 0 24px;

        font-family: $secondary-typo;
        font-size: 2em;
        font-weight: 600;
        font-style: italic;
        color: $color-gray-dark;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.08em;
        animation: fadeIn 0.6s ease-in-out forwards;

      &::after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 50%;
          margin-left: -12px;
          width: 24px;
          height: 2px;
          background-color: rgba($color-gray-darker, 0.16);
      }
    }

    .subtitle {
      div {
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        padding: 0 32px;

        font-family: $secondary-typo;
        font-size: 1em;
        font-weight: 600;
        font-style: italic;
        text-transform: initial;
        line-height: 1.6em;
        color: $color-gray-main;
        text-align: center;
        animation: fadeIn 0.8s ease-in-out forwards;
        max-width: 600px;

        &:last-child {
          font-weight: 400;
        }
      }
    }

    .slice {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      margin: 40px 0 0 0;
      animation: fadeInUp 0.8s ease-in-out forwards;

      .btn {
        padding: 8px 40px;
        margin: 8px;
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: 600px) {
  .error-state {
    .middle {

      .title {
        font-size: 1.4em;
      }

      .subtitle {
        font-size: 0.88em;
      }
    }
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  .error-state {
    .middle {

      .title {
        font-size: 1.2em;
      }
    }
  }
}

</style>