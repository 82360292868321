const isIos = () => navigator.userAgent.match(/(iPhone|iPod|iPad)/);
const isAndroid = () => navigator.userAgent.match(/(Android)/);
const isMobile = () => navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/);
const isApp = () => !!window.cordova;
const isAndroidApp = () => isAndroid() && isApp();
const isIosApp = () => isIos() && isApp();

const isLandscape = () => isMobile() && window.matchMedia('(orientation: landscape)').matches;

exports.isAndroid = () => isAndroid();
exports.isIos = () => isIos();
exports.isMobile = () => isMobile();
exports.isApp = () => isApp();
exports.isAndroidApp = () => isAndroidApp();
exports.isIosApp = () => isIosApp();
exports.isLandscape = () => isLandscape();

exports.ios = fn => (isIos() ? fn() : null);

exports.android = fn => (isAndroid() ? fn() : null);

exports.desktop = fn => (!isMobile() ? fn() : null);

exports.mobiles = fn => (isMobile() ? fn() : null);

exports.androidApp = fn => (isAndroidApp() ? fn() : null);

exports.iosApp = fn => (isIosApp() ? fn() : null);

exports.app = fn => (isApp() ? fn() : null);
