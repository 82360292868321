export class UndetectedRedirection extends Error {
  constructor() {
    super('UndetectedRedirection');

    this.name = 'UndetectedRedirection';
    Error.captureStackTrace(this, this.constructor);
  }
}

export class RedirectionError extends Error {
  constructor(error, description) {
    super(error);

    this.name = 'RedirectionError';
    this.error = error;
    this.description = description;

    Error.captureStackTrace(this, this.constructor);
  }
}
