import device from './device';

const getPermission = () => new Promise(
  (resolve, reject) => {
    try {
      window.FirebasePlugin.hasPermission(
        data => resolve(data.isEnabled),
      );
    } catch (error) {
      reject(error);
    }
  },
);

const grantPermission = () => window.FirebasePlugin.grantPermission();

exports.init = async () => {
  const hasPermission = await getPermission();
  // just in IOS we needed to grant permission
  if (!hasPermission && device.isIos()) grantPermission();
};

exports.getToken = () => new Promise(
  (resolve, reject) => {
    try {
      window.FirebasePlugin.getToken(
        token => resolve(token),
      );
    } catch (error) {
      reject(error);
    }
  },
);

exports.onMessageReceived = fn => window
  .FirebasePlugin.onMessageReceived((notification) => {
    fn(notification);
  });
