export default {
  methods: {
    transNetworkNameById(tvNetworkId) {
      let networkName = tvNetworkId;
      if (tvNetworkId === 'OCP') {
        networkName = 'PAGOS';
      } else if (tvNetworkId === 'CNN') {
        networkName = 'CNN BR';
      }

      return networkName;
    },
    transNetworkNameByIdExport(tvNetworkId) {
      let networkName = tvNetworkId;
      if (tvNetworkId === 'OCP') {
        networkName = 'CANAIS PAGOS';
      } else if (tvNetworkId === 'CNN') {
        networkName = 'CNN BR';
      }

      return networkName;
    },
    transNetworkNameByIdCustomName(networkId, networkName) {
      return networkName === undefined ? networkId : networkName;
    },


    removeNetWorksForFilterProgram(markets, names) {
      const listMarkets = [...markets];
      listMarkets.forEach((market, key) => {
        names.forEach((name) => {
          if (market.exhibitionName === name) {
            markets.splice(key, 1);
          }
        });
      }, listMarkets);

      return markets;
    },

    removeMarketsForSimulcastView(markets, names) {
      const listMarkets = [...markets];
      listMarkets.forEach((market, key) => {
        names.forEach((name) => {
          if (market.exhibitionName === name) {
            markets.splice(key, 1);
          }
        });
      }, listMarkets);
      return markets;
    },
  },
};
