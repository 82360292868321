<template>
  <div id="scrollArea" ref="scrollingTable"
       class="table_container"
       :class="{ loading: firstLoading }"
  >
    <div class="loading_inner">
      <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <circle class="path" fill="none" stroke-width="6"
                stroke-linecap="round" cx="33" cy="33" r="30"
        />
      </svg>
    </div>

    <table class="table program-view shadow" :class="tableCss">
      <thead>
        <tr>
          <th>
            <div class="screen">
              Programa
            </div>
            <div class="fullscreen">
              <template v-if="settings.isMarketView">
                {{ settings.market.exhibitionName }}
              </template>
              <img v-else-if="getNetworkLogo(settings.tvNetworkId, true)" :src="getNetworkLogo(settings.tvNetworkId, true)">
              <div v-else :style="{ color: getNetworkColor(settings.tvNetworkId, true) }">
                {{ settings.tvNetworkId }}
              </div>
            </div>
          </th>

          <th>
            <i class="icone material-icons">
              access_time
            </i>

            <button class="btn sort ripple"
                    :class="{'descending': settings.isDescending}"
                    @click="changeIsDescending"
            >
              <div class="chevron" />
            </button>
            <!-- fim da ordenação -->
          </th>

          <th
            v-for="column in secondaryColumns"
            :key="column.id"
            :style="{ minWidth: dataCellWidth }"
          >
            <img v-if="getNetworkLogo(column[idName])" :src="getNetworkLogo(column[idName])">
            <div v-else :style="{ color: getNetworkColor(column[idName]) }" class="tag">
              {{ transNetworkNameById(column[idName]) }}
              <span v-if="settings.isMarketsWeightVisible" :title="column.id === 'RPP' ? 'Posse' : 'Peso'" class="tag-weight">
                {{ column.weight ? column.weight : '-' }}
              </span>
            </div>
            <label v-if="!isMobileView && canHideNetworks(column.id)" class="switch">
              <input :checked="!hiddenNetworks.includes(column.id)"
                     type="checkbox" @change="changeNetworkStatus(column.id)"
              >
              <span class="slider round ripple" />
            </label>
          </th>
        </tr>
        <tr class="data-label-row" v-if="showMultipleDataTypes">
          <th></th>
          <th></th>
          <th
            v-for="column in secondaryColumns"
            :key="column.id"
            :style="{ minWidth: dataCellWidth }"
          >
            {{ dataTypeLabelText }}
          </th>
        </tr>
      </thead>

      <tbody id="contentArea" v-longpress="tbodyMouseClick" />

      <tfoot :class="{ 'averages-one': dayAverages }">
        <tr v-if="selectedAverages">
          <td>
            Média {{ formatDateMS(globoDate.startsIn) }} a {{ formatDateMS(globoDate.endsIn || 'agora') }}
          </td>
          <td />

          <td
            v-for="tvNetwork in secondaryColumns"
            :key="tvNetwork.id"
            :class="findAveragesWinners('selectedAverages', tvNetwork.id, tvNetwork.id)"
            :style="{ minWidth: dataCellWidth }"
          >
            <span v-if="findAverages('selectedAverages', tvNetwork.id, tvNetwork.id, dataTypeSelectedAsList[0]) === '-'">-</span>
            <span v-else>
              <template v-for="(dataType, i) in dataTypeSelectedAsList">
                {{ i > 0 ? ' | ' : '' }}
                <AnimatedNumber
                  :key="dataType"
                  :value="findAverages('selectedAverages', tvNetwork.id, tvNetwork.id, dataType)"
                  tag="span"
                />
              </template>
            </span>
          </td>
        </tr>

        <tr v-else-if="lastMinute">
          <td>
            Último Minuto
          </td>
          <td />
          <td
            v-for="tvNetwork in secondaryColumns"
            :key="tvNetwork.id"
            :class="findLastMinuteWinners(tvNetwork.id)"
            :style="{ minWidth: dataCellWidth }"
          >
            <span v-if="findLastMinute(tvNetwork.id, dataTypeSelectedAsList[0]) === '-'">-</span>
            <span v-else>
              <template v-for="(dataType, i) in dataTypeSelectedAsList">
                {{ i > 0 ? ' | ' : '' }}
                <AnimatedNumber
                  :key="dataType"
                  :value="findLastMinute(tvNetwork.id, dataType)"
                  tag="span"
                />
              </template>
            </span>
          </td>
        </tr>
        <tr v-if="dayAverages">
          <td>
            Média 07:00 a 00:00
          </td>
          <td />

          <td
            v-for="tvNetwork in secondaryColumns"
            :key="tvNetwork.id"
            :class="findAveragesWinners('dayAverages', tvNetwork.id, tvNetwork.id)"
            :style="{ minWidth: dataCellWidth }"
          >
            <span v-if="findAverages('dayAverages', tvNetwork.id, tvNetwork.id, dataTypeSelectedAsList[0]) === '-'">-</span>
            <span v-else>
              <template v-for="(dataType, i) in dataTypeSelectedAsList">
                {{ i > 0 ? ' | ' : '' }}
                <AnimatedNumber
                  :key="dataType"
                  :value="findAverages('dayAverages', tvNetwork.id, tvNetwork.id, dataType)"
                  tag="span"
                />
              </template>
            </span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import Clusterize from 'clusterize.js';
import _ from 'lodash';
import AnimatedNumber from '@/components/modules/AnimatedNumber.vue';
import transformMixin from '@/utils/transformMixin';

export default {
  components: {
    AnimatedNumber,
  },
  mixins: [transformMixin],
  data() {
    return {
      clusterize: null,
      currentAudienceTimeout: null,
      currentAudienceInterval: null,
      firstLoading: true,
      programLines: null,
      hiddenNetworks: [],
      currentScrollTimeout: null,
      isScrollHorizontal: null,
      currentScrollHorizontalTimeout: null,
      lastScrollLeft: 0,
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters([
      'programAverages', 'markets', 'tvNetworks', 'dayAverages',
      'selectedAverages', 'programNowAverages', 'globoDate',
      'marketDays', 'tvNetworks_v2', 'rivalsSchedules',
      'isMobileView', 'lastMinute', 'scheduleTvNetworkId',
      'dataTypeSelectedAsList'
    ]),
    showMultipleDataTypes() {
      return this.dataTypeSelectedAsList && this.dataTypeSelectedAsList.length > 1;
    },
    dataTypeLabelText() {
      const labelsMap = {
        audience: 'AUD',
        shareTLE: 'SHR',
        audienceAndShareTLE: 'AUD | SHR',
      };
      return labelsMap[this.settings.defaultValue] ? labelsMap[this.settings.defaultValue] : '';
    },
    secondaryColumns() {
      let marketTemp = this.markets.map(object => ({ ...object }));
      marketTemp = marketTemp.filter(item => item.exhibitionName != 'FLO');
      marketTemp = marketTemp.filter(item => item.id != 'FOT');
      marketTemp = marketTemp.filter(item => item.id != 'RB');
      marketTemp = marketTemp.filter(item => item.id != 'MAC');
      marketTemp = marketTemp.filter(item => item.id != 'PVE');
      marketTemp = marketTemp.filter(item => item.id != 'BVI');
      marketTemp = marketTemp.filter(item => item.id != 'CAM');
      marketTemp = marketTemp.filter(item => item.id != 'SOR');
      marketTemp = marketTemp.filter(item => item.id != 'SJP');
      marketTemp = marketTemp.filter(item => item.id != 'BAU');
      marketTemp = marketTemp.filter(item => item.id != 'ITA');
      marketTemp = marketTemp.filter(item => item.id != 'CAB');
      marketTemp = marketTemp.filter(item => item.id != 'SLU');
      return this.settings.isMarketView ? this.tvNetworks : marketTemp;
    },
    idName() {
      return this.settings.isMarketView ? 'id' : 'exhibitionName';
    },
    tableCss() {
      return {
        'market-view': this.settings.isMarketView,
        in: this.isScrollHorizontal,
        out: this.isScrollHorizontal === false,
        'has-data-type-label-row': this.showMultipleDataTypes
      };
    },
    dataCellWidth() {
      const dataTypesCount = this.dataTypeSelectedAsList.length;
      return dataTypesCount > 1 ? `${dataTypesCount * 70}px` : '88px';
    },
  },
  watch: {
    programAverages(newValue) {
      this.syncAudienceDataIntoLineTable(newValue);
    },
    programLines(newValue, oldValue) {
      this.syncProgramLineTableToClusterize(oldValue, newValue);
    },
  },
  mounted() {
    this.$store.dispatch('selectIsButtonDownloadActivate', true);
    this.setupClusterize();
    this.setupCurrentAudienceOnTop();
    this.clearAndGetNewProgramAverages();
    this.unwatch = this.$store.watch(state => state.settings, () => {
      this.firstLoading = true;
      this.clearAndGetNewProgramAverages();
    }, { deep: true });
  },
  beforeDestroy() {
    this.unwatch();
    clearTimeout(this.currentAudienceTimeout);
    clearInterval(this.currentAudienceInterval);
  },
  methods: {

    canHideNetworks(networkId) {

      return this.settings.isMarketView
        && networkId !== 'GLOBO'
        && networkId !== this.scheduleTvNetworkId
        && this.settings.isShowCompetitor
        && this.programAverages
        && this.programAverages.find(item => item.tvNetworkId === networkId);
    },
    getNetworkLogo(networkId, skipCheck) {
      if (!this.settings.isMarketView && !skipCheck) return '';
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    getNetworkColor(networkId, skipCheck) {
      if (!this.settings.isMarketView && !skipCheck) return '';
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customColor
        ? this.tvNetworks_v2[networkId].customColor : '';
    },

    async clearAndGetNewProgramAverages() {
      this.$store.dispatch('cancelRequests');
      this.separateLines();
      this.clusterize.clear();
      this.$store.dispatch('clearAverages');

      this.$store.dispatch('getDayAverages');
      this.$store.dispatch('getSelectedAverages');
      this.$store.dispatch('getLastMinute');

      await Promise.all([
        this.$store.dispatch('getProgramAverages'),
      ]).then((res) => {
        if (res[0].length === 0) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle: 'Não existe programação personalizada cadastrada para essa praça. Mude as configurações de preferência para visualizar a programação normal.',
          });
        }
      }).catch((error) => {
        if (error.response && error.response.status && error.response.status === 405) {
          this.$store.dispatch('showInnerError', {
            insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
            subtitle: 'Você está fora da janela de restrições configurada para seu usuário. Por favor, retorne no horário definido para seu usuário.',
          });
        } else if (error.response && error.response.config) {
          if (/program_averages/.test(error.response.config.url)) {
            this.$store.dispatch('showInnerError', { clicked: true });
          }
        }
      });

      this.firstLoading = false;
    },
    separateLines() {
      this.programLines = {};
      const timenow = moment().startOf('day');
      const timeDiff = timenow.diff(moment(`${timenow.format('YYYY-MM-DD')}T00:00:00Z`).local(), 'hours');
      const colspan = this.secondaryColumns.length;

      const { startsIn, endsIn } = this.marketDays[this.settings.dateOptions.day];
      [startsIn, endsIn].forEach((time, key) => {
        if (time) {
          const date = moment(time).add(timeDiff, 'hours');
          if (key === 1) date.add(1, 'minutes');
          this.programLines[`${date.format('YYYY-MM-DDTHH:mm:SS')}`] = `<td><span></span>${date.subtract(1, 'days').format('dddd, LL')}</td><td></td><td colspan="${colspan}"></td>`;
        }
      });
    },
    changeNetworkStatus(id) {

      const index = this.hiddenNetworks.indexOf(id);
      if (index > -1) {
        // remove
        this.hiddenNetworks.splice(index, 1);
      } else {
        // append
        this.hiddenNetworks.push(id);
      }
      this.$store.dispatch('selectMarketHiden', this.hiddenNetworks);
      this.firstLoading = true;
      this.clearAndGetNewProgramAverages();
    },
    changeIsDescending() {
      this.$store.dispatch('selectIsDescending', !this.settings.isDescending);
    },
    selectScrollDirection() {
      const table = this.$refs.scrollingTable;
      const sl = table.scrollLeft;
      const timeout = 500;
      if (sl !== this.lastScrollLeft) {
        this.isScrollHorizontal = true;
        clearTimeout(this.currentScrollHorizontalTimeout);
        this.currentScrollHorizontalTimeout = setTimeout(() => {
          this.isScrollHorizontal = false;
          const { scrollLeft } = table;
          // For Mobile or negative scrolling
          this.lastScrollLeft = scrollLeft <= 0 ? 0 : scrollLeft;
        }, timeout);
      }
    },
    formatDateMS(date) {
      return moment(date).format('HH:mm');
    },
    findAverages(averageType, marketId, networkId, dataType) {
      if (this.settings.isMarketView) {
        // eslint-disable-next-line no-param-reassign
        marketId = this.settings.market.id;
      } else {
        // eslint-disable-next-line no-param-reassign
        networkId = this.settings.tvNetworkId;
      }

      return this[averageType].averages[marketId] && this[averageType].averages[marketId][networkId]
        ? this[averageType].averages[marketId][networkId][dataType]
        : '-';
    },
    findAveragesWinners(type, marketId, networkId) {
      if (this.settings.isMarketView) {
        // eslint-disable-next-line no-param-reassign
        marketId = this.settings.market.id;
      } else {
        // eslint-disable-next-line no-param-reassign
        networkId = this.settings.tvNetworkId;
      }

      return this[type].tvNetworkWinners[marketId]
        ? this.indicadoresAudience(networkId, this[type].tvNetworkWinners[marketId]) : '';
    },
    findLastMinute(networkId, dataType) {
      const lastMinute = this.lastMinute.find(({ marketId, tvNetworkId }) => (
        this.settings.isMarketView
          ? marketId === this.settings.market.id && tvNetworkId === networkId
          : marketId === networkId && tvNetworkId === this.settings.tvNetworkId
      ));

      // TODO: ultimo minuto com ambos os valores
      return lastMinute ? lastMinute[dataType] : '-';
    },
    findLastMinuteWinners(networkId) {
      const lastMinute = this.lastMinute.find(({ marketId, tvNetworkId }) => (
        this.settings.isMarketView
          ? marketId === this.settings.market.id && tvNetworkId === networkId
          : marketId === networkId && tvNetworkId === this.settings.tvNetworkId
      ));

      return lastMinute
        ? this.indicadoresAudience(this.settings.isMarketView
          ? networkId : this.settings.tvNetworkId, lastMinute.tvNetworkWinners)
        : '';
    },
    setupClusterize() {
      this.clusterize = new Clusterize({
        rows: [],
        scrollId: 'scrollArea',
        contentId: 'contentArea',
        rows_in_block: 20,
        callbacks: {
          scrollingProgress: () => {
            this.selectScrollDirection();
          },
        },
      });
    },
    setupCurrentAudienceOnTop() {
      const date = new Date();
      const startOfMinute = (60 - date.getSeconds()) * 1000;
      this.currentAudienceTimeout = setTimeout(() => {
        this.currentAudienceInterval = setInterval(() => {
          this.getData();
        }, 60000);
        this.getData();
      }, startOfMinute);
    },
    getData() {
      this.$store.dispatch('getDayAverages');
      this.$store.dispatch('getSelectedAverages');
      this.$store.dispatch('getLastMinute');

      const { day, endTime } = this.settings.dateOptions;
      if (day > 0 || endTime) return;
      this.$store.dispatch('getProgramAverages');
    },
    indicadoresAudience(tvNetworkId, tvNetworkWinners) {
      let className = '';
      if (tvNetworkWinners) {
        if (tvNetworkWinners.includes(tvNetworkId)) {
          className = tvNetworkWinners.length === 1 ? 'won' : 'drew';
        } else if (tvNetworkId === this.scheduleTvNetworkId) {
          className = 'lost';
        }
      }

      return className;
    },
    populateAudiencePerNetwork(programLine, tvNetworkId, points,
      tvNetworkWinners, programNetwork) {
      // DANGER IS CHANGE THE VALUE INSIDE THE FUNCTION
      const networkInMinute = programLine.find(item => item.tv === tvNetworkId);

      if (networkInMinute) {
        // Check status
        const tvNetwork = this.settings.isMarketView ? tvNetworkId : this.settings.tvNetworkId;
        let borderColor = '';
        let className = this.indicadoresAudience(tvNetwork, tvNetworkWinners);
        borderColor = this.getNetworkColor(tvNetworkId) ? `border-color:${this.getNetworkColor(tvNetworkId)}` : '';
        className += tvNetworkId === programNetwork ? ' match' : '';
        networkInMinute.points = points;

        const currentPointsAreAudienceList = typeof points === 'object' && points !== null;

        if (!currentPointsAreAudienceList) {
          networkInMinute.line = `<td class="${className}" style="${borderColor}" tvNetworkId="${tvNetworkId}">${points}</td>`;
          return;
        }

        const cellContent = this.dataTypeSelectedAsList
          .reduce((acc, dataType, i) => {
            if (i > 0) acc += ' | ';
            return acc + points[dataType].toFixed(2);
          }, '');

        networkInMinute.line = networkInMinute.line = `
          <td
            class="${className}"
            style="${borderColor}; min-width: ${this.dataCellWidth};"
            tvNetworkId="${tvNetworkId}"
          >
            ${cellContent}
          </td>
        `;
      }
    },
    generateProgrmaLines(programData, initialLinesPerMinute) {
      //aqui é gerado o corpo da tabela
      const lines = {};
      programData.forEach(({
        program, startsIn, endsIn, averages, tvNetworkWinners, tvNetworkId, type, indexOrder,
      }) => {
        // REMOVE THIS BAND
        // eslint-disable-next-line no-param-reassign
        if (tvNetworkId === 'BAN') tvNetworkId = 'BAND';

        const isAnScheduleTvNetworkProgram = tvNetworkId === this.scheduleTvNetworkId;

        if (!isAnScheduleTvNetworkProgram && (!this.settings.isShowCompetitor
          || this.hiddenNetworks.includes(tvNetworkId)
          || !this.tvNetworks.find(item => item.id === tvNetworkId))) return;

        const index = `${startsIn}|${endsIn}|${program}|${tvNetworkId}`;
        // trick - making a deep copy of small array
        const copy = JSON.parse(JSON.stringify(initialLinesPerMinute));
        if (!lines[index]) lines[index] = copy;

        const backgroundColor = this.getNetworkColor(tvNetworkId, true) ? `background-color:${this.getNetworkColor(tvNetworkId, true)}` : '';

        if (type) {
          const tempLine = `<span style="${backgroundColor}"></span>${program}`;
          const tempLineProgram = tempLine;
          const tempLineFrame = `<i class='icone material-icons icon-segment'>keyboard_arrow_right</i><div class='row_program_segment'>${tempLine}</div>`;
          this.populateAudiencePerNetwork(lines[index], 'program', (type === 'P' ? tempLineProgram : tempLineFrame));
        } else {
          this.populateAudiencePerNetwork(lines[index], 'program', `<span style="${backgroundColor}"></span>${program}`);
        }

        // console.log('programLineTemp :: ', type);
        this.populateAudiencePerNetwork(lines[index], 'startsIn', this.formatDateMS(startsIn));

        if (this.settings.isMarketView) {
          if (!averages[this.settings.market.id]) return;
          Object.keys(averages[this.settings.market.id]).forEach((networkId) => {
            const point = averages[this.settings.market.id][networkId];
            if (!point) return;
            this.populateAudiencePerNetwork(lines[index], networkId, point,
              tvNetworkWinners[this.settings.market.id], tvNetworkId);
          });
        } else {
          Object.keys(averages).forEach((marketId) => {
            const point = averages[marketId][this.settings.tvNetworkId];
            if (!point) return;
            this.populateAudiencePerNetwork(lines[index], marketId, point,
              tvNetworkWinners[marketId], tvNetworkId);
          });
        }
      });
      return lines;
    },

    generateClusterizeTableView(programLines) {
      // iterate in evey minute to make table line -> | minute | tv1 | tv2 | tv3 | ...
      const rows = Object.keys(programLines).map((key) => {
        if (programLines[key] instanceof Object) {
          const [startsIn, endsIn, program] = key.split('|');
          const tableLineView = programLines[key].reduce((acc, curr) => acc + curr.line, '');
          return `<tr startsIn="${startsIn}" endsIn="${endsIn}" program="${program}">${tableLineView}</tr>`;
        }

        return `<tr class="date">${programLines[key]}</tr>`;
      });
      return rows;
    },

    mergeProgramLineTable(newProgramLineValue, oldProgramTableValue) {
      if (!oldProgramTableValue) return newProgramLineValue;

      newProgramLineValue.forEach((minute, index) => {
        if (minute.points !== '-') {
          oldProgramTableValue[index] = minute;
        }
      });
      return oldProgramTableValue;
    },

    dynamicSortMultiple() {
      const props = arguments;
      return function (obj1, obj2) {
        let i = 0; let result = 0; const
          numberOfProperties = props.length;
        while (result === 0 && i < numberOfProperties) {
          result = dynamicSort(props[i])(obj1, obj2);
          i++;
        }
        return result;
      };
    },

    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1);
      }
      return function (a, b) {
        const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
      };
    },

    sortLineTable(newProgramLineValues) {
      // --const sorted = Object.keys(newProgramLineValues).sort();
      const sorted = Object.keys(newProgramLineValues).sort(this.dynamicSort('indexOrder', '-startsIn'));
      if (this.settings.isDescending) {
        sorted.reverse();
      }
      const ordered = sorted.reduce((acc, key) => {
        acc[key] = newProgramLineValues[key];
        return acc;
      }, {});
      return ordered;
    },

    syncAudienceDataIntoLineTable(data) {
      const fixedColumns = ['program', 'startsIn'];
      const networks = fixedColumns.concat(this.secondaryColumns.map(item => item.id));
      const initialColumnValue = `<td style="min-width: ${this.dataCellWidth};">-</td>`;
      const initialNetworkLinesPerMinute = networks.map(item => ({ tv: item, points: '-', line: initialColumnValue }));
      const dataList = [];

      let indexOrder = 1;

      data.forEach((e) => {
        // PROGRAM HERE
        const { startsIn, endsIn } = e;
        const program = { ...e };
        delete program.schedule;

        // TRANSFORMATION
        program.type = 'P';
        program.indexOrder = indexOrder;
        indexOrder++;
        dataList.push(program);

        if (e.schedule && this.settings.useSchedule) {
          if (e.schedule.length > 0) {
            e.schedule.forEach((s) => {
            // FRAME HERE
              const { name, startTime, endTime } = s;
              const frame = { ...e };
              delete frame.averages;
              delete frame.schedule;


              if (!s.averages) {
                frame.averages = { ...program.averages };
              } else {
                frame.averages = { ...s.averages };
              }

              if (!s.tvNetworkWinners) {
                frame.tvNetworkWinners = { ...program.tvNetworkWinners };
              } else {
                frame.tvNetworkWinners = { ...s.tvNetworkWinners };
              }

              frame.startsIn = s.startDate;
              frame.endsIn = s.endTime;
              frame.program = name;
              frame.type = 'Q';
              frame.indexOrder = indexOrder;
              indexOrder++;

              dataList.push(frame);
            });
          }
        }
      });

      // for(let i = 0, len = dataList.length; i < len; ++i) {
      // }

      // let orderLine
      // dataList.map(p=>{

      // })

      const temp = this.generateProgrmaLines(dataList, initialNetworkLinesPerMinute);
      this.programLines = this.sortLineTable(temp);
    },

    syncProgramLineTableToClusterize(oldProgramTable, newProgramTable) {
      if (newProgramTable) {
        const clusterizeNewData = this.generateClusterizeTableView(newProgramTable);
        this.clusterize.update(clusterizeNewData);
      } else {
        this.clusterize.clear();
      }
    },
    tbodyMouseClick(event) {
      if (!event.target.parentNode.attributes.startsIn
        || !event.target.parentNode.attributes.endsIn
        || !event.target.attributes.tvNetworkId
      ) return;
      const startsIn = event.target.parentNode.attributes.startsIn.value;
      const endsIn = event.target.parentNode.attributes.endsIn.value;
      const program = event.target.parentNode.attributes.program.value;
      const tvNetworkId = event.target.attributes.tvNetworkId.value;

      if (tvNetworkId === 'program' || tvNetworkId === 'startsIn') return;
      this.$modal.show('programDetails', {
        startsIn, endsIn, program, networkId: tvNetworkId,
      });
    },
  },
};
</script>
