<template>
  <div class="user-rules">
    <div v-if="nextGroup && prevGroup" class="nav">
      <button type="button" class="btn btn_header ripple" @click="getPrev">
        &lt; Regra Anterior
      </button>
      <button type="button" class="btn btn_header ripple" @click="getNext">
        Regra Seguinte &gt;
      </button>
    </div>

    <div class="middle">
      <transition name="fade">
        <div v-if="isLoading" class="loading">
          <div class="loader">
            <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6"
                      stroke-linecap="round" cx="33" cy="33" r="30"
              />
            </svg>
          </div>
        </div>
      </transition>

      <div class="input-group name">
        <div class="item full">
          <input v-model="name" type="text" required="">
          <span class="highlight" />
          <span class="bar" />
          <label>Nome</label>
        </div>

        <button v-if="uuid"
                class="btn btn_icon tool"
                type="button"
                data-tip="Clonar"
                @click="clone"
        >
          <i class="icone material-icons">
            control_point_duplicate
          </i>
        </button>
      </div>

      <div class="input-group name">
        <div class="item full">
          <input v-model="description" type="text" required="">
          <span class="highlight" />
          <span class="bar" />
          <label>Descrição</label>
        </div>
      </div>

      <div class="option-group">
        <div class="head">
          Visibilidade
        </div>
        <div class="body">
          <label class="checkbox visibility">
            <input v-model="hidden" type="checkbox">
            <span>
              Ocultar regra do Acesso Globo
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div>

      <template v-if="uuid">
        <!-- <div class="input-group">
          <div class="item full inactive" :class="{ active }">
            <i class="icone material-icons">
            </i>
            <input type="text" required="" :value="statusName" disabled>

            <span class="highlight" />
            <span class="bar" />
            <label>Status</label>
          </div>
        </div> -->

        <!-- <div class="group head">
          <div>Status</div>
          <div>Clonar</div>
        </div>
        <div class="group values" :class="{ active }">
          <div>{{ active ? 'Ativo' : 'Inativo' }}</div>
            <i class="icone material-icons" @click="clone()">
            library_add
          </i>
        </div>
        <div class="divide" /> -->
      </template>

      <div class="option-group">
        <div class="head">
          Praças
        </div>
        <div class="body">
          <label v-for="market in markets" :key="market.id" class="checkbox market">
            <input v-model="marketsId" type="checkbox" :value="market.id">
            <span>
              {{ market.exhibitionName }}
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div>

      <transition name="fade">
        <div v-if="tvNetworks.length > 0" class="option-group">
          <div class="head">
            Emissoras
          </div>
          <transition-group name="fade" tag="div" class="body">
            <label v-for="tvNetwork in tvNetworks" :key="tvNetwork"
                   class="checkbox market"
                   :class="{ disabled: tvNetwork === 'TLE' }"
            >
              <input v-model="tvNetworksId" type="checkbox" :value="tvNetwork">
              <span>
                {{ transNetworkNameById(tvNetwork) }}
                <i class="icone material-icons" />
              </span>
            </label>
          </transition-group>
        </div>
      </transition>

      <div class="option-group">
        <div class="head">
          Dias
        </div>
        <div class="body">
          <label v-for="day in days" :key="day.name" class="checkbox day">
            <input v-model="weekDays" type="checkbox" :value="day.name">
            <span>
              {{ day.short }}
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div>

      <div class="option-group">
        <div class="head">
          <span>Horários</span>

          <transition name="fade">
            <button v-if="times.length > 1"
                    type="button"
                    class="btn btn_icon tool"
                    data-tip="Excluir"
                    @click="removeGroupTime"
            >
              <i class="icone material-icons" @click="addGroupTime">
                alarm_off
              </i>
            </button>
          </transition>

          <button type="button"
                  class="btn btn_icon tool"
                  data-tip="Incluir"
                  @click="addGroupTime"
          >
            <i class="icone material-icons">
              alarm_add
            </i>
          </button>
        </div>
        <transition-group name="fade">
          <div v-for="(time, key) in times" :key="`_${key}`">
            <div v-if="key !== 0" class="divide" />
            <div class="body">
              <vue-slider v-model="times[key]"
                          :min-range="1"
                          :data="timeData"
              />
            </div>
            <!-- <div class="body hours">
              <label v-for="hour in time" :key="hour" class="checkbox time">
                <span>
                  {{ hour }}
                </span>
              </label>
            </div> -->

            <div class="input-group hours">
              <div v-for="hour in time" :key="hour" class="item half">
                <!-- <i class="icone material-icons"></i> -->
                <input type="text" required="" :value="hour" disabled>

                <span class="highlight" />
                <span class="bar" />
                <label />
              </div>
            </div>
          </div>
        </transition-group>
      </div>

      <div class="option-group">
        <div class="head">
          Permissões
        </div>
        <div class="body">
          <label v-for="permission in permissions"
                 :key="permission.id"
                 class="checkbox permission"
                 :class="{ disabled: permission.id === 'hasTLE' }"
          >
            <input v-model="selectedPermissions" type="checkbox" :value="permission.id">
            <span>
              {{ permission.name }}
              <i class="icone material-icons" />
            </span>
          </label>
        </div>
      </div>
    </div>

    <footer v-if="message" class="done">
      <div class="input-group">
        <div class="item full">
          <i class="icone material-icons">mood</i>
          <input v-model="searchInput" type="text" required="">
          <span class="highlight" />
          <span class="bar" />
          <label>{{ message }}</label>
        </div>
      </div>
    </footer>
    <footer v-else>
      <!-- <button type="button"
              class="btn btn_secondary ripple"
              :class="{ disabled : !isValidate }"
              @click="clone"
      >
        Clonar
      </button> -->

      <button type="button"
              class="btn btn_primary ripple"
              :class="{ disabled : !isValidate }"
              @click="addGroup"
      >
        Salvar
      </button>
    </footer>
  </div>
</template>

<script>
import bffRealtime from '@/gateways/bff-realtime';
import _ from 'lodash';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import transformMixin from '@/utils/transformMixin';
import { generateTimeRanges } from '../../../../utils/time-utils';

export default {
  components: {
    VueSlider,
  },
  mixins: [transformMixin],
  props: {
    group: {
      type: Object,
      default: null,
    },
    updateGroup: {
      type: Function,
      default: null,
    },
    nextGroup: {
      type: Function,
      default: null,
    },
    prevGroup: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      total: 0,
      active: true,
      searchInput: '',
      search: '',
      isLoading: true,
      activeUser: '',
      message: '',
      messageTimeout: null,

      markets: null,
      days: [
        { short: 'D', name: 'DOMINGO' },
        { short: 'S', name: 'SEGUNDA' },
        { short: 'T', name: 'TERCA' },
        { short: 'Q', name: 'QUARTA' },
        { short: 'Q', name: 'QUINTA' },
        { short: 'S', name: 'SEXTA' },
        { short: 'S', name: 'SABADO' },
      ],
      timeData: [...generateTimeRanges(30, 0, 24), '23:59'],
      permissions: [
        { id: 'hasTL', name: 'Share TL' },
        { id: 'hasTLE', name: 'Share TLE' },
        { id: 'hasSimuCast', name: 'Simulcast' },
        { id: 'hasSampling', name: 'Amostra' },
        { id: 'isProgramRegister', name: 'Cadastro de Programa' },
        { id: 'isPermissionManager', name: 'Gerente' },
      ],
      uuid: null,
      name: '',
      description: '',
      marketsId: null,
      tvNetworksId: [],
      weekDays: [],
      times: [['00:00', '23:59']],
      selectedPermissions: ['hasTLE'],
      hidden: false,
    };
  },
  computed: {
    statusName() {
      return this.active ? 'Ativo' : 'Inativo';
    },
    isValidate() {
      return this.name !== ''
        && this.marketsId.length > 0
        && this.tvNetworksId.length > 0
        && this.weekDays.length > 0
        && this.canUpdate();
    },
    tvNetworks() {
      if (!this.marketsId || this.marketsId.length === 0) return [];

      return _.union(
        ...this.marketsId.map((marketId) => {
          const market = this.markets.find(({ id }) => id === marketId);
          return market.tvNetworks.map(({ id }) => id).filter(marketId => marketId !== 'TLE' && marketId !== 'TL');
        }),
        ['TLE', 'TL'],
      );
    },
  },
  watch: {
    marketsId(newValue, oldValue) {
      if (!oldValue) return;

      const tvNetworks = _.union(
        ...oldValue.map((marketId) => {
          const market = this.markets.find(({ id }) => id === marketId);
          return market.tvNetworks.map(({ id }) => id);
        }),
      );

      // Select market
      newValue.forEach((marketId) => {
        if (!oldValue.find(_marketId => _marketId === marketId)) {
          const market = this.markets.find(({ id }) => id === marketId);
          this.tvNetworksId = _.union(
            this.tvNetworksId,
            market.tvNetworks.map(({ id }) => id)
              .filter(id => !tvNetworks.find(networksId => networksId === id)),
          );
        }
      });

      // Remove networks not exists
      this.tvNetworksId = this.tvNetworksId.filter(
        networkId => this.tvNetworks.find(id => id === networkId),
      );
    },
    tvNetworksId(newValue, oldValue) {
      const exists = oldValue.includes('TL');
      if (newValue.includes('TL')) {
        if (!exists) this.selectedPermissions.push('hasTL');
      } else if (exists) {
        this.selectedPermissions = this.selectedPermissions.filter(permission => permission !== 'hasTL');
      }
    },
    selectedPermissions(newValue, oldValue) {
      const exists = oldValue.includes('hasTL');
      if (newValue.includes('hasTL')) {
        if (!exists) this.tvNetworksId.push('TL');
      } else if (exists) {
        this.tvNetworksId = this.tvNetworksId.filter(networksId => networksId !== 'TL');
      }
    },
  },
  mounted() {
    this.setupGroup();
  },
  beforeDestroy() {
    clearTimeout(this.messageTimeout);
    bffRealtime.cancelRequests('controlPanel');
  },
  methods: {
    async setupGroup() {
      await this.getMarkets();
      if (this.group) this.getGroup(this.group);

      else {
        // Select all
        this.marketsId = this.markets.map(({ id }) => id);
        this.tvNetworksId = this.tvNetworks.map(networkId => networkId);
        this.weekDays = this.days.map(({ name }) => name);
      }
    },
    clone() {
      this.name = '';
      this.uuid = null;
    },
    getNext() {
      this.getGroup(this.nextGroup());
    },
    getPrev() {
      this.getGroup(this.prevGroup());
    },
    async getGroup({
      uuid, name, active,
      marketsId, tvNetworksId,
      weekDays, times,
      hasTL, hasTLE, hasSimuCast, hasSampling, isProgramRegister, isPermissionManager, description,
      hidden,
    }) {
      this.message = '';
      this.description = description;
      this.uuid = uuid;
      this.name = name;
      this.active = active;
      this.marketsId = marketsId || [];
      this.tvNetworksId = tvNetworksId || [];
      this.times = this.getTime(times);
      this.weekDays = weekDays || [];
      this.hidden = !!hidden;
      this.selectedPermissions = [];

      if (hasTL) this.selectedPermissions.push('hasTL');
      // force TLE // if (hasTLE)
      this.selectedPermissions.push('hasTLE');
      if (hasSampling) this.selectedPermissions.push('hasSampling');
      if (isProgramRegister) this.selectedPermissions.push('isProgramRegister');
      if (isPermissionManager) this.selectedPermissions.push('isPermissionManager');
      if (hasSimuCast) this.selectedPermissions.push('hasSimuCast');
    },

    async getMarkets() {
      this.isLoading = true;

      try {
        const { data } = await bffRealtime.getMarkets(
          { skipPermissions: true },
          bffRealtime.tokenRequest('controlPanel', 'getMarkets'),
        );
        this.markets = data.source;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    fixSecond(time) {
      const second = /:59/.test(time) ? '59' : '00';
      return `${time}:${second}`;
    },
    async addGroup() {
      clearTimeout(this.messageTimeout);
      this.isLoading = true;

      try {
        const {
          name,
          description,
          marketsId, tvNetworksId,
          weekDays, times,
          selectedPermissions,
          hidden,
        } = this;

        const params = {
          name,
          description,
          marketsId,
          tvNetworksId,
          weekDays,
          times: times.map(time => `${this.fixSecond(time[0])}-${this.fixSecond(time[1])}`),
          hasTL: selectedPermissions.includes('hasTL'),
          hasTLE: selectedPermissions.includes('hasTLE'),
          hasSimuCast: selectedPermissions.includes('hasSimuCast'),
          hasSampling: selectedPermissions.includes('hasSampling'),
          isProgramRegister: selectedPermissions.includes('isProgramRegister'),
          isPermissionManager: selectedPermissions.includes('isPermissionManager'),
          hidden,
        };


        if (this.uuid) {
          await bffRealtime.updateGroup(
            this.uuid,
            params,
            bffRealtime.tokenRequest('controlPanel', 'addGroup'),
          );

          this.message = 'Regra alterada com sucesso!';
        } else {
          const { data } = await bffRealtime.addGroup(
            params,
            bffRealtime.tokenRequest('controlPanel', 'addGroup'),
          );

          this.uuid = data.uuid;
          this.active = true;

          this.message = 'Regra incluída com sucesso!';
        }

        if (this.updateGroup) {
          params.uuid = this.uuid;
          params.active = this.active;
          this.updateGroup(params);
        }

        this.messageTimeout = setTimeout(() => {
          this.message = '';
        }, 2000);
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    addGroupTime() {
      this.times.push(['00:00', '23:59']);
    },
    removeGroupTime() {
      this.times.pop();
    },
    getTime(times) {
      if (!times) return [];

      return times.map((time) => {
        const [start, end] = time.split('-');

        return [start.replace(/:\d{2}$/, ''), end.replace(/:\d{2}$/, '')];
      });
    },
    canUpdate() {
      const { SYSTEM_RULES } = process.env;

      return !SYSTEM_RULES.includes(this.uuid);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/variables.scss';
  @import '@/assets/scss/sidebar-content.scss';
</style>
