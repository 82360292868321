// Exibe tooltip com as informações de emissoras e audiência para determinada linha
export function displayTvNetworksTooltip(a, context) {
  const { hoverPoint, hoverPoints } = a.chart;

  const hasValidSeries = hoverPoints.some((point) => point.series.name !== "timeline");

  if (!hasValidSeries) return;

  const { x } = hoverPoint;

  const date = moment.unix(x / 1000);
  const dateFormat = date.format('DD/MM/YYYY HH:mm');

  if (context.isMinimized) return dateFormat;

  const hoverPointsGroupeds = _.chain(hoverPoints)
    .filter((point) => point.series.name !== 'timeline') // remove a linha do tempo [timeline]
    .groupBy('series.name')
    .map((value, key) => ({ tvNetworkId: key, hoverpoints: value }))
    .value();

  const html = hoverPointsGroupeds.map(({ hoverpoints, tvNetworkId }) => {
    let fixedTvNetworkId = tvNetworkId;
    
    if (tvNetworkId === 'PAGOS') {
      fixedTvNetworkId = 'OCP';
    }
    if (tvNetworkId === 'CNN BR') {
      fixedTvNetworkId = 'CNN';
    }

    const logo = context.getNetworkLogo(fixedTvNetworkId);

    const points = _.chain(hoverpoints)
      .map(({ y, series }) => ({
        isHistoric: series.options.dashStyle !== 'line',
        name: series.name,
        color: series.color,
        data: y,
      }))
      .orderBy('isHistoric', 'asc')
      .value();

    return `
        <div style="
          color: ${points[0].color};
          border-radius: 8px;
          margin: 4px;
          background: white;
          width: 65px;
        ">
          <div style="
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-left: 4px;
          ">
            <div>${logo ? `<img src="${logo}" height="16">` : points[0].name}</div>
            <div style="
              font-family: Audiowide;
              font-weight: normal;
              font-size: 1.2em;
              margin-right: 8px;
              text-align: right;
              color: #666666;
            ">
              ${points[0].data}
            </div>
          </div>
          <div style="
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 8px;
            font-size: 0.60em;
            font-weight: 650;
            border-top: solid 1px #F5F5F5;
            color: #666666;
          ">
            Média: ${typeof points[1] !== 'undefined' ? points[1].data : '0'}
          </div>
        </div>
      `;
  }).join('');

  return `
    <div style="
      margin: -16px;
      width: 416px;
      justify-content: space-around;
    ">
      <div style="
        font-weight: bold;
        text-align: center;
        background: #FFF;
        padding: 8px;
        z-index: 99;
        position: relative;
      ">
        ${dateFormat}
      </div>
      <div style="
        display: flex;
        flex-wrap: wrap;
        background: #F5F5F5;
        padding: 8px;
        z-index: 99;
        position: relative;
      ">
        ${html}
      </div>
    </div>
  `;
};