<template>
  <div class="container"
       :class="[{hovering: itemSelected, loading: isLoading}, tvNetworkId]"
       @mouseover="containerOver(tvNetworkId)"
       @mouseout="containerOut(tvNetworkId)"
  >
    <div v-if="showLogo" class="logo">
      <img :src="getNetworkLogo(tvNetworkId)">
    </div>
    <div class="program-container " :class="tvNetworkId " :style="getProgramContainerProperties()">
      <p class="programName">
        {{ programName }}
      </p>

      <p v-if="totalPrograms > 0 && !itemSelected" class="total-programs">
        [+ {{ totalPrograms }}]
      </p>

      <p v-for="rival in rivalPrograms" v-else class="programName" style="margin-left: 45px">
        {{ rival.program }}
      </p>

      <div v-if="tvNetworkId === 'GLOBO'" class="kpi-details-container">
        <p :class="{decreased: diffAudiencePoints < 0 }">
          {{ diffAudiencePoints > 0 ? '+' : '' }}{{ diffAudiencePoints }}
        </p>
        <p :class="{decreased: diffAudiencePercent < 0 }">
          {{ diffAudiencePercent > 0 ? '+' : '' }}{{ diffAudiencePercent }}%
        </p>
      </div>
    </div>
    
  <!-- <div class="audience-container" :class="tvNetworkId" :style="getAudienceContainerProperties()">
      <i v-if="isWinner" class="material-icons isWinner" >
        arrow_upward 
      </i>

      <i v-else class="material-icons">
         arrow_downward
      </i>

      <p>
        {{ audience }}
      </p>
    </div> -->

        <div v-if="tvNetworkId === 'GLOBO'" class="audience-container-globo" >
          <div v-if="isWinner" class="won">
               <i  class="material-icons isWinner" > </i>           
              <p>
                {{ audience }} 
              </p>
          </div>

          <div v-else-if="isWinner !== false && !isWinner "  class="drew">
            <i class="material-icons"> </i>       
            <p>
              {{ audience }} 
            </p>
        </div>

         <div v-else  class="lost">
            <i class="material-icons"> </i>       
            <p>
             {{ audience }} 
            </p>
        </div>

    </div>

    <div v-else class="audience-container" :class="tvNetworkId" :style="getAudienceContainerProperties()">

       <div v-if="isWinner" class="won-rivals" :class="tvNetworkId" :style="getAudienceContainerProperties()" >
               <i  class="material-icons isWinner" > </i>           
              <p>
                {{ audience }} 
              </p>
          </div>     
        
            <div v-else  class="rivals">
            <i class="material-icons"> </i>       
            <p>
             {{ audience }} 
            </p>
        </div>

   
    </div>
  
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SprintItem',
  props: [
    'tvNetworkId',
    'programName',
    'audience',
    'isWinner',
    'showLogo',
    'totalPrograms',
    'diffAudiencePercent',
    'diffAudiencePoints',
    'numItems',
    'itemSelected',
    'itemSelectedColor',
    'itemPosition',
    'rivalPrograms',
  ],
  computed: {
    ...mapGetters([
      'tvNetworks_v2', '',
    ]),
    ...mapGetters('sprintDash', ['isLoading']),
  },
  data() {
    return {
      offSet: {
        1: 0,
        2: 2.1,
        3: 2.5,
      },
      isContainerHovering: false,
    };
  },
  methods: {
    getProgramContainerProperties() {
      if (!this.itemSelected) return {};
      return {
        backgroundColor: `${this.itemSelectedColor}`,
        borderTopLeftRadius: this.itemPosition === 0 ? '20px!important' : '0px!important',
        borderBottomLeftRadius: this.itemPosition === 0 ? '20px!important' : '0px!important',
      };
    },
    getAudienceContainerProperties() {
      
      if (!this.itemSelected) return {};

      return {
      
        backgroundColor: `${this.itemSelectedColor}`,
        borderTopRightRadius: this.itemPosition === (this.numItems - 1) ? '20px!important' : '0px!important',
        borderBottomRightRadius: this.itemPosition === (this.numItems - 1) ? '20px!important' : '0px!important',
      };
    },

  
    getNetworkLogo(networkId) {
      return this.tvNetworks_v2[networkId] && this.tvNetworks_v2[networkId].customLogoPath
        ? this.tvNetworks_v2[networkId].customLogoPath : '';
    },
    containerOver(networkId) {
      this.$emit('sprint-item-over', networkId);
    },
    containerOut(networkId) {
      this.$emit('sprint-item-out', networkId);
    },
  },
};
</script>

<style lang="scss" scoped>
  @keyframes loading {
    100% {
      transform: translateX(100%);
    }
  }


  .loading {
    position: relative;

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background: linear-gradient(90deg, #f5f5f508, rgba(255, 255, 255, .2), transparent);
      animation: loading 1.5s infinite;
    }
  }

  .container{
    display: flex;
    flex-direction: row;
    height: 34px;
    margin-bottom: 24px;
    margin-left: 8px;

    &.hovering{
      margin-left: 0px!important;

      .program-container{
        border-top-left-radius: 0px!important;
        border-bottom-left-radius: 0px!important;
        color: white!important;
      }
      .audience-container{
        border-top-right-radius: 0px!important;
        border-bottom-right-radius: 0px!important;
        color: white!important;
      }
      .won-rivals{
        border-top-right-radius: 0px!important;
        border-bottom-right-radius: 0px!important;
        color: white!important;
      }
    }

    &.loading{
      opacity: 0.7;
      p, i{
        display: none;
      }
    }
  }

  .logo{
    margin-right: 8px;
    width: 34px;
    height: 34px;


    img{
      width: 34px;
      height: 34px
    }
  }

  .program-container{
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding-left: 16px;
    background-color:#EAEAEA;
    align-items: center;
    width: 80%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    color: black;

    &.GLOBO{
      background-color:#666666;
      color: white!important;
    }

    .total-programs{
      color: #999999;
      font-family: Roboto;
      font-weight: 700;
      font-style: italic;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  .audience-container{
    display: flex;
    background-color:#EFEFEF;
    align-items: center;
    width: 20%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: center;
    font-size: 14px;
    color: #000000;
  //    i{
  //     color: #FFA8A2;
  //     font-size: 12px!important;

  //     &.isWinner{
  //       color: #8DD9F6;
  //     }

  //   }

  //  &.GLOBO{
  //     background-color:#595959;
  //     color: #ffffff!important;
  //   }
  }


  .audience-container-globo{
    display: flex;
    align-items: center;
    width: 20%;
    border-top-right-radius: 18px;
    border-bottom-right-radius: 18px;
    justify-content: center;
    font-size: 14px;
    background-color:#595959;
    color: #fff;

    i{
      color: #FFA8A2;
      font-size: 12px!important;

      &.isWinner{
        color: #8DD9F6;
      }
    }

   &.GLOBO{
      background-color:#595959;
      color: #ffffff!important;
    }
  }

  .rivals{
      margin-bottom: 15px; 
      justify-content: center;

  }

  .won{
    display: flex;
    padding-right: 16px;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    background-color:#00AEEF;
     
  }

  .won-rivals{
    display: flex;
    padding-right: 16px;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: center;
    font-weight: 500;
    font-size: 14px;
    background-color:#EFEFEF;
    color:#00AEEF;

  }

  .drew{
    display: flex;
    padding-right: 16px;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    background-color:#595959;
  }

  .lost{
    display: flex;
    padding-right: 16px;
    align-items: center;
    width: 100%;
    height: 100%;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;  
    justify-content: center;
    color: #fff;
    font-size: 14px;
    color: #fff;
    background-color:#DC5045;

  }

  .kpi-details-container{
    display: flex;
    flex-direction: row;
    margin-left: auto;
    color: #95E18B;
    font-weight: 400;
    font-size: 14px;
    margin-right: 8px;

    :first-child{
      margin-right: 5px;
    }

    .decreased{
      color: #FFA8A2;
    }
  }

  p{
    font-family: Roboto;
    font-style: italic;

    &.programName{
      font-weight: 500;
      font-size: 12px;
    }
  }
</style>
