import { render, staticRenderFns } from "./NavHome.vue?vue&type=template&id=c2027166&scoped=true&"
import script from "./NavHome.vue?vue&type=script&lang=js&"
export * from "./NavHome.vue?vue&type=script&lang=js&"
import style0 from "./NavHome.vue?vue&type=style&index=0&id=c2027166&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2027166",
  null
  
)

export default component.exports