import Vue from 'vue';
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger';

// Modules
import user from './modules/user';
import audience from './modules/audience';
import emadAudience from './modules/emad-audience';
import market from './modules/market';
import globoSchedule from './modules/globo-schedule';
import rivalsSchedule from './modules/rivals-schedule';
import schedule from './modules/schedule';
import settings from './modules/settings';
import system from './modules/system';
import sprintChart from '../v2/Views/Home/SprintView/Store/sprint-chart.store';
import railChart from '../v2/Views/Home/RailView/Store/rail-chart.store';
import hourNavigator from '../v2/Views/Home/SprintView/Store/hour-navigator.store';
import sprintDash from '../v2/Views/Home/SprintView/Store/sprint-dash.store';
import './modules/longpress-directive';

Vue.use(Vuex);

const isDebug = (
  process.env.NODE_ENV === 'development' && process.env.IS_DEBUG_MODE === true);

export default new Vuex.Store({
  modules: {
    user,
    audience,
    emadAudience,
    market,
    globoSchedule,
    rivalsSchedule,
    schedule,
    settings,
    system,
    sprintChart,
    railChart,
    hourNavigator,
    sprintDash,
  },
  strict: isDebug,
  plugins: isDebug ? [createLogger()] : [],
});
