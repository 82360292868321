<template>
  <div class="select_dropdown" :class="{ disabled }">
    <div v-if="optionsIsOpen" class="resetDate" @click="closeOptions" />
    <div class="dropdown-menu ripple" @click="openOptions()">
      {{ dateSelected }}
      <i v-if="settings.dateOptions.startTime || settings.dateOptions.endTime"
         class="icone material-icons"
      >
       access_time
      </i>
    </div>

    <div v-if="optionsIsOpen" class="options">
      <h1>Selecione o dia</h1>

      <div v-for="d in marketDays" :key="d.id"
           class="days" :class="{'selected': d.id === day}"
           @click="changeDay(d.id)"
      >
        {{ d.name }}
      </div>

      <div class="time">
        <div class="time-selector">
          <div>
            <h2>Selecione o horário</h2>
          </div>
          <button class="btn  btn_primary ripple" @click="clearRangeTimeOptions()">
            Limpar
          </button>
        </div>

        <vue-slider ref="slider" v-model="rangeTime" :min-range="1" :data="timeData" style="width: 100%; " />
        <div class="item">
          <input type="time" required :value="rangeTime[0]" @input="changeStartTimeValue">
          <span class="highlight" />
          <label />
          <span class="bar" />
        </div>
        <div class="item">
          <input type="time" required :value="rangeTime[1]" @input="changeEndTimeValue">
          <span class="highlight" />
          <label />
          <span class="bar" />
        </div>
      </div>
      <div class="button">
        <div v-if="!isRangeTimeSelectedBeforeOrEqualLastTimeUpdated" class="feedback" :class="cssFeedBack">
          {{ `Por favor, escolha um período antes ou igual ao horário de última atualização: 
          ${this.lastTimeUpdated.format('HH:mm')}.` }}
        </div>
        <div v-if="!isStartSliderLowerOrEqualEndSlider" class="feedback" :class="cssFeedBack">
          {{ `A hora inicial da grade deve ser menor do que a hora final.` }}
        </div>
        <button class="btn btn_full btn_primary" @click="applyChanges()">
          Aplicar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import moment from 'moment';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import { generateTimeRanges } from '../../../utils/time-utils';

export default {
  components: {
    VueSlider,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOptions: false,
      rangeTime: ['04:00', '03:59'],
      timeData: [...generateTimeRanges(1, 4, 24), ...generateTimeRanges(1, 0, 4), '03:59'],
      optionsIsOpen: false,
      day: 0,
      feedBackOpen: null,
    };
  },
  computed: {
    ...mapState(['settings']),
    ...mapGetters(['marketDays', 'lastTimeUpdated']),
    dateSelected() {
      const { day } = this.settings.dateOptions;
      if (this.marketDays[day]) {
        const { name } = this.marketDays[day];
        return name;
      }
      return '--';
    },
    cssFeedBack() {
      return {
        'feedback--show': this.feedBackOpen === true,
        'feedback--hide': this.feedBackOpen === false,
      };
    },
    currentGridDate() {
      return moment(this.marketDays[0].startsIn).format('YYYY-MM-DD');
    },
    selectedGridDate() {
      return moment(this.marketDays[this.day].startsIn).format('YYYY-MM-DD');
    },
    // Deve me retornar uma data e horário inicial da grade atual selecionada no slider
    startSliderDateTime() {
      const [startTime] = this.rangeTime;
      const [startTimeHour, startTimeMinute] = startTime.split(':');
      const startDateTime = moment(this.selectedGridDate, 'YYYY-MM-DD')
        .set({ hours: startTimeHour, minutes: startTimeMinute, seconds: 0 });

      if (this.isStartSliderOnNextDay) {
        startDateTime.add(1, 'd');
      }

      return startDateTime.format();
    },
    // Deve me retornar uma data e horário final da grade atual selecionada no slider
    endSliderDateTime() {
      const [, endTime] = this.rangeTime;
      const [endTimeHour, endTimeMinute] = endTime.split(':');
      const endDateTime = moment(this.selectedGridDate, 'YYYY-MM-DD')
        .set({ hours: endTimeHour, minutes: endTimeMinute, seconds: 0 });

      if (this.isEndSliderOnNextDay) {
        endDateTime.add(1, 'd');
      }

      return endDateTime.format();
    },
    isStartSliderOnNextDay() {
      const [startTime] = this.rangeTime;
      return startTime < '04:00';
    },
    isEndSliderOnNextDay() {
      const [, endTime] = this.rangeTime;
      return endTime >= '00:00' && endTime < '04:00';
    },
    isRangeTimeSelectedBeforeOrEqualLastTimeUpdated() {
      // A data/hora final deve ser sempre menor ou igual a data/hora da última
      //  atualização se o dia selecionado for o atual
      if (this.selectedGridDate === this.currentGridDate) {
        return this.endSliderDateTime <= this.lastTimeUpdated.format();
      }
      return true;

    },
    isStartSliderLowerOrEqualEndSlider() {
      // A data/hora inicial deve ser sempre menor que a data/hora final
      return this.startSliderDateTime < this.endSliderDateTime;
    },
    isValidRangeTimeSelection() {
      return this.isRangeTimeSelectedBeforeOrEqualLastTimeUpdated && this.isStartSliderLowerOrEqualEndSlider;
    },

  },
  created() {
    this.initializeSliderOptionsAsCurrentDaySelected();
  },
  methods: {
    openOptions() {
      this.optionsIsOpen = true;
    },
    closeOptions() {
      this.optionsIsOpen = false;
    },
    closeValidation() {
      if (this.feedBackOpen) {
        this.feedBackOpen = false;
        setTimeout(() => {
          this.feedBackOpen = null;
        }, 1000);
      }
    },
    openValidation() {
      this.feedBackOpen = true;
    },
    animateValidationBox() {
      // Show the validation box -> start gradient animation -> close
      this.openValidation();
      clearTimeout(this.timerToHideValidation);
      this.isValidationShow = true;
      setTimeout(() => {
        this.isValidationShow = false;
        this.timerToHideValidation = setTimeout(() => {
          this.closeValidation();
        }, 3000);
      }, 750);
    },
    initializeSliderOptionsAsCurrentDaySelected() {
      let [startTime, endTime] = this.rangeTime;

      const lastTimeUpdated = this.lastTimeUpdated.format('HH:mm');

      if (startTime > lastTimeUpdated || this.isStartSliderOnNextDay) startTime = '04:00';
      if (endTime > lastTimeUpdated || this.isEndSliderOnNextDay) endTime = lastTimeUpdated;

      this.rangeTime = [startTime, endTime];
    },
    // Atualiza o slider com base nos valores inseridos manualmente pelos inputs de time.
    changeStartTimeValue(event) {
      this.rangeTime = [event.target.value, this.rangeTime[1]];
    },
    changeEndTimeValue(event) {
      this.rangeTime = [this.rangeTime[0], event.target.value];
    },
    changeDay(day) {
      const isCurrentDaySelected = day === 0;
      if (isCurrentDaySelected) {
        this.initializeSliderOptionsAsCurrentDaySelected();
      }
      else {
        this.rangeTime = ['04:00', '03:59'];
      }
      this.day = day;
    },
    clearRangeTimeOptions() {
      const isCurrentDaySelected = this.day === 0;

      const [startTime, endTime] = ['04:00', '03:59'];

      if (isCurrentDaySelected) {
        this.rangeTime = [startTime, this.lastTimeUpdated.format('HH:mm')];
      } else {
        this.rangeTime = [startTime, endTime];
      }
    },
    applyChanges() {
      const [startTime, endTime] = this.rangeTime;


      if (!this.isValidRangeTimeSelection) {
        this.animateValidationBox();
        return;
      }

      this.closeOptions();
      this.$store.dispatch('selectDate', { day: this.day, startTime, endTime });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.resetDate {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
}

// Select
.select_dropdown {
  position: relative;
  height: 40px;
  box-shadow: 0 4px 24px rgba($color-gray-darker, 0.08);

  // DISABLED
  &.disabled {
    pointer-events: none;
    background-color: #CCCCCC;
    opacity: 0.2;
  }
  // ---------------------------

  .dropdown-menu {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    height: 100%;
    padding: 12px 16px;
    width: 216px;
    border-radius: 4px;

    font-family: $primary-typo;
    font-weight: 500;
    font-size: 0.64em;
    color: $color-gray-dark;
    cursor: pointer;
    background-position: center;
    background: $color-gray-lighter;
    transition: background 0.4s ease-in-out, color 0.4s ease-in-out;

    &:hover {
      background: $color-gray-lighter radial-gradient(circle, transparent 1%, $color-gray-lighter 1%) center/15000%;
      color: $color-secondary;
    }

    &:active {
      background-color: rgba($color-gray-light, 0.48);
      background-size: 100%;
      transition: background 0s;
    }

    i {
      font-size: 1.8em;
      margin: -2px 0 0 8px;
      opacity: 0.4;
    }
  }
  // Fim do Dropdown-Menu

  .options {
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 6;
    width: 324px;
    box-sizing: border-box;
    background-color: $color-gray-lighter;
    border-radius: 4px;
    box-shadow: 0 24px 80px rgba($color-gray-darker, 0.4);
    animation: fadeIn 0.4s ease-in-out forwards;

    // Títulos
    h2 {
      box-sizing: border-box;
      font-family: $secondary-typo;
      font-weight: 500;
      font-size: 0.64em;
      font-style: italic;
      color: $color-gray-darker;
      text-transform: uppercase;

      &::after {
        content: "";
        width: 16px;
        height: 2px;
        display: block;
        margin: 4px 0 0 0;
        background: rgba($color-gray-light, 0.64);
      }
    }
    h1 {
      width: 100%;
      box-sizing: border-box;
      padding: 16px 16px 0 16px;
      font-family: $secondary-typo;
      font-weight: 500;
      font-size: 0.64em;
      font-style: italic;
      color: $color-gray-darker;
      text-transform: uppercase;

      &::after {
        content: "";
        width: 16px;
        height: 2px;
        display: block;
        margin: 4px 0 0 0;
        background: rgba($color-gray-light, 0.64);
      }
    }

    .days {
      padding: 16px;
      display: flex;
      justify-content: space-between;
      font-family: $secondary-typo;
      font-weight: 400;
      font-size: 0.8em;
      color: $color-gray-main;
      border-bottom: solid 2px $color-gray-back;
      background-position: center;
      transition: background 0.4s ease-in-out, border-bottom 0.4s ease-in-out, color 0.4s ease-in-out;
      cursor: pointer;

      &:hover {
        background: $color-gray-back radial-gradient(circle, transparent 1%, $color-gray-back 1%) center/15000%;
        border-bottom: solid 2px $color-gray-light;
        color: $color-gray-dark;
      }

      &:active {
        background-color: rgba($color-gray-light, 0.48);
        background-size: 100%;
        transition: background 0s;
      }

      &.selected {
        font-weight: 600;
        color: $color-secondary;
      }
    }

    .time {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin: 0px;
      padding: 0px 18px 18px 18px;
      .time-selector {
        display: flex;
        height: 30px;
        width: 100%;
        margin-top: 10px;
        justify-content: space-between;
      }
      .time-selector .btn {
        font-size: 9px;
        margin: 0px;
        height: 23px;
        width: 60px;
        border-radius: 12px;
      }

      .item {
        position: relative;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        width: 48%;

        i {
          position: absolute;
          font-size: 1em;
          color: $color-gray-main;
          left: 8px;
        }

        input {
          width: 100%;
          background: $color-gray-lighter;
          box-shadow: 0 2px 16px rgba($color-gray-darker, 0.16);
          border: none;
          border-radius: 4px;
          padding: 12px 8px 12px 32px;
          margin-top: 20px;
          font-family: $secondary-typo;
          font-size: 0.88em;
        }

      }
    }

    .button {
      position: relative;
      width: 100%;

      .btn {
        border-radius: 0 0 4px 4px;
      }

      // Feedback
      .feedback {
        display: flex;
        align-items: center;
        border-radius: 0 0 4px 4px;
        text-align: center;
        font-size: 0.8em;
        height: 56px;
        padding: 0 24px;
      }
    }
  }
  // Fim do options

  &:after {
    content: " ";
    position: absolute;
    top: 50%;
    margin-top: -2px;
    right: 16px;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid $color-gray-main;
    pointer-events: none;
  }
}
// Fim do Select --------------

// Validation
.feedback {
  position: absolute;
  bottom: 0;
  z-index: 4;
  width: 100%;
  height: 64px;
  opacity: 0;
  pointer-events: none;
  box-sizing: border-box;
  padding: 16px 14px;

  font-family: $secondary-typo;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1.4em;
  color: rgba($color-gray-lighter, 1);
  border-radius: 4px;

  background: linear-gradient(233deg, #630d58, #a8203c);
  background-size: 100% 100%;

  // Show
  &.feedback--show {
    opacity: 0;
    animation: fadeInUp 0.8s ease-in-out forwards;
  }

  // Hide
  &.feedback--hide {
    opacity: 1;
    animation: fadeOutDown 0.8s ease-in-out forwards;
  }
}

// Second Break : 850px
@media (max-width: 850px) {

  .header {
    .box {
      .navigation {

        // Select
        .select_dropdown {

          .dropdown-menu {
            padding: 14px 8px 12px 8px;
            width: 156px;
            font-size: 0.56em;

            i {
              font-size: 1.6em;
              margin: -1px 0 0 8px;
            }
          }
          // Fim do Dropdown-Menu

          .options {
            left: inherit;
            right: 0;
          }
          // Fim do options
        }
        // Fim do Select --------------
      }
    }
  }
}
</style>
