<template>
  <div class="home fullscreen">
    <RouterView v-if="marketDays[settings.dateOptions.day] && globoSchedule.globoSchedules.length > 0 && market.markets.length > 0" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import _ from 'lodash';
import moment from 'moment-timezone';
import 'moment/locale/pt-br';

moment.tz.setDefault('America/Sao_Paulo');
moment.locale('pt-br');

export default {
  data() {
    return {
      allowedMainTvNetworkMoves: process.env.ALLOWED_MAIN_TV_NETWORKS_SETTINGS_PANEL_MOVE,
      lastProgramTimeout: null,
      lastProgramInterval: null,
    };
  },
  computed: {
    ...mapState(['globoSchedule', 'market', 'system', 'settings']),
    ...mapGetters(['marketDays']),
    marketsEnabled() {
      return this.settings.marketIdOrder.map(
        marketId => this.market.markets.find(market => market.id === marketId),
      ).filter(market => market instanceof Object);
    },
    marketsDefaultEnabled() {
      const { MARKETS_DEFAULT } = process.env;

      return MARKETS_DEFAULT.map(
        marketId => this.market.markets.find(market => market.id === marketId),
      ).filter(market => market instanceof Object);
    },
    tvNetworksAvailable() {
      return _.union(...this.marketsEnabled
        .map(({ tvNetworks }) => tvNetworks.map(({ id }) => id)));
    },
    tvNetworksEnabled() {
      return this.settings.tvNetworkIdOrder.filter(
        tvNetworkId => this.tvNetworksAvailable.indexOf(tvNetworkId) >= 0,
      );
    },
    tvNetworksDefaultEnabled() {
      const { TV_NETWORKS_DEFAULT } = process.env;
      return TV_NETWORKS_DEFAULT.filter(
        tvNetworkId => this.tvNetworksAvailable.indexOf(tvNetworkId) >= 0,
      );
    },
  },
  async beforeCreate() {
    try {
      await this.$store.dispatch('getMarkets');
      this.setupSystem();
    } catch (error) {
      this.$store.dispatch('showError', { clicked: true });
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.unwatch = this.$store.watch(state => state.settings, async () => {
      this.$store.dispatch('getDays');
      this.$store.dispatch('updatePreferencesDB');
      await this.$store.dispatch('getLastProgram');
    }, { deep: true });
  },
  beforeDestroy() {
    window.addEventListener('resize', this.handleResize);
    this.unwatch();
    clearTimeout(this.lastProgramTimeout);
    clearInterval(this.lastProgramInterval);
  },
  methods: {
    checkMarkets() {
      let marketIdsEnabled = this.marketsEnabled.map(({ id }) => id);
      const updateMarket = !_.isEqual(marketIdsEnabled, this.settings.marketIdOrder);
      if (updateMarket) {
        if (marketIdsEnabled.length === 0) {
          marketIdsEnabled = this.marketsDefaultEnabled.map(({ id }) => id);
          this.$store.dispatch('selectMarketIdOrder', marketIdsEnabled);
        }

        // Select the first market
        const defaultMarket = this.market.markets.find(
          ({ id }) => id === marketIdsEnabled[0],
        );
        this.$store.dispatch('selectMarket', defaultMarket);

        // If there is only one market we need select market view
        if (marketIdsEnabled.length === 1) {
          this.$store.dispatch('selectIsMarketView', true);
        }
      } else {
        const defaultMarket = this.market.markets.find(
          ({ id }) => id === this.settings.market.id,
        );

        if (!_.isEqual(defaultMarket, this.settings.market)) {
          this.$store.dispatch('selectMarket', defaultMarket);
        }
      }

      let { tvNetworksEnabled } = this;
      // Force Globo to be the first item, maybe we need to check the index
      if (!this.allowedMainTvNetworkMoves.includes(tvNetworksEnabled[0])) {
        const globoIndex = tvNetworksEnabled.findIndex(x => x === 'GLOBO');
        if (globoIndex !== -1) {
          tvNetworksEnabled.splice(globoIndex, 1);
        }
        tvNetworksEnabled.unshift('GLOBO');
      }
      if (!_.isEqual(tvNetworksEnabled, this.settings.tvNetworkIdOrder)
        || updateMarket) {
        if (tvNetworksEnabled.length < 2) {
          tvNetworksEnabled = this.tvNetworksDefaultEnabled;
        }
        this.$store.dispatch('selectTvNetworkIdOrder', tvNetworksEnabled);
        if (!tvNetworksEnabled.includes(this.settings.tvNetworkId)) {
          this.$store.dispatch('selectTvNetworkId', tvNetworksEnabled[0]);
        }
      }
    },
    async setupSystem() {
      if (this.market.markets.length === 0) {
        this.$store.dispatch('showError', {
          insides: ['notallowed_back', 'notallowed_item', 'notallowed_block'],
          subtitle: 'Você ainda não possui restrições atribuídas ao seu usuário. Por favor, entre em contato com a área de Pesquisa para solicitar o acesso.',
          buttons: [{
            text: 'sair',
            click: () => this.logout(),
          }],
        });
      } else {
        this.checkMarkets(); // just for automatic select default markets and tvnetworks
        try {
          await this.$store.dispatch('getRivalsSchedules');
          // this need to be updated when there is no program on live
          await this.$store.dispatch('getGloboSchedules');

          await this.$store.dispatch('getNetworks');
          await this.$store.dispatch('getSchedules');

          this.setupDays();
        } catch (error) {
          this.$store.dispatch('showError', { clicked: true });
        }
      }
    },
    async setupDays() {
      try {
        this.$store.dispatch('getDays');
        await this.$store.dispatch('getLastProgram');
        this.setProgramTimeout();
      } catch (error) {
        console.error(error);
      }
    },
    setProgramTimeout() {
      const date = new Date();
      const startOfMinute = (60 - date.getSeconds()) * 1000;
      this.lastProgramTimeout = setTimeout(() => {
        this.lastProgramInterval = setInterval(async () => {
          await this.$store.dispatch('getGloboSchedules');
          await this.$store.dispatch('getSchedules');
          this.$store.dispatch('getDays');
          this.$store.dispatch('getLastProgram');
        }, 30000);
        this.$store.dispatch('getLastProgram');
      }, startOfMinute);
    },
    logout() {
      this.$store.dispatch('logout');
      this.$store.dispatch('hideError');
    },
    handleResize() {
      const elem = document.getElementById('scrollArea');
      if (elem) {
        elem.scrollLeft = 0;
        elem.scrollTop = this.settings.isDescending ? 0 : elem.scrollHeight;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @import '@/assets/scss/variables.scss';
// @import "@/assets/scss/audience.scss";
// @import "@/assets/scss/audience-responsive.scss";
// @import "@/assets/scss/audience-fullscreen.scss";

</style>
