var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{mobile_tips: _vm.isTourOpen}},[_c('Tips',{ref:"tipStartTutorial",attrs:{"title":"Bem-vindo","contents":[
      'Deseja iniciar o tutorial? Em 3 minutos faremos um tour mostrando seu novo app. Se preferir fazer em outro momento, o tutorial estará disponível na tela de configurações, último botão da barra de navegação :)'
    ],"is-filled":true,"has-cancel-btn":true},on:{"on-cancel":function($event){return _vm.finishTipsTour()},"on-open":function($event){return _vm.goToPage('Home')},"on-confirm":function($event){return _vm.openTip('tipTutorial')}}}),_vm._v(" "),_c('Tips',{ref:"tipTutorial",attrs:{"title":"Tutorial","contents":[
      'Vamos iniciar o tour pelo app! Utilize os botões ( <  > ) para retroceder e avançar pelas dicas. Durante o tutorial as telas estarão bloqueadas, impedindo a interação com os elementos da interface.' ],"is-filled":true,"has-cancel-btn":true,"cancel-icon":"keyboard_arrow_left","confirm-icon":"keyboard_arrow_right"},on:{"on-open":function($event){return _vm.goToPage('Home')},"on-confirm":function($event){return _vm.openTip('tipHome')},"on-cancel":function($event){return _vm.openTip('tipStartTutorial')}}}),_vm._v(" "),_c('Tips',{ref:"tipHome",attrs:{"title":"Tabelas","contents":[
      'A tabela exibe os dados de audiência ou share em tempo real. Ao realizar gestos verticais, é possível visualizar mais minutos. Já com os gestos horizontais, visualizar mais emissoras.',
      'No topo do app é possível visualizar o programa que está no ar e sua média atualizada em tempo real. Caso o seu usuário tenha restrições de horário, será exibido o último programa dentro da regra.',
      'Ainda no topo, mas na esquerda, temos o botão de filtros. Vamos conhecer um pouco mais dessa funcionalidade.'
    ],"is-filled":true,"has-cancel-btn":false,"cancel-icon":"keyboard_arrow_left","confirm-icon":"keyboard_arrow_right"},on:{"on-open":function($event){return _vm.goToPage('Home')},"on-confirm":function($event){return _vm.openTip('tipFilter')}}}),_vm._v(" "),_c('Tips',{ref:"tipFilter",attrs:{"title":"Filtros","contents":[
      'Na tela de filtros é possível alternar entre as visões de minutos e programas, além da navegação por praças e emissoras.',
      'Nessa tela também é possível aplicar filtros de dias e horários, além de definir suas preferências para dados e indicadores que serão exibidos na aplicação.',
      'Já passamos pela tela de tabelas e como configurar suas visões. Vamos agora conhecer um pouco mais do Dashboard de Programas.'
    ],"confirm-icon":"keyboard_arrow_right","cancel-icon":"keyboard_arrow_left","is-filled":true,"has-cancel-btn":false},on:{"on-open":function($event){return _vm.openUserPreferences()},"on-close":function($event){return _vm.closeUserPreferences()},"on-confirm":function($event){return _vm.openTip('tipDashboards')}}}),_vm._v(" "),_c('Tips',{ref:"tipDashboards",attrs:{"title":"Dashboard","contents":[
      'O Dashboard de Programas oferece uma alternativa visual para consumir os dados de audiência. Acompanhe o desempenho dos programas por praça no topo do app. Ao tocar no botão com a seta para baixo, o conteúdo será ampliado na tela, oferecendo uma visão de todas as praças.',
      'Visualize em tempo real o desempenho dos programas através da esteira. É possível voltar para os programas anteriores e ter acesso às informações de entrega e média.',
      'Utilize a navegação de cards na parte de baixo da tela para comparar o desempenho dos programas com a programação das emissoras concorrentes.',
      'Já passamos pelas tabelas e dashboard, vamos agora para o último passo do tutorial: a tela de configurações.'
    ],"confirm-icon":"keyboard_arrow_right","cancel-icon":"keyboard_arrow_left","is-filled":true,"has-cancel-btn":false},on:{"on-open":function($event){return _vm.goToPage('Dashboards')},"on-confirm":function($event){return _vm.openTip('tipSettings')}}}),_vm._v(" "),_c('Tips',{ref:"tipSettings",attrs:{"title":"Dicas","contents":[
      'Na tela de configurações é possível ativar ou desativar as praças e emissoras que estiverem disponíveis para o seu perfil.'
    ],"confirm-icon":"keyboard_arrow_right","cancel-icon":"keyboard_arrow_left","is-filled":true,"has-cancel-btn":false},on:{"on-open":function($event){return _vm.goToPage('Settings')},"on-confirm":function($event){return _vm.openTip('tipMarket')}}}),_vm._v(" "),_c('Tips',{ref:"tipMarket",attrs:{"title":"Dicas","contents":[
      'Utilize o botão de switch para ligar ou desligar uma praça. Para mudar a ordem, basta segurar de forma prolongada o item e arrastar para cima ou para baixo. Salve suas alterações tocando no botão de aplicar.'
    ],"confirm-icon":"keyboard_arrow_right","cancel-icon":"keyboard_arrow_left","is-filled":true,"has-cancel-btn":true},on:{"on-open":function($event){return _vm.goToPage('MarketPreferences')},"on-confirm":function($event){return _vm.openTip(_vm.isApp ? 'tipNotification' : 'tipHome3')},"on-cancel":function($event){return _vm.openTip('tipSettings')}}}),_vm._v(" "),_c('Tips',{ref:"tipNotification",attrs:{"title":"Dicas","contents":[
      'Utilize o botão de switch para selecionar as praças e ligar ou desligar o envio de notificação com sua audiência ao término do programa.'
    ],"confirm-icon":"keyboard_arrow_right","cancel-icon":"keyboard_arrow_left","is-filled":true,"has-cancel-btn":true},on:{"on-open":function($event){return _vm.goToPage('Notifications')},"on-confirm":function($event){return _vm.openTip('tipHome3')},"on-cancel":function($event){return _vm.openTip('tipSettings')}}}),_vm._v(" "),_c('Tips',{ref:"tipHome3",attrs:{"title":"Parabéns","contents":[
      'Você concluiu o tutorial com sucesso! Caso precise visualizar as dicas novamente, acesse a tela de configurações.' ],"cancel-icon":"keyboard_arrow_left","has-cancel-btn":false,"is-filled":true},on:{"on-open":function($event){return _vm.goToPage('Home')},"on-confirm":function($event){return _vm.finishTipsTour()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }