<template>
  <Offline>
    <Orientation>
      <ErrorState
        v-if="errorOptions"
        :insides="errorOptions.insides"
        :title="errorOptions.title"
        :subtitle="errorOptions.subtitle"
        :clicked="errorOptions.clicked"
        :buttons="errorOptions.buttons"
      />
      <div v-else-if="isLoggedIn" style="padding-top: env(safe-area-inset-top, 0)">
        <RouterView v-if="user.currentUser" />
        <PageLoading v-else />
        <Chart />
        <ChartSimulcast />
        <Export />
        <ExportGroups />
        <ExportUsers />
        <Sidebar />
      </div>
      <Login v-else />
    </Orientation>
  </Offline>
</template>

<script>
/* eslint-disable no-undef */
import Login from '@/v2/Views/Login/Login.vue';
import { mapState, mapGetters } from 'vuex';
import fastclick from 'fastclick';
import Offline from '@/components/system/Offline.vue';
import Orientation from '@/components/system/Orientation.vue';
import PageLoading from '@/components/system/PageLoading.vue';
import ErrorState from '@/components/system/ErrorState.vue';
import Chart from '@/components/system/container/Chart.vue';
import ChartSimulcast from '@/components/system/container/ChartSimulcast.vue';
import Export from '@/components/system/container/Export.vue';
import ExportGroups from '@/components/system/container/ExportGroups.vue';
import ExportUsers from '@/components/system/container/ExportUsers.vue';
import Sidebar from '@/components/system/container/Sidebar.vue';


export default {
  components: {
    Login,
    Offline,
    Orientation,
    PageLoading,
    ErrorState,
    Chart,
    ChartSimulcast,
    Export,
    ExportGroups,
    Sidebar,
    ExportUsers,
  },
  computed: {
    ...mapState(['user']),
    ...mapGetters(['isLoggedIn', 'errorOptions']),
  },
  watch: {
    isLoggedIn: {
      immediate: true,
      async handler(newVal) {
        // Get Current User if is already logged in
        if (newVal) {
          try {
            await this.$store.dispatch('getCurrentUser');
            if (process.env.GOOGLE_ANALYTICS_APP_ID && this.$gtag) {
              this.$gtag.event('login', {
                user_email: this.user.currentUser.email,
              });
            }
          } catch (error) {
            await this.$store.dispatch('logout');
          }
        }
      },
    },
  },
  beforeMount() {
    // fastclick init becasuse mobiles has delay when click in buttons
    fastclick.attach(document.body);
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/theme.scss";
</style>
