<template>
  <!-- Tips -->
  <div v-if="isOpen !== null" class="container-tips" :class="{...tipsCss, ...containerTipsCss}">
    <!-- O container pode ter as classes: filled / transparent -->
    <div class="tips">
      <div class="header">
        // {{ title }}
      </div>
      <div class="middle">
        {{ contents[pageNumber] }}
      </div>
      <div class="footer">
        <button v-show="showCancelBtn" type="button" class="btn btn_individual btn_primary ripple"
                @click="cancel"
        >
          <i class="icone material-icons">
            {{ cancelIcon }}
          </i>
        </button>
        <button v-show="hasArrows && !showCancelBtn" type="button" class="btn btn_individual btn_primary ripple"
                :class="arrowLeftCss"
                @click="previousPage"
        >
          <i class="icone material-icons">
            keyboard_arrow_left
          </i>
        </button>
        <button v-show="hasArrows && !showConfirmButton" type="button" class="btn btn_individual btn_primary ripple"
                :class="arrowRightCss"
                @click="nextPage"
        >
          <i class="icone material-icons">
            keyboard_arrow_right
          </i>
        </button>
        <button v-if="showConfirmButton"
                type="button" class="btn btn_individual btn_secondary ripple"
                @click="confirm()"
        >
          <i class="icone material-icons">
            {{ confirmIcon }}
          </i>
        </button>
      </div>
    </div>
  </div>
  <!-- // -->
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    contents: {
      type: Array,
      default: () => [''],
    },
    isFilled: {
      type: Boolean,
      default: false,
    },
    autoOpen: {
      type: Boolean,
      default: false,
    },
    confirmIcon: {
      type: String,
      default: 'check',
    },
    cancelIcon: {
      type: String,
      default: 'close',
    },
    hasCancelBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pageNumber: 0,
      isOpen: null,
    };
  },
  computed: {
    containerTipsCss() {
      return {
        filled: this.isFilled,
        transparent: !this.isFilled,
      };
    },
    showConfirmButton() {
      return this.pageNumber >= this.contents.length - 1;
    },
    showCancelBtn() {
      return this.hasCancelBtn && this.pageNumber === 0;
    },
    hasArrows() {
      return this.contents.length > 1;
    },
    arrowLeftCss() {
      return {
        disabled: this.pageNumber === 0,
      };
    },
    arrowRightCss() {
      return {
        disabled: this.contents.length === (this.pageNumber + 1),
      };
    },
    checkButtonCss() {
      return {
        disabled: this.contents.length !== (this.pageNumber + 1),
      };
    },
    tipsCss() {
      return {
        in: this.isOpen,
        out: this.isOpen === false,
      };
    },
  },
  mounted() {
    if (this.autoOpen) this.open();
  },
  methods: {
    open() {
      this.$emit('on-open');
      this.pageNumber = 0;
      this.isOpen = true;
    },
    cancel() {
      this.close('on-cancel');
    },
    confirm() {
      this.close('on-confirm');
    },
    close(emitName) {
      this.isOpen = false;
      setTimeout(() => {
        this.isOpen = null;
        if (emitName) this.$emit(emitName);
        this.$emit('on-close');
      }, 1000);
    },
    nextPage() {
      this.pageNumber += 1;
    },
    previousPage() {
      this.pageNumber -= 1;
    },
  },
};
</script>

<style lang="scss" scoped>

@import '@/assets/scss/variables.scss';

.container-tips {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 999;

  &.filled {
    background-color: rgba($color-gray-lighter, 0.64);
  }

  &.transparent {
    background-color: rgba($color-gray-lighter, 0);
  }

  // Tips
  .tips {
    // display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: absolute;
    z-index: 999;
    top: 64px;
    right: 16px;
    width: 400px;
    border-radius: 8px 64px 8px 64px;

    box-shadow: 0 24px 80px rgba($color-gray-darker, 0.8);
    opacity: 0;
    font-family: $secondary-typo;

    background: linear-gradient(-140deg, $color-primary, $color-secondary);
    background-size: 140% 140%;

    // Header
    .header {
      width: 100%;
      height: 48px;
      padding: 16px;
      font-size: 0.8em;
      font-weight: 500;
      font-style: italic;
      color: $color-gray-lighter;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      &::after {
        display: block;
        content: "";
        width: 80%;
        height: 1px;
        margin: 8px 0 0 0;
        background: rgba($color-gray-lighter, 0.16);
      }
    }

    // Content
    .middle {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      padding: 16px 40px;
      font-weight: 400;
      font-size: 0.92em;
      line-height: 1.4em;
      text-align: justify;
      color: $color-gray-lighter;
      transition: all 0.4s ease-in-out;
    }

    // Footer
    .footer {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;
      margin: auto 0 0 0;
      padding: 0 16px 16px 16px;
      font-size: 0.8em;
      font-weight: 500;
      color: $color-gray-lighter;
      text-transform: uppercase;
      letter-spacing: 0.2em;

      .btn {
        width: 32px;
        height: 32px;
        padding: 0;
        margin: 16px 0 0 8px;

        i {
          margin: 0;
          padding: 0;
          color: $color-gray-main;
        }

        &.btn_primary {
          background-color: $color-secondary;

          i {
            color: $color-gray-lighter;
          }

          &:hover {
            background-color: $color-secondary;

            i {
              color: $color-gray-lighter;
            }
          }

          &:active {
            background-color: $color-secondary;
          }
        }

        &.btn_secondary {
          background-color: $color-gray-lighter;
          border: none;

          i {
            color: $color-primary;
          }

          &:hover {
            background-color: rgba($color-primary, 0.8);
            border: none;

            i {
              color: $color-gray-lighter;
            }
          }
        }

        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
  }

    // Tratamento de entrada
    &.in {
      animation: fadeIn 0.8s forwards;

      .tips {
        pointer-events: visible;
        animation: slideInDown 0.8s forwards, tipsRound 8s ease-in-out infinite;

        @keyframes tipsRound {
          0% {
            opacity: 1;
            border-radius: 8px 64px 8px 64px;
          }

          25% {
            opacity: 1;
            border-radius: 8px 16px 8px 64px;
          }

          50% {
            opacity: 1;
            border-radius: 8px 64px 8px 16px;
          }

          75% {
            opacity: 1;
            border-radius: 8px 16px 8px 64px;
          }

          100% {
            opacity: 1;
            border-radius: 8px 64px 8px 64px;
          }
        }
      }
    }

    // Tratamento de saída
    &.out {
      animation-delay: 0.4s !important;
      animation: fadeOut 0.8s forwards;

      .tips {
        opacity: 1;
        pointer-events: none;
        animation: bounceOutUp 1s ease-in-out forwards, tipsRound 8s ease-in-out infinite;
      }
    }
  }

// Fim dos tips
// ----------------------------------
// ----------------------------------

  // SMARTPHONES
  @media (max-width: 600px) {

    .container-tips {
      display: block;

      .tips {
        position: fixed;
        display: flex;
        top: inherit;
        bottom: 96px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
        height: auto;
        text-align: justify;
        pointer-events: none;
        box-shadow: 0 4px 24px 4px rgba($color-gray-darker, 0.24);
      }

      // Tratamento de entrada
      &.in {
        position: fixed;
        width: 100vw;
        height: 100vh;
        animation: fadeIn 0.4s forwards;

        .tips {
          pointer-events: visible;
          opacity: 1;
          animation: slideInUp 0.8s forwards;
        }
      }

      // Tratamento de saída
      &.out {
        animation-delay: 0.4s !important;
        animation: fadeOut 0.4s forwards;

        .tips {
          opacity: 1;
          pointer-events: none;
          animation: bounceOutDown 1s ease-in-out forwards;
        }
      }
    }

  }
</style>
