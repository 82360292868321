<template>
  <Modal :name="name"
         height="100%"
         width="500px"
         :pivot-x="1"
         :delay="500"
         @before-open="beforeOpen"
         @before-close="beforeClose"
  >
    <header>
      <i class="icone material-icons">
        {{ icon }}
      </i>
      <h1>{{ title }}</h1>
      <div class="buttons">
        <button type="button" class="btn btn_individual btn_secondary ripple"
                @click="closeSidebar()"
        >
          <i class="icone material-icons">
            close
          </i>
        </button>
      </div>
    </header>
    <div class="content">
      <component :is="componentName" v-bind="props" />
    </div>
  </Modal>
</template>

<script>
import UserRules from './Sidebar/UserRules.vue';
import GroupRules from './Sidebar/GroupRules.vue';
import CreateProgram from './Sidebar/CreateProgram.vue';
import CreateFrame from './Sidebar/CreateFrame.vue';
import CreateRule from './Sidebar/CreateRule.vue';
import Filters from './Sidebar/Filters.vue';


export default {
  components: {
    UserRules,
    GroupRules,
    CreateProgram,
    CreateFrame,
    CreateRule,
    Filters,
  },
  data() {
    return {
      name: 'sidebar',
      componentName: null,
      title: '',
      icon: '',
      props: {},
      onClose: null,
    };
  },
  methods: {
    beforeOpen({ params, stop }) {
      const { componentName, title, icon, onClose, ...props } = params;

      this.title = title || '';
      this.icon = icon || '';
      this.props = props;
      this.onClose = onClose;
      this.componentName = componentName || null;

      if (!componentName) stop();
    },
    async beforeClose() {
      if (this.onClose) await this.onClose();
    },
    closeSidebar() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.v--modal-overlay {
  background-color: rgba($color-gray-darker, 0.8);

  &.overlay-fade-enter-active {
    animation: fadeIn 1s ease-in-out forwards;
  }

  &.overlay-fade-leave-active {
    animation: fadeOut 1s ease-in-out forwards;
  }

  & /deep/ .v--modal {
    border-radius: 0;
    display: flex;
    flex-direction: column;

    &.v-enter-active {
      animation: fadeInRight 0.8s ease-in-out forwards;
    }

    &.v-leave-active {
      animation: slideOutRight 0.8s ease-in-out forwards;
    }

    header {
      position: relative;
      z-index: 2;
      height: 80px;
      padding: 0 24px;
      background-color: $color-gray-lighter;
      box-shadow: 0 4px 40px rgba($color-gray-darker, 0.16);
      display: flex;
      align-items: center;

      h1 {
        flex-grow: 1;
        font-family: $primary-typo;
        font-size: 0.8em;
        font-weight: normal;
        color: $color-gray-darker;
        text-transform: uppercase;
        letter-spacing: 0.08em;
      }

      i {
        font-size: 1.2em;
        margin-right: 12px;
        color: rgba($color-gray-dark, 0.4);
      }

      .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        margin: 0 0 0 auto;
        padding: 0;

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          box-shadow: none;

          i {
            margin: 0;
            padding: 0 4px;
            color: $color-primary;
          }

          &:hover {
            i {
              color: $color-gray-lighter;
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-grow: 1;
      height: 0;
      background-color: $color-gray-back;
      overflow: auto;
      font-family: $secondary-typo;
    }
  }
}

</style>
