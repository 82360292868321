<template>
  <div id="scrollArea" class="program_vision">
    <Tips
      ref="tipProgramVision"
      title="Dashboard"
      :contents="[
        'Esse é o Dashboard de Programas, uma nova forma de consumir dados de audiência em tempo real.',
        'Acompanhe o desempenho dos programas em todas as praças. As cores de status constroem um mapa de calor que revela o desempenho de cada minuto exibido. Ao clicar em uma nova praça os dados serão atualizados na tela.',
        'Com a esteira de programas é possível acompanhar em tempo real os dados de média. Navegue entre os programas que terminaram e visualize as informações de média e entrega.',
        'Os valores de média e entrega são acompanhados de um indicador que compara a média atual com o desempenho histórico do programa. O intervalo que gera esse indicador pode ser configurado nas preferências, alternando entre 2 ou 4 semanas.',
        'Compare o desempenho dos programas da TV Globo com as emissoras concorrentes mais bem posicionadas. A lista exibida é ordenada por emissoras com melhor desempenho.',
      ]"
      :is-filled="true"
      :has-cancel-btn="true"
    />

    <div class="head">
      <div class="title">
        AUDIÊNCIA REAL TIME
      </div>
      <div class="market">
        {{ marketSelected.exhibitionName }}
      </div>
      <div class="update">
        último min. {{ lastMinute ? lastMinute : '--:--' }}
      </div>
      <div class="tag">
        {{ tagName }} - {{ settings.performanceHistory }} Semanas
      </div>

      <div class="subtitle">
        <div class="item won">
          Ganhou
        </div>

        <div class="item lost">
          Perdeu
        </div>

        <div class="item drew">
          Empatou
        </div>
      </div>

      <!-- Actions -->
      <div class="actions">
        <button type="button" class="btn btn_header ripple" @click="openTipProgramVision()">
          <i class="icone material-icons tool" data-tip="Dicas">
            help
          </i>
        </button>
      </div>
    </div>
    <!-- // -->

    <!-- Esteira de Programas -->

    <!-- Swiper -->
    <!-- {{ covers }} -->
    <div class="esteira swiper-container">
      <div class="swiper-wrapper" />
      <!-- Add Arrows -->
      <div class="swiper-button-next swiper-button-white" />
      <div class="swiper-button-prev swiper-button-white" />
    </div>
    <!-- // -->

    <!-- <div class="timeline">
      <div class="average past" :class="{disabled: !previousProgram,
                                         ...programStatusCss(previousProgram)}"
      >
        <span class="title">
          Média
        </span>
        <span class="value">

          <AnimatedNumber v-if="getProgramValue(previousProgram)"
                          :value="getProgramValue(previousProgram)"
          />
          <svg v-else class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
        <span v-if="getProgramValue(previousProgram) && kpiPreviousProgram" class="extra"
              :class="kpiStatusCss(previousProgram, kpiPreviousProgram)"
        >
          <div>{{ diffPoints(previousProgram, kpiPreviousProgram) }}pts</div>
          <div>{{ percentPoints(previousProgram, kpiPreviousProgram) }}%</div>
        </span>
        <span v-else class="extra">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
      </div>


      <div class="delivery past" :class="{disabled: !previousProgram, [lastMinutePreviousClass]: true}">
        <span class="title">
          Entrega
        </span>
        <span class="value">
          <AnimatedNumber v-if="lastMinutePreviousProgram" :value="lastMinutePreviousProgram" />
          <svg v-else class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
        <span v-if="getProgramValue(previousProgram) && kpiPreviousProgramLastMinute" class="extra"
              :class="kpiStatusCss(previousProgram, kpiPreviousProgramLastMinute)"
        >
          <div>{{ diffPoints(previousProgram, kpiPreviousProgramLastMinute) }}pts</div>
          <div>{{ percentPoints(previousProgram, kpiPreviousProgramLastMinute) }}%</div>
        </span>
        <span v-else class="extra">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
      </div>

      <div class="average now" :class="programStatusCss(selectedProgram)">
        <span class="title">
          Média
        </span>
        <span v-if="selectedProgram" class="value">

          <AnimatedNumber v-if="getProgramValue(selectedProgram)" :value="getProgramValue(selectedProgram)" />
          <svg v-else class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
        <span v-if="getProgramValue(selectedProgram) && kpiSelectedProgram" class="extra"
              :class="kpiStatusCss(selectedProgram, kpiSelectedProgram)"
        >
          <div>{{ diffPoints(selectedProgram, kpiSelectedProgram) }}pts</div>
          <div>{{ percentPoints(selectedProgram, kpiSelectedProgram) }}%</div>
        </span>
        <span v-else class="extra">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
      </div>


      <div v-if="isSelectedInCurrentProgram" class="delivery future won" :class="{disabled: !isToday}">
        <span class="title">
          Entrega Histórica
        </span>
        <span class="value">
          <AnimatedNumber :value="kpiSelectedProgramLastMinute" />
        </span>
        <span class="extra">
          --
        </span>
      </div>
      <div v-else-if="nextProgram" class="delivery past" :class="lastMinuteSelectedClass">
        <span class="title">
          Entrega
        </span>
        <span class="value">
          <AnimatedNumber v-if="lastMinuteSelectedProgram" :value="lastMinuteSelectedProgram" />
          <svg v-else class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
        <span v-if="getProgramValue(selectedProgram) && kpiSelectedProgramLastMinute" class="extra"
              :class="kpiStatusCss(selectedProgram, kpiSelectedProgramLastMinute)"
        >
          <div>{{ diffPoints(selectedProgram, kpiSelectedProgramLastMinute) }}pts</div>
          <div>{{ percentPoints(selectedProgram, kpiSelectedProgramLastMinute) }}%</div>
        </span>
        <span v-else class="extra">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
      </div>

      <div v-if="isSelectedInCurrentProgram" class="average future won" :class="{disabled: !isToday}">
        <span class="title">
          Média Histórica
        </span>
        <span class="value">
          <AnimatedNumber
            :value="kpiFutureProgram"
          />
        </span>
        <span class="extra">
          --
        </span>
      </div>
      <div v-else-if="nextProgram" class="average past" :class="programStatusCss(nextProgram)">
        <span class="title">
          Média
        </span>
        <span class="value">
          <template v-if="nextProgram">
            <AnimatedNumber v-if="getProgramValue(nextProgram)" :value="getProgramValue(nextProgram)" />
            <svg v-else class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
              <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
            </svg>
          </template>
        </span>
        <span v-if="getProgramValue(nextProgram) && kpiNextProgram" class="extra" :class="kpiStatusCss(nextProgram, kpiNextProgram)">
          <div>{{ diffPoints(nextProgram, kpiNextProgram) }}pts</div>
          <div>{{ percentPoints(nextProgram, kpiNextProgram) }}%</div>
        </span>
        <span v-else class="extra">
          <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
        </span>
      </div>
    </div> -->
  </div>
</template>

<script>
import anime from 'animejs';
import Swiper from 'swiper/dist/js/swiper.min';
import 'swiper/dist/css/swiper.min.css';
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapGetters } from 'vuex';
import globoImg from '../../../../../static/tv-network/brand-globo-white.png';
import bffRealtime from '@/gateways/bff-realtime';
import Tips from '@/components/modules/Tips.vue';

export default {
  components: {
    Tips,
  },
  props: {
    globoPrograms: {
      type: Array,
      default: () => [],
    },
    lastMinute: {
      type: String,
      default: '',
    },
    lastMinutePreviousProgram: {
      type: Number,
      default: null,
    },
    lastMinutePreviousClass: {
      type: String,
      default: '',
    },
    lastMinuteSelectedProgram: {
      type: Number,
      default: null,
    },
    lastMinuteSelectedClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      covers: [],
      swiper: null,
      indexSelected: 0,
      prevIndex: null,
      kpiFutureProgram: null,
      kpiPreviousProgramLastMinute: null,
      kpiSelectedProgramLastMinute: null,
      kpiNextProgram: null,
      kpiSelectedProgram: null,
      kpiPreviousProgram: null,
      lastMarketIdSelected: null,
      values: {},
    };
  },
  computed: {
    tagName() {
      return {
        audience: 'Audiência',
        shareTL: 'Share TL',
        shareTLE: 'Share TLE',
      }[this.singleDefaultValue];
    },
    marketSelected() {
      return this.settings.market;
    },
    previousProgram() {
      const previousIndex = this.indexSelected - 1;
      return this.globoPrograms[previousIndex];
    },
    selectedProgram() {
      const { indexSelected, globoPrograms } = this;

      return {
        indexSelected,
        ...globoPrograms[indexSelected],
      };
    },
    nextProgram() {
      const nextIndex = this.indexSelected + 1;
      return this.globoPrograms[nextIndex];
    },
    allPrograms() {
      return this.isToday ? [...this.globoPrograms, this.futureProgram] : this.globoPrograms;
    },
    isToday() {
      const { day, endTime } = this.settings.dateOptions;
      return day === 0 && !endTime;
    },
    currentProgram() {
      if (!this.globoPrograms || !this.isToday) return null;
      return this.globoPrograms[this.globoPrograms.length - 1];
    },
    futureProgram() {
      if (!this.currentProgram) return null;
      const unknowProgram = {
        program: 'Em breve',
        startsIn: this.currentProgram.endsIn,
        marketName: this.settings.market.name,
      };
      // the next line is not necessary when the back pass numSeqExib in programAverages
      const futureProgram = this.getNextProgram(this.currentProgram) || unknowProgram;
      return futureProgram;
    },
    isFutureProgramExists() {
      return this.futureProgram && this.futureProgram.endsIn;
    },
    isSelectedInCurrentProgram() {
      return this.indexSelected === this.globoPrograms.length - 1;
    },
    isSelectedInFirstProgram() {
      return this.indexSelected === 0;
    },
    ...mapState(['settings']),
    ...mapGetters(['programByDate', 'getNextProgram', 'programAverages', 'programImg', 'singleDefaultValue']),
  },
  watch: {
    globoPrograms(newVal, oldVal) {
      if (!newVal || !oldVal) return;
      this.updateIndexSelected(newVal, oldVal);
    },
    selectedProgram() {
      this.updateValues();
    },
    lastMinutePreviousProgram(newVal) {
      if (newVal) this.updateValues();
    },
    lastMinuteSelectedProgram(newVal) {
      if (newVal) this.updateValues();
    },
  },
  mounted() {
    this.initSwiper();
    this.indexSelected = (this.isToday ? this.globoPrograms.length - 1 : 0);
    this.remountEverything();
  },
  methods: {
    kpiDefaultValue(kpi) {
      const valueKey = {
        audience: 'audience',
        shareTL: 'share',
        shareTLE: 'shareE',
      }[this.singleDefaultValue];
      return kpi[valueKey];
    },
    openTipProgramVision() {
      this.$refs.tipProgramVision.open();
    },
    programStatusCss(program) {
      if (!program
        || !program.tvNetworkWinners
        || !program.tvNetworkWinners[this.settings.market.id]
      ) return {};
      const winners = program.tvNetworkWinners[this.settings.market.id];
      const hasGloboInWinners = winners.includes('GLOBO');
      const hasMoreThanOneWinner = winners.length > 1;
      return {
        won: hasGloboInWinners && !hasMoreThanOneWinner,
        drew: hasGloboInWinners && hasMoreThanOneWinner,
        lost: !hasGloboInWinners,
      };
    },
    kpiStatusCss(program, kpi) {
      if (!program || !kpi) return {};
      const points = this.getProgramValue(program) - kpi;
      return {
        won: points > 0,
        drew: points === 0,
        lost: points < 0,
      };
    },
    updateIndexSelected(newVal, oldVal) {
      const marketChanged = (this.lastMarketIdSelected !== this.marketSelected.id);
      const forceUpdate = (newVal.length !== oldVal.length || marketChanged);
      if (forceUpdate) {
        if (marketChanged) {
          const { startsIn } = oldVal[this.indexSelected];
          const programs = newVal.map((globoProgram, index) => ({
            index,
            diff: Math.abs(moment(globoProgram.startsIn).diff(startsIn, 'minutes')),
          }));

          this.indexSelected = _.orderBy(programs, ['diff'], ['asc'])[0].index;
        } else {
          const wasInTheLastSlide = (this.indexSelected === oldVal.length - 1);
          const defaultIndexDay = (this.isToday ? newVal.length - 1 : 0);
          this.indexSelected = (wasInTheLastSlide) ? defaultIndexDay : this.indexSelected;
        }
        this.remountEverything();
      }
    },
    getProgramValue(program) {
      if (!program
        || !program.averages
        || !program.averages[this.marketSelected.id]
        || !program.averages[this.marketSelected.id].GLOBO
      ) return null;
      return program.averages[this.marketSelected.id].GLOBO[this.singleDefaultValue];
    },
    percentPoints(program, kpi) {
      if (!program) return null;

      const programValue = this.getProgramValue(program);
      if (!programValue || !kpi) return null;

      return (100 * programValue / kpi) - 100;
    },
    diffPoints(program, kpi) {
      if (!program) return null;

      const programValue = this.getProgramValue(program);
      if (!programValue || !kpi) return null;

      return programValue - kpi;
    },
    remountEverything() {
      this.prevIndex = null;
      this.lastMarketIdSelected = this.marketSelected.id;
      this.removeAllSlide();
      this.appendAllPrograms(this.indexSelected);
      this.goToSlide(this.indexSelected);
    },
    slidePrimaryClassCss(selectedIndex, index) {
      if (selectedIndex > index) return 'past';
      if (selectedIndex === index) return 'now';
      return 'future';
    },
    slideSecondaryClassCss(index) {
      if (index === this.globoPrograms.length - 1) return 'current';
      if (index === this.allPrograms.length - 1) return 'last';
      return '';
    },
    getSlideClassName(selectedIndex, index) {
      const primaryClass = this.slidePrimaryClassCss(selectedIndex, index);
      const secondaryClass = this.slideSecondaryClassCss(index);
      return `${primaryClass} ${secondaryClass}`;
    },
    appendAllPrograms(indexSelected) {
      this.allPrograms.forEach((program, index) => {
        const imgUrl = this.programImg(program);
        const className = this.getSlideClassName(indexSelected, index);
        this.appendSlide(className, imgUrl, index);
      });
    },
    liveProgramHtmlTemplate() {
      return `
        <div class="now">
          <i class="material-icons">
            fiber_manual_record
          </i>
          No Ar
        </div>
      `;
    },
    slideHtmlTemplate(className, imgUrl, index) {
      const isLive = index === (this.globoPrograms.length - 1) && this.isToday;

      return `
        <div class="cover" style="background-image: url('${imgUrl}')">
          ${isLive ? this.liveProgramHtmlTemplate() : ''}
        </div>
      `;
    },
    slideTapumeHtmlTemplate(className, index) {
      const isLive = index === (this.globoPrograms.length - 1) && this.isToday;
      const feedbackText = 'A imagem será carregada assim que o programa entrar no ar';
      const programTime = moment(this.allPrograms[index].startsIn).format('HH:mm');
      return `
        <div class="placeholder">
          ${isLive ? this.liveProgramHtmlTemplate() : ''}
          <div class="brand">
            <img src="${globoImg}"/>
          </div>
          <div class="program-name">
            ${this.allPrograms[index].program}
          </div>
          <div class="time">
            ${programTime}
          </div>
          <div class="feedback">
            ${feedbackText}
          </div>
          <div class="icon">
            <i class="icone material-icons">
              broken_image
            </i>
          </div>
        </div>
      `;
    },
    appendSlide(className, imgUrl, index) {
      const isLive = index === (this.globoPrograms.length - 1) && this.isToday;

      const contentTemplate = (imgUrl)
        ? this.slideHtmlTemplate(className, imgUrl, index)
        : this.slideTapumeHtmlTemplate(className, index);

      const { program, startsIn } = this.allPrograms[index];
      const programTime = moment(startsIn).format('HH:mm');

      const slideTemplate = `
        <div id="slide${index}" class="swiper-slide ${className}">
          ${contentTemplate}

          <div class="delivery-in">
            <div class="loading">
              <div class="loader">
                <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle class="path" fill="none" stroke-width="6"
                          stroke-linecap="round" cx="33" cy="33" r="30"
                  />
                </svg>
              </div>
            </div>
            <div class="label">recebeu</div>
            <div class="value">--</div>
          </div>

          <div class="delivery-out ${isLive ? 'history' : 'entrega'}">
            <div class="loading">
              <div class="loader">
                <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle class="path" fill="none" stroke-width="6"
                          stroke-linecap="round" cx="33" cy="33" r="30"
                  />
                </svg>
              </div>
            </div>
            <div class="label">${isLive ? 'entrega histórica' : 'entrega'}</div>
            <div class="value">--</div>
          </div>

          <div class="slots">
            <div class="info">
              <div class="program_name">${program}</div>
              <div class="program_time">${programTime}</div>
            </div>

            <div class="average">
              <div class="loading">
                <div class="loader">
                  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"
                    />
                  </svg>
                </div>
              </div>
              <div class="label">Média</div>
              <div class="value">--</div>
            </div>

            <div class="kpi">
              <div class="loading">
                <div class="loader">
                  <svg class="spinner" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                    <circle class="path" fill="none" stroke-width="6"
                            stroke-linecap="round" cx="33" cy="33" r="30"
                    />
                  </svg>
                </div>
              </div>
              <div class="label">Média Histórica</div>
              <div class="value">--</div>
            </div>
          </div>
      `;
      this.swiper.appendSlide(slideTemplate);
    },
    initSwiper() {
      this.swiper = new Swiper('.swiper-container', {
        slidesPerView: 3,
        spaceBetween: 8,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // breakpoints: {
        //   2000: {
        //     slidesPerView: 3,
        //   },
        //   1440: {
        //     slidesPerView: 2.8,
        //   },
        //   1340: {
        //     slidesPerView: 2.4,
        //   },
        //   1200: {
        //     slidesPerView: 3,
        //   },
        // },
      });
      this.swiper.on('slideChangeTransitionStart', this.onSlideChange);
    },
    changeCssOfAllSlides(indexSelected) {
      this.allPrograms.forEach((program, index) => {
        const className = this.getSlideClassName(indexSelected, index);
        const element = document.getElementById(`slide${index}`);
        if (element) element.className = `swiper-slide ${className}`;
      });
    },
    onSlideChange() {
      // the last slide can't be active
      if (this.swiper.activeIndex >= this.globoPrograms.length) {
        return this.goToSlide(this.globoPrograms.length - 1);
      }

      if (this.swiper.activeIndex === this.prevIndex) {
        return null;
      }

      this.prevIndex = this.swiper.activeIndex;
      this.indexSelected = this.swiper.activeIndex;
      this.setArrowsSlideCss();
      bffRealtime.cancelRequests('programVision');
      this.setKpis();
      this.$emit('on-selected-program-change', this.selectedProgram);
      return this.changeCssOfAllSlides(this.indexSelected);
    },
    setKpis() {
      this.setKpiSelectedProgramLastMinute();
      // this.setKpiPreviousProgramLastMinute();
      this.setKpiNextProgram();
      this.setKpiSelectedProgram();
      this.setKpiPreviousProgram();
      this.setKpiFutureProgram();
    },
    updateSwipe() {
      this.swiper.update();
    },
    removeAllSlide() {
      this.swiper.removeAllSlides();
    },
    goToSlide(index) {
      this.swiper.slideTo(index);
      if (index === 0) {
        this.$emit('on-selected-program-change', this.selectedProgram);
      }
    },
    setArrowsSlideCss() {
      // next arrow
      const { nextEl } = this.swiper.navigation;
      if (this.isSelectedInCurrentProgram) {
        nextEl.classList.add('disabled');
      } else {
        nextEl.classList.remove('disabled');
      }
      // prev arrow
      const { prevEl } = this.swiper.navigation;
      if (this.isSelectedInFirstProgram) {
        prevEl.classList.add('disabled');
      } else {
        prevEl.classList.remove('disabled');
      }
    },
    async setKpiPreviousProgramLastMinute() {
      this.kpiPreviousProgramLastMinute = null;
      if (!this.previousProgram) return;
      this.kpiPreviousProgramLastMinute = await this.getKpiProgramLastMinute(this.previousProgram);
    },
    async setKpiSelectedProgramLastMinute() {
      this.kpiSelectedProgramLastMinute = null;
      if (!this.selectedProgram) return;
      this.kpiSelectedProgramLastMinute = await this.getKpiProgramLastMinute(this.selectedProgram);
    },
    async setKpiPreviousProgram() {
      this.kpiPreviousProgram = null;
      if (!this.previousProgram) return;
      this.kpiPreviousProgram = await this.getKpiProgram(this.previousProgram);
    },
    async setKpiSelectedProgram() {
      this.kpiSelectedProgram = null;
      if (!this.selectedProgram) return;
      this.kpiSelectedProgram = await this.getKpiProgram(this.selectedProgram);
    },
    async setKpiFutureProgram() {
      // this.kpiFutureProgram = null;
      if (!this.futureProgram) return;
      this.kpiFutureProgram = await this.getKpiProgram(this.futureProgram);
    },
    async setKpiNextProgram() {
      this.kpiNextProgram = null;
      if (!this.nextProgram) return;
      this.kpiNextProgram = await this.getKpiProgram(this.nextProgram);
    },
    async getKpiProgram(program) {
      const params = {
        startDateTime: program.startsIn,
        endDateTime: program.endsIn,
        tvNetworks: 'GLOBO',
        markets: this.marketSelected.id,
        historicMark: this.settings.performanceHistory,
        compiledAvg: true,
      };
      try {
        const { data } = await bffRealtime.getKpiConsolidateByRangeTime(
          params, bffRealtime.tokenRequest('programVision', `kpiConsolidateByRangeTime${program.startsIn}${program.endsIn}`),
        );
        const kpi = (
          data[0]
          && data[0].markets[0]
          && data[0].markets[0].tvNetworksAudiences[0]
        );
        if (!kpi) return null;
        return this.kpiDefaultValue(kpi);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async getKpiProgramLastMinute(program) {
      const params = {
        dateTime: program.endsIn,
        tvNetworks: 'GLOBO',
        markets: this.marketSelected.id,
        historicMark: this.settings.performanceHistory,
      };
      try {
        const { data } = await bffRealtime.getKpiConsolidateByTime(
          params, bffRealtime.tokenRequest('programVision', `kpiConsolidateByTime${program.endsIn}`),
        );
        const kpi = (
          data
          && data.markets[0]
          && data.markets[0].tvNetworksAudiences[0]
        );
        if (!kpi) return null;
        return this.kpiDefaultValue(kpi);
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    updateValues() {
      const { indexSelected } = this;

      const nowElement = document.querySelector('.now');
      if (nowElement) {
        const values = this.setDefaultValue(indexSelected);
        const delivery = nowElement.querySelector('.delivery-in');
        const average = nowElement.querySelector('.average');
        const kpi = nowElement.querySelector('.kpi');
        const history = nowElement.querySelector('.history');
        const entrega = nowElement.querySelector('.entrega');

        anime({
          targets: values,
          lastMinutePreviousProgram:
            this.lastMinutePreviousProgram || values.lastMinutePreviousProgram,
          average: this.getProgramValue(this.selectedProgram) || this.getProgramValue(this.previousProgram),
          diffPoints: this.diffPoints(this.selectedProgram, this.kpiSelectedProgram) || values.diffPoints,
          percentPoints: this.percentPoints(this.selectedProgram, this.kpiSelectedProgram) || values.percentPoints,
          kpiSelectedProgramLastMinute:
            this.kpiSelectedProgramLastMinute || values.kpiSelectedProgramLastMinute,
          lastMinuteSelectedProgram:
            this.lastMinuteSelectedProgram || values.lastMinuteSelectedProgram,
          duration: 500,
          easing: 'linear',
          update: () => {
            if (indexSelected !== this.indexSelected) return;
            delivery.querySelector('.value').innerText = values.lastMinutePreviousProgram.toFixed(2);
            average.querySelector('.value').innerText = values.average.toFixed(2);

            const diffPoints = values.diffPoints.toFixed(2);
            const percentPoints = values.percentPoints.toFixed(0);
            const showPercent = this.singleDefaultValue === 'audience';
            kpi.querySelector('.value').innerText = (showPercent)
              ? `${diffPoints}pts ${percentPoints}%`
              : `${diffPoints}pts`;
            if (history) {
              history.querySelector('.value').innerText = values.kpiSelectedProgramLastMinute.toFixed(2);
            } else {
              entrega.querySelector('.value').innerText = values.lastMinuteSelectedProgram.toFixed(2);
            }
          },
          complete: () => {
            if (indexSelected !== this.indexSelected) return;

            this.setClassList(delivery.querySelector('.value'), {
              won: this.lastMinutePreviousClass === 'won',
              drew: this.lastMinutePreviousClass === 'drew',
              lost: this.lastMinutePreviousClass === 'lost',
            });
            this.setClassList(average, (this.getProgramValue(this.selectedProgram))
              ? this.programStatusCss(this.selectedProgram) : this.programStatusCss(this.previousProgram));
            this.setClassList(kpi, this.kpiStatusCss(this.selectedProgram, this.kpiSelectedProgram));
            if (history) {
              // this.setClassList(history.querySelector('.value'), this.kpiStatusCss(this.selectedProgram, this.kpiSelectedProgramLastMinute));
            } else {
              this.setClassList(entrega.querySelector('.value'), {
                won: this.lastMinuteSelectedClass === 'won',
                drew: this.lastMinuteSelectedClass === 'drew',
                lost: this.lastMinuteSelectedClass === 'lost',
              });
            }

            if (this.selectedProgram) {
              average.querySelector('.loading').style.display = 'none';
            }
            if (this.kpiSelectedProgram) {
              kpi.querySelector('.loading').style.display = 'none';
            }
            if (this.lastMinutePreviousProgram) {
              delivery.querySelector('.loading').style.display = 'none';
            }
            if (history && this.kpiSelectedProgramLastMinute) {
              history.querySelector('.loading').style.display = 'none';
            } else if (entrega && this.lastMinuteSelectedProgram) {
              entrega.querySelector('.loading').style.display = 'none';
            }
          },
          delay: 0,
          round: null,
        });
      }

      const previousIndex = this.indexSelected - 1;
      const prevElement = document.querySelector(`#slide${previousIndex}`);
      if (prevElement) {
        const values = this.setDefaultValue(previousIndex);

        const average = prevElement.querySelector('.average');
        const kpi = prevElement.querySelector('.kpi');

        anime({
          targets: values,
          average: this.getProgramValue(this.previousProgram),
          diffPoints: this.diffPoints(this.previousProgram, this.kpiPreviousProgram) || values.diffPoints,
          percentPoints: this.percentPoints(this.previousProgram, this.kpiPreviousProgram) || values.percentPoints,
          duration: 500,
          easing: 'linear',
          update: () => {
            if (indexSelected !== this.indexSelected) return;

            average.querySelector('.value').innerText = values.average.toFixed(2);

            const diffPoints = values.diffPoints.toFixed(2);
            const percentPoints = values.percentPoints.toFixed(0);
            const showPercent = this.singleDefaultValue === 'audience';
            kpi.querySelector('.value').innerText = (showPercent)
              ? `${diffPoints}pts ${percentPoints}%`
              : `${diffPoints}pts`;
          },
          complete: () => {
            if (indexSelected !== this.indexSelected) return;

            this.setClassList(average, this.programStatusCss(this.previousProgram));
            this.setClassList(kpi, this.kpiStatusCss(this.previousProgram, this.kpiPreviousProgram));

            if (this.previousProgram) {
              average.querySelector('.loading').style.display = 'none';
            }
            if (this.kpiPreviousProgram) {
              kpi.querySelector('.loading').style.display = 'none';
            }
          },
          delay: 0,
          round: null,
        });
      }

      const nextIndex = this.indexSelected + 1;
      const nextElement = document.querySelector(`#slide${nextIndex}`);
      if (nextElement) {
        const values = this.setDefaultValue(nextIndex);

        const average = nextElement.querySelector('.average');
        const kpi = nextElement.querySelector('.kpi');

        const isLive = nextIndex === this.globoPrograms.length && this.isToday;
        if (isLive) {
          // The future program never has the endDate, so we ignore all logic
          anime({
            targets: values,
            kpi: '--', // this.kpiFutureProgram || values.kpi,
            duration: 500,
            easing: 'linear',
            // update: () => {
            //   if (indexSelected !== this.indexSelected) return;
            //   kpi.querySelector('.value').innerText = values.kpi.toFixed(2);
            // },
            complete: () => {
              kpi.querySelector('.loading').style.display = 'none';
              // if (indexSelected !== this.indexSelected) return;
            },
            delay: 0,
            round: null,
          });
        } else {
          anime({
            targets: values,
            average: this.getProgramValue(this.nextProgram),
            diffPoints: this.diffPoints(this.nextProgram, this.kpiNextProgram) || values.diffPoints,
            percentPoints: this.percentPoints(this.nextProgram, this.kpiNextProgram) || values.percentPoints,
            duration: 500,
            easing: 'linear',
            update: () => {
              if (indexSelected !== this.indexSelected) return;
              average.querySelector('.value').innerText = values.average.toFixed(2);
              const diffPoints = values.diffPoints.toFixed(2);
              const percentPoints = values.percentPoints.toFixed(0);
              const showPercent = this.singleDefaultValue === 'audience';
              kpi.querySelector('.value').innerText = (showPercent)
                ? `${diffPoints}pts ${percentPoints}%`
                : `${diffPoints}pts`;
            },
            complete: () => {
              if (indexSelected !== this.indexSelected) return;

              this.setClassList(average, this.programStatusCss(this.nextProgram));
              this.setClassList(kpi, this.kpiStatusCss(this.nextProgram, this.kpiNextProgram));

              if (this.nextProgram) {
                average.querySelector('.loading').style.display = 'none';
              }
              if (this.nextProgram) {
                kpi.querySelector('.loading').style.display = 'none';
              }
            },
            delay: 0,
            round: null,
          });
        }
      }
    },
    setDefaultValue(index) {
      if (!this.values[index]) {
        this.values[index] = {
          lastMinutePreviousProgram: 0,
          average: 0,
          diffPoints: 0,
          percentPoints: 0,
          kpiSelectedProgramLastMinute: 0,
          lastMinuteSelectedProgram: 0,
          kpi: 0,
        };
      }

      return this.values[index];
    },
    setClassList(element, list) {
      if (!element || !list) return;

      Object.keys(list).forEach((className) => {
        if (list[className]) {
          element.classList.add(className);
        } else {
          element.classList.remove(className);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';

// Program Vision
.program_vision {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  width: calc(100% - 388px);
  height: 100%;
  // min-height: 540px;
  background-color: $color-gray-back;

  // Head
  .head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 56px;
    padding: 0 8px;
    margin: 0 0 8px 0;
    background-color: $color-gray-lighter;

    // Title
    .title {
      margin: 0 0 0 8px;
      font-family: $secondary-typo;
      font-weight: 400;
      font-size: 0.72em;
      font-style: italic;
      color: $color-gray-main;
      text-transform: uppercase;

      span {
        font-weight: 500;
        color: $color-gray-dark;
      }
    }

    // Market + Update
    .market,
    .update,
    .tag {
      padding: 4px 8px;
      border: solid 1px $color-secondary;
      border-radius: 4px;

      font-family: $secondary-typo;
      font-weight: 600;
      font-size: 0.64em;
      font-style: italic;
      text-transform: uppercase;
      color: $color-secondary;
    }

    .market {
      flex: 0 auto 0 0;
      margin: 0 8px;
    }

    .update {
      margin: 0;
      background-color: $color-secondary;
      color: $color-gray-lighter;
    }

    .tag {
      margin: 0 auto 0 8px;
      background-color: $color-gray-dark;
      border: solid 1px $color-gray-dark;
      color: $color-gray-lighter;
    }

    .subtitle {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: 240px;
      height: 100%;
      margin: 0;

      font-family: $secondary-typo;
      font-weight: 500;
      font-size: 0.8em;
      font-style: italic;
      text-transform: uppercase;
      color: $color-gray-main;

      .item {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &::before {
          content: "";
          width: 8px;
          height: 8px;
          background-color: $color-gray-light;
          border-radius: 100%;
          margin: 0 8px 0 0;
        }

        // Tratamento de cores
        &.won {

          &::before {
            background-color: $color-status-won;
          }
        }

        &.lost {

          &::before {
            background-color: $color-status-lost;
          }
        }

        &.drew {

          &::before {
            background-color: $color-status-drew;
          }
        }
      }
    }

    // Actions
    .actions {
      margin: 0 0 0 16px;
    }
  }

  // Esteira de Programas
  .esteira {

    &.swiper-container {
      width: 100%;
      height: calc(100% - 64px);
      box-sizing: border-box;
      padding: 0;

      .swiper-button-next,
      .swiper-button-prev {
        background-color: rgba($color-secondary, 0.72);
        background-size: 40%;
        border-radius: 4px;
        box-shadow: 0 8px 24px rgba($color-gray-dark, 0.24);

        &.disabled {
          display: none;
        }
      }

      .swiper-button-next {
        margin-right: 16px;
      }

      .swiper-button-prev {
        margin-left: 16px;
      }

      .swiper-slide {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: auto;
        padding: 8px;
        text-align: center;
        font-size: 2em;
        border-radius: 4px;
        background-color: $color-gray-lighter;

        .now {
          position: absolute;
          top: 16px;
          right: 16px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          padding: 8px;
          border-radius: 4px;
          background-color: $color-gray-darker;
          font-family: $primary-typo;
          font-size: 0.32em;
          text-transform: uppercase;
          color: $color-primary-light;
          box-shadow: 0 4px 16px rgba($color-gray-dark, 0.4);

          i {
            font-size: 1.2em;
            margin: 0 4px 0 0;
            animation: flash 2.4s infinite;
          }
        }

        .placeholder {
          width: 100%;
          height: 100%;

          // Ajustes no contraste
          -webkit-filter: contrast(120%);
          filter: contrast(120%);
        }

        .delivery-in,
        .delivery-out {
          position: absolute;
          display: none;
          flex-wrap: wrap;
          flex-direction: row;
          z-index: 9;
          bottom: 48px;
          width: 96px;
          height: 96px;
          padding: 8px;
          border-radius: 100%;
          background-color: $color-gray-lighter;
          box-shadow: 0 4px 24px rgba($color-gray-dark, 0.24);
          overflow: hidden;

          &.history {
            .label {
              font-size: 0.30em;
              height: 25px;
            }
          }

          .label {
            width: 100%;
            height: 16px;
            margin: auto 0 0 0;
            font-family: $secondary-typo;
            font-weight: 500;
            font-style: italic;
            font-size: 0.32em;
            color: $color-gray-main;
            text-transform: uppercase;
          }

          .value {
            width: 100%;
            height: 24px;
            margin: 0 0 auto 0;
            font-family: $primary-typo;
            font-weight: normal;
            font-size: 0.64em;
            color: $color-gray-dark;

            &.won {
              color: $color-status-won;
            }

            &.drew {
              color: $color-status-drew;
            }

            &.lost {
              color: $color-status-lost;
            }
          }
        }

        .delivery-in {
          left: -54px;
        }

        .delivery-out {
          right: -54px;
        }

        .slots {
          position: absolute;
          bottom: 24px;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;
          min-width: 200px;
          margin: auto 0 0 0;
          opacity: 0.96;
          will-change: bottom opacity;
          transition: bottom 0.4s ease-in-out, opacity 0.4s ease-in-out;
          cursor: pointer;

          &:hover {
            bottom: 48px;
            opacity: 1;
          }

          .info {
            position: relative;
            z-index: 2;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            flex-direction: row;
            width: 100%;
            height: 48px;
            margin: auto 0 0 0;
            border-radius: 8px 8px 0 0;
            background-color: $color-gray-lighter;
            box-shadow: 0 -8px 24px rgba($color-gray-dark, 0.24);


            .program_name,
            .program_time {
              display: block;
              padding: 4px 0;
              width: 100%;
              box-sizing: border-box;
              font-family: $secondary-typo;
              font-size: 0.32em;
              color: $color-gray-dark;
              text-transform: uppercase;
            }

            .program_name {
              min-width: 0;
              margin: 0 auto;
              font-weight: 500;
              font-size: 0.4em;
              font-style: italic;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .program_time {
              justify-content: flex-end;
              font-weight: 600;
              text-align: center;
              color: $color-gray-main;
              border-radius: 0;
              padding: 0;
              margin: -16px 0 0 0;
            }
          }

          .average,
          .kpi {
            position: relative;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 100%;
            background-color: $color-gray-main;
            transition: all 0.4s ease-in-out;

            .label {
              width: 100%;
              margin: 8px 0 0 0;
              font-family: $secondary-typo;
              font-weight: 600;
              font-size: 0.32em;
              font-style: italic;
              letter-spacing: 0.16em;
              color: rgba($color-gray-lighter, 0.8);
              text-transform: uppercase;
            }

            .value {
              width: 100%;
              font-family: $primary-typo;
              font-weight: normal;
              font-size: 1.2em;
              color: $color-gray-lighter;
              text-align: center;
            }
          }

          .average {
            z-index: 1;
            background-color: $color-gray-main;
            height: 80px;
            box-shadow: 0 8px 16px rgba($color-gray-dark, 0.24);

            &:hover {
              box-shadow: 0 8px 24px rgba($color-gray-dark, 0.56);
            }

            .label {
              display: none;
            }

            .value {
              font-size: 1.4em;
            }

            &.won {
              background-color: $color-status-won;
            }

            &.drew {
              background-color: $color-status-drew;
            }

            &.lost {
              background-color: $color-status-lost;
            }
          }

          .kpi {
            z-index: 0;
            height: 56px;
            border-radius: 0 0 8px 8px;
            box-shadow: 0 4px 24px rgba($color-gray-dark, 0.56);

            .label {
              margin: 0;
              font-size: 0.32em;
            }

            .value {
              font-family: $secondary-typo;
              font-size: 0.48em;
              font-weight: 500;
              font-style: italic;
              margin: -24px 0 0 0;
            }

            &.won {
              background-color: darken($color-status-won, 16%);
            }

            &.drew {
              background-color: darken($color-status-drew, 16%);
            }

            &.lost {
              background-color: darken($color-status-lost, 16%);
            }
          }
        }

        // Tratamentos para o cover
        .cover {
          width: 100%;
          // height: calc(100% - 158px);
          height: 100%;
          margin: 0 0 auto 0;
          border-radius: 4px 4px 0 0;

          background-size: cover;
          background-repeat: no-repeat;
          background-position: center top;
          background-color: $color-gray-light;

          transition: all 0.8s ease-in-out;

          // Ajustes no contraste
          -webkit-filter: contrast(110%);
          filter: contrast(110%);
        }

        // Exibir itens de entrega apenas para o slide CURRENT / NOW
        &.now {
          .delivery-in,
          .delivery-out {
            display: flex;
          }
        }

        // Tratamento para placeholder
        .placeholder {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          align-items: center;
          background-image: linear-gradient( 135deg, $color-secondary 16%, $color-primary 88%);
          padding: 16px 24px;
          pointer-events: none;
          border-radius: 4px;
          // height: calc(100% - 180px);
          height: 100%;

          // Brand
          .brand {
            margin: auto 0 auto 0;
            width: 100%;

            img {
              width: 16%;
              opacity: 0.48;
            }
          }

          // Nome + Horário + Feedback
          .program-name,
          .time,
          .feedback {
            width: 100%;
            font-family: $secondary-typo;
            font-weight: 400;
            font-size: 0.8em;
            text-align: center;
            color: $color-gray-lighter;
          }

          .program-name {
            display: inline-block;
            font-size: 0.8em;
            font-weight: 300;
            font-style: italic;
            margin: auto 0 auto 0;
            color: rgba($color-gray-lighter, 0.8);
            // text-shadow: 0 8px 32px rgba($color-gray-darker, 0.4);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .time {
            font-size: 0.64em;
            font-weight: 400;
            font-style: italic;
            margin: -24px 0 auto 0;

            &::before {
              content: "";
              width: 24px;
              height: 2px;
              display: block;
              margin: 24px auto 16px auto;
              background-color: rgba($color-gray-darker, 0.16);
            }
          }

          .feedback {
            font-size: 0.48em;
            font-weight: 400;
            font-style: italic;
            margin: auto 0;
            color: rgba($color-gray-lighter, 0.8);
          }

          .icon {
            width: 100%;
            margin: auto 0 216px 0;

            i {
              color: rgba($color-gray-lighter, 0.64);
            }
          }
        }

        // Tratamentos para Past / Now / Future
        &.past {
          opacity: 0.64;
          filter: grayscale(48%) alpha(opacity=64) contrast(140%);
          -webkit-filter: grayscale(48%) contrast(140%);
        }

        &.future {
          opacity: 0.40;
          filter: grayscale(48%) alpha(opacity=40) contrast(140%);
          -webkit-filter: grayscale(48%) contrast(140%);

          .cover,
          .placeholder {
            opacity: 0.88;
            filter: alpha(opacity=88);
          }

          &.last {
            .placeholder {
              background-image: linear-gradient( 135deg, $color-primary 8%, $color-primary-light 80%);
            }
          }
        }

        &.past, &.future {
          .slots {
            .info {
              .program_name {
                width: 100%;
                padding: 6px 0;
                text-align: center;
              }

              // .program_time {
              //   display: none;
              // }
            }
          }
        }

        &.now {
          opacity: 1;
          filter: alpha(opacity=100);
        }

        // Loading
        .delivery-in,
        .delivery-out,
        .average,
        .kpi {
          .loading {
            z-index: 2;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 0 0 8px 8px;
            background-color: rgba($color-gray-lighter, 0.56);

            &.in {
              animation: fadeIn 0.4s ease-in-out forwards;
            }

            &.out {
              animation: fadeOut 0.4s ease-in-out forwards;
            }

            .loader {
              width: 24px;
              height: 24px;

              .spinner {
                width: 100%;
                height: 100%;
                animation: rotator $duration linear infinite;
              }

              .path {
                stroke-dasharray: $offset;
                stroke-dashoffset: 0;
                transform-origin: center;
                animation:
                  dash $duration ease-in-out infinite,
                  colors ($duration*4) ease-in-out infinite;
              }
            }
          }
        }

        &.past, &.future {
          .average,
          .kpi {
            .loading {
              background: rgba(255, 255, 255, 0.24);
            }
          }
        }

        &.last {
          .average {
            .loading {
              display: none;
            }
          }
        }
        // ------

        &:first-child {
          .delivery-in {
            display: none;
          }
        }
      }
    }
  }
  // -----

  // Timeline
  .timeline {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 524px);
    height: 196px;
    margin: auto auto 0 auto;
    padding: 0 0 16px 0;

    .average,
    .delivery {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      flex-grow: 1;

      // Value
      .value {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        background-color: $color-gray-light;

        font-family: $secondary-typo;
        font-size: 1em;
        font-weight: 600;
        color: $color-gray-lighter;

        transition: all 0.8s ease-in-out;

        // Loading

        $offset: 187;
        $duration: 1.4s;

        .spinner {
          width: 24%;
          height: 24%;
          animation: rotator $duration linear infinite;
        }

        .path {
          stroke-dasharray: $offset;
          stroke-dashoffset: 0;
          transform-origin: center;
          animation:
            dash $duration ease-in-out infinite,
            colors ($duration*4) ease-in-out infinite;
        }
        // -----------------------
        // -----------------------

      }

      // Title + Extra
      .title,
      .extra {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: flex-end;
        width: 96px;
        height: 32px;
        word-break: break-word;
        font-family: $secondary-typo;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: $color-gray-main;

        &:last-child {
          align-items: flex-start;
        }

        // Loading

        $offset: 187;
        $duration: 1.4s;

        .spinner {
          width: 40%;
          height: 40%;
          animation: rotator $duration linear infinite;
        }

        .path {
          stroke-dasharray: $offset;
          stroke-dashoffset: 0;
          transform-origin: center;
          animation:
            dash $duration ease-in-out infinite,
            colorsExtra ($duration*4) ease-in-out infinite;
        }

        @keyframes colorsExtra {
          0%    { stroke: $color-gray-light; }
          25%   { stroke: $color-gray-light; }
          50%   { stroke: rgba($color-gray-light, 0.4); }
          75%   { stroke: $color-gray-light; }
          100%  { stroke: $color-gray-light; }
        }
      }

      // Title
      .title {
        width: 80px;
        font-size: 0.72em;
        margin: 0 0 8px 0;
      }

      // Extra
      .extra {
        font-size: 0.88em;
        font-weight: 600;
        margin: 8px 0 0 0;

        div {
          width: 100%;
          margin: 2px 0;
        }

        &.won {
          color: $color-status-won;
        }

        &.drew {
          color: $color-status-drew;
        }

        &.lost {
          color: $color-status-lost;
        }
      }
    }

    .average {

      // Value
      .value {
        z-index: 1;
        width: 96px;
        height: 96px;
        font-size: 1.2em;

        &::after,
        &::before {
          width: 100%;
        }
      }

      &.past {
        margin: 0;

        // Tratamento de status
        &.won {
          .value {
            background-color: lighten( $color-status-won, 24% );
          }
        }

        &.drew {
          .value {
            background-color: lighten( $color-status-drew, 24% );
          }
        }

        &.lost {
          .value {
            background-color: lighten( $color-status-lost, 24% );
          }
        }
        // ----
      }

      &.future {
        margin: 0;

        // Tratamento de status
        &.won,
        &.drew,
        &.lost {
          .value {
            border: solid 2px $color-gray-line;
            background-color: $color-gray-lighter;
            font-weight: 400;
            color: rgba($color-gray-main, 0.56);
          }
        }
        // ----
      }

      &.now {
        // Tratamento de status
        &.won {
          .value {
            background-color: $color-status-won;
          }
        }

        &.drew {
          .value {
            background-color: $color-status-drew;
          }
        }

        &.lost {
          .value {
            background-color: $color-status-lost;
          }
        }
        // ----
      }

      &.disabled {
        background-color: $color-gray-lighter;
        border: solid 1px $color-gray-lighter;

        &.won,
        &.lost,
        &.drew {
          .value {
            background-color: $color-gray-lighter;
            border: solid 1px $color-gray-lighter;
            visibility: hidden;

            &::after,
            &::before {
              display: none;
            }

            div {
              visibility: hidden;
            }
          }
        }

        .value {
          background-color: $color-gray-lighter;
          border: solid 1px $color-gray-lighter;
          visibility: hidden;

          &::after,
          &::before {
            display: none;
          }

          div {
            visibility: hidden;
          }
        }

        .title,
        .extra {
          visibility: hidden;
        }
      }
    }

    .delivery {

      // Value
      .value {
        z-index: 2;
        width: 64px;
        height: 64px;
        font-size: 1em;

        &::after,
        &::before {
          content: "";
          position: absolute;
          z-index: -2;
          top: 50%;
          width: 56%;
          height: 1px;
          margin: -0.5px 0 0 0;
          background-color: $color-gray-line;
        }

        &::after {
          left: 100%;
        }

        &::before {
          right: 100%;
        }
      }

      &.past {
        margin: 0;

          // Tratamento de status
          &.won {
            .value {
              background-color: darken( $color-status-won, 24% );
            }
          }

          &.drew {
            .value {
              background-color: darken( $color-status-drew, 24% );
            }
          }

          &.lost {
            .value {
              background-color: darken( $color-status-lost, 24% );
            }
          }
          // ----
        }

        &.future {
          margin: 0;

          // Tratamento de status
          &.won,
          &.drew,
          &.lost {
            .value {
              border: solid 2px $color-gray-line;
              background-color: $color-gray-lighter;
              font-weight: 500;
              color: rgba($color-gray-main, 0.56);
            }
          }

          // ----
        }

        &.disabled {
          background-color: $color-gray-lighter;
          border: solid 1px $color-gray-lighter;
          z-index: 1;

          &.won,
          &.lost,
          &.drew {
            .value {
              background-color: $color-gray-lighter;
              border: solid 1px $color-gray-lighter;
              visibility: hidden;

              &::after,
              &::before {
                display: none;
              }

              div {
                visibility: hidden;
              }
            }

            .title,
            .extra {
              visibility: hidden;
            }
          }

          .value {
            background-color: $color-gray-lighter;
            border: solid 1px $color-gray-lighter;

            &::after,
            &::before {
              display: none;
            }

            div {
              visibility: hidden;
            }
          }
        }
      }
    }
}

// LARGE DEVICES
@media (max-width: 1340px) {
  .program_vision {

    .head {
      background-color: $color-gray-lighter;
      border-radius: 4px;

      .subtitle,
      .actions {
        display: none;
      }
    }

    // .esteira {
    //   &.swiper-container {
    //     .swiper-slide {

    //       .slots {
    //         .average {
    //           .value {
    //             font-size: 0.88em;
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

// MEDIUM DEVICES
@media (max-width: 1200px) {

  .program_vision {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .head {
      padding: 0 0 0 8px;
      height: 56px;

      .actions,
      .subtitle {
        display: flex;
      }

      .actions {
        margin: 0 8px 0 16px;
      }
    }

    .esteira {

      &.swiper-container {
        height: calc(100% - 64px);

        .swiper-slide {

          // Past + Future
          &.past,
          &.future {
            .slots {
              .average,
              .kpi {
                .value,
                .label {
                  text-align: center;
                  padding: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  // -----
}

// SMALL DEVICES
@media (max-width: 800px) {

  .program_vision {

    .esteira {

      &.swiper-container {

        .swiper-slide {

          // Past + Future
          &.past,
          &.future,
          &.now {
            .slots {
              min-width: 95px;

              .average {
                .value {
                  font-size: 0.88em;
                }
              }
            }
          }
        }
      }
    }

    .head {

      .actions {
        display: flex;
      }

      .subtitle {
        display: none;
      }
    }
  }
}

// SMALL SCREENS
@media (max-width: 680px) {

    .timeline {
      position: relative;
      width: 100%;
      height: 180px;
      margin: auto auto 0 auto;
      padding: 0 0 16px 0;
      width: 100%;
      box-sizing: border-box;
      background-color: rgba($color-gray-lighter, 0.96);
      box-shadow: none;

      &::after {
        top: 54%;
      }

      &::before {
        display: none;
      }

      // .average {
      //   .value {
      //     width: 80px;
      //     height: 80px;
      //     font-size: 1.4em;
      //   }

      //   &.past,
      //   &.future {
      //     display: none;
      //   }
      // }

      .delivery {

        &.past,
        &.future {
          margin: 0;
        }

        .value {
          width: 48px;
          height: 48px;
          margin: 0 24px;
          font-size: 0.88em;
        }
      }
    }
}

// Controle de Altura
@media (max-height: 800px) and (min-width: 610px) {
  .program_vision {
    .esteira {
      &.swiper-container {
        .swiper-slide {
          .cover {
            background-position: top;
          }
        }
      }
    }
  }
}

// SMARTPHONES
@media (max-width: 600px) {

  .program_vision {

    .head {
      display: none;
    }

    .esteira {

      &.swiper-container {
        margin: 0;
        height: calc(100% - 180px);

        .swiper-slide {

          .now {
            top: 4px;
            right: 4px;
            font-size: 0.08em;
          }

          .placeholder {
            .program-name {
              font-size: 0.8em;
            }

            .time {
              font-size: 0.48em;

              &::before {
                margin: 24px auto;
              }
            }
          }
        }
      }
    }

    .timeline {
      position: relative;
      width: 100%;
      height: 180px;
      margin: auto auto 0 auto;
      padding: 0 0 16px 0;
      width: 100%;
      box-sizing: border-box;
      background-color: rgba($color-gray-lighter, 0.96);
      box-shadow: none;

      &::after {
        top: 54%;
      }

      &::before {
        display: none;
      }

      // .average {
      //   .value {
      //     width: 80px;
      //     height: 80px;
      //     font-size: 1.4em;
      //   }

      //   &.past,
      //   &.future {
      //     display: none;
      //   }
      // }

      .delivery {

        &.past,
        &.future {
          margin: 0;
        }

        .value {
          width: 48px;
          height: 48px;
          margin: 0 24px;
          font-size: 0.88em;
        }
      }
    }
  }
}

// iPhone 5
@media screen and (device-aspect-ratio: 40/71) {

  .program_vision {

      .esteira {

        &.swiper-container {
          margin: 8px 0 0 0;
          height: calc(100% - 188px);
        }
      }

  }

}

</style>
