// initial state
const state = {
  isPageLoading: false, // The loader of entire page
  errorOptions: null,
  innerErrorOptions: null,
  isUserPreferencesOpen: null,
  isMobileNavigation: true,
  isMobileView: window.innerWidth < 600,
  isFullSizeView: false,
};

// getters
const getters = {
  isMobileView: state => state.isMobileView,
  isFullSizeView: state => state.isFullSizeView,
  errorOptions: state => state.errorOptions,
  innerErrorOptions: state => state.innerErrorOptions,
};

// actions
const actions = {
  openUserPreferences({ commit }) {
    commit('OPEN_USER_PREFERENCES');
  },
  closeUserPreferences({ commit }) {
    commit('CLOSE_USER_PREFERENCES');
  },
  showPageLoading({ commit }) {
    commit('SHOW_PAGE_LOADING');
  },
  hidePageLoading({ commit }) {
    commit('HIDE_PAGE_LOADING');
  },
  showError({ commit }, errorOptions) {
    commit('SHOW_ERROR', errorOptions);
  },
  hideError({ commit }) {
    commit('HIDE_ERROR');
    commit('HIDE_INNER_ERROR');
  },
  showInnerError({ commit }, innerErrorOptions) {
    commit('SHOW_INNER_ERROR', innerErrorOptions);
  },
  hideInnerError({ commit }) {
    commit('HIDE_INNER_ERROR');
  },
  showMobileNavigation({ commit }) {
    commit('SHOW_MOBILE_NAVIGATION');
  },
  hideMobileNavigation({ commit }) {
    commit('HIDE_MOBILE_NAVIGATION');
  },
  setFullSizeView({ commit }, isFullSizeView) {
    commit('SET_IS_FULL_SIZE_VIEW', isFullSizeView);
  },
  setMobileView({ commit }, isMobileView) {
    commit('SET_MOBILE_VIEW', isMobileView);
  },
};

// mutations
const mutations = {
  OPEN_USER_PREFERENCES(state) {
    state.isUserPreferencesOpen = true;
  },
  CLOSE_USER_PREFERENCES(state) {
    state.isUserPreferencesOpen = false;
  },
  SHOW_PAGE_LOADING(state) {
    state.isPageLoading = true;
  },
  HIDE_PAGE_LOADING(state) {
    state.isPageLoading = false;
  },
  SHOW_ERROR(state, errorOptions) {
    state.errorOptions = errorOptions;
  },
  HIDE_ERROR(state) {
    state.errorOptions = null;
  },
  SHOW_INNER_ERROR(state, innerErrorOptions) {
    state.innerErrorOptions = innerErrorOptions;
  },
  HIDE_INNER_ERROR(state) {
    state.innerErrorOptions = null;
  },
  SHOW_MOBILE_NAVIGATION(state) {
    state.isMobileNavigation = true;
  },
  HIDE_MOBILE_NAVIGATION(state) {
    state.isMobileNavigation = false;
  },
  SET_IS_FULL_SIZE_VIEW(state, isFullSizeView) {
    state.isFullSizeView = isFullSizeView;
  },
  SET_MOBILE_VIEW(state, isMobileView) {
    state.isMobileView = isMobileView;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
