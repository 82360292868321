/* eslint-disable no-unused-vars */
import '@capacitor/core';
import Vue from 'vue';
import VModal from 'vue-js-modal';

// Google Analytics
import VueGtag from 'vue-gtag';

// CSS imports
import '@/assets/css/main.css';
import 'material-design-icons/iconfont/material-icons.css';
import '@/assets/css/animate.css';
import 'swiper/dist/css/swiper.min.css';

// Routes
import router from '@/router';

// Vuex Store
import store from '@/store';

// Initial Component
import App from '@/App.vue';

// Start Analytics
if (process.env.GOOGLE_ANALYTICS_APP_ID) {
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.GOOGLE_ANALYTICS_APP_ID,
      },
      pageTrackerScreenviewEnabled: true,
      appName: 'RT',
    },
    router
  );
}

Vue.use(VModal, { dialog: true });
// Instantiate application to the DOM
const app = new Vue({
  router,
  el: '#app',
  store,
  render: h => h(App),
});
