import bffRealtime from '@/gateways/bff-realtime';
import moment from 'moment';

// initial state
const state = {
  marketDays: [],
  globoDays: {},
  globoSchedules: [],
  lastProgram: {},
};

// getters
const getters = {
  marketDays: state => state.marketDays,
  globoDays: state => state.globoDays,
  globoSchedules: state => state.globoSchedule,
  mapAllProgramsByDate: (state, _, rootState) => (date) => {
    const globoSchedules = state.globoSchedules.filter(schedule => (
      moment(schedule.endsIn).isAfter(date)
      && moment(schedule.startsIn).isSameOrBefore(date)
    ));
    const rivalsSchedules = rootState.rivalsSchedule.rivalsSchedules
      .filter(schedule => (
        moment(schedule.endsIn).isAfter(date)
        && moment(schedule.startsIn).isSameOrBefore(date)
      ));
    const schedules = [...globoSchedules, ...rivalsSchedules];
    const mapPrograms = schedules.reduce((acc, schedule) => {
      const marketName = schedule.marketName || 'SP1';
      // REMOVE THIS BAND
      const tvNetworkId = schedule.tvNetworkId === 'BAN' ? 'BAND' : schedule.tvNetworkId || 'GLOBO';
      acc[marketName] = acc[marketName] || {};
      acc[marketName][tvNetworkId] = schedule.program;
      return acc;
    }, {});
    return mapPrograms;
  },
  programByDate: (state, _, rootState) => date => state.globoSchedules
    .find(schedule => (
      schedule.marketName === rootState.settings.market.name
        && moment(schedule.endsIn).isAfter(date)
        && moment(schedule.startsIn).isSameOrBefore(date)
    )),
  getNextProgram: (state, _, rootState) => program => state.globoSchedules
    .find(schedule => (
      schedule.marketName === rootState.settings.market.name
        && schedule.numSeqExib === (program.numSeqExib + 1)
        && moment(schedule.endsIn).isSameOrAfter(moment(program.endsIn))
    )),
  // Função responsável por realizar o corte na grade com base na configuração no menu de preferências.
  globoDate: (state, _, rootState) => {
    const { dateOptions } = rootState.settings;
    const { day, startTime, endTime } = dateOptions;
    const selectedDate = state.marketDays[day];

    if (!selectedDate) return { startsIn: null, endsIn: null };

    let { startsIn, endsIn } = state.marketDays[day];
    const isRangeTimeSelected = startTime && endTime;

    if (isRangeTimeSelected) {
      const [startHour, startMinute] = startTime.split(':');
      const [endHour, endMinute] = endTime.split(':');

      startsIn = moment(startsIn).set({ hour: startHour, minute: startMinute, second: 0 });
      endsIn = moment(startsIn).set({ hour: endHour, minute: endMinute, second: 0 });

      const isCurrentStartTimeSelectedOnNextDay = startTime < '04:00';
      const isCurrrentEndTimeSelectedOnNextDay = endTime >= '00:00' && endTime <= '03:59';
      // Adiciona 1 dia para horários do range selecionados entre 00:00 às 03:59 por serem partes da grade do dia prosseguinte.
      if (isCurrrentEndTimeSelectedOnNextDay) endsIn = endsIn.add(1, 'd');
      if (isCurrentStartTimeSelectedOnNextDay) startsIn = startsIn.add(1, 'd');

      endsIn = endsIn.format();
      startsIn = startsIn.format();
    }

    return { startsIn, endsIn };
  },
  programImg: (state, _, rootState) => (program) => {
    const scheduleFound = state.globoSchedules.find(
      schedule => schedule.marketName === program.marketName
        && schedule.program === program.program,
    );
    if (!scheduleFound) return null;
    const { market } = rootState.settings;
    // try get images in this priory order
    return (
      scheduleFound.coversByMarketId[market.id]
      || scheduleFound.coversByMarketId['*']
      || scheduleFound.coversByMarketId.SP1
    );
  },
};

// actions
const actions = {
  async getGloboSchedules({ commit }) {
    const params = {
      startDate: moment().subtract(4, 'day').format(), // -4 - seguranca
      endDate: moment().add(3, 'day').format(), // +3 - seguranca
      sortBy: ['-startsIn'],
    };
    const { data } = await bffRealtime.getGloboSchedules(params);
    commit('SET_GLOBO_SCHEDULES', data.source);
  },
  async getLastProgram({ rootState, commit, rootGetters }) {
    const lastDay = state.marketDays.slice(-1).pop();
    if (!lastDay) return;
    const params = {
      startDate: moment(lastDay.startsIn).format(),
      endDate: moment().format(),
      marketName: rootState.settings.market.name,
      marketId: rootState.settings.market.id,
      scheduleTvNetworkId: rootGetters.scheduleTvNetworkId,
      isCustom: rootGetters.showCustomPrograms,
    };
    const { data } = await bffRealtime.getLastProgramAverage(params);
    commit('SET_LAST_PROGRAM', data);
  },
  // eslint-disable-next-line no-shadow
  getDays({ rootState, commit }) {
    const now = moment();
    const firstPrograms = state.globoSchedules.filter(schedule => (
      schedule.numSeqExib === 1
      && schedule.marketName === rootState.settings.market.name
      && moment(schedule.startsIn).isBefore(now)
    ));

    if (firstPrograms.length === 0) {
      commit('SET_MARKET_DAYS', []);
      return;
    }

    const firstDay = moment(firstPrograms[0].startsIn).endOf('day');

    const marketDays = firstPrograms.map(({ startsIn }, index) => {
      const days = firstDay.diff(startsIn, 'days');
      const dayName = moment(startsIn).format('ddd').toUpperCase();
      const dateFormat = moment(startsIn).format('DD/MM/YYYY').toUpperCase();

      const dFormat = (days)
        ? `D-${days}`
        : 'D';
      const endsIn = (index === 0)
        ? null
        : moment(firstPrograms[index - 1].startsIn).subtract(1, 'minute').format();
      return {
        startsIn,
        endsIn,
        name: `[${dFormat}] ${dayName} ${dateFormat}`,
        dFormat,
        dayName,
        dateFormat,
        id: days,
      };
    });

    commit('SET_MARKET_DAYS', marketDays);
  },
};

// mutations
const mutations = {
  SET_GLOBO_SCHEDULES(state, globoSchedules) {
    state.globoSchedules = globoSchedules;
  },
  SET_LAST_PROGRAM(state, lastProgram) {
    state.lastProgram = lastProgram;
  },
  SET_MARKET_DAYS(state, marketDays) {
    state.marketDays = marketDays;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
